import React, { useState, useRef, Fragment } from "react";
import { View } from "react-native";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Formik } from "formik";
import styled from "styled-components/native";
import { RootStackScreenProps } from "../../navigation/types";
import { ScrollContainer } from "../../components/shared";
import { personalDetailsSchema } from "../../utils/schema";
import FieldText from "../../components/FormFields/FieldText";
import { PersonalDetailsParam } from "../../interfaces/profile";

import WaitingModal from "../../components/Modals/WaitingModal";
import ToastMessage from "../../components/ToastMessages/Toast";
import PageTemplateScreen from "../../components/Templates/PageTemplate";
import { setToastContent } from "../../feature/notification/notificationSlice";
import FieldDropdown from "../../components/FormFields/FieldDropdown";
import {
  incomeRangeList,
  occupationList,
  statesList,
} from "../../utils/constants";
import FieldDatePicker from "../../components/FormFields/FieldDatePicker";
import FieldTextArea from "../../components/FormFields/FieldTextArea";
import { useFocusEffect } from "@react-navigation/native";
import {
  getPersonalDetails,
  updatePersonaDetails,
} from "../../feature/profile/profileSlice";
import { getCurrentUserDetails } from "../../feature/auth/authSlice";

const FieldSection = styled.View`
  margin-top: 24px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  align-items: center;
  flex-direction: column;
`;

const PersonalDetailsScreen = ({
  navigation,
}: RootStackScreenProps<"PersonalDetails">) => {
  const personaDetailsFormRef = useRef(null);
  const dispatch = useAppDispatch();
  const profile = useAppSelector((state) => state.profile);
  const [personalDetails, setPersonalDetails] = useState<
    PersonalDetailsParam[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [activeField, setActiveField] = useState(false);

  const [toastVisible, setToastVisible] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const init = async () => {
    setLoading(true);
    const result = await dispatch(getPersonalDetails());
    if (result?.payload?.currentUser) {
      const userDetails = result.payload.currentUser;
      setPersonalDetails({
        firstName: userDetails?.firstName ?? "",
        middleName: userDetails?.middleName ?? "",
        lastName: userDetails?.lastName ?? "",
        bio: userDetails?.bio ?? "",
        phoneNumber: userDetails?.phoneNumber ?? "",
        birthDate: userDetails?.birthDate ?? "",
        address: userDetails?.address ?? "",
        suburb: userDetails?.suburb ?? "",
        state: userDetails?.state ?? "",
        postCode: userDetails?.postCode ?? "",
        occupation: userDetails?.occupation ?? "",
        incomeRange: userDetails?.incomeRange ?? "",
      });
    } else {
      setToastVisible(true);
      setError("System not available. Please try again later.");
    }
    setLoading(false);
  };

  useFocusEffect(
    React.useCallback(() => {
      init();
    }, [])
  );

  const triggerSubmit = () => {
    if (personaDetailsFormRef.current) {
      personaDetailsFormRef.current.handleSubmit();
    }
  };

  const handleSubmit = async (values: PersonalDetailsParam) => {
    try {
      setLoading(true);
      const result = await dispatch(updatePersonaDetails(values));

      if (result?.meta?.requestStatus === "fulfilled") {
        await dispatch(getCurrentUserDetails());
        const params = {
          errorType: "success",
          content: result?.error?.message || "Personal Details Updated.",
        };
        await dispatch(setToastContent(params));
        navigation.navigate("Profile");
      } else {
        setToastVisible(true);
        setError(
          result?.error?.message ||
            "System not available. Please try again later."
        );
      }
    } catch (error) {
      setToastVisible(true);
      setError("System not available. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const cancelForm = () => {
    setPersonalDetails({
      firstName: profile?.personalDetails?.firstName ?? "",
      middleName: profile?.personalDetails?.middleName ?? "",
      lastName: profile?.personalDetails?.lastName ?? "",
      bio: profile?.personalDetails?.bio ?? "",
      phoneNumber: profile?.personalDetails?.phoneNumber ?? "",
      birthDate: profile?.personalDetails?.birthDate
        ? new Date(profile?.personalDetails?.birthDate)
        : "",
      address: profile?.personalDetails?.address ?? "",
      suburb: profile?.personalDetails?.suburb ?? "",
      state: profile?.personalDetails?.state ?? "",
      postCode: profile?.personalDetails?.postCode ?? "",
      occupation: profile?.personalDetails?.occupation ?? "",
      incomeRange: profile?.personalDetails?.incomeRange ?? "",
    });
  };

  return (
    <Fragment>
      <PageTemplateScreen
        header="Personal details"
        submitButtonEditMode={activeField}
        onSubmit={() => triggerSubmit()}
        submitTitle="Save"
        submitDisabled={loading}
        backButtonIcon="arrow-back"
        keyboardVerticalOffset={-180}
        hideNotifications={true}
        showEditButton={true}
        onEdit={() => {
          setIsEdit(!isEdit);
          if (isEdit === true) {
            cancelForm();
          }
        }}
        hideSubmitButton={isEdit ? false : true}
        isEdit={isEdit}
      >
        <ScrollContainer
          automaticallyAdjustKeyboardInsets={true}
          showsVerticalScrollIndicator={false}
        >
          <View onStartShouldSetResponder={() => true}>
            <Formik
              initialValues={personalDetails}
              validationSchema={personalDetailsSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={handleSubmit}
              innerRef={personaDetailsFormRef}
              enableReinitialize={true}
            >
              {({ values, errors }) => (
                <FieldSection>
                  <FieldText
                    label="First Name"
                    fieldName="firstName"
                    value={values.firstName}
                    onShow={() => {
                      setActiveField(true);
                    }}
                    onHide={() => {
                      setActiveField(false);
                    }}
                    error={errors.firstName}
                    isDisabled={isEdit ? false : true}
                    isEdit={isEdit}
                  />
                  <FieldText
                    label="Middle Name (Optional)"
                    fieldName="middleName"
                    value={values.middleName}
                    onShow={() => {
                      setActiveField(true);
                    }}
                    onHide={() => {
                      setActiveField(false);
                    }}
                    error={errors.middleName}
                    isDisabled={isEdit ? false : true}
                  />
                  <FieldText
                    label="Last Name"
                    fieldName="lastName"
                    value={values.lastName}
                    onShow={() => {
                      setActiveField(true);
                    }}
                    onHide={() => {
                      setActiveField(false);
                    }}
                    error={errors.lastName}
                    isDisabled={isEdit ? false : true}
                  />
                  <FieldTextArea
                    key={"bio"}
                    label="Bio"
                    fieldName="bio"
                    onShow={() => {
                      setActiveField(true);
                    }}
                    onHide={() => {
                      setActiveField(false);
                    }}
                    value={values.bio}
                    error={errors.bio}
                    isDisabled={isEdit ? false : true}
                  />
                  <FieldText
                    key={"phone_number"}
                    label="Phone Number"
                    fieldName="phone_number"
                    value={values.phoneNumber}
                    isDisabled={true}
                    isEdit={isEdit}
                  />
                  <FieldDatePicker
                    label="Date of Birth"
                    fieldName="birthDate"
                    value={values.birthDate ? new Date(values.birthDate) : ""}
                    error={errors.birthDate}
                    isDisabled={isEdit ? false : true}
                  />
                  <FieldText
                    label="Address"
                    fieldName="address"
                    value={values.address}
                    onShow={() => {
                      setActiveField(true);
                    }}
                    onHide={() => {
                      setActiveField(false);
                    }}
                    error={errors.address}
                    isDisabled={isEdit ? false : true}
                  />
                  <FieldText
                    label="Suburb"
                    fieldName="suburb"
                    value={values.suburb}
                    onShow={() => {
                      setActiveField(true);
                    }}
                    onHide={() => {
                      setActiveField(false);
                    }}
                    error={errors.suburb}
                    isDisabled={isEdit ? false : true}
                  />
                  <FieldDropdown
                    label="State"
                    fieldName="state"
                    options={statesList}
                    value={values.state}
                    error={errors.state}
                    isDisabled={isEdit ? false : true}
                  />
                  <FieldText
                    label="Postal Code"
                    fieldName="postCode"
                    value={values.postCode}
                    maxLength={4}
                    onShow={() => {
                      setActiveField(true);
                    }}
                    onHide={() => {
                      setActiveField(false);
                    }}
                    error={errors.postCode}
                    isDisabled={isEdit ? false : true}
                  />
                  <FieldDropdown
                    label="Occupation"
                    fieldName="occupation"
                    options={occupationList}
                    value={values.occupation}
                    error={errors.occupation}
                    isDisabled={isEdit ? false : true}
                  />
                  <FieldDropdown
                    label="Income Range"
                    fieldName="incomeRange"
                    options={incomeRangeList}
                    value={values.incomeRange}
                    error={errors.incomeRange}
                    isDisabled={isEdit ? false : true}
                  />
                </FieldSection>
              )}
            </Formik>
          </View>
        </ScrollContainer>
      </PageTemplateScreen>
      <WaitingModal visible={loading} />
      {toastVisible ? (
        <ToastMessage type="error" onClose={() => setToastVisible(false)}>
          {error}
        </ToastMessage>
      ) : null}
    </Fragment>
  );
};

export default PersonalDetailsScreen;
