import React, { FunctionComponent, useRef, useEffect } from "react";
import {
  Image,
  ImageRequireSource,
  TouchableOpacity,
  Keyboard,
  Text,
  PixelRatio,
} from "react-native";
import styled from "styled-components/native";
import { useFormikContext } from "formik";
import { colors } from "../colors";

import { Col, ErrorText, Row } from "../shared";
import { BodyDefault } from "../Texts/Typography";

const fontScale = PixelRatio.getFontScale();

const Container = styled.View`
  margin-bottom: 12px;
  width: 100%;
`;

const TextInput = styled.TextInput`
  font-family: "DMSans";
  font-size: ${12 / fontScale}px;
  color: ${(props) => (props.isDisabled ? colors.neutral50 : colors.neutral90)};
`;

const TextContainer = styled.TouchableOpacity`
  padding: 13px 10px;
  margin-top: 5px;
  border-color: ${(props) =>
    props.hasErrors ? colors.error : colors.neutral60};
  border-width: 1px;
  border-radius: 8px;
  height: 55px;
  justify-content: center;
`;

const ColText = styled(Col)`
  justify-content: space-evenly;
`;

const BodyDefaultText = styled(BodyDefault)`
  color: ${(props) => (props.isDisabled ? colors.neutral50 : colors.neutral60)};
`;

interface TextInputProps {
  label: string;
  fieldName: string;
  value: string;
  icon?: ImageRequireSource;
  icon2?: ImageRequireSource;
  showIcon2?: boolean;
  error?: string;
  onShow?: (() => void) | undefined;
  onHide?: (() => void) | undefined;
  onIconPress?: (() => void) | undefined;
  onChange?: ((val: string) => void) | undefined;
  secureTextEntry?: boolean;
  keyboardType?: string;
  maxLength?: number;
  isMobileNumber?: boolean;
  children?: () => React.JSX.Element | null | undefined;
  isDisabled?: boolean;
  isEdit?: boolean;
  validateOnChange?: boolean;
}

const FieldText: FunctionComponent<TextInputProps> = (props) => {
  const {
    setFieldValue,
    touched,
    setFieldTouched,
    isSubmitting,
    validateField,
  } = useFormikContext();
  const inputRef = useRef();
  useEffect(() => {
    const keyboardDidHideListener = Keyboard.addListener(
      "keyboardDidHide",
      () => {
        setFieldTouched(props.fieldName, false);
      }
    );
    return () => {
      keyboardDidHideListener.remove();
    };
  }, []);

  useEffect(() => {
    if (!isSubmitting) {
      if (
        touched[props.fieldName] == true &&
        Object.values(touched).filter((item) => item).length == 1
      ) {
        inputRef?.current?.focus();
        if (props.onShow) {
          props.onShow();
        }
      } else {
        if (touched[props.fieldName] == false) {
          if (props.onHide) {
            props.onHide();
          }
          setFieldTouched(props.fieldName, null);
        }
      }
    } else {
      setFieldTouched(props.fieldName, null);
    }
  }, [touched, isSubmitting]);

  const handleChangeText = (e) => {
    if (props.onChange) {
      props.onChange(e);
    }
    setFieldValue(props.fieldName, e);
    if (props.validateOnChange) {
      setTimeout(() => {
        validateField(props.fieldName);
      }, 100);
    }
  };

  return (
    <Container key={`formField-${props.fieldName}`}>
      <TextContainer
        hasErrors={props.error}
        onPress={() => {
          if (inputRef.current) {
            setFieldTouched(props.fieldName, true);
          }
        }}
        disabled={props.isDisabled ? true : false}
      >
        <Row>
          <ColText style={{ width: props.icon ? "90%" : "100%" }}>
            <BodyDefaultText
              style={{
                fontSize:
                  props.value === ""
                    ? touched[props.fieldName]
                      ? 12
                      : 14
                    : 12,
              }}
              isDisabled={props.isDisabled}
            >
              {props.label}
            </BodyDefaultText>
            {props.isMobileNumber && (
              <Text
                style={
                  touched[props.fieldName] || props.value !== ""
                    ? {
                        position: "absolute",
                        paddingTop: 20,
                        fontSize: 11,
                        width: 200,
                      }
                    : { display: "none" }
                }
              >
                +61
              </Text>
            )}
            <TextInput
              style={
                touched[props.fieldName] || props.value !== ""
                  ? {
                      paddingLeft: props.isMobileNumber ? 30 : 0,
                    }
                  : { width: 0, height: 0 }
              }
              ref={inputRef}
              value={props.value}
              autoFocus={false}
              secureTextEntry={props.secureTextEntry}
              maxLength={props.maxLength}
              keyboardType={props.keyboardType}
              onChangeText={(e) => {
                handleChangeText(e);
              }}
              onFocus={() => {
                setFieldTouched(props.fieldName, true);
              }}
              onBlur={() => {
                setFieldTouched(props.fieldName, false);
              }}
              editable={props.isDisabled ? false : true}
              isDisabled={props.isDisabled}
            />
          </ColText>
          <ColText>
            {props.icon && (
              <TouchableOpacity onPress={props.onIconPress}>
                <Image
                  source={!props.showIcon2 ? props.icon : props.icon2}
                  style={{
                    marginTop:
                      touched[props.fieldName] || props.value !== "" ? 15 : 5,
                  }}
                />
              </TouchableOpacity>
            )}
          </ColText>
        </Row>
      </TextContainer>
      {props?.children && props.children()}
      {!!props.error && <ErrorText>{props.error}</ErrorText>}
    </Container>
  );
};

export default FieldText;
