import React, { FunctionComponent, useEffect, useState, useRef } from "react";
import { Keyboard, Platform, Modal, PixelRatio } from "react-native";
import styled from "styled-components/native";
import { useFormikContext } from "formik";
import { colors } from "../colors";

import { Col, ErrorText, Row } from "../shared";
import { BodyDefault } from "../Texts/Typography";
import IconButton from "../Buttons/IconButton";
import { Picker } from "@react-native-picker/picker";
import { MaterialIcons } from "@expo/vector-icons";

const fontScale = PixelRatio.getFontScale();

const Container = styled.View`
  margin-bottom: 12px;
  width: 100%;
`;

const TextContainer = styled.TouchableOpacity`
  padding-horizontal: 10px;
  margin-top: 5px;
  border-color: ${(props) => (props.hasErrors ? colors.error : "#938f96")};
  border-width: 1px;
  border-radius: 8px;
  height: 55px;
  justify-content: center;
`;

const RowText = styled(Row)`
  flex: 1;
`;

const ColText = styled(Col)`
  padding-top: ${(props) => (props.touched ? "4px" : "15px")};
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  flex: 1;
`;

const ColIcon = styled(Col)`
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-shrink: 1;
`;

const BodyDefaultText = styled(BodyDefault)`
  color: ${(props) => (props.isDisabled ? colors.neutral50 : colors.neutral60)};
`;

const ModalContainer = styled(Row)`
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
`;

const ModalView = styled.View`
  width: 90%;
  background-color: ${colors.neutral0};
  border-radius: 20px;
  padding: 35px;
  shadow-color: ${colors.neutral90};
  shadow-opacity: 0.25;
  shadow-radius: 4px;
`;

const SelectedValueText = styled.Text`
  font-family: "DMSans";
  font-size: ${12 / fontScale}px;
  color: ${(props) => (props.isDisabled ? colors.neutral50 : colors.neutral90)};
`;

interface DropdownOptions {
  label: string;
  value: string;
}

interface TextInputProps {
  label: string;
  fieldName: string;
  value: string;
  error?: string;
  onShow?: (() => void) | undefined;
  onHide?: (() => void) | undefined;
  onChange?: ((val: string) => void) | undefined;
  children?: () => React.JSX.Element | null | undefined;
  options: Array<DropdownOptions>;
  isDisabled?: boolean;
}

const FieldDropdown: FunctionComponent<TextInputProps> = (props) => {
  const { setFieldValue, touched, setFieldTouched, isSubmitting } =
    useFormikContext();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const pickerRef = useRef();

  useEffect(() => {
    const keyboardDidHideListener = Keyboard.addListener(
      "keyboardDidHide",
      () => {
        setFieldTouched(props.fieldName, false);
      }
    );
    return () => {
      keyboardDidHideListener.remove();
    };
  }, []);

  useEffect(() => {
    if (!isSubmitting) {
      if (
        touched[props.fieldName] == true &&
        Object.values(touched).filter((item) => item).length == 1 &&
        !dropdownVisible
      ) {
        if (pickerRef.current) {
          pickerRef.current.focus();
        }
        setDropdownVisible(true);
        if (props.onShow) {
          props.onShow();
        }
      } else {
        if (touched[props.fieldName] == false) {
          if (props.onHide) {
            props.onHide();
          }
          setFieldTouched(props.fieldName, null);
        }
      }
    } else {
      setFieldTouched(props.fieldName, null);
    }
  }, [touched, isSubmitting]);

  const handleChangeValue = (e: string) => {
    if (props.onChange) {
      props.onChange(e);
    }
    if (Platform.OS === "android" && pickerRef.current) {
      setDropdownVisible(false);
      setFieldTouched(props.fieldName, false);
      pickerRef.current.blur();
    }
    setFieldValue(props.fieldName, e);
  };

  return (
    <Container key={`formField-${props.fieldName}`}>
      <TextContainer
        hasErrors={props.error}
        onPress={() => {
          setFieldTouched(props.fieldName, true);
        }}
        disabled={props.isDisabled ? true : false}
      >
        <RowText>
          <ColText
            touched={props.value !== "" || touched[props.fieldName]}
            style={{
              width: props.icon ? "90%" : "100%",
            }}
          >
            <BodyDefaultText
              style={{
                fontSize:
                  props.value === ""
                    ? touched[props.fieldName]
                      ? 12
                      : 14
                    : 12,
              }}
              isDisabled={props.isDisabled}
            >
              {props.label}
            </BodyDefaultText>
            <SelectedValueText
              style={
                touched[props.fieldName] || props.value !== ""
                  ? {}
                  : { width: 0, height: 0 }
              }
              isDisabled={props.isDisabled}
            >
              {props.value
                ? props.options.find((e) => e.value === props.value)?.label
                : ""}
            </SelectedValueText>
            {Platform.OS === "ios" ? (
              <Modal
                animationType="slide"
                transparent={false}
                visible={dropdownVisible}
                onRequestClose={() => {
                  setFieldTouched(props.fieldName, false);
                  setDropdownVisible(false);
                }}
              >
                <ModalContainer>
                  <ModalView>
                    <Row
                      style={{ justifyContent: "flex-end", marginBottom: 10 }}
                    >
                      <IconButton
                        onPress={() => {
                          if (props.value === "") {
                            setFieldValue(
                              props.fieldName,
                              props.options[0].value
                            );
                          }
                          setFieldTouched(props.fieldName, false);
                          setDropdownVisible(false);
                        }}
                        name="close"
                        color={colors.primary100}
                        size={18}
                        btnStyles={{
                          backgroundColor: colors.neutral20,
                          borderRadius: 300,
                        }}
                        iconStyles={{
                          padding: 10,
                        }}
                      />
                    </Row>
                    <Picker
                      selectedValue={props.value}
                      onValueChange={(itemValue) =>
                        handleChangeValue(itemValue)
                      }
                      style={{ width: "100%" }}
                    >
                      {props.options.map((item, index) => {
                        return (
                          <Picker.Item
                            key={`DropdownItem-${props.fieldName}-${index}`}
                            label={item.label}
                            value={item.value}
                          />
                        );
                      })}
                    </Picker>
                  </ModalView>
                </ModalContainer>
              </Modal>
            ) : (
              <Picker
                ref={pickerRef}
                selectedValue={props.value}
                width={"100%"}
                onValueChange={(itemValue) => handleChangeValue(itemValue)}
                onBlur={() => {
                  setFieldTouched(props.fieldName, false);
                  setDropdownVisible(false);
                }}
                dropdownIconColor={colors.neutral0}
                style={
                  Platform.OS === "web" && touched[props.fieldName]
                    ? { width: "100%", height: "100%" }
                    : { width: 0, height: 0 }
                }
                mode="dialog"
                enabled={props.isDisabled ? false : true}
              >
                <Picker.Item
                  enabled={false}
                  label={`-- ${props.label} --`}
                  value={null}
                  style={{
                    color: colors.neutral60,
                  }}
                />
                {props.options.map((item, index) => {
                  return (
                    <Picker.Item
                      key={`DropdownItem-${props.fieldName}-${index}`}
                      label={item.label}
                      value={item.value}
                    />
                  );
                })}
              </Picker>
            )}
          </ColText>
          <ColIcon>
            <MaterialIcons
              name={"arrow-drop-down"}
              size={24}
              color={props.isDisabled ? colors.neutral40 : colors.neutral90}
            />
          </ColIcon>
        </RowText>
      </TextContainer>
      {props?.children && props.children()}
      {!!props.error && <ErrorText>{props.error}</ErrorText>}
    </Container>
  );
};

export default FieldDropdown;
