import React, { FunctionComponent } from "react";
import styled from "styled-components/native";
import { format } from "date-fns";
import { colors } from "../colors";
import { Row } from "../shared";
import { BodyLargeBold, BodySmall } from "../Texts/Typography";
import { PAYMENT_STATUS } from "../../utils/enums";
import { formatToOrdinalNumber } from "../../utils/formatNumber";
import { MyActivitiesCardItemProps } from "../../interfaces/plans";

const CardContainer = styled.View`
  width: 100%;
`;

const Divider = styled.View`
  width: 100%;
  border: 0.5px ${colors.neutral30};
  margin: 8px 0px 12px 0px;
`;

const BodySmallText = styled(BodySmall)`
  color: ${colors.neutral60};
`;

const ActivityGroup = styled.View`
  width: 100%;
  margin-bottom: 12px;
`;

const DetailsSection = styled(Row)`
  justify-content: flex-start;
`;

const MyActivityCardItem: FunctionComponent<MyActivitiesCardItemProps> = (
  props
) => {
  return (
    <CardContainer>
      <BodySmallText>
        {format(new Date(props.actionedAt), "dd MMMM yyyy")}
      </BodySmallText>
      <Divider />
      {props.activities.map((activity, index) => (
        <ActivityGroup key={`Activity-${index}`}>
          <Row>
            <DetailsSection>
              <BodyLargeBold
                style={{
                  color:
                    activity.status === PAYMENT_STATUS.FAIL
                      ? colors.error
                      : colors.neutral90,
                }}
              >
                {activity.eventOrder +
                  formatToOrdinalNumber(activity.eventOrder)}{" "}
                {activity.event === "CONTRIBUTION"
                  ? "Payment"
                  : activity.event === "PAYOUT"
                  ? "Payout"
                  : activity.event}
              </BodyLargeBold>
            </DetailsSection>
            <BodyLargeBold
              style={{
                color:
                  activity.status === PAYMENT_STATUS.FAIL
                    ? colors.error
                    : colors.neutral100,
              }}
            >
              {activity.event === "CONTRIBUTION" ? "-$" : "$"}
              {activity.amount}
            </BodyLargeBold>
          </Row>
          <BodySmallText>
            {activity.status === PAYMENT_STATUS.FAIL ? "Missed" : "Successful"}
          </BodySmallText>
        </ActivityGroup>
      ))}
    </CardContainer>
  );
};

export default MyActivityCardItem;
