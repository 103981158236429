import React, { FunctionComponent } from "react";
import styled from "styled-components/native";
import { GestureResponderEvent, StyleProp, ViewStyle } from "react-native";
import { colors } from "../colors";

const ButtonContainer = styled.TouchableOpacity`
  justify-content: center;
  align-items: ${(props) => (props.active ? "flex-end" : "flex-start")};
  height: 32px;
  width: 52px;
  padding: 4px;
  background-color: ${(props) =>
    props.active ? colors.primary70 : colors.neutral40};
  border-radius: 16px;
`;

const Toggler = styled.View`
  height: 24px;
  width: 24px;
  border-radius: 23px;
  background-color: ${(props) =>
    props.active ? colors.neutral0 : colors.neutral0};
`;

interface ButtonProps {
  active: boolean;
  btnStyles?: StyleProp<ViewStyle>;
  onPress: ((event: GestureResponderEvent) => void) | undefined;
}

const ToggleButton: FunctionComponent<ButtonProps> = (props) => {
  return (
    <ButtonContainer
      onPress={props.onPress}
      style={props.btnStyles}
      active={props.active}
    >
      <Toggler active={props.active} />
    </ButtonContainer>
  );
};

export default ToggleButton;
