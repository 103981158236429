import { Auth } from "aws-amplify";
import backendAPI from "../../api/client";
import endpoints from "../../api/endpoints";

const getNotifications = async () => {
  const user = await Auth.currentAuthenticatedUser();
  if (!user) {
    throw new Error("User not logged in");
  }
  const result = await backendAPI.get(
    endpoints.getNotificationsList.replace(":userId", user.attributes.sub)
  );
  return result;
};

const updateNotification = async (notificationId: string) => {
  const user = await Auth.currentAuthenticatedUser();
  if (!user) {
    throw new Error("User not logged in");
  }
  const result = await backendAPI.post(
    endpoints.updateNotification
      .replace(":userId", user.attributes.sub)
      .replace(":notificationId", notificationId)
  );
  return result;
};

const api = {
  getNotifications,
  updateNotification,
};

export default api;
