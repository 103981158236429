import React, { useState, Fragment, useEffect } from "react";
import styled from "styled-components/native";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootStackScreenProps } from "../../../navigation/types";

import { colors } from "../../../components/colors";
import { Col, Container } from "../../../components/shared";
import PageTemplateScreen from "../../../components/Templates/PageTemplate";
import WaitingModal from "../../../components/Modals/WaitingModal";
import ToastMessage from "../../../components/ToastMessages/Toast";
import {
  BodyDefaultBold,
  BodyLargeBold,
  BodySmall,
} from "../../../components/Texts/Typography";
import CheckButton from "../../../components/Buttons/CheckButton";
import { UserAccountType } from "../../../interfaces/plans";
import { MaterialIcons } from "@expo/vector-icons";
import {
  generateConsentURL,
  getUserAccounts,
} from "../../../feature/plan/planSlice";

const SelectAccountContainer = styled(Container)`
  flex: 1;
  background-color: ${colors.neutral0};
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

const ContentSection = styled.View`
  padding: 0px 16px 20px 16px;
  width: 100%;
  align-items: center;
  flex-direction: column;
  flex-basis: auto;
`;

const OuterCard = styled.View`
  display: flex;
  flex: 1;
  border-radius: 16px;
  border: 1px solid ${colors.neutral30};
  padding: 16px;
  width: 100%;
  flex-basis: auto;
`;

const InnerCard = styled.View`
  border-radius: 16px;
  border: 1px solid ${colors.neutral30};
  padding: 16px;
  width: 100%;
`;

const Title = styled(BodyLargeBold)`
  color: ${colors.neutral100};
`;

const SubTitle = styled(BodyDefaultBold)`
  color: ${colors.neutral90};
`;

const LineBreak = styled.View`
  height: 1px;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: ${colors.neutral30};
`;

const AccountItem = styled.TouchableOpacity`
  width: 100%;
  flex-direction: row;
  align-items: center;
  flex: 1;
  flex-basis: auto;
`;
const CheckButtonCol = styled.View`
  padding-right: 12px;
  height: 100%;
  justify-content: center;
`;

const BSBCol = styled(Col)`
  flex: 1;
`;

const AccountNumberCol = styled(Col)`
  flex: 1;
`;

const CreditAndDebitRow = styled.TouchableOpacity`
  flex-direction: row;
  margin-top: 8px;
  padding: 8px 0px;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

const AddAnotherAccountButton = styled.TouchableOpacity`
  flex: 1;
  padding: 16px;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const AnotherAccountText = styled(BodyDefaultBold)`
  color: ${colors.primary100};
`;

const FlexView = styled.View`
  flex: 1;
  flex-basis: auto;
`;

const SelectAccountScreen = ({
  route,
  navigation,
}: RootStackScreenProps<"SelectAccount">) => {
  const {
    selectedPlan,
    reasonToSave,
    customPlanName,
    debitAccount = null,
    creditAccount = null,
    fromProfile = false,
  } = route.params;
  const { myAccounts } = useAppSelector((state) => state.plan);
  const user = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [creditAndDebit, setCreditAndDebit] = useState(true);
  const [selectedDebitAccount, setSelectedDebitAccount] =
    useState<UserAccountType | null>(debitAccount);
  const [selectedCreditAccount, setSelectedCreditAccount] =
    useState<UserAccountType | null>(creditAccount);

  const [loading, setLoading] = useState(false);
  const [toastVisible, setToastVisible] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadAccount = async () => {
      await dispatch(getUserAccounts());
    };
    loadAccount();
  }, []);

  useEffect(() => {
    if (
      creditAccount &&
      debitAccount &&
      creditAccount.accountId !== debitAccount.accountId
    ) {
      setCreditAndDebit(false);
    }
  }, [debitAccount, creditAccount]);

  const addAccount = async () => {
    try {
      setLoading(true);
      const isEmailVerified =
        user?.currentUserCognitoDetails?.attributes?.email_verified === true;

      const params = {
        firstName: user?.currentUserDetails?.firstName,
        lastName: user?.currentUserDetails?.lastName,
        phoneNumber: user?.currentUserDetails?.phoneNumber,
        ...(isEmailVerified && {
          email: user?.currentUserCognitoDetails?.attributes?.email,
        }),
      };
      let result = null;

      if (user.currentUserDetails) {
        result = await dispatch(generateConsentURL(params));
      }
      if (result?.payload?.url) {
        setLoading(false);
        navigation.navigate("ConsentWebview", {
          fromProfile,
          selectedPlan,
          reasonToSave,
          customPlanName,
          consentURL: result?.payload?.url,
          type: "connect",
        });
      } else {
        setToastVisible(true);
        setError(
          result?.error?.message ||
            "System not available. Please try again later."
        );
      }
    } catch (err) {
      setToastVisible(true);
      setError("System not available. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setToastVisible(false);
      setError(null);
      if (selectedCreditAccount && selectedDebitAccount) {
        if (fromProfile) {
          navigation.navigate("AccountSummary", {
            fromProfile,
            debitAccount: selectedDebitAccount,
            creditAccount: selectedCreditAccount,
          });
        } else {
          const params = {
            firstName: user?.currentUserDetails?.firstName,
            lastName: user?.currentUserDetails?.lastName,
            phoneNumber: user?.currentUserDetails?.phoneNumber,
          };
          let result = null;
          if (user.currentUserDetails) {
            result = await dispatch(generateConsentURL(params));
          }
          if (result?.payload?.url) {
            navigation.push("ConsentWebview", {
              fromProfile,
              selectedPlan,
              reasonToSave,
              customPlanName,
              consentURL: result?.payload?.url,
              creditAccount: selectedCreditAccount,
              debitAccount: selectedDebitAccount,
              type: "payment",
            });
          } else {
            setToastVisible(true);
            setError(
              result?.error?.message ||
                "System not available. Please try again later."
            );
          }
        }
      } else {
        setToastVisible(true);
        setError("Please select account for both debit and credit.");
      }
    } catch (error) {
      setToastVisible(true);
      setError("System not available. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const selectAccount = (account: UserAccountType, isCredit = false) => {
    if (creditAndDebit) {
      setSelectedDebitAccount(account);
      setSelectedCreditAccount(account);
    } else if (isCredit) {
      setSelectedCreditAccount(account);
    } else {
      setSelectedDebitAccount(account);
    }
  };

  if (!myAccounts) {
    return null;
  }
  return (
    <SelectAccountContainer>
      <PageTemplateScreen
        header="Select the account"
        subHeader="Please check your debit and credit account carefully."
        onSubmit={() => handleSubmit()}
        submitButtonEditMode={false}
        submitDisabled={
          selectedDebitAccount === null || selectedDebitAccount === null
        }
        submitTitle="Confirm"
        hideNavigationSection={fromProfile ? false : true}
        hideNotifications={true}
      >
        <ContentSection onStartShouldSetResponder={() => true}>
          <OuterCard>
            <InnerCard>
              <Col>
                <Title>{creditAndDebit ? "Accounts" : "You pay from"}</Title>
                <SubTitle>Linked bank account</SubTitle>
                <LineBreak />
                {myAccounts.map((account, index) => {
                  return (
                    <FlexView key={`AccountItemDebit-${index}`}>
                      <AccountItem onPress={() => selectAccount(account)}>
                        <CheckButtonCol>
                          <CheckButton
                            circle={true}
                            active={
                              (selectedDebitAccount &&
                                selectedDebitAccount.accountId ===
                                  account.accountId) ||
                              false
                            }
                            onPress={() => selectAccount(account)}
                          />
                        </CheckButtonCol>
                        <BSBCol>
                          <SubTitle>BSB ({account.bank})</SubTitle>
                          <Title>{account.bsb}</Title>
                        </BSBCol>
                        <AccountNumberCol>
                          <SubTitle>Account number</SubTitle>
                          <Title>{account.accountNumber}</Title>
                        </AccountNumberCol>
                      </AccountItem>
                      {index !== myAccounts.length - 1 ? <LineBreak /> : null}
                    </FlexView>
                  );
                })}
              </Col>
            </InnerCard>
            <CreditAndDebitRow
              onPress={() => {
                setCreditAndDebit(!creditAndDebit);
                setSelectedCreditAccount(selectedDebitAccount);
              }}
            >
              <CheckButton
                active={creditAndDebit}
                onPress={() => {
                  setCreditAndDebit(!creditAndDebit);
                  setSelectedCreditAccount(selectedDebitAccount);
                }}
              />
              <BodySmall>
                Your credit account is same as my debit account
              </BodySmall>
            </CreditAndDebitRow>
            {!creditAndDebit ? (
              <InnerCard style={{ marginTop: 8 }}>
                <Col>
                  <Title>You receive to</Title>
                  <SubTitle>Linked bank account</SubTitle>
                  <LineBreak />
                  {myAccounts.map((account, index) => {
                    return (
                      <Fragment key={`AccountItemCredit-${index}`}>
                        <AccountItem
                          onPress={() => selectAccount(account, true)}
                        >
                          <CheckButtonCol>
                            <CheckButton
                              circle={true}
                              active={
                                (selectedCreditAccount &&
                                  selectedCreditAccount.accountId ===
                                    account.accountId) ||
                                false
                              }
                              onPress={() => selectAccount(account, true)}
                            />
                          </CheckButtonCol>
                          <BSBCol>
                            <SubTitle>BSB ({account.bank})</SubTitle>
                            <Title>{account.bsb}</Title>
                          </BSBCol>
                          <AccountNumberCol>
                            <SubTitle>Account number</SubTitle>
                            <Title>{account.accountNumber}</Title>
                          </AccountNumberCol>
                        </AccountItem>
                        {index !== myAccounts.length - 1 ? <LineBreak /> : null}
                      </Fragment>
                    );
                  })}
                </Col>
              </InnerCard>
            ) : null}
          </OuterCard>
          <AddAnotherAccountButton onPress={addAccount}>
            <MaterialIcons name={"add"} size={16} color={colors.primary100} />
            <AnotherAccountText>Add another account</AnotherAccountText>
          </AddAnotherAccountButton>
        </ContentSection>
      </PageTemplateScreen>
      <WaitingModal visible={loading} />
      {toastVisible ? (
        <ToastMessage type="error" onClose={() => setToastVisible(false)}>
          {error}
        </ToastMessage>
      ) : null}
    </SelectAccountContainer>
  );
};

export default SelectAccountScreen;
