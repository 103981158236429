import * as React from "react";
import { NavigationContainer, DefaultTheme } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import styled from "styled-components/native";
import { Hub } from "aws-amplify";
import { Platform, AppState, AppStateStatus } from "react-native";

import * as Device from "expo-device";
import * as Notifications from "expo-notifications";

import { RootStackParamList, RootTabParamList } from "./types";
import LinkingConfiguration from "./LinkingConfiguration";

import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  disableUserPushToken,
  getCurrentUserCognitoDetails,
  getCurrentUserDetails,
  loadPersistedAuthData,
  refreshCurrentUserSession,
  setFromSignupScreen,
  setVerifyScreenStatus,
  signOutUser,
} from "../feature/auth/authSlice";
import { colors } from "../components/colors";

import MarketingScreen from "../screens/welcome/MarketingScreen";
import {
  SignInScreen,
  ForgotPasswordScreen,
  SignUpScreen,
  VerifyAccountScreen,
  AccountDetailsScreen,
  ResetPasswordScreen,
  SignUpTermsScreen,
  PinSetupScreen,
} from "../screens/auth";
import OnboardingScreen from "../screens/onboarding/OnboardingScreen";
import HomeScreen from "../screens/home/HomeScreen";
import NotificationsScreen from "../screens/notifications/NotificationsScreen";

import HomeActiveIcon from "../assets/icons/home-active-icon.svg";
import HomeInctiveIcon from "../assets/icons/home-inactive-icon.svg";
import PlansActiveIcon from "../assets/icons/plans-active-icon.svg";
import PlansInctiveIcon from "../assets/icons/plans-inactive-icon.svg";
import ProfileActiveIcon from "../assets/icons/profile-active-icon.svg";
import ProfileInctiveIcon from "../assets/icons/profile-inactive-icon.svg";
import { BodySmall } from "../components/Texts/Typography";
import PlansScreen from "../screens/plans/PlansScreen";
import RequestSubmittedScreen from "../components/Pages/RequestSubmitted";
import RequestCustomPlanScreen from "../screens/plans/RequestCustomPlanScreen";
import SignInVerifyEmailScreen from "../screens/auth/SignIn/VerifyEmailScreen";
import ProfileScreen from "../screens/profile/ProfileScreen";
import HelpAndSupportScreen from "../screens/profile/AboutChippit/HelpAndSupport/HelpAndSupportScreen";
import DisclaimerScreen from "../screens/profile/AboutChippit/DisclaimerScreen";
import TermsOfUseScreen from "../screens/profile/AboutChippit/TermsOfUse";
import PrivacyStatementScreen from "../screens/profile/AboutChippit/PrivacyStatementScreen";
import FaqScreen from "../screens/profile/AboutChippit/HelpAndSupport/Faqs/FaqScreen";
import QuestionsScreen from "../screens/profile/AboutChippit/HelpAndSupport/Faqs/QuestionsScreen";
import SplashScreen from "../screens/welcome/SplashScreen";
import TalkToOurTeamScreen from "../screens/profile/AboutChippit/HelpAndSupport/Faqs/TalkToOurTeam";
import PlanDetailsScreen from "../screens/plans/PlanDetailsScreen";
import AdditionalInformationScreen from "../screens/plans/Payment/AdditionalInformation";
import ConnectBankScreen from "../screens/plans/Payment/ConnectBank";
import ConsentWebviewScreen from "../screens/plans/Payment/ConsentWebviewScreen";
import SubmitConsentScreen from "../screens/plans/Payment/SubmitConsent";
import SecurityScreen from "../screens/profile/Security/SecurityScreen";
import ConfirmEmailScreen from "../screens/profile/VerifyEmail/ConfirmEmailScreen";
import SelectAccountScreen from "../screens/plans/Payment/SelectAccount";
import AccountSummaryScreen from "../screens/plans/Payment/AccountSummary";
import ChangePasswordScreen from "../screens/profile/Security/ChangePasswordScreen";
import PersonalDetailsScreen from "../screens/profile/PersonalDetails";
import ConfirmPinScreen from "../screens/profile/Security/ConfirmPinScreen";
import ConfirmPasswordScreen from "../screens/profile/Security/ConfirmPasswordScreen";
import AccountSettingsScreen from "../screens/profile/AccountSettings";
import { loadPersistedPlanData } from "../feature/plan/planSlice";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { differenceInMinutes } from "date-fns";
import { navigationRef } from "./RootNavigation";
import { VERIFY_PIN_SCREEN_STATUS } from "../utils/enums";
import ReferFriendsScreen from "../screens/plans/ReferFriendsScreen";
import NoAccountConnectedScreen from "../components/Pages/NoAccountConnectedScreen";
import ReactivateAccountScreen from "../screens/profile/Security/ReactivateAccountScreen";
import { USER_STATUS } from "../utils/constants";

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

export default function Navigation() {
  return (
    <NavigationContainer
      theme={DefaultTheme}
      linking={LinkingConfiguration}
      ref={navigationRef}
    >
      <RootNavigator />
    </NavigationContainer>
  );
}

const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  const [isAuthenticated, setIsAuthenticated] = React.useState<
    undefined | boolean
  >(undefined);
  const dispatch = useAppDispatch();
  const [isLoggedIn, setIsLoggedIn] = React.useState<unknown | boolean>(
    undefined
  );
  const [fromSignup, setFromSignup] = React.useState<boolean>(false);
  const [verifyPin, setVerifyPin] = React.useState<boolean>(false);
  const [isInactiveAccount, setIsInactiveAccount] =
    React.useState<boolean>(false);
  const auth = useAppSelector((state) => state.auth);

  const appState = React.useRef(AppState.currentState);
  const [inactiveTimestamp, setInactiveTimestamp] = React.useState<Date | null>(
    null
  );

  const notificationListener = React.useRef<Notifications.Subscription>();
  const responseListener = React.useRef<Notifications.Subscription>();

  const registerForPushNotificationsAsync = async () => {
    if (Device.isDevice) {
      if (Platform.OS === "android") {
        Notifications.setNotificationChannelAsync("default", {
          name: "default",
          importance: Notifications.AndroidImportance.MAX,
          vibrationPattern: [0, 250, 250, 250],
          lightColor: "#FF231F7C",
        });
      }
      const { status: existingStatus } =
        await Notifications.getPermissionsAsync();

      let finalStatus = existingStatus;
      if (existingStatus !== "granted") {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== "granted") {
        return;
      }
    }
  };

  React.useEffect(() => {
    if (auth.fromSignupScreen === true && !fromSignup) {
      setFromSignup(true);
    } else if (auth.fromSignupScreen === false && fromSignup) {
      setFromSignup(false);
    }
  }, [auth.fromSignupScreen]);

  React.useEffect(() => {
    if (
      auth.verifyScreenStatus === VERIFY_PIN_SCREEN_STATUS.SHOW &&
      !verifyPin
    ) {
      setVerifyPin(true);
    } else if (
      auth.verifyScreenStatus === VERIFY_PIN_SCREEN_STATUS.HIDE &&
      verifyPin
    ) {
      setVerifyPin(false);
    } else if (auth.verifyScreenStatus === VERIFY_PIN_SCREEN_STATUS.SIGN_IN) {
      if (!isLoggedIn && !verifyPin) {
        dispatch(disableUserPushToken());
        dispatch(signOutUser());
        dispatch(setVerifyScreenStatus(undefined));
        navigationRef.navigate("SignIn", {
          username: auth?.currentUserCognitoDetails?.attributes?.phone_number,
        });
      } else {
        setVerifyPin(false);
        setIsLoggedIn(false);
      }
    }
  }, [auth.verifyScreenStatus, isLoggedIn, verifyPin]);

  React.useEffect(() => {
    if (auth?.currentUserDetails?.status === USER_STATUS.INACTIVE) {
      setIsInactiveAccount(true);
    } else {
      setIsInactiveAccount(false);
    }
  }, [auth?.currentUserDetails?.status]);

  const checkUserAuthenticated = async () => {
    try {
      const authUser = await dispatch(
        getCurrentUserCognitoDetails({ bypassCache: true })
      );

      const isLoggedIn = await AsyncStorage.getItem("isLoggedIn");
      if (authUser.payload) {
        await dispatch(refreshCurrentUserSession());
        await dispatch(getCurrentUserDetails());
        setIsAuthenticated(true);
        setIsLoggedIn(isLoggedIn);
      } else {
        setIsAuthenticated(false);
        if (isLoggedIn) {
          await AsyncStorage.removeItem("isLoggedIn");
        }
        setIsLoggedIn(false);
        setFromSignup(false);
        setVerifyPin(false);
      }
    } catch (e) {
      setIsAuthenticated(false);
      setIsLoggedIn(false);
      setFromSignup(false);
      setVerifyPin(false);
    }
  };

  const loadPersistedData = async () => {
    const result = await dispatch(loadPersistedAuthData());
    if (
      result?.payload?.currentUserCognitoDetails &&
      Object.keys(result.payload.currentUserCognitoDetails).length > 0
    ) {
      if (
        result.payload.currentUserCognitoDetails?.attributes?.[
          "custom:pin_code"
        ]
      ) {
        setVerifyPin(true);
      }
      const isLoggedIn = await AsyncStorage.getItem("isLoggedIn");
      setIsAuthenticated(true);
      setIsLoggedIn(isLoggedIn);
    }
    await dispatch(loadPersistedPlanData());
    checkUserAuthenticated();
  };

  React.useEffect(() => {
    loadPersistedData();
    registerForPushNotificationsAsync();

    notificationListener.current =
      Notifications.addNotificationReceivedListener((notification) => {
      });

    responseListener.current =
      Notifications.addNotificationResponseReceivedListener((response) => {
      });

    return () => {
      if (notificationListener.current) {
        Notifications.removeNotificationSubscription(
          notificationListener.current
        );
      }
      if (responseListener.current) {
        Notifications.removeNotificationSubscription(responseListener.current);
      }
    };
  }, []);

  React.useEffect(() => {
    const listener = (data: { payload: { event: string } }) => {
      if (data.payload.event === "signIn" || data.payload.event == "signOut") {
        checkUserAuthenticated();
        if (data.payload.event == "signOut" && fromSignup) {
          if (auth?.fromSignupScreen === true) {
            dispatch(setFromSignupScreen(false));
          }
        }
      }
    };

    Hub.listen("auth", listener);
    return () => Hub.remove("auth", listener);
  }, []);

  const _handleAppStateChange = React.useCallback(
    (nextAppState: AppStateStatus) => {
      if (
        appState.current.match(/inactive|background/) &&
        nextAppState === "active" &&
        inactiveTimestamp !== null &&
        isAuthenticated &&
        isLoggedIn
      ) {
        const inactiveTimestampValue = new Date(inactiveTimestamp);
        if (differenceInMinutes(new Date(), inactiveTimestampValue) >= 15) {
          setInactiveTimestamp(null);
          dispatch(setVerifyScreenStatus(VERIFY_PIN_SCREEN_STATUS.SHOW));
        }
      } else if (
        appState.current === "active" &&
        nextAppState.match(/inactive|background/) &&
        isAuthenticated &&
        isLoggedIn
      ) {
        const currentTimestamp = new Date();
        setInactiveTimestamp(currentTimestamp);
      }
      appState.current = nextAppState;
    },
    [isAuthenticated, isLoggedIn, inactiveTimestamp]
  );

  React.useEffect(() => {
    const subscription = AppState.addEventListener(
      "change",
      _handleAppStateChange
    );
    return () => {
      subscription.remove();
    };
  }, [_handleAppStateChange]);

  if (isAuthenticated === undefined) {
    return <SplashScreen />;
  }

  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      {/* {verifyPin ? (
        <Stack.Screen
          name="ConfirmPin"
          initialParams={{ fromSignIn: true }}
          component={ConfirmPinScreen}
        />
      ) :  */}
      {isAuthenticated && isLoggedIn ? (
        <Stack.Group>
          {fromSignup ? (
            <Stack.Screen
              name="AccountDetails"
              initialParams={
                fromSignup ? { fieldSet: 1, hideNavigation: true } : undefined
              }
              component={AccountDetailsScreen}
            />
          ) : null}
          {isInactiveAccount && (
            <Stack.Screen
              name="ReactivateAccount"
              component={ReactivateAccountScreen}
            />
          )}
          <Stack.Screen name="BottomNav" component={BottomTabNavigator} />
          <Stack.Screen name="Home" component={HomeScreen} />
          <Stack.Screen name="Notifications" component={NotificationsScreen} />
          <Stack.Screen name="Plans" component={PlansScreen} />
          <Stack.Screen
            name="RequestCustomPlan"
            component={RequestCustomPlanScreen}
          />
          <Stack.Screen
            name="RequestSubmitted"
            component={RequestSubmittedScreen}
          />
          <Stack.Screen name="Profile" component={ProfileScreen} />
          <Stack.Screen
            name="HelpAndSupport"
            component={HelpAndSupportScreen}
          />
          <Stack.Screen name="Disclaimer" component={DisclaimerScreen} />
          <Stack.Screen name="Faq" component={FaqScreen} />
          <Stack.Screen name="Questions" component={QuestionsScreen} />
          <Stack.Screen name="TalkToOurTeam" component={TalkToOurTeamScreen} />
          <Stack.Screen name="PlanDetails" component={PlanDetailsScreen} />
          <Stack.Screen
            name="ConsentWebview"
            component={ConsentWebviewScreen}
          />
          <Stack.Screen name="SubmitConsent" component={SubmitConsentScreen} />
          <Stack.Screen name="SelectAccount" component={SelectAccountScreen} />
          <Stack.Screen
            name="AccountSummary"
            component={AccountSummaryScreen}
          />
          <Stack.Screen name="Security" component={SecurityScreen} />
          <Stack.Screen
            name="ChangePassword"
            component={ChangePasswordScreen}
          />
          <Stack.Screen
            name="PersonalDetails"
            component={PersonalDetailsScreen}
          />
          {fromSignup ? null : (
            <Stack.Screen
              name="AccountDetails"
              component={AccountDetailsScreen}
            />
          )}
          <Stack.Screen name="SignUpTerms" component={SignUpTermsScreen} />
          <Stack.Screen name="PinSetup" component={PinSetupScreen} />
          <Stack.Screen name="Onboarding" component={OnboardingScreen} />
          <Stack.Screen name="ReferFriends" component={ReferFriendsScreen} />
          <Stack.Screen
            name="NoAccountConnected"
            component={NoAccountConnectedScreen}
          />
        </Stack.Group>
      ) : (
        <Stack.Group>
          <Stack.Screen name="Root" component={MarketingScreen} />
          <Stack.Screen name="SignIn" component={SignInScreen} />
          <Stack.Screen
            name="ForgotPassword"
            component={ForgotPasswordScreen}
          />
          <Stack.Screen name="SignUp" component={SignUpScreen} />
          <Stack.Screen name="VerifyAccount" component={VerifyAccountScreen} />
          <Stack.Screen name="ResetPassword" component={ResetPasswordScreen} />
          <Stack.Screen
            name="SignInVerifyEmail"
            component={SignInVerifyEmailScreen}
          />
        </Stack.Group>
      )}

      <Stack.Screen name="TermsOfUse" component={TermsOfUseScreen} />
      <Stack.Screen
        name="PrivacyStatement"
        component={PrivacyStatementScreen}
      />
    </Stack.Navigator>
  );
}

const BottomTab = createBottomTabNavigator<RootTabParamList>();

const HomeStack = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Home" component={HomeScreen} />
      <Stack.Screen name="Notifications" component={NotificationsScreen} />
    </Stack.Navigator>
  );
};

const PlansStack = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Plans" component={PlansScreen} />
      <Stack.Screen name="Notifications" component={NotificationsScreen} />
      <Stack.Screen
        name="RequestCustomPlan"
        component={RequestCustomPlanScreen}
      />
      <Stack.Screen name="PlanDetails" component={PlanDetailsScreen} />
      <Stack.Screen
        name="AdditionalInformation"
        component={AdditionalInformationScreen}
      />
      <Stack.Screen name="ConnectBank" component={ConnectBankScreen} />
    </Stack.Navigator>
  );
};

const ProfileStack = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Profile" component={ProfileScreen} />
      <Stack.Screen name="Notifications" component={NotificationsScreen} />
      <Stack.Screen name="HelpAndSupport" component={HelpAndSupportScreen} />
      <Stack.Screen name="Disclaimer" component={DisclaimerScreen} />
      <Stack.Screen name="TermsOfUse" component={TermsOfUseScreen} />
      <Stack.Screen
        name="PrivacyStatement"
        component={PrivacyStatementScreen}
      />
      <Stack.Screen name="Faq" component={FaqScreen} />
      <Stack.Screen name="Questions" component={QuestionsScreen} />
      <Stack.Screen name="TalkToOurTeam" component={TalkToOurTeamScreen} />
      <Stack.Screen name="Security" component={SecurityScreen} />
      <Stack.Screen name="ConfirmEmail" component={ConfirmEmailScreen} />
      <Stack.Screen name="VerifyEmail" component={VerifyAccountScreen} />
      <Stack.Screen name="ChangePassword" component={ChangePasswordScreen} />
      <Stack.Screen name="PersonalDetails" component={PersonalDetailsScreen} />
      <Stack.Screen name="ConfirmPin" component={ConfirmPinScreen} />
      <Stack.Screen name="PinSetup" component={PinSetupScreen} />
      <Stack.Screen name="ConfirmPassword" component={ConfirmPasswordScreen} />
      <Stack.Screen name="AccountSettings" component={AccountSettingsScreen} />
      <Stack.Screen
        name="NoAccountConnected"
        component={NoAccountConnectedScreen}
      />
    </Stack.Navigator>
  );
};

const BodySmallText = styled(BodySmall)`
  color: ${(props) => (props.focused ? colors.primary100 : colors.neutral60)};
`;

function BottomTabNavigator() {
  return (
    <BottomTab.Navigator
      initialRouteName="HomeTab"
      screenOptions={{
        tabBarActiveTintColor: colors.primary100,
        tabBarStyle: {
          height: Platform.OS === "ios" ? 100 : 88,
        },
        headerShown: false,
      }}
    >
      <BottomTab.Screen
        name="HomeTab"
        component={HomeStack}
        options={{
          tabBarActiveTintColor: "rgba(0,0,0,0)",
          tabBarInactiveTintColor: "rgba(0,0,0,0)",
          tabBarIcon: ({ focused }) => {
            return focused ? (
              <React.Fragment>
                <HomeActiveIcon />
                <BodySmallText focused={focused}>Home</BodySmallText>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <HomeInctiveIcon />
                <BodySmallText>Home</BodySmallText>
              </React.Fragment>
            );
          },
        }}
      />
      <BottomTab.Screen
        name="PlansTab"
        component={PlansStack}
        options={{
          tabBarActiveTintColor: "rgba(0,0,0,0)",
          tabBarInactiveTintColor: "rgba(0,0,0,0)",
          tabBarIcon: ({ focused }) => {
            return focused ? (
              <React.Fragment>
                <PlansActiveIcon />
                <BodySmallText focused={focused}>Plans</BodySmallText>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <PlansInctiveIcon />
                <BodySmallText>Plans</BodySmallText>
              </React.Fragment>
            );
          },
        }}
      />
      <BottomTab.Screen
        name="ProfileTab"
        component={ProfileStack}
        options={{
          tabBarActiveTintColor: "rgba(0,0,0,0)",
          tabBarInactiveTintColor: "rgba(0,0,0,0)",
          tabBarIcon: ({ focused }) => {
            return focused ? (
              <React.Fragment>
                <ProfileActiveIcon />
                <BodySmallText focused={focused}>Profile</BodySmallText>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <ProfileInctiveIcon />
                <BodySmallText>Profile</BodySmallText>
              </React.Fragment>
            );
          },
        }}
      />
    </BottomTab.Navigator>
  );
}
