import React, { FunctionComponent } from "react";
import styled from "styled-components/native";
import { GestureResponderEvent } from "react-native";
import { getInitialName } from "../../utils/formatText";
import { colors } from "../colors";
import { BodyDefaultBold } from "../Texts/Typography";
import CameraIcon from "../../assets/icons/camera-icon.svg";

const ProfileSection = styled.TouchableOpacity`
  background-color: ${(props) =>
    props.notWhiteBg ? colors.neutral20 : colors.neutral0};
  width: ${(props) => (props.size ? props.size : `34`)}px;
  height: ${(props) => (props.size ? props.size : `34`)}px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const ProfilePicture = styled.Image`
  width: 100%;
  height: 100%;
  border-radius: 100px;
`;

const BodyDefaultBoldText = styled(BodyDefaultBold)`
  color: ${colors.primary100};
`;

// types
interface ProfileImageProps {
  onPress?: ((event: GestureResponderEvent) => void) | undefined;
  onLongPress?: ((event: GestureResponderEvent) => void) | undefined;
  imageSrc?: unknown;
  firstName: string;
  lastName: string;
  showCameraIcon?: boolean;
  size?: number;
  notWhiteBg?: boolean;
}

const ProfileImage: FunctionComponent<ProfileImageProps> = (props) => {
  return (
    <ProfileSection
      size={props.size}
      onPress={props.onPress}
      onLongPress={props.onLongPress}
      activeOpacity={!props.onPress && !props.onLongPress ? 1 : undefined}
      notWhiteBg={props.notWhiteBg}
    >
      {props.showCameraIcon ? (
        <CameraIcon />
      ) : props.imageSrc ? (
        <ProfilePicture source={{ uri: props.imageSrc }} />
      ) : (
        props.firstName &&
        props.lastName && (
          <BodyDefaultBoldText>
            {getInitialName(props.firstName, props.lastName)}
          </BodyDefaultBoldText>
        )
      )}
    </ProfileSection>
  );
};

export default ProfileImage;
