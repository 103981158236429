import React, { FunctionComponent } from "react";
import styled from "styled-components/native";
import { TextProps } from "./types";
import { colors } from "../colors";
import { BodySmall } from "./Typography";

const BodySmallText = styled(BodySmall)`
  margin-top: 6px;
  color: ${(props) =>
    props.strength >= 3
      ? colors.correct
      : props.strength == 2
      ? colors.fair
      : colors.error};
`;

const PasswordStrengthText: FunctionComponent<TextProps> = (props) => {
  return (
    <BodySmallText {...props}>
      Password Strength: {props.children}
    </BodySmallText>
  );
};

export default PasswordStrengthText;
