import React from "react";
import { PixelRatio } from "react-native";
import styled from "styled-components/native";
import { RootStackScreenProps } from "../../navigation/types";
import MarketingHeader from "../../assets/marketing_bg.png";
import { Container, ScreenWidth } from "../../components/shared";
import { colors } from "../../components/colors";
import RegularButton from "../../components/Buttons/RegularButton";
import {
  TitleScreen,
  LinkDefault,
  BodySmall,
} from "../../components/Texts/Typography";
import {
  welcomeMarketingHeight,
  welcomeMarketingWidth,
} from "../../utils/constants";

const fontScale = PixelRatio.getFontScale();

const WelcomeContainer = styled(Container)`
  background-color: ${colors.darkBlue};
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

const TopSection = styled.View`
  width: 100%;
  flex: 1;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  margin: 0 auto;
`;

const TopBackground = styled.ImageBackground`
  width: 100%;
  height: 100%;
  padding-top: ${(welcomeMarketingHeight / welcomeMarketingWidth) * 100}%;
  background-color: transparent;
`;

const BottomSection = styled.View`
  width: 100%;
  padding: 32px 16px;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  max-width: 500px;
  margin: 0 auto;
`;

const BottomTextSection = styled.View`
  width: 100%;
`;

const TitleSceenText = styled(TitleScreen)`
  color: ${colors.neutral0};
`;

const BodySmallText = styled(BodySmall)`
  color: ${colors.neutral30};
  margin-top: 24px;
  margin-bottom: 27px;
`;

const LinkDefaultText = styled(LinkDefault)`
  color: ${colors.neutral30};
  font-size: ${12 / fontScale}px;
`;

const MarketingScreen = ({ navigation }: RootStackScreenProps<"Root">) => {
  return (
    <WelcomeContainer>
      <TopSection>
        <TopBackground source={MarketingHeader}></TopBackground>
      </TopSection>
      <BottomSection>
        <BottomTextSection>
          <TitleSceenText>
            Pool money as a {"\n"}group to achieve {"\n"}goals faster
          </TitleSceenText>
          <BodySmallText>
            By proceeding, I accept the{" "}
            <LinkDefaultText
              onPress={() => {
                navigation.navigate("TermsOfUse", {
                  type: "Marketing",
                });
              }}
            >
              Terms of use
            </LinkDefaultText>{" "}
            of Chippit app{"\n"} and confirm that I have read{" "}
            <LinkDefaultText
              onPress={() => {
                navigation.navigate("PrivacyStatement", {
                  type: "Marketing",
                });
              }}
            >
              Privacy Policy
            </LinkDefaultText>
          </BodySmallText>
        </BottomTextSection>
        <RegularButton
          textStyles={{ color: colors.primary100 }}
          btnStyles={{ marginTop: 20, backgroundColor: colors.neutral0 }}
          onPress={() => {
            navigation.navigate("SignUp");
          }}
        >
          Create account
        </RegularButton>
        <RegularButton
          textStyles={{ color: colors.neutral0 }}
          btnStyles={{
            marginTop: 20,
            backgroundColor: colors.primary100,
            borderWidth: 1,
            borderColor: colors.neutral0,
          }}
          onPress={() => {
            navigation.navigate("SignIn");
          }}
        >
          Sign In
        </RegularButton>
      </BottomSection>
    </WelcomeContainer>
  );
};
export default MarketingScreen;
