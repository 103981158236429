import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import notificationAPI from "./notificationAPI";
import {
  NotificationListProps,
  ToastProps,
} from "../../interfaces/notifications";
import { formatNotificationList } from "./helpers/formatNotificationList";
import { NOTIFICATION_STATUS } from "../../utils/enums";

type StateStatus = "idle" | "loading" | "succeeded" | "failed";

interface NotificationState {
  status: StateStatus;
  notificationList: NotificationListProps[];
  hasUnreadNotification: boolean;
  error: string;
  toastContent: ToastProps;
}

const initialState: NotificationState = {
  status: "idle",
  notificationList: [],
  hasUnreadNotification: false,
  error: "",
  toastContent: {
    errorType: "",
    content: "",
  },
};

export const getNotificationList = createAsyncThunk(
  "/get-notification-list",
  async () => {
    try {
      const response = await notificationAPI.getNotifications();
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const updateNotification = createAsyncThunk(
  "/update-notification",
  async (notificationId: string) => {
    try {
      const response = await notificationAPI.updateNotification(notificationId);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const setToastContent = createAsyncThunk(
  "/set-toast-content",
  async (params: ToastProps) => {
    return params;
  }
);

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNotificationList.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getNotificationList.fulfilled, (state, action) => {
      state.status = "succeeded";
      if (action.payload?.data?.notifications) {
        state.hasUnreadNotification = action.payload.data.notifications.some(
          (notification) => notification.status === NOTIFICATION_STATUS.UNREAD
        );
        state.notificationList = formatNotificationList(
          action.payload.data.notifications
        );
      }
    });
    builder.addCase(getNotificationList.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });
    builder.addCase(setToastContent.fulfilled, (state, action) => {
      state.toastContent = action.payload;
    });
    builder.addCase(updateNotification.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(updateNotification.fulfilled, (state) => {
      state.status = "succeeded";
    });
    builder.addCase(updateNotification.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });
  },
});

export default notificationSlice.reducer;
