import React from "react";
// import { View } from "react-native";
import styled from "styled-components/native";
import { colors } from "../../../components/colors";
import { Container } from "../../../components/shared";
import PageTemplateScreen from "../../../components/Templates/PageTemplate";
import { BodyDefault } from "../../../components/Texts/Typography";

const DisclaimerContainer = styled(Container)`
  background-color: ${colors.neutral0};
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

const ContentSection = styled.View`
  width: 100%;
  padding: 0px 16px;
`;

const BodyDefaultText = styled(BodyDefault)`
  color: ${colors.neutral90};
`;

const DisclaimerScreen = () => {
  return (
    <DisclaimerContainer>
      {/* <ScrollContainer
        automaticallyAdjustKeyboardInsets={true}
        showsVerticalScrollIndicator={false}
      >
        <View onStartShouldSetResponder={() => true}> */}
      <PageTemplateScreen header="Disclaimer" hideSubmitButton={true}>
        <ContentSection>
          <BodyDefaultText>
            Chippit operates a platform which enables a group of people to come
            together to jointly save towards a set goal amount. It involves
            members of the group making serial loans to each other and then
            repaying them. By joining a group, you are committing to the agreed
            payment schedule. At the end of each savings round, a payday occurs
            and one member withdraws the payday amount. The savings rounds
            continue until each member has withdrawn the payday amount, and you
            may not leave the group until this time.{"\n\n"} All loans made to
            you are made by other members of your group and not by Chippit.
            Loans provided to you are unregulated under the National Credit Code
            because your fellow group members are not in the business of
            providing credit and do not charge you for the loans they make.
            {"\n\n"}Chippit only provides administrative services to support
            your savings group, including effecting payments in accordance with
            the group’s instructions. Chippit does not provide any regulated
            consumer credit services (because the credit you and your group
            members provide to each other is unregulated) and the platform is
            not any kind of regulated financial service.{"\n\n"}You are
            responsible for making all necessary investigations to confirm that
            the group members will be able to meet the payment schedule. Chippit
            does not conduct any credit checks or other due diligence to confirm
            whether your group members will be able to make payments in
            accordance with the payment schedule. You should be aware that you
            could lose some or all of your money if members of your group do not
            contribute funds in accordance with the payment schedule. In the
            event that you lose some or all of your money, you acknowledge that
            Chippit is not liable to compensate you for your loss, and Chippit
            makes no guarantee, warranty or promise that you will receive any of
            the money owed to you. Chippit does not provide any debt collection
            services and you are responsible for any debt recovery action and
            the associated costs.{"\n\n"}If you do not understand any of the
            Terms and Conditions, how the platform works, or what you are
            required to do, please obtain independent advice or consider whether
            this service is suitable for you.
          </BodyDefaultText>
        </ContentSection>
      </PageTemplateScreen>
      {/* </View>
      </ScrollContainer> */}
    </DisclaimerContainer>
  );
};

export default DisclaimerScreen;
