import React, { Fragment, useState } from "react";
import { View } from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import styled from "styled-components/native";
import { colors } from "../../components/colors";
import { RootStackScreenProps } from "../../navigation/types";
import {
  getMyActivitiesList,
  getMyPlanList,
} from "../../feature/plan/planSlice";
import { MyActivitiesCardItemProps } from "../../interfaces/plans";
import { Col, Container, Row, ScrollContainer } from "../../components/shared";
import PlanTemplateScreen from "../../components/Templates/PlanTemplate";
import {
  BodyLarge,
  BodySmall,
  TitleSection,
} from "../../components/Texts/Typography";
import WaitingModal from "../../components/Modals/WaitingModal";
import MyActivitiesList from "../../components/Lists/MyActivtiesList";
import ToastMessage from "../../components/ToastMessages/Toast";
import { setToastContent } from "../../feature/notification/notificationSlice";
import PayoutOrderList from "../../components/Lists/PayoutOrderList";
import { compareAsc, parse } from "date-fns";

const PlanDetailsContainer = styled(Container)`
  background-color: ${colors.neutral0};
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  max-width: 500px;
  margin: 0 auto;
`;

const ListContainer = styled.View`
  width: 100%;
  gap: 8px;
  padding: 0px 16px;
  margin-bottom: 21px;
`;

const TabButton = styled.TouchableHighlight`
  width: 100%;
  height: 32px;
  padding: 0px 12px;
  border-radius: 64px;
  gap: 10px;
  justify-content: center;
  align-items: center;
`;

const TabLine = styled.View`
  width: 100%;
  height: 8px;
  position: absolute;
  align-self: center;
  background-color: ${colors.neutral30};
`;

const BodySmallText = styled(BodySmall)`
  color: ${colors.neutral0};
`;

const TextRow = styled(Row)`
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 46px;
`;

const TextCol = styled(Col)`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const BodyLargeText = styled(BodyLarge)`
  text-align: center;
`;

const PlanDetailsScreen = ({
  route,
  navigation,
}: RootStackScreenProps<"PlanDetails">) => {
  const details = route?.params?.data;
  const { toastContent } = useAppSelector((state) => state.notification);
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isPlanStarted, setIsPlanStarted] = useState<boolean>(false);
  const [isPlanDelayed, setIsPlanDelayed] = useState<boolean>(false);
  const [activities, setActivities] = useState<unknown>([]);
  const [progresstList, setProgressList] = useState<unknown>([]);
  const [payoutOrders, setPayoutOrders] = useState<unknown>([]);

  const getList = async (tabId: number) => {
    setLoading(true);
    setActiveTab(tabId);

    if (details?.status === "ACTIVE") {
      setIsPlanStarted(true);
    } else {
      setIsPlanStarted(false);
      setActiveTab(0);
    }

    const startDate = parse(details.startDate, "dd MMM yyyy", new Date());

    if (compareAsc(new Date(), startDate) === 1) {
      setIsPlanDelayed(true);
    } else {
      setIsPlanDelayed(false);
    }

    if (tabId === 1) {
      const result = await dispatch(getMyActivitiesList(details?.id));
      const activities = result.payload["activities"];

      //GROUP BY DATE
      const groupByDate = activities?.reduce(
        (activity, index: MyActivitiesCardItemProps) => {
          const date = index.actionedAt;
          if (!activity[date]) {
            activity[date] = [];
          }

          activity[date].push(index);
          return activity;
        },
        {}
      );

      //FORMAT LIST
      const formattedList = Object.keys(groupByDate).map((actionedAt) => {
        return {
          actionedAt,
          activities: groupByDate[actionedAt],
        };
      });

      //SORT LATEST DATES
      const sortedByDateList = formattedList?.sort((a, b) =>
        b.actionedAt.localeCompare(a.actionedAt)
      );

      setActivities(sortedByDateList);

      const list = [...activities];
      setProgressList(list);
    } else {
      const result = await dispatch(getMyPlanList());
      const plans = result.payload;

      // FILTER BY LIST
      const list = plans.filter((plan) => plan.id === details?.id);

      setPayoutOrders(list[0].memberSchedule);
    }
    setLoading(false);
  };

  useFocusEffect(
    React.useCallback(() => {
      getList(1);
    }, [details])
  );

  const closeToast = async () => {
    const params = {
      errorType: "",
      icon: undefined,
      content: "",
    };
    await dispatch(setToastContent(params));
  };

  return (
    <Fragment>
      <PlanDetailsContainer>
        <PlanTemplateScreen
          header=""
          onSubmit={() => {
            navigation.navigate("ReferFriends");
          }}
          submitTitle={
            isPlanStarted === false ? "Refer plans to friends & family" : ""
          }
          submitButtonEditMode={false}
          // showButtonOutline={isPlanStarted === false ? true : false}
          // titleButtonOutline={isPlanStarted === false ? "View rewards" : ""}
          submitButtonOutline={() => {}}
          headerDetails={details}
          headerPlanProgress={progresstList}
          hideSubmitButton={isPlanStarted === false ? false : true}
        >
          <ScrollContainer showsVerticalScrollIndicator={false}>
            <View onStartShouldSetResponder={() => true} style={{ flex: 1 }}>
              <ListContainer>
                <Row>
                  <TabLine />
                  <Col style={{ width: "45%" }}>
                    <TabButton
                      style={{
                        backgroundColor:
                          activeTab === 1
                            ? colors.neutral100
                            : colors.neutral30,
                      }}
                      onPress={() => {
                        getList(1);
                      }}
                      disabled={!isPlanStarted}
                    >
                      <BodySmallText
                        style={{
                          color:
                            activeTab === 1
                              ? colors.neutral0
                              : colors.neutral60,
                        }}
                      >
                        My activities
                      </BodySmallText>
                    </TabButton>
                  </Col>
                  <Col style={{ width: "45%" }}>
                    <TabButton
                      style={{
                        backgroundColor:
                          activeTab === 2
                            ? colors.neutral100
                            : colors.neutral30,
                      }}
                      onPress={() => {
                        getList(2);
                      }}
                      disabled={!isPlanStarted}
                    >
                      <BodySmallText
                        style={{
                          color:
                            activeTab === 2
                              ? colors.neutral0
                              : colors.neutral60,
                        }}
                      >
                        Payout order
                      </BodySmallText>
                    </TabButton>
                  </Col>
                </Row>
              </ListContainer>
              {isPlanStarted ? (
                activeTab === 1 ? (
                  <MyActivitiesList data={activities} loading={loading} />
                ) : (
                  <PayoutOrderList data={payoutOrders} loading={loading} />
                )
              ) : (
                <TextRow>
                  {isPlanDelayed ? (
                    <TextCol>
                      <TitleSection>Almost there!</TitleSection>
                      <BodyLargeText>
                        We are still waiting to connect you to {"\n"}the next
                        available group.{"\n"} You will be notified as soon as a
                        group is ready.{"\n"} You can also invite friends &
                        family to join your plan to get started faster.
                      </BodyLargeText>
                    </TextCol>
                  ) : (
                    <TextCol>
                      <TitleSection>All set!</TitleSection>
                      <BodyLargeText>
                        You will be notified when{"\n"}your group is ready to go
                      </BodyLargeText>
                    </TextCol>
                  )}
                </TextRow>
              )}
            </View>
          </ScrollContainer>
        </PlanTemplateScreen>
      </PlanDetailsContainer>
      <WaitingModal visible={loading} />
      {toastContent.content !== "" ? (
        <ToastMessage
          type={toastContent?.errorType}
          onClose={closeToast}
          icon={toastContent?.icon}
        >
          {toastContent?.content}
        </ToastMessage>
      ) : null}
    </Fragment>
  );
};

export default PlanDetailsScreen;
