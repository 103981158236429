import React from "react";
// import { View } from "react-native";
import styled from "styled-components/native";
import { colors } from "../../../components/colors";
import { Container } from "../../../components/shared";
import PageTemplateScreen from "../../../components/Templates/PageTemplate";
import {
  BodyDefault,
  BodyDefaultBold,
  TitleBody,
} from "../../../components/Texts/Typography";
import { RootStackScreenProps } from "../../../navigation/types";

const TermsOfUseContainer = styled(Container)`
  background-color: ${colors.neutral0};
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

const ContentSection = styled.View`
  width: 100%;
  padding: 0px 16px;
`;

const TitleBodyText = styled(TitleBody)`
  color: ${colors.neutral90};
`;

const BodyDefaultText = styled(BodyDefault)`
  color: ${colors.neutral90};
`;

const BodyDefaultUnderlineText = styled(BodyDefaultText)`
  text-decoration-line: underline;
`;

const BodyDefaultBoldText = styled(BodyDefaultBold)`
  color: ${colors.neutral90};
`;

const TermsOfUseScreen = ({
  route,
}: RootStackScreenProps<"PrivacyStatement">) => {
  const type = route?.params?.type;
  return (
    <TermsOfUseContainer>
      {/* <ScrollContainer
        automaticallyAdjustKeyboardInsets={true}
        showsVerticalScrollIndicator={false}
      >
        <View onStartShouldSetResponder={() => true}> */}
      <PageTemplateScreen
        header="Terms of use"
        hideSubmitButton={true}
        hideNotifications={type === "Marketing" ? true : false}
      >
        <ContentSection>
          <BodyDefaultText>
            Please read these Terms and Conditions carefully as they govern your
            usage of our Platform and the Services we provide to you to support
            your establishment and operation of shared savings. By your
            acceptance of these Terms and Conditions, a contract is created (the
            Terms) between you (you or your) and Chippit Pty Ltd (we, us, our,
            Chippit) effective on and from the Commencement Date, which will
            continue until terminated.{"\n\n"}
            These Terms do not limit or modify our Privacy Policy.
          </BodyDefaultText>
          <TitleBodyText>
            {"\n"}Summary of how the Platform works{"\n"}
          </TitleBodyText>
          <BodyDefaultText>
            (a) Chippit operate the Platform which enables you to establish and
            operate your shared savings. The Platform enables a group of people
            to come together to jointly save towards a set goal amount using a
            series of saving rounds and involves members of the group making
            serial loans to each other and then repaying them. The savings
            rounds continue until each Member has withdrawn the goal amount.
            {"\n\n"}(b) You and at least two but no more than nine family and/or
            friends can form a Savings Group. The first person to create a group
            will be the Group Admin and will invite friends/family to the
            Savings Group. The Group Admin sets the dollar amount that each
            Member will contribute to the Savings Group (Individual Savings) and
            the frequency for Individual Savings. The Chippit application will
            generate the Schedule and the goal amount (Savings Goal) is
            automatically calculated based on the Individual Savings set by the
            Group Admin and the number of Members.{"\n\n"}(c) A savings round
            (Savings Round) is the scheduled recurring interval i.e. on a
            weekly, fortnightly or monthly that members are required to
            contribute their Individual Savings and one member is provided with
            their Payday. For example, if the Schedule set an Individual Savings
            of $100 per Member on a weekly basis, the Savings Group would have
            $800 per week ($100 per week x 8 Members) in their Shared Savings.
            At the end of the Savings Round, this total amount will be provided
            to one Savings Group Member (Payday). The Payday is rotated amongst
            Members in the Savings Group in 8 Savings Rounds (in this case, 8
            weeks) for each Member to receive their Payday.{"\n\n"}(d) A savings
            cycle (Savings Cycle) is the time it takes to complete the same
            number of Savings Rounds as there are Members in that Savings Group,
            such that each Member has withdrawn their Payday. In the above
            example, there are 8 Savings Rounds, which means the Savings Cycle
            is 8 weeks. The Savings Cycle achieves the aggregate Savings Goal
            amount of $6,400, of which $800 has been paid to each Member.
            {"\n\n"}(e) When you receive the Payday, you will receive a
            combination of your own Individual Savings, repayments made to you
            by other Members in respect of Individual Savings you have loaned to
            them, and loans of other Members’ Individual Savings. For example,
            in the above example, if you are the first Member to withdraw the
            Payday amount, $100 of the Payday is your own Individual Savings,
            and the other $700 is Individual Savings contributed by other
            Members and which they are loaning to you. If you are the second
            Member to withdraw the Payday, $100 is your own Individual Savings,
            $100 is a repayment from the Member to whom you loaned funds in the
            first Savings Round, and $600 is Individual Savings contributed by
            other Members and which they are loaning to you. If you are the last
            Member to withdraw the Payday, $100 is your own Individual Savings
            and $700 is repayments from other Members of amounts you loaned to
            them in previous Savings Rounds.{"\n\n"}(g) Once a Savings Cycle is
            complete, you may start a new Savings Cycle with the same Savings
            Group, add or remove Members from the Savings Group, or archive the
            Savings Group. If the Savings Group retains the same Members, the
            Savings Group may agree to another Savings Cycle, with the same
            Schedule but a reversed order for Payday. You can only be a Member
            of up to three (3) Savings Groups at any one time.{"\n\n"}(h)
            Chippit assists you to operate and manage savings and loans
            associated with each Savings Group. For the Services we provide to
            you, we will charge a service fee calculated as 2.5% of the Savings
            Goal paid on your Payday from the Group Savings.{"\n\n"}(i) No loan
            is made to you by Chippit as part, of using the Platform. We make no
            guarantee that you will receive any amounts on your Payday or that
            any amounts you lend to other Members will be repaid. We are unable
            to enforce repayment of any loans you make to other Members, and we
            are not liable in the event they fail to repay you. You may forfeit
            some or all of your money in the event that Members do not
            contribute their Individual Savings. For this reason, it is
            important that if you are Group Admin you only add Members to your
            Savings Group who are personally known to, and trusted by, you, or
            that you only join Savings Groups with Members who are personally
            known to and trusted by, you.
          </BodyDefaultText>
          <TitleBodyText>
            {"\n"}Terms of Use{"\n"}
          </TitleBodyText>
          <BodyDefaultText>
            1. Your Eligibility{"\n\n"}1.1 To be eligible to sign-up to the
            Platform and form a Savings Group, you must:{"\n\n"}(a) be an
            individual who is at least 18 years old;{"\n\n"}(b) be capable of
            entering into a legally binding contract;{"\n\n"}(c) have an
            Australian bank account that enables PayID, which can be linked to
            your Wallet; have a valid and verifiable email address and
            Australian mobile number.{"\n\n"}(d) entering into these Terms, you
            represent and warrant to us that:{"\n\n"}1.2{"\n\n"}(a) you meet the
            eligibility criteria set out in clause 1.1;{"\n\n"}(b) all
            information you provide to us is accurate and complete; and
            {"\n\n"}(c) you will only add persons to your Savings Group(s) who
            are personally known to you.{"\n\n"}2. Services{"\n\n"}We will
            provide the Services to you in accordance with these Terms.
            {"\n\n"}3. Forming Savings Groups{"\n\n"}3.1 You may form a Savings
            Group with at least two other people and not more than nine other
            people.{"\n\n"}3.2 You may archive a Savings Group, or add or remove
            Members, or change the Group Admin, only when a Savings Cycle is not
            currently in progress.{"\n\n"}3.3 You may not be a Member of more
            than three (3) Savings Groups at any one time.{"\n\n"}3.4 The Member
            who initiates a Savings Group will be the Group Admin.{"\n\n"}3.5 By
            joining a Savings Group, you agree that these Terms also form a
            contract between you and all other Members of that Savings Group.
            {"\n\n"}3.6 We will not conduct any credit checks, or other due
            diligence on the ability to repay of any Member of your Savings
            Group. All Members of your Savings Groups must be known to you
            personally and you must make appropriate investigations and
            determine for yourself that Members are able to repay to you any
            amounts that they borrow.
            {"\n\n"}4. The Schedule{"\n\n"}4.1 The Group Admin is responsible
            for setting the:{"\n\n"}(a) start date;{"\n\n"}(b) dollar amount of
            the Individual Savings; and{"\n\n"}(c) frequency of Individual
            Savings.{"\n\n"}5. Savings, Loan Advances and Repayment{"\n\n"}5.1
            As a Member of a Savings Group, you agree to:{"\n\n"}(a) make
            available in your Wallet sufficient funds for each Individual
            Savings according to the Schedule;{"\n\n"}(b) allow the Individual
            Savings you contribute to form part of the Savings Goal and to be
            provided to a Member in accordance with the Schedule either:{"\n\n"}
            (i) as a loan to Members who have not already loaned Individual
            Savings to you; or{"\n\n"} (ii) as a repayment of Individual Savings
            already loaned to you by other Members.{"\n\n"}5.2 You agree to
            accept repayment from Members of amounts you have loaned to them by
            receiving the Savings Goal on your Payday in accordance with the
            Schedule.{"\n\n"}6. Wallet{"\n\n"}6.1 We will maintain a Wallet for:
            {"\n\n"}(a) you to deposit Individual Savings into; and
            {"\n\n"}(b) us to make available the Savings Goal to you on your
            Payday in accordance with the Schedule.{"\n\n"}6.2 We will deduct
            each Individual Savings from funds available in your Wallet as and
            when they fall due according to the Schedule and will add each
            Individual Savings to the funds we hold for the Savings Group.
            {"\n\n"}6.3 Funds held in your Wallet may only be withdrawn to your
            linked bank account using PayID. You may not transfer funds to any
            third-party destination.{"\n\n"}6.4 No interest is paid on any funds
            held in your Wallet.{"\n\n"}6.5 You must ensure your Wallet has a
            sufficient balance when each Individual Savings falls due and
            payable.{"\n\n"}6.6 If you deposit an amount greater than your
            Individual Savings into your Wallet, we will not deduct any surplus
            towards the Savings Goal.
            {"\n\n"}6.7 If, at the end of the Savings Cycle, you have funds
            remaining in your Wallet, we will hold the funds until you withdraw
            them or close your account.{"\n\n"}7. Group Savings
            {"\n\n"}7.1 We will hold and administer all Individual Savings which
            have been contributed to the Group Savings in a bank account opened
            with an Australian deposit-taking institution in our name.{"\n\n"}
            7.2 No interest is payable on any Individual Savings held by us as
            part of the Group Savings.{"\n\n"}7.3 We will pay the Savings Goal
            to the Wallet of the relevant Member of your Savings Group in
            accordance with the Schedule on each Payday.{"\n\n"}7.4 No Member
            has any beneficial entitlement to any Group Savings we hold.{"\n\n"}
            8. Loan Fees and Interest Charges{"\n\n"}
            8.1 Chippit does not provide any loan and accordingly does not
            charge fees or interest in respect of any loan.{"\n\n"}8.2 You may
            not charge any Member fees or interest:{"\n\n"}(a) in respect of
            Individual Savings that you advance to them as a loan; or{"\n\n"}(b)
            any late repayments.{"\n\n"}8.3 No other Member may charge, and you
            agree not to pay, fees or interest:
            {"\n\n"}(a) in respect of Individual Savings that they advance to
            you as a loan; or{"\n\n"}(b) any late repayments.{"\n\n"}9. Service
            Fee{"\n\n"}We charge a service fee for providing the Platform and
            the Services calculated as 2.5% of the Savings Goal paid to a
            Member’s Wallet each Payday. The service fee will be deducted from
            the relevant Member’s Wallet.{"\n\n"}10. Debt Collection And
            Enforcement{"\n\n"}10.1 We do not provide any debt collection or
            enforcement as part of the Services.{"\n\n"}
            10.2 If any Member fails to repay to you any amount they owe to you
            as a result of participation in a Savings Group, you must enforce
            the debt against them directly.{"\n\n"}11. Non-Payment
            {"\n\n"}11.1 If any Member fails to pay any Individual Savings, then
            the Savings Group will be placed on hold and will not be able to
            accept any further Individual Savings into the Group Savings and no
            Savings Goals will be paid out on any Payday as set out in the
            Schedule, until such time as the missed payments are made.{"\n\n"}
            11.2 If you have any difficulty paying any Individual Savings, you
            should contact your Savings Group as soon as possible to discuss
            your circumstances. We are unable to provide any assistance to you
            or your Savings Group to make Individual Savings.{"\n\n"}12. Billing
            and communications
            {"\n\n"}12.1 We will remind you via application notification when
            your Individual Savings payment date is approaching.
            {"\n\n"}12.2 You can check your Individual Savings due date, the
            Schedule, and all the amounts you are required to pay at any time by
            logging in to your home page.{"\n\n"}13. Liability, Indemnity and
            Disclaimers{"\n\n"}13.1 We do not provide any credit, advance of
            funds, loan, deferral of debt, or any other financial accommodation
            to you under these Terms or via your use of the Platform.{"\n\n"}
            13.2 You acknowledge that we do not have any control over, or
            responsibility for:{"\n\n"}(a) the loans provided to you by other
            Members of your Savings Group; or
            {"\n\n"}(b) the repayment to you of any loans you provide to other
            Members of your Savings Group.{"\n\n"}13.3 We give no
            representation, warranty or undertaking in relation to the condition
            or suitability of any loan provided to you by Members of your
            Savings Group.{"\n\n"}13.4 We are not liable for any loss that you
            may suffer, whether in tort, under statute or contract, or in
            equity, arising from the acts or omissions of any third party,
            including but not limited to Members of your Savings Group.{"\n\n"}
            13.5 You agree to indemnify us and our Related Companies, and our
            respective directors, employees, agents and representatives
            (Indemnified Parties) in respect of any loss, cost, damage,
            liability or expense incurred by an Indemnified Party in connection
            with your negligent or wrongful acts or omissions or your breach of
            these Terms, to the extent to which the loss, cost, damage or
            expense was caused by your negligent or wrongful acts or omissions.
            {"\n\n"}13.6 To the maximum extent permitted by law, and subject to
            clause 13.1:
            {"\n\n"}(a) our liability to you for all claims arising out of or in
            connection with these Terms no matter how arising (whether in
            contract, tort (including negligence) or at law) shall not exceed
            the Savings Goal for the Savings Group to which the relevant claim
            relates; and{"\n\n"}(b) neither Chippit or its Related Companies
            will be liable to you for any consequential or indirect loss arising
            out of or in connection with these Terms including, but not limited
            to, loss of profit, loss of revenue, damage to goodwill, loss of
            savings, lost opportunity cost, loss of enjoyment.{"\n\n"}13.7
            Clauses 13.5 and 13.6 are intended to be for the benefit of, and
            enforceable by, any Related Company or Indemnified Party.{"\n\n"}14.
            Default{"\n\n"}14.1 You are in default if:{"\n\n"}(a) you do not pay
            any Individual Savings under these Terms on time; or{"\n\n"}(b) you
            are in breach of any other obligation under these Terms.{"\n\n"}14.2
            If you are in default, and the default is not remedied within three
            days, the Schedule will be paused.{"\n\n"}14.3 If you are in default
            and the default has not been remedied within the period specified in
            a notice from the relevant Member to you stating what the default is
            and giving you a period of at least 30 days to remedy it, then any
            amounts due and payable by you to that Member shall become
            immediately due and payable and that Member may begin enforcement
            proceedings against you in relation to that loan. We will not
            commence such action on their behalf.
            {"\n\n"}14.4 Chippit may ban you from the Platform in the event that
            you have been in default on more than two occasions in a twelve
            month period.{"\n\n"}15. Termination{"\n\n"}15.1 You may terminate
            these Terms when you repay all amounts due and payable to all of
            your Savings Groups in full by closing your account.
            {"\n\n"}15.2 You cannot terminate these Terms while a Savings Cycle
            is in progress.{"\n\n"} 15.3 We may terminate these Terms at any
            time with immediate notice if you are banned under clause 14 or if
            you breach any of the Terms.{"\n\n"}15.4 Notwithstanding any other
            provision of these Terms, Chippit may terminate any portion of these
            Terms at any time. Advance notice of 14 days will be provided via
            the Platform where possible. Where we terminate these Terms during
            any Savings Cycle, we will continue to administer your Savings Group
            until such time as the Savings Cycle is complete but will not be
            able to join any new Savings Groups or start any new Savings Cycles.
            These Terms will then terminate in full once all your Savings Cycles
            are complete.{"\n\n"}16. Assignment{"\n\n"}16.1 We may assign these
            Terms, and our rights under these Terms, to any party and such
            assignment does not require your consent.{"\n\n"} 16.2 You cannot
            transfer or assign your rights under these Terms or any loan without
            our prior written consent, which may be withheld in our sole
            discretion.{"\n\n"}17. Changes to terms{"\n\n"}We may modify, update
            or otherwise alter the terms of these Terms at any time for any
            reason by giving you 28 days’ notice in writing. You acknowledge and
            agree that notice includes these Terms being displayed to you inside
            any part of the Platform.
            {"\n\n"}18. Complaints and Disputes{"\n\n"}18.1 If you wish to raise
            a dispute or submit a complaint to us about the Platform or our
            Services, you should do so by contacting us using the details stated
            on our Website. You agree and acknowledge that we are not the
            provider of any loan to you and we cannot accept complaints about
            the conduct of other Members of your Savings Group.{"\n\n"}18.2 We
            may request additional documentation from you to assist us in
            resolving any complaints or disputes, and you must provide all
            reasonable assistance to us to facilitate resolution of any
            complaints or disputes in a timely manner.
            {"\n\n"}19. General{"\n\n"}19.1 These Terms are governed by the laws
            of New South Wales and the parties agree to submit to the
            non-exclusive jurisdiction of the courts of New South Wales.
            {"\n\n"}19.2 Any failure of ours to exercise or enforce any right or
            provision of these Terms shall not constitute a waiver of such right
            or provision.{"\n\n"}19.3 If any provision of these Terms is found
            by a court of competent jurisdiction to be invalid or unenforceable,
            the other provisions of these Terms will remain in full force and
            effect.{"\n\n"}19.4 All notices or other communications regarding
            these Terms will be provided to you electronically by email or by
            text message to the phone number nominated by you. You consent to
            receive all communications from us in electronic form. Notices sent
            by electronic mail, or through other electronic means, will be
            deemed to have been served on the day after the day on which it is
            sent, unless we receive notification that delivery has failed.
            {"\n\n"}20. Definitions{"\n\n"}In these Terms, unless the context
            indicates otherwise:{"\n\n"}
            <BodyDefaultBoldText>Commencement Date</BodyDefaultBoldText> means
            the date that you agree to these Terms.{"\n\n"}
            <BodyDefaultBoldText>Chippit</BodyDefaultBoldText> means the person
            who leads a Savings Group as designated in the Platform.
            {"\n\n"}
            <BodyDefaultBoldText>Group Savings</BodyDefaultBoldText> means the
            total amount of Individual Savings contributed by the Members
            towards the payment of the Savings Goal on a Payday and which is
            held by Chippit.{"\n\n"}
            <BodyDefaultBoldText>Individual Savings</BodyDefaultBoldText> means
            the dollar amount (as set out in the Schedule) that each Member must
            contribute to the Savings Goal each time a contribution is due and
            payable.{"\n\n"}
            <BodyDefaultBoldText>Member</BodyDefaultBoldText> means any person
            who is a part of a Savings Group. Platform means the Website and the
            Chippit mobile application.{"\n\n"}
            <BodyDefaultBoldText>Payday</BodyDefaultBoldText> means day on which
            the Savings Goal is paid to a Member at the end of a Savings Round
            in accordance with the Schedule.{"\n\n"}
            <BodyDefaultBoldText>Related Companies</BodyDefaultBoldText> has the
            meaning given in section 9 of the Corporations Act 2001 (Cth).
            Savings Cycle means the amount of time it takes to complete a
            Savings Round such that each Member of a Savings Group has had a
            Payday.{"\n\n"}
            <BodyDefaultBoldText>Savings Round</BodyDefaultBoldText> means the
            amount of time it takes to reach the Savings Goal, assuming each
            Member contributes according to the Schedule.{"\n\n"}
            <BodyDefaultBoldText>Savings Goal</BodyDefaultBoldText> means the
            total amount saved each Savings Round.{"\n\n"}
            <BodyDefaultBoldText>Savings Group</BodyDefaultBoldText> means a
            group of Platform users who have joined together to set joint
            savings goals.{"\n\n"}
            <BodyDefaultBoldText>Schedule</BodyDefaultBoldText> means the record
            of the Individual Savings, the Savings Goal, and the times at which
            all payments are due, as recorded in the Platform.
            <BodyDefaultBoldText>Services</BodyDefaultBoldText> means all the
            services we provide to you under these Terms, including but not
            limited to providing the Platform, keeping the Platform up-to-date,
            coordinating and administering the Savings Groups, managing Members’
            details, supporting Group Admin to set Schedules and administer and
            manage Members of Savings Groups, providing Wallet functionality,
            facilitating payments to and from Wallets in respect of Individual
            Savings and the disbursement of Savings Goals, and maintaining the
            bank account.
            {"\n\n"}
            <BodyDefaultBoldText>Terms</BodyDefaultBoldText> means these terms
            and conditions, together with any policies and documents
            incorporated by reference, including our Privacy Policy (set out on
            our Website).{"\n\n"}
            <BodyDefaultBoldText>Wallet</BodyDefaultBoldText> means the
            individual PayID provided to you by Chippit.{"\n\n"}
            <BodyDefaultBoldText>Website</BodyDefaultBoldText> means our website
            located at{" "}
            <BodyDefaultUnderlineText>
              https://chippit.com.au/
            </BodyDefaultUnderlineText>
            .
          </BodyDefaultText>
        </ContentSection>
      </PageTemplateScreen>
      {/* </View>
      </ScrollContainer> */}
    </TermsOfUseContainer>
  );
};

export default TermsOfUseScreen;
