import { Dimensions, ScrollView } from "react-native";
import styled from "styled-components/native";
import { colors } from "./colors";

export const Container = styled.View`
  flex: 1;
  align-items: center;
  background-color: ${colors.neutral0};
`;

export const Row = styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

export const Col = styled.View`
  flex-direction: column;
  justify-content: space-between;
`;

export const ScrollContainer = styled(ScrollView)`
  flex: 1;
  width: 100%;
  display: flex;
  background-color: ${colors.neutral0};
`;

export const ErrorText = styled.Text`
  width: 100%;
  margin-bottom: 12px;
  color: ${colors.error};
`;

export const ScreenWidth = Dimensions.get("window").width;
export const ScreenHeight = Dimensions.get("window").height;
