import React, { FunctionComponent } from "react";
import styled from "styled-components/native";
import { GestureResponderEvent, StyleProp, ViewStyle } from "react-native";
import { colors } from "../colors";
import { MaterialIcons } from "@expo/vector-icons";

const ButtonContainer = styled.TouchableOpacity`
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  background-color: ${(props) =>
    props.active ? colors.primary100 : colors.neutral0};
  border: 1px solid
    ${(props) => (props.active ? colors.primary100 : colors.neutral90)};
  border-radius: ${(props) => (props.circle ? "8px" : "4px")};
`;

interface ButtonProps {
  active: boolean;
  circle?: boolean;
  btnStyles?: StyleProp<ViewStyle>;
  onPress: ((event: GestureResponderEvent) => void) | undefined;
}

const CheckButton: FunctionComponent<ButtonProps> = (props) => {
  return (
    <ButtonContainer
      onPress={props.onPress}
      style={props.btnStyles}
      active={props.active}
      circle={props.circle}
    >
      <MaterialIcons name={"check"} size={14} color={colors.neutral0} />
    </ButtonContainer>
  );
};

export default CheckButton;
