import React, { useEffect, useState } from "react";
import styled from "styled-components/native";
import { View } from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import { useFocusEffect } from "@react-navigation/native";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  disableUserPushToken,
  signOutUser,
} from "../../feature/auth/authSlice";
import { colors } from "../../components/colors";
import { RootStackScreenProps } from "../../navigation/types";
import { Row, Col, Container } from "../../components/shared";
import PageTemplateScreen from "../../components/Templates/PageTemplate";
import {
  BodyDefaultBold,
  BodySmall,
  TitleBody,
} from "../../components/Texts/Typography";
import StatusBadge from "../../components/Badges/StatusBadge";
import ProfileMenuIcon from "../../components/Icons/ProfileMenuIcon";
import QuestionMarkIcon from "../../assets/icons/question_mark.svg";
import ReleaseAlertIcon from "../../assets/icons/release_alert.svg";
import LabProfileIcon from "../../assets/icons/lab_profile.svg";
import ShieldIcon from "../../assets/icons/shield.svg";
import CameraIcon from "../../assets/icons/photo_camera.svg";
import AlertModal from "../../components/Modals/AlertModal";

import { loadImage } from "../../utils/imageUtils/loadImage";
import { formatDateToMonthYear } from "../../utils/formatDateToMonthYear";
import ToastMessage from "../../components/ToastMessages/Toast";
import { setToastContent } from "../../feature/notification/notificationSlice";
import { getUserAccounts } from "../../feature/plan/planSlice";
import MyProfileImage from "../../components/Images/MyProfileImage";

const ProfileContainer = styled(Container)`
  background-color: ${colors.neutral0};
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  flex: 1;
`;

const ContentSection = styled.View`
  width: 100%;
  align-items: center;
  flex: 1;
`;

const DetailSection = styled.View`
  width: 100%;
  padding: 0px 16px;
`;

const ProfileRow = styled(Row)`
  margin-top: 8px;
`;

const BadgeCol = styled(Col)`
  justify-content: center;
`;

const SettingsSection = styled.View`
  width: 100%;
  gap: 24px;
  padding: 0px 16px;
  margin-top: 32px;
`;

const AboutChippitSection = styled(SettingsSection)`
  margin-top: 54px;
  margin-bottom: 63px;
`;

const BodySmallText = styled(BodySmall)`
  color: ${colors.neutral80};
`;

const Divider = styled.View`
  border: 1px solid ${colors.neutral20};
  margin-top: -12px;
`;

const MenuItemButton = styled.TouchableOpacity`
  flex-direction: row;
  justify-content: space-between;
`;

const MenuItemRow = styled(Row)`
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const MenuItemArrow = styled(Col)`
  justify-content: center;
`;

const ProfileImageContainer = styled.View`
  border-radius: 100px;
  overflow: hidden;
  width: 70px;
  height: 70px;
`;

const CameraIconOverlay = styled.View`
  background-color: rgba(0, 0, 0, 0.6);
  width: 70px;
  position: absolute;
  z-index: 999;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 7px;
  padding-top: 7px;
`;

const ProfileScreen = ({ navigation }: RootStackScreenProps<"Profile">) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth);
  const { toastContent } = useAppSelector((state) => state.notification);
  const { myAccounts } = useAppSelector((state) => state.plan);
  const [alertVisible, setAlertVisible] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [profileImage, setProfileImage] = useState<unknown>(null);
  const {
    id: userId,
    firstName = "",
    lastName = "",
    createdAt,
    status = "",
    imageUrl = "",
  } = user.currentUserDetails;
  const [hasBankAccount, setHasBankAccount] = useState(false);

  useFocusEffect(
    React.useCallback(() => {
      loadAccount();
    }, [])
  );

  const loadAccount = async () => {
    await dispatch(getUserAccounts());
  };

  useEffect(() => {
    if (myAccounts.length > 0) {
      setHasBankAccount(true);
    }
  }, [myAccounts]);

  const signOut = async () => {
    setAlertVisible(false);
    await dispatch(disableUserPushToken());
    await dispatch(signOutUser());
  };

  const closeToast = async () => {
    const params = {
      errorType: "",
      icon: undefined,
      content: "",
    };
    await dispatch(setToastContent(params));
  };

  useEffect(() => {
    if (imageUrl === "REMOVED") {
      setProfileImage(null);
    } else if (imageUrl) {
      const fetchProfileImage = async () => {
        try {
          const profileURL = await loadImage(imageUrl);
          setProfileImage(profileURL);
        } catch (err) {
          setProfileImage(null);
        }
      };
      fetchProfileImage();
    }
  }, [imageUrl]);

  return (
    <View style={{ flex: 1 }}>
      <ProfileContainer>
        {/* <ScrollContainer> */}
        {/* <View onStartShouldSetResponder={() => true}> */}
        <PageTemplateScreen
          header="Profile"
          onSubmit={() => {}}
          hideSubmitButton={true}
        >
          <ContentSection>
            <DetailSection>
              <ProfileImageContainer>
                <MyProfileImage
                  userId={userId}
                  firstName={firstName}
                  lastName={lastName}
                  size={70}
                  imageSrc={profileImage}
                />
                <CameraIconOverlay>
                  <CameraIcon />
                </CameraIconOverlay>
              </ProfileImageContainer>
              <ProfileRow>
                <Col>
                  <TitleBody>
                    {firstName} {lastName}
                  </TitleBody>
                  <BodySmall>
                    Saving since{" "}
                    {createdAt ? formatDateToMonthYear(createdAt) : "-"}
                  </BodySmall>
                </Col>
                <BadgeCol>
                  <StatusBadge status={status}>{status}</StatusBadge>
                </BadgeCol>
              </ProfileRow>
            </DetailSection>
            <SettingsSection>
              <BodySmallText>Settings</BodySmallText>
              <Divider />
              <MenuItemButton
                onPress={() => {
                  navigation.navigate("PersonalDetails");
                }}
              >
                <Col>
                  <MenuItemRow>
                    <ProfileMenuIcon name="person-outline" />
                    <BodyDefaultBold>Personal Details</BodyDefaultBold>
                  </MenuItemRow>
                </Col>
                <MenuItemArrow>
                  <MaterialIcons name="arrow-forward-ios" size={16} />
                </MenuItemArrow>
              </MenuItemButton>
              <MenuItemButton
                onPress={() => {
                  navigation.navigate("Security");
                }}
              >
                <Col>
                  <MenuItemRow>
                    <ProfileMenuIcon name="lock-outline" />
                    <BodyDefaultBold>Security</BodyDefaultBold>
                  </MenuItemRow>
                </Col>
                <MenuItemArrow>
                  <MaterialIcons name="arrow-forward-ios" size={16} />
                </MenuItemArrow>
              </MenuItemButton>
              <MenuItemButton
                onPress={() => {
                  navigation.navigate(
                    hasBankAccount ? "AccountSettings" : "NoAccountConnected",
                    {
                      fromProfile: true,
                    }
                  );
                }}
              >
                <Col>
                  <MenuItemRow>
                    <ProfileMenuIcon name="toll" />
                    <BodyDefaultBold>Bank Accounts</BodyDefaultBold>
                  </MenuItemRow>
                </Col>
                <MenuItemArrow>
                  <MaterialIcons name="arrow-forward-ios" size={16} />
                </MenuItemArrow>
              </MenuItemButton>
            </SettingsSection>
            <AboutChippitSection>
              <BodySmallText>About Chippit</BodySmallText>
              <Divider />
              <MenuItemButton
                onPress={() => {
                  navigation.navigate("HelpAndSupport");
                }}
              >
                <Col>
                  <MenuItemRow>
                    <ProfileMenuIcon isSvg={true}>
                      <QuestionMarkIcon />
                    </ProfileMenuIcon>
                    <BodyDefaultBold>Help and support</BodyDefaultBold>
                  </MenuItemRow>
                </Col>
                <MenuItemArrow>
                  <MaterialIcons name="arrow-forward-ios" size={16} />
                </MenuItemArrow>
              </MenuItemButton>
              <MenuItemButton
                onPress={() => {
                  navigation.navigate("Disclaimer");
                }}
              >
                <Col>
                  <MenuItemRow>
                    <ProfileMenuIcon isSvg={true}>
                      <ReleaseAlertIcon />
                    </ProfileMenuIcon>
                    <BodyDefaultBold>Disclaimer</BodyDefaultBold>
                  </MenuItemRow>
                </Col>
                <MenuItemArrow>
                  <MaterialIcons name="arrow-forward-ios" size={16} />
                </MenuItemArrow>
              </MenuItemButton>
              <MenuItemButton
                onPress={() => {
                  navigation.navigate("TermsOfUse");
                }}
              >
                <Col>
                  <MenuItemRow>
                    <ProfileMenuIcon isSvg={true}>
                      <LabProfileIcon />
                    </ProfileMenuIcon>
                    <BodyDefaultBold>Terms of use</BodyDefaultBold>
                  </MenuItemRow>
                </Col>
                <MenuItemArrow>
                  <MaterialIcons name="arrow-forward-ios" size={16} />
                </MenuItemArrow>
              </MenuItemButton>
              <MenuItemButton
                onPress={() => {
                  navigation.navigate("PrivacyStatement");
                }}
              >
                <Col>
                  <MenuItemRow>
                    <ProfileMenuIcon isSvg={true}>
                      <ShieldIcon />
                    </ProfileMenuIcon>
                    <BodyDefaultBold>Privacy statement</BodyDefaultBold>
                  </MenuItemRow>
                </Col>
                <MenuItemArrow>
                  <MaterialIcons name="arrow-forward-ios" size={16} />
                </MenuItemArrow>
              </MenuItemButton>
              <MenuItemButton
                onPress={() => {
                  setAlertVisible(true);
                  setError("Are you sure want to log out from Chippit?");
                }}
              >
                <Col>
                  <MenuItemRow>
                    <ProfileMenuIcon name="logout" />
                    <BodyDefaultBold>Log out</BodyDefaultBold>
                  </MenuItemRow>
                </Col>
                <MenuItemArrow>
                  <MaterialIcons name="arrow-forward-ios" size={16} />
                </MenuItemArrow>
              </MenuItemButton>
            </AboutChippitSection>
          </ContentSection>
        </PageTemplateScreen>
        {/* </View> */}
        {/* </ScrollContainer> */}
      </ProfileContainer>
      <AlertModal
        isVisible={alertVisible}
        title="Log out"
        onOkay={() => {
          signOut();
        }}
        okayTitle="Yes, log out"
        cancelTitle="Cancel"
        onCancel={() => {
          setAlertVisible(false);
          setError(null);
        }}
      >
        {error}
      </AlertModal>
      {toastContent.content !== "" ? (
        <ToastMessage
          type={toastContent?.errorType}
          onClose={closeToast}
          icon={toastContent?.icon}
        >
          {toastContent?.content}
        </ToastMessage>
      ) : null}
    </View>
  );
};

export default ProfileScreen;
