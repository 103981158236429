import React, { FunctionComponent } from "react";
import styled from "styled-components/native";
import { Modal } from "react-native";
import { colors } from "../colors";
import { Col, Row } from "../shared";
import LinkButton from "../Buttons/LinkButton";
import { BodyDefault, TitleBody } from "../Texts/Typography";
import { NotificationItem } from "../../interfaces/notifications";

const ModalContainer = styled(Row)`
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
`;

const ModalView = styled.View`
  margin: 25px 20px 0px 20px;
  background-color: ${colors.neutral0};
  border-radius: 20px;
  padding: 35px 35px 10px 35px;
  shadow-color: ${colors.neutral90};
  shadow-opacity: 0.25;
  shadow-radius: 4px;
  elevation: 5;
  align-items: center;
`;

const ModalBody = styled.View`
  padding: 10px 0px;
  align-items: center;
`;

const FooterColumn = styled(Col)`
  flex: 1;
  align-items: center;
`;

const VerticalDivider = styled.View`
  margin-top: 16px;
  height: 1px;
  width: 100%;
  background-color: ${colors.neutral30};
`;

const BodyDefaultText = styled(BodyDefault)`
  text-align: center;
`;

// types
interface DialogProps {
  isVisible: boolean;
  onCancel: () => void;
  onOkay: () => void;
  notificationData?: NotificationItem;
}

const NotificationDetailsModal: FunctionComponent<DialogProps> = (props) => {
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={props.isVisible}
      onRequestClose={props.onCancel}
    >
      <ModalContainer>
        <ModalView>
          <TitleBody>{props.notificationData?.messageTitle}</TitleBody>
          <ModalBody>
            <BodyDefaultText>{props.notificationData?.message}</BodyDefaultText>
          </ModalBody>
          <Row>
            <VerticalDivider />
          </Row>
          <Row>
            <FooterColumn>
              <LinkButton
                textStyles={{
                  color: colors.primary100,
                  padding: 16,
                  width: "100%",
                  textAlign: "center",
                }}
                btnStyles={{ width: "100%" }}
                onPress={props.onOkay}
              >
                {"Okay"}
              </LinkButton>
            </FooterColumn>
          </Row>
        </ModalView>
      </ModalContainer>
    </Modal>
  );
};

export default NotificationDetailsModal;
