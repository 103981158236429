import React, { useState } from "react";
import styled from "styled-components/native";
import {
  NotificationItem,
  NotificationListProps,
  NotificationsCardItemProps,
} from "../../interfaces/notifications";
import NotificationCardItem from "../Card/NotificationCardItem";
import NotificationDetailsModal from "../Modals/NotificationDetailsModal";
import {
  getNotificationList,
  updateNotification,
} from "../../feature/notification/notificationSlice";
import { useAppDispatch } from "../../app/hooks";
import { NOTIFICATION_STATUS } from "../../utils/enums";

const CardContainer = styled.View`
  width: 100%;
  padding: 0px 16px;
  gap: 24px;
`;

const NotificationsList: React.FC<NotificationListProps> = (props) => {
  const dispatch = useAppDispatch();
  const [selectedNotification, setSelectedNotification] = useState<
    NotificationItem | undefined
  >(undefined);

  const closeNotification = async () => {
    try {
      if (
        selectedNotification &&
        selectedNotification.status === NOTIFICATION_STATUS.UNREAD
      ) {
        dispatch(updateNotification(selectedNotification.id));
        dispatch(getNotificationList());
      }
    } finally {
      setSelectedNotification(undefined);
    }
  };

  return (
    <CardContainer>
      {props?.data.map((item: NotificationsCardItemProps, index: number) => {
        return (
          <NotificationCardItem
            {...item}
            key={`NotificationItem-${index}`}
            onPress={(notification) => setSelectedNotification(notification)}
          />
        );
      })}
      <NotificationDetailsModal
        notificationData={selectedNotification}
        isVisible={Boolean(selectedNotification)}
        onCancel={closeNotification}
        onOkay={closeNotification}
      />
    </CardContainer>
  );
};

export default NotificationsList;
