import React, { useState, useRef, Fragment } from "react";
import { View } from "react-native";
import { useAppDispatch } from "../../../app/hooks";
import { Formik } from "formik";
import styled from "styled-components/native";

import { RootStackScreenProps } from "../../../navigation/types";
import { ScrollContainer } from "../../../components/shared";
import {
  registerAccountDetailsSchema1,
  registerAccountDetailsSchema2,
  registerAccountDetailsSchema3,
} from "../../../utils/schema";
import AuthTemplateScreen from "../../../components/Templates/AuthTemplate";
import FieldText from "../../../components/FormFields/FieldText";
import { updateAccountDetails } from "../../../feature/auth/authSlice";
import WaitingModal from "../../../components/Modals/WaitingModal";
import { updateAccountDetailsParam } from "../../../interfaces/auth";
import FieldDropdown from "../../../components/FormFields/FieldDropdown";
import FieldDatePicker from "../../../components/FormFields/FieldDatePicker";
import {
  incomeRangeList,
  statesList,
  occupationList,
} from "../../../utils/constants";
import ToastMessage from "../../../components/ToastMessages/Toast";

const FieldSection = styled.View`
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  align-items: center;
  flex-direction: column;
`;

const AccountDetailsScreen = ({
  route,
  navigation,
}: RootStackScreenProps<"AccountDetails">) => {
  const fieldSetIndex = route?.params?.fieldSet;
  const hideNavigation = route?.params?.hideNavigation;
  const registerFormRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const [activeField, setActiveField] = useState(false);
  const [toastVisible, setToastVisible] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useAppDispatch();

  const triggerSubmit = () => {
    if (registerFormRef.current) {
      registerFormRef.current.handleSubmit();
    }
  };

  const handleSubmit = async (values: updateAccountDetailsParam) => {
    setLoading(true);
    setError(null);
    try {
      const result = await dispatch(updateAccountDetails(values));

      if (result?.meta?.requestStatus === "fulfilled") {
        if (fieldSetIndex + 1 <= 3) {
          navigation.push("AccountDetails", {
            fieldSet: fieldSetIndex + 1,
          });
        } else {
          navigation.navigate("SignUpTerms");
        }
      } else {
        setToastVisible(true);
        setError(
          result?.error?.message ||
            "System not available. Please try again later."
        );
      }
    } catch (error) {
      setToastVisible(true);
      setError("System not available. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <AuthTemplateScreen
        header="Your Details"
        submitTitle="Continue"
        backButtonIcon="arrow-back"
        submitButtonEditMode={activeField}
        onSubmit={() => triggerSubmit()}
        keyboardVerticalOffset={-180}
        hideNavigationSection={hideNavigation}
      >
        <ScrollContainer
          automaticallyAdjustKeyboardInsets={true}
          showsVerticalScrollIndicator={false}
          removeClippedSubviews={false}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          <View onStartShouldSetResponder={() => true}>
            <Formik
              initialValues={
                fieldSetIndex === 1
                  ? {
                      firstName: "",
                      middleName: "",
                      lastName: "",
                      birthDate: "",
                    }
                  : fieldSetIndex === 2
                  ? {
                      address: "",
                      suburb: "",
                      state: "",
                      postCode: "",
                    }
                  : fieldSetIndex === 3
                  ? {
                      occupation: "",
                      incomeRange: "",
                    }
                  : {}
              }
              validationSchema={
                fieldSetIndex === 1
                  ? registerAccountDetailsSchema1
                  : fieldSetIndex === 2
                  ? registerAccountDetailsSchema2
                  : registerAccountDetailsSchema3
              }
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={handleSubmit}
              innerRef={registerFormRef}
            >
              {({ values, errors }) => (
                <Fragment>
                  {fieldSetIndex === 1 ? (
                    <FieldSection>
                      <FieldText
                        label="First Name"
                        fieldName="firstName"
                        value={values.firstName}
                        onShow={() => {
                          setActiveField(true);
                        }}
                        onHide={() => {
                          setActiveField(false);
                        }}
                        error={errors.firstName}
                      />
                      <FieldText
                        label="Middle Name (Optional)"
                        fieldName="middleName"
                        value={values.middleName}
                        onShow={() => {
                          setActiveField(true);
                        }}
                        onHide={() => {
                          setActiveField(false);
                        }}
                        error={errors.middleName}
                      />
                      <FieldText
                        label="Last Name"
                        fieldName="lastName"
                        value={values.lastName}
                        onShow={() => {
                          setActiveField(true);
                        }}
                        onHide={() => {
                          setActiveField(false);
                        }}
                        error={errors.lastName}
                      />
                      <FieldDatePicker
                        label="Date of Birth"
                        fieldName="birthDate"
                        value={values.birthDate}
                        error={errors.birthDate}
                      />
                    </FieldSection>
                  ) : fieldSetIndex === 2 ? (
                    <FieldSection>
                      <FieldText
                        label="Address"
                        fieldName="address"
                        value={values.address}
                        onShow={() => {
                          setActiveField(true);
                        }}
                        onHide={() => {
                          setActiveField(false);
                        }}
                        error={errors.address}
                      />
                      <FieldText
                        label="Suburb"
                        fieldName="suburb"
                        value={values.suburb}
                        onShow={() => {
                          setActiveField(true);
                        }}
                        onHide={() => {
                          setActiveField(false);
                        }}
                        error={errors.suburb}
                      />
                      <FieldDropdown
                        label="State"
                        fieldName="state"
                        options={statesList}
                        value={values.state}
                        error={errors.state}
                      />
                      <FieldText
                        label="Postal Code"
                        fieldName="postCode"
                        value={values.postCode}
                        maxLength={4}
                        onShow={() => {
                          setActiveField(true);
                        }}
                        onHide={() => {
                          setActiveField(false);
                        }}
                        error={errors.postCode}
                      />
                    </FieldSection>
                  ) : fieldSetIndex === 3 ? (
                    <FieldSection>
                      <FieldDropdown
                        label="Occupation"
                        fieldName="occupation"
                        options={occupationList}
                        value={values.occupation}
                        error={errors.occupation}
                      />
                      <FieldDropdown
                        label="Income Range"
                        fieldName="incomeRange"
                        options={incomeRangeList}
                        value={values.incomeRange}
                        error={errors.incomeRange}
                      />
                    </FieldSection>
                  ) : null}
                </Fragment>
              )}
            </Formik>
          </View>
        </ScrollContainer>
      </AuthTemplateScreen>
      <WaitingModal visible={loading} />
      {toastVisible ? (
        <ToastMessage type="error" onClose={() => setToastVisible(false)}>
          {error}
        </ToastMessage>
      ) : null}
    </>
  );
};

export default AccountDetailsScreen;
