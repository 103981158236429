import React, { FunctionComponent } from "react";
import styled from "styled-components/native";
import { colors } from "../colors";
import SVGBackground from "../../assets/svg/select_a_plan.svg";
import { ScreenWidth } from "../shared";
import {
  selectPlanCardHeight,
  selectPlanCardWidth,
} from "../../utils/constants";
import { BodySmall } from "../Texts/Typography";
import { useNavigation } from "@react-navigation/native";

const componentPadding = 16 * 2;

const svgHeight = Math.round(
  (selectPlanCardHeight / selectPlanCardWidth) *
    (ScreenWidth - componentPadding)
);

const svgWidth = Math.round(ScreenWidth - componentPadding);

const CardContainer = styled.TouchableOpacity`
  margin-top: 18px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 32px;
`;
/* 
  Formula for card height to keep the aspect ratio depending on screen width:
  (original height / original width) x new width = new height
*/
const Card = styled.View`
  aspect-ratio: ${svgWidth / svgHeight};
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  border-radius: 16px;
`;

const ButtonView = styled.View`
  background-color: ${colors.neutral0};
  display: flex;
  width: 200px;
  height: 32px;
  padding: 0px 12px;
  margin-right: 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 64px;
  position: absolute;
  bottom: 16px;
  right: 16px;
  z-index: 1;
`;

const BodySmallText = styled(BodySmall)`
  font-family: "DMSans Bold";
  color: ${colors.primary100};
  line-height: 20px;
`;

const SelectPlanCard: FunctionComponent = () => {
  const navigation = useNavigation();
  return (
    <CardContainer
      onPress={() => {
        navigation.navigate("PlansTab");
      }}
    >
      <Card>
        <ButtonView>
          <BodySmallText>Select a plan and start saving</BodySmallText>
        </ButtonView>
        <SVGBackground
          height={"100%"}
          width={"100%"}
          viewBox={`0 0 ${selectPlanCardWidth} ${selectPlanCardHeight}`}
          preserveAspectRatio="slice"
        />
      </Card>
    </CardContainer>
  );
};

export default SelectPlanCard;
