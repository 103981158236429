export const getGreeting = (curerntTime: number) => {
  if (curerntTime < 12) {
    return "Good Morning";
  } else if (curerntTime >= 12 && curerntTime < 18) {
    return "Good Afternoon";
  } else if (curerntTime >= 18 && curerntTime <= 24) {
    return "Good Evening";
  } else {
    return "Welcome";
  }
};
