import React, { useState, useRef, Fragment } from "react";
import { View } from "react-native";
import { useAppDispatch } from "../../../app/hooks";
import { Formik } from "formik";
import styled from "styled-components/native";
import { RootStackScreenProps } from "../../../navigation/types";
import { ScrollContainer } from "../../../components/shared";
import { resetPasswordSchema } from "../../../utils/schema";
import { passwordChecker } from "../../../utils/passwordStrengthMeter";
import AuthTemplateScreen from "../../../components/Templates/AuthTemplate";
import FieldText from "../../../components/FormFields/FieldText";
import {
  changePasswordParam,
  passwordStrengthType,
} from "../../../interfaces/auth";
import eyeClose from "../../../assets/icons/eye-close.png";
import eyeOpen from "../../../assets/icons/eye-open.png";

import WaitingModal from "../../../components/Modals/WaitingModal";
import {
  changePassword,
  signInUser,
  signOutUser,
} from "../../../feature/auth/authSlice";
import PasswordStrengthText from "../../../components/Texts/PasswordStrengthText";
import ToastMessage from "../../../components/ToastMessages/Toast";

const FieldSection = styled.View`
  margin-top: 24px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  align-items: center;
  flex-direction: column;
`;

const ResetPasswordScreen = ({
  route,
  navigation,
}: RootStackScreenProps<"ResetPassword">) => {
  const registerFormRef = useRef(null);
  const username = route?.params?.username || "";
  const tempPassword = route?.params?.tempPassword || "";
  const [loading, setLoading] = useState(false);

  const [activeField, setActiveField] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] =
    useState<passwordStrengthType | null>(null);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [toastVisible, setToastVisible] = useState(false);
  const [message, setMessage] = useState<string | null>(null);
  const [resultStatus, setResultStatus] = useState("");

  const dispatch = useAppDispatch();

  const triggerSubmit = () => {
    if (registerFormRef.current) {
      registerFormRef.current.handleSubmit();
    }
  };

  const handleSubmit = async (values: changePasswordParam) => {
    try {
      const { username, oldPassword, password } = values;
      setLoading(true);
      await dispatch(signInUser({ username, password: oldPassword }));
      const result = await dispatch(
        changePassword({
          oldPassword,
          password,
        })
      );
      setToastVisible(true);
      if (result?.meta?.requestStatus === "fulfilled") {
        setResultStatus(result.meta.requestStatus);
        setMessage(
          "You have successfully reset your password. You will redirect to Sign In."
        );
        await dispatch(signOutUser());
        setTimeout(() => {
          navigation.navigate("SignIn");
        }, 3000);
      } else if (result?.error?.message) {
        setMessage(result.error.message);
      } else {
        setMessage("System not available. Please try again later.");
      }
    } catch (error) {
      setMessage("System not available. Please try again later.");
      setToastVisible(true);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Fragment>
      <AuthTemplateScreen
        header="Reset Password"
        subHeader="Create your new password"
        submitTitle="Reset password"
        navigation={navigation}
        submitButtonEditMode={activeField}
        onSubmit={() => triggerSubmit()}
        submitDisabled={loading}
        backButtonIcon="arrow-back"
        keyboardVerticalOffset={-180}
      >
        <ScrollContainer
          automaticallyAdjustKeyboardInsets={true}
          showsVerticalScrollIndicator={false}
        >
          <View onStartShouldSetResponder={() => true}>
            <Formik
              initialValues={{
                username: username,
                oldPassword: tempPassword,
                password: "",
                confirmPassword: "",
              }}
              validationSchema={resetPasswordSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={handleSubmit}
              innerRef={registerFormRef}
            >
              {({ values, errors }) => (
                <FieldSection>
                  <FieldText
                    key={"password"}
                    label="Password"
                    fieldName="password"
                    validateOnChange={true}
                    onChange={(val) => {
                      if (val !== "") {
                        setPasswordStrength(passwordChecker(val));
                      } else {
                        setPasswordStrength(null);
                      }
                    }}
                    onShow={() => {
                      setActiveField(true);
                    }}
                    onHide={() => {
                      setActiveField(false);
                    }}
                    value={values.password}
                    icon={eyeClose}
                    icon2={eyeOpen}
                    showIcon2={showPassword}
                    secureTextEntry={!showPassword}
                    onIconPress={() => {
                      setShowPassword((prevState: boolean) => !prevState);
                    }}
                    children={() =>
                      !errors.password && passwordStrength ? (
                        <PasswordStrengthText
                          strength={passwordStrength?.strength}
                        >
                          {passwordStrength?.val}
                        </PasswordStrengthText>
                      ) : null
                    }
                    error={errors.password}
                  />
                  <FieldText
                    key={"confirmPassword"}
                    label="Confirm Password"
                    fieldName="confirmPassword"
                    validateOnChange={true}
                    value={values.confirmPassword}
                    icon={eyeClose}
                    icon2={eyeOpen}
                    onShow={() => {
                      setActiveField(true);
                    }}
                    onHide={() => {
                      setActiveField(false);
                    }}
                    showIcon2={showConfirmPassword}
                    secureTextEntry={!showConfirmPassword}
                    onIconPress={() => {
                      setShowConfirmPassword(
                        (prevState: boolean) => !prevState
                      );
                    }}
                    error={errors.confirmPassword}
                  />
                </FieldSection>
              )}
            </Formik>
          </View>
        </ScrollContainer>
      </AuthTemplateScreen>
      <WaitingModal visible={loading} />
      {toastVisible ? (
        <ToastMessage
          type={resultStatus === "fulfilled" ? "success" : "error"}
          onClose={async () => {
            setToastVisible(false);
          }}
        >
          {message}
        </ToastMessage>
      ) : null}
    </Fragment>
  );
};

export default ResetPasswordScreen;
