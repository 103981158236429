import { FontAwesome } from "@expo/vector-icons";
import * as Font from "expo-font";
import * as SplashScreen from "expo-splash-screen";
import { useEffect, useState } from "react";

export default function useCachedResources() {
  const [isLoadingComplete, setLoadingComplete] = useState(false);

  // Load any resources or data that we need prior to rendering the app
  useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHideAsync();

        // Load custom fonts here
        await Font.loadAsync({
          ...FontAwesome.font,
          "space-mono": require("../assets/fonts/SpaceMono-Regular.ttf"),
          DMSans: require("../assets/fonts/DMSans-Regular.ttf"), //300
          "DMSans Bold": require("../assets/fonts/DMSans-Bold.ttf"), //700
          "DMSans BoldItalic": require("../assets/fonts/DMSans-BoldItalic.ttf"), //Italic 700
          "DMSans Italic": require("../assets/fonts/DMSans-Italic.ttf"),
          "DMSans Medium": require("../assets/fonts/DMSans-Medium.ttf"), //500
          "DMSans MediumItalic": require("../assets/fonts/DMSans-MediumItalic.ttf"), //Italic 500
          "Plaid-XL": require("../assets/fonts/Plaid-XL.otf"),
          "Plaid-XL Italic": require("../assets/fonts/Plaid-XL-Italic.otf"),
        });
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setLoadingComplete(true);
        SplashScreen.hideAsync();
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  return isLoadingComplete;
}
