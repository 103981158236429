export const colors = {
  primary100: "#3D0783",
  primary_light: "#64399c",
  error: "#B3261E",
  error_light: "#FFDFDD",
  darkBlue: "#3E0883",
  correct: "#0EA772",
  fair: "#ffcc00",
  neutral0: "#FFFFFF",
  neutral10: "#FFFBFE",
  neutral20: "#F5EFF7",
  neutral30: "#E6E0E9",
  neutral40: "#CAC5CD",
  neutral50: "#AEA9B1",
  neutral60: "#938F96",
  primary70: "#706BFF",
  neutral80: "#605D64",
  neutral90: "#48464C",
  neutral100: "#000000",
  white70: "rgba(255,255,255,0.7)",
  tertiary_light: "#C8F3D9",
  tertiary2: "#DCFBE8",
};
