import React, { useState } from "react";
import { Dimensions } from "react-native";
import styled from "styled-components/native";
import { colors } from "../../../components/colors";
import RegularButton from "../../../components/Buttons/RegularButton";
import { RootStackScreenProps } from "../../../navigation/types";
import ChippitTitle from "../../../assets/chippit.png";
import { Col, Container, Row, ScreenWidth } from "../../../components/shared";
import SmallToggleButton from "../../../components/Buttons/SmallToggleButton";
import { useAppDispatch } from "../../../app/hooks";
import { updateAccountDetails } from "../../../feature/auth/authSlice";
import WaitingModal from "../../../components/Modals/WaitingModal";
import { BodySmall } from "../../../components/Texts/Typography";
import MarketingHeader from "../../../assets/terms_and_conditions_bg.png";
import {
  signUpMarketingHeight,
  signUpMarketingWidth,
} from "../../../utils/constants";
import ToastMessage from "../../../components/ToastMessages/Toast";

const SignUpTermsContainer = styled(Container)`
  background-color: ${colors.darkBlue};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

const TopSection = styled(Col)`
  width: 100%;
  align-items: center;
`;

const TopBackground = styled.ImageBackground`
  width: ${Math.round(ScreenWidth)}px;
  height: ${Math.round(
    (signUpMarketingHeight / signUpMarketingWidth) * ScreenWidth
  )}px;
`;

const Title = styled.Image`
  position: absolute;
  width: 148px;
  height: 32px;
  top: ${Dimensions.get("window").height / 4 - 82 + 16}px;
  left: ${Dimensions.get("window").width / 2 - 74}px;
`;

const BottomSection = styled.View`
  width: 100%;
  padding: 32px 16px;
  align-items: center;
`;

const RowText = styled(Row)`
  margin-bottom: 28px;
  justify-content: flex-start;
`;

const BodySmallText = styled(BodySmall)`
  color: ${colors.neutral0};
  flex: 1;
  margin-left: 10px;
`;

const SignUpTermsScreen = ({
  navigation,
}: RootStackScreenProps<"SignUpTerms">) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [toastVisible, setToastVisible] = useState(false);
  const [subscribe, setSubscribe] = useState(false);

  const triggerSubmit = async () => {
    try {
      setError(null);
      setLoading(true);
      const result = await dispatch(
        updateAccountDetails({ marketingPermission: subscribe })
      );
      if (result?.meta?.requestStatus === "fulfilled") {
        navigation.navigate("PinSetup", {});
      } else {
        setToastVisible(true);
        setError(
          result?.error?.message ||
            "System not available. Please try again later."
        );
      }
    } catch (err) {
      setToastVisible(true);
      setError("System not available. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <SignUpTermsContainer>
      <TopSection>
        <TopBackground source={MarketingHeader}>
          <Title source={ChippitTitle} />
        </TopBackground>
      </TopSection>
      <BottomSection>
        <RowText>
          <SmallToggleButton
            btnStyles={{ marginTop: 4 }}
            active={subscribe}
            onPress={() => {
              setSubscribe(!subscribe);
            }}
          />
          <BodySmallText>
            Chippit will send you members-only deals, marketing{"\n"} emails and
            push notifications. You can opt out any{"\n"} time in your account
            settings.
          </BodySmallText>
        </RowText>
        <RegularButton
          textStyles={{ color: colors.primary100 }}
          btnStyles={{
            backgroundColor: colors.neutral0,
          }}
          onPress={triggerSubmit}
        >
          Continue
        </RegularButton>
      </BottomSection>
      <WaitingModal visible={loading} />
      {toastVisible ? (
        <ToastMessage type="error" onClose={() => setToastVisible(false)}>
          {error}
        </ToastMessage>
      ) : null}
    </SignUpTermsContainer>
  );
};
export default SignUpTermsScreen;
