import React, { FunctionComponent, useState, useEffect, Fragment } from "react";
import styled from "styled-components/native";
import { useNavigation } from "@react-navigation/native";
import RecommendedPlanCard from "../Card/RecommendedPlanCard";
import CustomPlanCard from "../Card/CustomPlanCard";
import { colors } from "../colors";
import { BodyLargeBold, BodySmall, TitleBody } from "../Texts/Typography";
import { Col, Row } from "../shared";
import {
  RecommendedPlanItemProps,
  RecommendedPlanListProps,
  recommendedPlanType,
} from "../../interfaces/plans";
import WaitingModal from "../Modals/WaitingModal";
import { useAppSelector } from "../../app/hooks";

const ListContainer = styled.View`
  width: 100%;
  gap: 8px;
  padding: 0px 16px;
  margin-bottom: 21px;
`;

const TabButton = styled.TouchableHighlight`
  width: 100%;
  height: 32px;
  padding: 5px 20px;
  border-radius: 64px;
  gap: 10px;
  justify-content: center;
  align-items: center;
`;

const TabLine = styled.View`
  width: 100%;
  height: 8px;
  position: absolute;
  align-self: center;
  background-color: ${colors.neutral30};
`;

const BodySmallText = styled(BodySmall)`
  color: ${colors.neutral0};
`;

const EmptyPlansTextCard = styled.View`
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 16px;
  border-width: 1px;
  border-color: ${colors.neutral30};
  background-color: ${colors.neutral10};
  padding: 16px;
`;

const EmptyPlansText = styled(BodyLargeBold)`
  margin-top: 12px;
  margin-bottom: 12px;
`;

const PlansList: FunctionComponent<RecommendedPlanListProps> = (props) => {
  const navigation = useNavigation();
  const plan = useAppSelector((state) => state.plan);
  const [planList, setPlanList] = useState<RecommendedPlanItemProps[]>([]);
  const [activeCard, setActiveCard] = useState<number | null>(null);
  const [activeTab, setActiveTab] = useState(1);

  const getList = (tabId: number) => {
    setActiveTab(tabId);
    setActiveCard(0);
    if (tabId === 2) {
      setPlanList(
        props?.data.filter((data) => data?.month === "This month") || []
      );
    } else if (tabId === 3) {
      setPlanList(
        props?.data.filter((data) => data?.month === "Next month") || []
      );
    } else {
      setPlanList(props?.data);
    }
  };

  useEffect(() => {
    if (props?.data) {
      getList(1);
    }
  }, [props?.data]);

  const expandCard = (index: number) => {
    setActiveCard(index !== activeCard ? index : null);
  };

  const startPlan = (data: recommendedPlanType) => {
    navigation.navigate("PlansTab", {
      screen: "AdditionalInformation",
      params: { data },
    });
  };

  if (planList.length === 0 && plan.recommendedPlanListStatus === "loading") {
    return <WaitingModal visible={true} />;
  }

  return (
    <Fragment>
      <ListContainer>
        <Row>
          <TabLine />
          <Col style={{ width: "30%" }}>
            <TabButton
              style={{
                // maxWidth: 74,
                backgroundColor:
                  activeTab === 1 ? colors.neutral100 : colors.neutral30,
              }}
              onPress={() => {
                getList(1);
              }}
            >
              <BodySmallText
                style={{
                  color: activeTab === 1 ? colors.neutral0 : colors.neutral60,
                }}
              >
                All
              </BodySmallText>
            </TabButton>
          </Col>
          <Col style={{ width: "30%" }}>
            <TabButton
              style={{
                // maxWidth: 124,
                backgroundColor:
                  activeTab === 2 ? colors.neutral100 : colors.neutral30,
              }}
              onPress={() => {
                getList(2);
              }}
            >
              <BodySmallText
                style={{
                  color: activeTab === 2 ? colors.neutral0 : colors.neutral60,
                }}
              >
                This Month
              </BodySmallText>
            </TabButton>
          </Col>
          <Col style={{ width: "30%" }}>
            <TabButton
              style={{
                // maxWidth: 128,
                backgroundColor:
                  activeTab === 3 ? colors.neutral100 : colors.neutral30,
              }}
              onPress={() => {
                getList(3);
              }}
            >
              <BodySmallText
                style={{
                  color: activeTab === 3 ? colors.neutral0 : colors.neutral60,
                }}
              >
                Next Month
              </BodySmallText>
            </TabButton>
          </Col>
        </Row>
        {planList.map((list, index) => (
          <Fragment key={index}>
            <TitleBody>{list.month}</TitleBody>
            {list.plans.length === 0 ? (
              <EmptyPlansTextCard>
                <EmptyPlansText>
                  Plans are filled, join next month plans
                </EmptyPlansText>
              </EmptyPlansTextCard>
            ) : (
              list.plans.map((data) => (
                <RecommendedPlanCard
                  key={`RecommendedPlansCard-${data?.id}`}
                  index={data?.id}
                  data={data}
                  expandCard={() => expandCard(data?.id)}
                  isExpanded={data?.id === activeCard}
                  startPlan={(data) => startPlan(data)}
                  activePlans={props.activePlans}
                  redirectToast="PlansTab"
                />
              ))
            )}

            {list.month === "Next month" && activeTab === 1 ? null : (
              <div>
                <CustomPlanCard showImage={true} navigation={navigation} />
              </div>
            )}
          </Fragment>
        ))}
      </ListContainer>
    </Fragment>
  );
};

export default PlansList;
