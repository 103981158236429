export const selectPlanCardHeight = 117;
export const selectPlanCardWidth = 343;
export const planCardItemHeight = 244;
export const planCardItemWidth = 310;
export const planImageWidth = 311;
export const planImageHeight = 174;
export const planCardHeaderWidth = 375;
export const planCardHeaderHeight = 300;
export const onboardingVideoHeight = 285;
export const onboardingVideoWidth = 305;
export const signUpMarketingWidth = 375;
export const signUpMarketingHeight = 438;
export const welcomeMarketingWidth = 375;
export const welcomeMarketingHeight = 438;
export const userProfileModalWidth = 375;
export const userProfileModalHeight = 388;
export const referFriendsCardHeight = 219;
export const referFriendsCardWidth = 343;
export const onboardingScreen1 = {
  title: "Select the plan to reach your goal.",
  video: require("../assets/videos/onboarding-1.mov"),
  duration: 10000,
};
export const onboardingScreen2 = {
  title: "No interest cash advance credit on your savings.",
  video: require("../assets/videos/onboarding-2.mov"),
  duration: 7000,
};
export const onboardingScreen3 = {
  title: "Payout and pay-in tracking in real time.",
  video: require("../assets/videos/onboarding-3.mov"),
  duration: 7000,
};

export const InformationTabData = [
  {
    icon: "shield",
    content:
      "Don’t worry! Your data is secure with bank grade 256-bit SSL encryption. ",
  },
  {
    icon: "card",
    content:
      "We cannot initiate any transactions from your account. We will seek your consent before setting up Auto-debit for your monthly payments.",
  },
  {
    icon: "account_balance",
    content:
      "Please choose your primary personal account only, typically where you receive your salary. Note that we don't accept credit card accounts.",
  },
];

export const incomeRangeList = [
  { label: "below 50k", value: "below 50k" },
  { label: "50k - 100k", value: "50k - 100k" },
  { label: "above 100k", value: "above 100k" },
];

export const statesList = [
  { label: "NSW", value: "NSW" },
  { label: "VIC", value: "VIC" },
  { label: "QLD", value: "QLD" },
  { label: "ACT", value: "ACT" },
  { label: "NT", value: "NT" },
  { label: "WA", value: "WA" },
  { label: "SA", value: "SA" },
  { label: "TAS", value: "TAS" },
];

export const occupationList = [
  { label: "Employed", value: "Employed" },
  { label: "Unemployed", value: "Unemployed" },
  { label: "Student", value: "Student" },
  { label: "Others", value: "Others" },
];

export const planReasonsList = [
  { label: "Buy a car", value: "Car" },
  { label: "Holiday", value: "Holiday" },
  { label: "Debt repayment", value: "Debt_repayment" },
  { label: "House deposit", value: "House_deposit" },
  { label: "Education", value: "Education" },
  { label: "Start a business", value: "Start_a_business" },
  { label: "Wedding", value: "Wedding" },
  { label: "Home renovation", value: "Home_renovation" },
  { label: "Rainy day funds", value: "Rainy_day_funds" },
  { label: "Others", value: "Others" },
  { label: "Prefer not to say", value: "Prefer_not_to_say" },
];

export const ConsentLearnMoreURL = "https://www.basiq.io/open-banking-hub/";

export enum Nomination {
  NONE = "NONE",
  ALL = "ALL",
  DEBIT = "DEBIT",
  CREDIT = "CREDIT",
}

export const initialCurrentUserDetails = {
  id: "",
  phoneNumber: "",
  email: "",
  firstName: "",
  lastName: "",
  birthDate: "",
  address: "",
  suburb: "",
  state: "",
  postCode: "",
  occupation: "",
  incomeRange: "",
  status: "",
  imageUrl: "",
  bio: "",
  kycId: "",
  kycDate: "",
  createdAt: "",
};

export enum USER_STATUS {
  UNPROFILED = "UNPROFILED",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export const referralHowItWorks = [
  {
    title: "1. Share your invite link",
    description:
      // "Invite fiends and family who haven't used Chippit before and make sure they use your link to get it.",
      "Invite fiends and family who haven't used Chippit before.",
  },
  {
    title: "2. Your friend or family member sign up",
    description:
      // "They will download the app, join a plan and start saving with $15 chip in and achieve their goals faster.",
      "They will download the app and seamlessly sign up.",
  },
  {
    title: "3. Start saving",
    description:
      // "For each friend or family member that completes the above, you’ll get $15 deposited by Chippit in your savings plan.",
      "Each friend or family can now join a plan and start saving to achieve their goals faster",
  },
];

export const GET_CHIPPIT_URL = "https://chippit.app/#download";

export enum UserPreferenceKeys {
  isBiometricsEnabled = "isBiometricsEnabled",
  isPushTokenActive = "isPushTokenActive",
}
