import React, { Fragment, FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components/native";
import { format } from "date-fns";
import { colors } from "../colors";
import { Col, Row } from "../shared";
import { BodyLargeBold, BodySmall } from "../Texts/Typography";
import {
  PayoutOrderCardItemProps,
  UserDetailsProps,
} from "../../interfaces/plans";
import { TouchableOpacity } from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import { getUserDetails } from "../../feature/plan/planSlice";
import { useAppDispatch } from "../../app/hooks";
import ProfileImage from "../Images/ProfileImage";
import { loadImage } from "../../utils/imageUtils/loadImage";
import UserProfileModal from "../Modals/UserProfileModal";
// import { getBorderRadius } from "../../utils/borderEnums";

const Card = styled.View`
  flex: 0px;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 16px;
  background-color: ${(props) =>
    props.isTouchCard ? colors.neutral100 : colors.neutral0};
  padding: 12px 12px 50px 16px;
  border: 1px solid #e6e0e9;
`;

const BodyLargeBoldText = styled(BodyLargeBold)`
  color: ${(props) =>
    props.isTouchCard ? colors.neutral0 : colors.neutral100};
`;

const BodySmallText = styled(BodySmall)`
  color: ${(props) => (props.isTouchCard ? colors.neutral0 : colors.neutral60)};
`;

const DetailsRow = styled(Row)`
  width: 100%;
`;

const PayoutOrderItem: FunctionComponent<PayoutOrderCardItemProps> = (
  props
) => {
  const dispatch = useAppDispatch();
  const [memberDetails, setMemberDetails] = useState<UserDetailsProps[]>([]);
  const [profileImage, setProfileImage] = useState<unknown>(null);
  const [userProfileVisible, setUserProfileVisible] = useState(false);
  const [isTouchCard, setIsTouchCard] = useState(false);

  const loadMember = async () => {
    const result = await dispatch(getUserDetails(props.userId));
    setMemberDetails(result?.payload?.user);

    if (result?.payload?.user?.imageUrl) {
      try {
        const profileURL = await loadImage(result.payload.user.imageUrl);
        setProfileImage(profileURL);
      } catch (error) {
        setProfileImage(null);
      }
    }
  };

  useEffect(() => {
    loadMember();
  }, []);

  return (
    <Fragment>
      <TouchableOpacity
        onPress={() => {
          setUserProfileVisible(true);
          setIsTouchCard(true);
        }}
      >
        <Card index={props.index} isTouchCard={isTouchCard}>
          <DetailsRow>
            <Col>
              <Row>
                <Col style={{ marginRight: 8, justifyContent: "center" }}>
                  <BodySmallText isTouchCard={isTouchCard}>
                    {props.index + 1}
                  </BodySmallText>
                </Col>
                <Col style={{ marginRight: 8, justifyContent: "center" }}>
                  <ProfileImage
                    firstName={memberDetails?.firstName}
                    lastName={memberDetails?.lastName}
                    imageSrc={profileImage}
                    notWhiteBg={true}
                  />
                </Col>
                <Col>
                  <BodyLargeBoldText isTouchCard={isTouchCard}>
                    {memberDetails?.firstName} {memberDetails?.lastName}
                  </BodyLargeBoldText>
                  <BodySmallText isTouchCard={isTouchCard}>
                    Payday: {format(new Date(props.payoutDate), "dd MMMM yyyy")}
                  </BodySmallText>
                </Col>
              </Row>
            </Col>
            <Col style={{ justifyContent: "center" }}>
              <MaterialIcons
                name="arrow-right-alt"
                size={24}
                color={isTouchCard ? colors.neutral0 : colors.neutral100}
              />
            </Col>
          </DetailsRow>
        </Card>
      </TouchableOpacity>
      <UserProfileModal
        isVisible={userProfileVisible}
        details={memberDetails}
        payoutDate={props?.payoutDate}
        profileImage={profileImage}
        cancelButton={() => {
          setUserProfileVisible(false);
          setIsTouchCard(false);
        }}
      />
    </Fragment>
  );
};

export default PayoutOrderItem;
