import React, { FunctionComponent } from "react";
import styled from "styled-components/native";
import { colors } from "../colors";
import {
  GestureResponderEvent,
  StyleProp,
  TextStyle,
  ViewStyle,
} from "react-native";
import { BodyDefaultBold } from "../Texts/Typography";

const ButtonView = styled.TouchableOpacity`
  background-color: ${colors.neutral0};
  display: flex;
  height: 26px;
  padding: 2px 10px 2px 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 20px;
  border: 1px solid ${colors.primary100};
`;

const BodyDefaultBoldText = styled(BodyDefaultBold)`
  color: ${colors.primary100};
`;

// types
interface ButtonProps {
  btnStyles?: StyleProp<ViewStyle>;
  onPress: ((event: GestureResponderEvent) => void) | undefined;
  textStyles?: StyleProp<TextStyle>;
  children: React.ReactNode;
}

const EditButton: FunctionComponent<ButtonProps> = (props) => {
  return (
    <ButtonView onPress={props.onPress} style={props.btnStyles}>
      <BodyDefaultBoldText style={props.textStyles}>
        {props.children}
      </BodyDefaultBoldText>
    </ButtonView>
  );
};

export default EditButton;
