import React, { Fragment, useRef, useState } from "react";
import styled from "styled-components/native";
import { useAppDispatch } from "../../app/hooks";
import { ScrollContainer } from "../../components/shared";
import PageTemplateScreen from "../../components/Templates/PageTemplate";
import { View } from "react-native";
import { Formik } from "formik";
import FieldDropdown from "../../components/FormFields/FieldDropdown";
import FieldTextArea from "../../components/FormFields/FieldTextArea";
import { RootStackScreenProps } from "../../navigation/types";
import { requestCustomPlanSchema } from "../../utils/schema";
import { planReasonsList } from "../../utils/constants";
import { requestCustomPlan } from "../../feature/plan/planSlice";
import WaitingModal from "../../components/Modals/WaitingModal";
import ToastMessage from "../../components/ToastMessages/Toast";
import { requestCustomPlanParams } from "../../interfaces/plans";

const FieldSection = styled.View`
  margin-top: 24px;
  padding: 0px 16px;
  width: 100%;
  align-items: center;
  flex-direction: column;
`;

const RequestCustomPlanScreen = ({
  navigation,
}: RootStackScreenProps<"RequestCustomPlan">) => {
  const customPlanFormRef = useRef(null);
  const dispatch = useAppDispatch();
  const [activeField, setActiveField] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toastVisible, setToastVisible] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const triggerSubmit = () => {
    if (customPlanFormRef.current) {
      customPlanFormRef.current.handleSubmit();
    }
  };

  const handleSubmit = async (values: requestCustomPlanParams) => {
    try {
      setLoading(true);
      setError(null);
      setToastVisible(false);
      const result = await dispatch(requestCustomPlan(values));

      if (result?.meta?.requestStatus === "fulfilled") {
        return navigation.navigate("RequestSubmitted", {
          buttonTitle: "Go to plans listing",
          buttonNavigate: () => {
            navigation.navigate("PlansTab", {
              screen: "Plans",
              initial: false,
            });
          },
        });
      } else {
        setToastVisible(true);
        setError("System not available. Please try again later.");
      }
    } catch (error) {
      setToastVisible(true);
      setError("System not available. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <PageTemplateScreen
        header="Request a custom plan"
        subHeader={
          "Fill out this information and one of our customer\nrepresentative will contact you in next 24-48\nbusiness hours."
        }
        submitTitle="Send request"
        submitButtonEditMode={activeField}
        onSubmit={() => triggerSubmit()}
        submitDisabled={loading}
        keyboardVerticalOffset={-150}
      >
        <ScrollContainer
          automaticallyAdjustKeyboardInsets={true}
          showsVerticalScrollIndicator={false}
        >
          <View onStartShouldSetResponder={() => true}>
            <Formik
              initialValues={{
                reasonToSave: "",
                moreInfo: "",
              }}
              validationSchema={requestCustomPlanSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={handleSubmit}
              innerRef={customPlanFormRef}
            >
              {({ values, errors }) => (
                <FieldSection>
                  <FieldDropdown
                    label="Reason to save"
                    fieldName="reasonToSave"
                    options={planReasonsList}
                    value={values.reasonToSave}
                    error={errors.reasonToSave}
                  />
                  <FieldTextArea
                    key={"moreInfo"}
                    label="Tell us more"
                    placeholder={
                      "How many members, length of the plan and\nsaving target you are looking for?"
                    }
                    fieldName="moreInfo"
                    onShow={() => {
                      setActiveField(true);
                    }}
                    onHide={() => {
                      setActiveField(false);
                    }}
                    value={values.moreInfo}
                    error={errors.moreInfo}
                  />
                </FieldSection>
              )}
            </Formik>
          </View>
        </ScrollContainer>
      </PageTemplateScreen>
      <WaitingModal visible={loading} />
      {toastVisible ? (
        <ToastMessage type="error" onClose={() => setToastVisible(false)}>
          {error}
        </ToastMessage>
      ) : null}
    </Fragment>
  );
};

export default RequestCustomPlanScreen;
