import React, { Fragment } from "react";
import styled from "styled-components/native";
import { RootStackScreenProps } from "../../../navigation/types";
import AuthTemplateScreen from "../../../components/Templates/AuthTemplate";
import SubmitButton from "../../../components/Buttons/SubmitButton";
import { colors } from "../../../components/colors";

const ButtonSection = styled.View`
  margin-top: 60px;
  padding: 0px 16px;
  width: 100%;
  align-items: center;
  flex-direction: column;
`;

const VerifyEmailScreen = ({
  navigation,
}: RootStackScreenProps<"SignInVerifyEmail">) => {
  return (
    <Fragment>
      <AuthTemplateScreen
        header="Verify email"
        subHeader="We haven't verified your email yet. Please log-in with your phone number."
        hideSubmitButton={true}
        navigation={navigation}
        backButtonIcon="arrow-back"
      >
        <ButtonSection>
          <SubmitButton
            textStyles={{ color: colors.neutral0 }}
            onPress={() => navigation.goBack()}
          >
            Login with phone number
          </SubmitButton>
        </ButtonSection>
      </AuthTemplateScreen>
    </Fragment>
  );
};

export default VerifyEmailScreen;
