import React, { useEffect, useState } from "react";
import styled from "styled-components/native";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootStackScreenProps } from "../../navigation/types";

import { colors } from "../../components/colors";
import { Col, Container } from "../../components/shared";
import PageTemplateScreen from "../../components/Templates/PageTemplate";
import ToastMessage from "../../components/ToastMessages/Toast";
import {
  BodyDefaultBold,
  BodyLargeBold,
} from "../../components/Texts/Typography";
import { UserAccountType } from "../../interfaces/plans";
import { getUserAccounts } from "../../feature/plan/planSlice";
import { useFocusEffect } from "@react-navigation/native";
import { Nomination } from "../../utils/constants";
import { setToastContent } from "../../feature/notification/notificationSlice";

const SelectAccountContainer = styled(Container)`
  flex: 1;
  background-color: ${colors.neutral0};
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

const ContentSection = styled.View`
  padding: 0px 16px 20px 16px;
  width: 100%;
  align-items: center;
  flex-direction: column;
  flex-basis: auto;
`;

const InnerCard = styled.View`
  border-radius: 16px;
  border: 1px solid ${colors.neutral30};
  padding: 16px;
  width: 100%;
  flex: 1;
  flex-basis: auto;
`;

const Title = styled(BodyLargeBold)`
  color: ${colors.neutral100};
`;

const SubTitle = styled(BodyDefaultBold)`
  color: ${colors.neutral90};
`;

const LineBreak = styled.View`
  height: 1px;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: ${colors.neutral30};
`;

const AccountItem = styled.View`
  width: 100%;
  flex-direction: row;
  align-items: center;
  flex: 1;
  flex-basis: auto;
`;

const BSBCol = styled(Col)`
  flex: 1;
`;

const AccountNumberCol = styled(Col)`
  flex: 1;
`;

const AccountSettingsScreen = ({
  navigation,
  route,
}: RootStackScreenProps<"AccountSettings">) => {
  const { fromProfile } = route.params;
  const dispatch = useAppDispatch();
  const { myAccounts } = useAppSelector((state) => state.plan);
  const { toastContent } = useAppSelector((state) => state.notification);
  const [selectedDebitAccount, setSelectedDebitAccount] =
    useState<UserAccountType | null>(null);
  const [selectedCreditAccount, setSelectedCreditAccount] =
    useState<UserAccountType | null>(null);
  useFocusEffect(
    React.useCallback(() => {
      loadAccount();
    }, [])
  );

  const loadAccount = async () => {
    await dispatch(getUserAccounts());
  };

  useEffect(() => {
    if (myAccounts.length > 0) {
      myAccounts.some((account) => {
        if (account?.nomination === Nomination.ALL) {
          setSelectedCreditAccount(account);
          setSelectedDebitAccount(account);
          return true;
        } else if (account?.nomination === Nomination.DEBIT) {
          setSelectedDebitAccount(account);
          if (selectedCreditAccount) return true;
        } else if (account?.nomination === Nomination.CREDIT) {
          setSelectedCreditAccount(account);
          if (selectedDebitAccount) return true;
        }
      });
    }
  }, [myAccounts]);

  const handleSubmit = async () => {
    if (selectedDebitAccount && selectedCreditAccount)
      navigation.navigate("SelectAccount", {
        fromProfile: true,
        debitAccount: selectedDebitAccount,
        creditAccount: selectedCreditAccount,
      });
  };

  const closeToast = async () => {
    const params = {
      errorType: "",
      icon: undefined,
      content: "",
    };
    await dispatch(setToastContent(params));
  };

  return (
    <SelectAccountContainer>
      <PageTemplateScreen
        header="Bank Accounts"
        onSubmit={() => handleSubmit()}
        submitTitle="Update the account"
        submitButtonEditMode={false}
        onCancel={() => {
          if (fromProfile) {
            navigation.navigate("ProfileTab", { screen: "Profile" });
          } else {
            navigation.goBack();
          }
        }}
      >
        <ContentSection onStartShouldSetResponder={() => true}>
          <InnerCard>
            <Col>
              <Title>You Pay from</Title>
              <SubTitle>Linked bank account</SubTitle>
              <LineBreak />
              <AccountItem>
                <BSBCol>
                  <SubTitle>BSB ({selectedDebitAccount?.bank})</SubTitle>
                  <Title>{selectedDebitAccount?.bsb}</Title>
                </BSBCol>
                <AccountNumberCol>
                  <SubTitle>Account number</SubTitle>
                  <Title>{selectedDebitAccount?.accountNumber}</Title>
                </AccountNumberCol>
              </AccountItem>
            </Col>
          </InnerCard>
          <InnerCard style={{ marginTop: 8 }}>
            <Col>
              <Title>You receive to</Title>
              <SubTitle>Linked bank account</SubTitle>
              <LineBreak />
              <AccountItem>
                <BSBCol>
                  <SubTitle>BSB ({selectedCreditAccount?.bank})</SubTitle>
                  <Title>{selectedCreditAccount?.bsb}</Title>
                </BSBCol>
                <AccountNumberCol>
                  <SubTitle>Account number</SubTitle>
                  <Title>{selectedCreditAccount?.accountNumber}</Title>
                </AccountNumberCol>
              </AccountItem>
            </Col>
          </InnerCard>
        </ContentSection>
      </PageTemplateScreen>
      {toastContent.content !== "" ? (
        <ToastMessage
          type={toastContent?.errorType}
          onClose={closeToast}
          icon={toastContent?.icon}
        >
          {toastContent?.content}
        </ToastMessage>
      ) : null}
    </SelectAccountContainer>
  );
};

export default AccountSettingsScreen;
