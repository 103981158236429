import React, { FunctionComponent, useState } from "react";
import styled from "styled-components/native";
import { BottomSheet } from "react-native-btr";
import { colors } from "../colors";
import { BodyDefault, TitleSubsection } from "../Texts/Typography";
import RegularButton from "../Buttons/RegularButton";

import ShieldPersonIcon from "../../assets/svg/shield_person_icon.svg";
import {
  getCurrentUserDetails,
  setUserBiometricsPreference,
} from "../../feature/auth/authSlice";
import { useAppDispatch } from "../../app/hooks";
import WaitingModal from "./WaitingModal";

const BottomSheetBodySection = styled.View`
  background-color: ${colors.neutral0};
  width: 100%;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 0px 15px;
`;

const LineBar = styled.View`
  margin-top: 10px;
  margin-bottom: 16px;
  background-color: ${colors.primary100};
  width: 32px;
  height: 3px;
  border-radius: 16px;
`;

const BottomSheetContentSection = styled.View`
  align-self: flex-start;
`;

const BottomSheetButtonSection = styled.View`
  width: 100%;
  margin-top: 28px;
  margin-bottom: 10px;
`;

const BodyDefaultText = styled(BodyDefault)`
  color: ${colors.neutral90};
`;

// types
interface SheetProps {
  isVisible: boolean;
  closeBottomSheet: () => void;
}

const SecurityBottomSheet: FunctionComponent<SheetProps> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const enableBiometrics = async (param: boolean) => {
    setIsLoading(true);
    await dispatch(setUserBiometricsPreference(param));
    await dispatch(getCurrentUserDetails());
    setIsLoading(false);
    props.closeBottomSheet();
  };

  return (
    <BottomSheet
      visible={props.isVisible}
      onBackButtonPress={props.closeBottomSheet}
    >
      <BottomSheetBodySection>
        <LineBar />
        <BottomSheetContentSection>
          <ShieldPersonIcon />
          <TitleSubsection style={{ marginTop: 30 }}>
            Set up face ID or finger print
          </TitleSubsection>
          <BodyDefaultText>
            Use face ID or finger print to approve sensitive{"\n"} actions and
            sign in faster next time.
          </BodyDefaultText>
        </BottomSheetContentSection>
        <BottomSheetButtonSection>
          <RegularButton
            textStyles={{ color: colors.neutral0 }}
            onPress={() => enableBiometrics(true)}
          >
            Enable now
          </RegularButton>
          <RegularButton
            textStyles={{ color: colors.primary100 }}
            btnStyles={{
              marginTop: 20,
              backgroundColor: colors.neutral0,
              borderWidth: 1,
              borderColor: colors.primary100,
            }}
            onPress={() => enableBiometrics(false)}
          >
            No thanks, I’ll do later
          </RegularButton>
        </BottomSheetButtonSection>
      </BottomSheetBodySection>
      <WaitingModal visible={isLoading} />
    </BottomSheet>
  );
};

export default SecurityBottomSheet;
