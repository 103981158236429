import React, { FunctionComponent } from "react";
import styled from "styled-components/native";
import {
  GestureResponderEvent,
  StyleProp,
  TextStyle,
  ViewStyle,
} from "react-native";
import { BodyDefaultBold } from "../Texts/Typography";

const ButtonView = styled.TouchableOpacity`
  align-items: center;
  justify-content: center;
`;

// types
interface ButtonProps {
  btnStyles?: StyleProp<ViewStyle>;
  onPress: ((event: GestureResponderEvent) => void) | undefined;
  textStyles?: StyleProp<TextStyle>;
  children: React.ReactNode;
}

const LinkButton: FunctionComponent<ButtonProps> = (props) => {
  return (
    <ButtonView onPress={props.onPress} style={props.btnStyles}>
      <BodyDefaultBold style={props.textStyles}>
        {props.children}
      </BodyDefaultBold>
    </ButtonView>
  );
};

export default LinkButton;
