import React, { useEffect } from "react";
import styled from "styled-components/native";
import { colors } from "../colors";
import { BackHandler, Image, Platform } from "react-native";
import { BodySmall, TitleSection } from "../Texts/Typography";
import SubmitButton from "../Buttons/SubmitButton";
import EmptyBoxGif from "../../assets/gifs/empty-box.gif";
import { RootStackScreenProps } from "../../navigation/types";
import PageTemplateScreen from "../Templates/PageTemplate";

const ContentSection = styled.View`
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 0px 16px;
`;

const TitleSectionText = styled(TitleSection)`
  text-align: center;
  margin-top: 40px;
`;

const BodySmallText = styled(BodySmall)`
  color: ${colors.neutral90};
  text-align: center;
`;

const NoAccountConnectedScreen = ({
  navigation,
  route,
}: RootStackScreenProps<"NoAccountConnected">) => {
  const { fromProfile } = route.params;
  const handleBackButtonClick = () => {
    navigation.goBack();
    return true;
  };

  useEffect(() => {
    if (Platform.OS === "android") {
      BackHandler.addEventListener("hardwareBackPress", handleBackButtonClick);
      return () => {
        BackHandler.removeEventListener(
          "hardwareBackPress",
          handleBackButtonClick
        );
      };
    }
  });

  return (
    <PageTemplateScreen header="" hideSubmitButton={true}>
      <ContentSection>
        <Image source={EmptyBoxGif} />
        <TitleSectionText>No account connected</TitleSectionText>
        <BodySmallText>
          There is no account attached to your profile{"\n"} yet. Please attach
          your bank account.
        </BodySmallText>
        <SubmitButton
          btnStyles={{
            marginTop: 40,
          }}
          onPress={() => {
            const plansTabProps = {
              screen: "ConnectBank",
              params: {
                fromProfile,
              },
            };
            navigation.navigate("PlansTab", plansTabProps);
          }}
        >
          Connect the account
        </SubmitButton>
      </ContentSection>
    </PageTemplateScreen>
  );
};

export default NoAccountConnectedScreen;
