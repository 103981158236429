import React from "react";
import styled from "styled-components/native";
import { PayoutOrderListProps } from "../../interfaces/plans";
import { TitleSubsection } from "../Texts/Typography";
import PayoutOrderItem from "../Card/PayoutOrderItem";

const CardContainer = styled.View`
  width: 100%;
  padding: 0px 16px;
  gap: 24px;
`;

const ContentSection = styled.View`
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
`;

const PayoutOrderList: React.FC<PayoutOrderListProps> = (props) => {
  return (
    <CardContainer>
      {props.loading ? null : props?.data?.length > 0 ? (
        props?.data.map((item, index: number) => {
          return (
            <PayoutOrderItem
              {...item}
              key={`PayoutOrderItem-${index}`}
              index={index}
            />
          );
        })
      ) : (
        <ContentSection>
          <TitleSubsection>No payout order</TitleSubsection>
        </ContentSection>
      )}
    </CardContainer>
  );
};

export default PayoutOrderList;
