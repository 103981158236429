import React, { useState, useRef, Fragment, useEffect } from "react";
import {
  NativeSyntheticEvent,
  TextInput,
  TextInputKeyPressEventData,
  PixelRatio,
} from "react-native";
import styled from "styled-components/native";
import * as LocalAuthentication from "expo-local-authentication";
import { RootStackScreenProps } from "../../../navigation/types";
import AuthTemplateScreen from "../../../components/Templates/AuthTemplate";
import WaitingModal from "../../../components/Modals/WaitingModal";
import { Container } from "../../../components/shared";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  disableUserPushToken,
  refreshCurrentUserSession,
  setVerifyScreenStatus,
  signOutUser,
  verifyUserPin,
} from "../../../feature/auth/authSlice";
import { colors } from "../../../components/colors";
import ToastMessage from "../../../components/ToastMessages/Toast";
import { BodyLargeBold } from "../../../components/Texts/Typography";
import { VERIFY_PIN_SCREEN_STATUS } from "../../../utils/enums";
import { getDevicePreferenceByKey } from "../../../utils/getDevicePreferences";
import { UserPreferenceKeys } from "../../../utils/constants";

const fontScale = PixelRatio.getFontScale();

const FieldSection = styled.View`
  margin-top: 24px;
  padding-left: 18px;
  padding-right: 18px;
  width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  gap: 6px;
`;

const CodeTextInputContainer = styled.View`
  height: 48px;
  width: 48px;
  border-color: ${(props) =>
    props?.isActive ? colors.neutral90 : colors.neutral40};
  border-width: ${(props) => (props?.isActive ? "2px" : "1px")};
  border-radius: 12px;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const CodeTextInput = styled.TextInput`
  font-family: "DMSans";
  font-size: ${12 / fontScale}px;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 0px;
  padding-bottom: 0px;
`;

const ForgotPasswordButton = styled.TouchableOpacity`
  margin-top: 18px;
  margin-left: 18px;
`;

const ForgotPasswordText = styled(BodyLargeBold)`
  color: ${colors.neutral90};
`;

const codeLength = 4;

const ConfirmPinScreen = ({
  route,
  navigation,
}: RootStackScreenProps<"ConfirmPin">) => {
  const fromSignIn = route?.params?.fromSignIn;
  const pinCodeInputRefs = useRef<TextInput[]>([]);

  const [pinCodeArray, setPinCodeArray] = useState(Array(codeLength).fill(""));

  const [loading, setLoading] = useState(false);
  const [toastVisible, setToastVisible] = useState(false);
  const [activeFieldIndex, setActiveFieldIndex] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [errorTimes, setErrorTimes] = useState<number>(0);

  const user = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const handleRef = (ref: TextInput, index: number) => {
    pinCodeInputRefs.current[index] = ref;
  };

  useEffect(() => {
    if (pinCodeArray.filter((d) => d.trim() === "").length == 0) {
      triggerSubmit();
    }
  }, [pinCodeArray]);

  useEffect(() => {
    if (
      fromSignIn &&
      user?.currentUserDetails?.preferences &&
      user?.currentUserDetails?.preferences?.length > 0
    ) {
      const authenticateWithBiometrics = async () => {
        const isBiometricsEnabled = await getDevicePreferenceByKey(
          user.currentUserDetails.preferences,
          UserPreferenceKeys.isBiometricsEnabled
        );
        if (isBiometricsEnabled) {
          const response = await LocalAuthentication.authenticateAsync({
            promptMessage: "Verify with biometrics",
            disableDeviceFallback: true,
            cancelLabel: "Cancel",
          });
          if (response?.success === true) {
            setLoading(true);
            await dispatch(
              setVerifyScreenStatus(VERIFY_PIN_SCREEN_STATUS.HIDE)
            );
            setLoading(false);
          }
        }
      };
      authenticateWithBiometrics();
    }
  }, [user?.currentUserDetails?.preferences]);

  const triggerSubmit = async () => {
    setError(null);
    setToastVisible(false);
    try {
      setLoading(true);
      const userPin = pinCodeArray.join("");
      await dispatch(refreshCurrentUserSession());
      const result = await dispatch(verifyUserPin({ code: userPin }));
      if (result?.meta?.requestStatus === "fulfilled") {
        if (fromSignIn) {
          setLoading(false);
          await dispatch(setVerifyScreenStatus(VERIFY_PIN_SCREEN_STATUS.HIDE));
        } else {
          navigation.navigate("PinSetup", { fromProfile: true });
        }
      } else {
        if (result?.error?.message === "Invalid pin") {
          if (errorTimes + 1 === 3) {
            setToastVisible(true);
            setError("Retried too many times, redirecting to sign in.");
            setTimeout(async () => {
              await dispatch(
                setVerifyScreenStatus(VERIFY_PIN_SCREEN_STATUS.SIGN_IN)
              );
            }, 2000);
          } else {
            setToastVisible(true);
            setError(
              result?.error?.message ||
                "System not available. Please try again later."
            );
          }
          setErrorTimes(errorTimes + 1);
        } else {
          setToastVisible(true);
          setError(
            result?.error?.message ||
              "System not available. Please try again later."
          );
        }
      }
    } catch (e) {
      setToastVisible(true);
      setError("System not available. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (index: number) => (text: string) => {
    if (text !== "") {
      let nextIndex = index + 1;
      const newArr = [...pinCodeArray];
      if (text.length == codeLength) {
        [...text].forEach((t, index) => (newArr[index] = t));
        setActiveFieldIndex(text.length);
        nextIndex = text.length;
      } else {
        newArr[index] = text.charAt(text.length - 1);
        setActiveFieldIndex(index + 1);
      }
      setPinCodeArray(newArr);
      if (pinCodeInputRefs.current[nextIndex]) {
        pinCodeInputRefs.current[nextIndex].focus();
      }
    }
  };

  const handleBackspace =
    (index: number) =>
    (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
      if (e.nativeEvent.key === "Backspace") {
        const newArr = [...pinCodeArray];
        newArr[index] = "";
        setActiveFieldIndex(index - 1);
        setPinCodeArray(newArr);
        if (pinCodeInputRefs.current[index - 1]) {
          pinCodeInputRefs.current[index - 1].focus();
        }
      }
    };

  return (
    <Fragment>
      <AuthTemplateScreen
        header="Enter your pin"
        subHeader={`Enter your 4 digit pin code`}
        submitTitle="Confirm"
        backButtonIcon={"arrow-back"}
        navigation={navigation}
        submitButtonEditMode={activeFieldIndex !== null}
        submitDisabled={loading}
        onSubmit={() => triggerSubmit()}
        hideSubmitButton={true}
        onCancel={async () => {
          if (fromSignIn) {
            await dispatch(disableUserPushToken());
            await dispatch(signOutUser());
          } else {
            navigation.goBack();
          }
        }}
      >
        <Container style={{ alignItems: "flex-start" }}>
          <FieldSection>
            {pinCodeArray.map((value, index) => (
              <CodeTextInputContainer
                key={`VerificationCodeInput-${index}`}
                isActive={index === activeFieldIndex}
              >
                <CodeTextInput
                  style={
                    index === activeFieldIndex
                      ? { borderColor: "#48464C" }
                      : undefined
                  }
                  key={`PinCodeInput-${index}`}
                  onKeyPress={handleBackspace(index)}
                  ref={(ref: TextInput) => handleRef(ref, index)}
                  value={value}
                  onChangeText={handleChange(index)}
                  onFocus={() => setActiveFieldIndex(index)}
                  onBlur={() =>
                    index === activeFieldIndex
                      ? setActiveFieldIndex(null)
                      : null
                  }
                  placeholder="-"
                  keyboardType="number-pad"
                  disabled={errorTimes === 3}
                />
              </CodeTextInputContainer>
            ))}
          </FieldSection>
          {fromSignIn ? (
            <ForgotPasswordButton
              onPress={async () => {
                await dispatch(
                  setVerifyScreenStatus(VERIFY_PIN_SCREEN_STATUS.SIGN_IN)
                );
              }}
            >
              <ForgotPasswordText>Sign in with password</ForgotPasswordText>
            </ForgotPasswordButton>
          ) : (
            <ForgotPasswordButton
              onPress={() => navigation.navigate("ConfirmPassword")}
            >
              <ForgotPasswordText>Forgot Pin?</ForgotPasswordText>
            </ForgotPasswordButton>
          )}
        </Container>
      </AuthTemplateScreen>
      <WaitingModal visible={loading} />
      {toastVisible ? (
        <ToastMessage
          type="error"
          onClose={(fromAutoClose) => {
            if (!(fromAutoClose === true && errorTimes > 0 && fromSignIn)) {
              setToastVisible(false);
            }
          }}
        >
          {error}
        </ToastMessage>
      ) : null}
    </Fragment>
  );
};

export default ConfirmPinScreen;
