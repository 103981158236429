import React, { useEffect } from "react";
import styled from "styled-components/native";
import { Container } from "../shared";
import { colors } from "../colors";
import { BackHandler, Image, LogBox, Platform } from "react-native";
import { BodySmall, TitleSection } from "../Texts/Typography";
import SubmitButton from "../Buttons/SubmitButton";
import SentGif from "../../assets/gifs/sent.gif";
import { RootStackScreenProps } from "../../navigation/types";

LogBox.ignoreLogs([
  "Non-serializable values were found in the navigation state",
]);

const RequestSubmittedContainer = styled(Container)`
  background-color: ${colors.primary100};
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

const ContentSection = styled.View`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
`;

const TitleSectionText = styled(TitleSection)`
  color: ${colors.neutral0};
  text-align: center;
  margin-top: 40px;
`;

const BodySmallText = styled(BodySmall)`
  color: ${colors.neutral0};
  text-align: center;
`;

const RequestSubmittedScreen = ({
  route,
}: RootStackScreenProps<"RequestSubmitted">) => {
  const buttonTitle = route?.params?.buttonTitle;
  const buttonNavigate = route?.params?.buttonNavigate;

  const handleBackButtonClick = () => {
    buttonNavigate();
    return true;
  };

  useEffect(() => {
    if (Platform.OS === "android") {
      BackHandler.addEventListener("hardwareBackPress", handleBackButtonClick);
      return () => {
        BackHandler.removeEventListener(
          "hardwareBackPress",
          handleBackButtonClick
        );
      };
    }
  });

  return (
    <RequestSubmittedContainer>
      <ContentSection>
        <Image source={SentGif} />
        <TitleSectionText>Request submitted</TitleSectionText>
        <BodySmallText>
          Your request has being submitted successfully.{"\n"}Our customer
          representative will contact you in{"\n"}next 24-48 business hours.
        </BodySmallText>
        <SubmitButton
          textStyles={{ color: colors.neutral0 }}
          btnStyles={{
            marginTop: 40,
            backgroundColor: colors.primary100,
            borderWidth: 1,
            borderColor: colors.neutral0,
          }}
          onPress={() => {
            buttonNavigate();
          }}
        >
          {buttonTitle}
        </SubmitButton>
      </ContentSection>
    </RequestSubmittedContainer>
  );
};

export default RequestSubmittedScreen;
