import React, { useEffect, useState, Fragment } from "react";
import { useFocusEffect } from "@react-navigation/native";
import styled from "styled-components/native";
import { Platform } from "react-native";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getAbridgedRecommendedPlans,
  getMyPlanList,
} from "../../feature/plan/planSlice";
import { colors } from "../../components/colors";
import { RootStackScreenProps } from "../../navigation/types";
import { Container, Row, ScrollContainer } from "../../components/shared";
import { getGreeting } from "../../utils/getGreetings";
import * as LocalAuthentication from "expo-local-authentication";
import CustomPlanCard from "../../components/Card/CustomPlanCard";
import SelectPlanCard from "../../components/Card/SelectPlanCard";
import InviteCard from "../../components/Card/InviteCard";
import SecurityBottomSheet from "../../components/Modals/SecurityBottomSheet";
import PlanCardList from "../../components/Lists/PlanCardList";
import RecommendedPlansList from "../../components/Lists/RecommendedPlansList";
import MoreButton from "../../components/Buttons/MoreButton";
import {
  BodyDefault,
  TitleBody,
  TitleSection,
} from "../../components/Texts/Typography";
import RequestVerifyEmailModal from "../../components/Modals/RequestVerifyEmailModal";
import { isToday } from "date-fns";
import { loadImage } from "../../utils/imageUtils/loadImage";
import ToastMessage from "../../components/ToastMessages/Toast";
import {
  getNotificationList,
  setToastContent,
} from "../../feature/notification/notificationSlice";
import MyProfileImage from "../../components/Images/MyProfileImage";
import SplashScreen from "../welcome/SplashScreen";
import {
  getDevicePreferenceByKey,
  getDevicePreferences,
} from "../../utils/getDevicePreferences";
import {
  getCurrentUserDetails,
  setUserPreferences,
} from "../../feature/auth/authSlice";
import { UserPreferenceKeys } from "../../utils/constants";
import NotificationIcon from "../../components/Icons/NotificationIcon";

const HomeContainer = styled(Container)`
  background-color: ${colors.neutral0};
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  max-width: 500px;
  margin: 0 auto;
`;

const HeaderSection = styled.View`
  background-color: ${colors.primary100};
  width: 100%;
  border-radius: 0px 0px 16px 16px;
  padding: 49px 16px 35px 16px;
`;

const HeaderRow = styled(Row)`
  margin-bottom: 26px;
`;

const ContentSection = styled.View`
  width: 100%;
  height: 100%;
  padding-top: 24px;
`;

const BodyDefaultText = styled(BodyDefault)`
  color: ${colors.neutral30};
`;

const TitleSectionText = styled(TitleSection)`
  color: ${colors.neutral10};
`;

const TitleBodyText = styled(TitleBody)`
  padding-left: 16px;
`;

const RecommendedRow = styled(Row)`
  align-items: center;
  padding-right: 12px;
`;

const HomeScreen = ({ navigation }: RootStackScreenProps<"Home">) => {
  const dispatch = useAppDispatch();
  const plans = useAppSelector((state) => state.plan);
  const user = useAppSelector((state) => state.auth);
  const { toastContent } = useAppSelector((state) => state.notification);
  const [greetingMessage, setGreetingMessage] = useState("");
  const [securityVisible, setSecurityVisible] = useState(false);
  const [requestVerifyEmailVisible, setRequestVerifyEmailVisible] =
    useState(false);
  const [profileImage, setProfileImage] = useState<unknown>(null);

  useEffect(() => {
    const currentTime = new Date().getHours();
    const message = getGreeting(currentTime);
    setGreetingMessage(message);
  }, []);

  useEffect(() => {
    if (user?.currentUserDetails?.imageUrl === "REMOVED") {
      setProfileImage(null);
    } else if (user?.currentUserDetails?.imageUrl) {
      const fetchProfileImage = async () => {
        try {
          const profileURL = await loadImage(
            user?.currentUserDetails?.imageUrl
          );
          setProfileImage(profileURL);
        } catch (err) {
          setProfileImage(null);
        }
      };
      fetchProfileImage();
    }
  }, [user?.currentUserDetails?.imageUrl]);

  const init = async () => {
    if (
      user?.currentUserDetails &&
      user.currentUserDetails.status &&
      !user.currentUserDetails.incomeRange
    ) {
      navigation.navigate("AccountDetails", {
        fieldSet: 1,
        hideNavigation: true,
      });
      return;
    }

    const isSupported = await LocalAuthentication.hasHardwareAsync();
    if (
      isSupported &&
      user?.currentUserDetails?.preferences &&
      user?.currentUserDetails?.preferences?.length > 0
    ) {
      const isBiometricsEnabled = await getDevicePreferenceByKey(
        user.currentUserDetails.preferences,
        UserPreferenceKeys.isBiometricsEnabled
      );
      const isEnrolled = await LocalAuthentication.isEnrolledAsync();
      if (isEnrolled && isBiometricsEnabled === null) {
        const securityLevel = LocalAuthentication.SecurityLevel;
        const levelEnrolled = (
          await LocalAuthentication.getEnrolledLevelAsync()
        ).toString();
        if (securityLevel.BIOMETRIC.toString() === levelEnrolled) {
          setSecurityVisible(true);
        }
      }
    } else {
      checkIfEmailVerified();
    }
    await dispatch(getMyPlanList());
    await dispatch(getAbridgedRecommendedPlans());
    await dispatch(getNotificationList());
  };

  useFocusEffect(
    React.useCallback(() => {
      init();
    }, [user])
  );

  const closeToast = async () => {
    const params = {
      errorType: "",
      icon: undefined,
      content: "",
    };
    await dispatch(setToastContent(params));
  };

  const checkIfEmailVerified = () => {
    if (user?.currentUserCognitoDetails?.attributes?.email_verified === false) {
      if (user.requestVerificationEmailTimestamp === undefined) {
        return;
      } else if (user.requestVerificationEmailTimestamp) {
        const lastRequestedDate = new Date(
          user.requestVerificationEmailTimestamp
        );
        if (!isToday(lastRequestedDate)) {
          setRequestVerifyEmailVisible(true);
        }
      } else {
        setRequestVerifyEmailVisible(true);
      }
    }
  };

  useEffect(() => {
    if (user.status === "loading") {
      navigation.getParent()?.setOptions({
        tabBarStyle: { display: "none" },
        tabBarVisible: false,
      });
    } else {
      navigation.getParent()?.setOptions({
        tabBarStyle: {
          display: "block",
          height: Platform.OS === "ios" ? 100 : 88,
        },
        tabBarVisible: true,
      });
    }
  }, [user.status]);

  useEffect(() => {
    const initiateUserPreference = async () => {
      const userPreferences = await getDevicePreferences();
      if (userPreferences?.deviceId) {
        await dispatch(setUserPreferences(userPreferences));
        await dispatch(getCurrentUserDetails());
      }
    };
    initiateUserPreference();
  }, []);

  if (user.status === "loading" && plans.status === "loading") {
    return <SplashScreen />;
  }

  return (
    <Fragment>
      <HomeContainer>
        <ScrollContainer showsVerticalScrollIndicator={false}>
          <HeaderSection>
            <HeaderRow>
              <MyProfileImage
                userId={user?.currentUserDetails?.id}
                firstName={user?.currentUserDetails?.firstName}
                lastName={user?.currentUserDetails?.lastName}
                showCameraIcon={true}
                imageSrc={profileImage}
              />
              <NotificationIcon hasPrimaryOverlay={true} />
            </HeaderRow>
            <BodyDefaultText>{greetingMessage + ","}</BodyDefaultText>
            <TitleSectionText>
              {user?.currentUserDetails?.firstName}{" "}
              {user?.currentUserDetails?.lastName}
            </TitleSectionText>
          </HeaderSection>
          <ContentSection>
            <TitleBodyText>My Plans</TitleBodyText>
            {plans?.myPlanList?.length > 0 ? (
              <PlanCardList
                data={plans?.myPlanList || []}
                navigation={navigation}
              />
            ) : (
              <SelectPlanCard />
            )}
            <RecommendedRow>
              <TitleBodyText>Recommended Plans</TitleBodyText>
              <MoreButton
                onPress={() => {
                  navigation.navigate("PlansTab", { screen: "Plans" });
                }}
                name={"arrowright"}
                color={colors.primary100}
                iconStyles={{ marginTop: 2 }}
                size={14}
              >
                More
              </MoreButton>
            </RecommendedRow>
            <RecommendedPlansList />
            <CustomPlanCard navigation={navigation} />
            <TitleBodyText>Invite friends and family</TitleBodyText>
            <InviteCard />
          </ContentSection>
        </ScrollContainer>
        {toastContent.content !== "" ? (
          <ToastMessage
            type={toastContent?.errorType}
            onClose={closeToast}
            icon={toastContent?.icon}
          >
            {toastContent?.content}
          </ToastMessage>
        ) : null}
      </HomeContainer>
      <SecurityBottomSheet
        isVisible={securityVisible}
        closeBottomSheet={() => {
          setSecurityVisible(false);
          setTimeout(() => {
            checkIfEmailVerified();
          }, 500);
        }}
      />
      <RequestVerifyEmailModal
        isVisible={requestVerifyEmailVisible}
        submitButton={() => {
          navigation.navigate("ProfileTab", {
            initial: false,
            screen: "ConfirmEmail",
            params: {
              email: user?.currentUserCognitoDetails?.attributes?.email,
            },
          });
          setRequestVerifyEmailVisible(false);
        }}
        cancelButton={() => {
          setRequestVerifyEmailVisible(false);
        }}
      />
    </Fragment>
  );
};

export default HomeScreen;
