import React, { FunctionComponent } from "react";
import styled from "styled-components/native";
import { Container } from "../shared";
import { colors } from "../colors";
import { BodyDefaultBold } from "../Texts/Typography";
import ShieldIcon from "../../assets/icons/shield-icon.svg";
import CardIcon from "../../assets/icons/card-icon.svg";
import AccountBalanceIcon from "../../assets/icons/account-balance.svg";

const InformationCardContainer = styled(Container)`
  background-color: ${colors.neutral0};
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  width: 100%;
  flex: 1;
  flex-basis: auto;
  margin-bottom: 8px;
`;

const Card = styled.View`
  width: 100%;
  display: flex;
  padding: 16px 12px 16px 16px;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  border: 1px solid ${colors.neutral30};
`;

const IconContainer = styled.View`
  width: 24px;
  height: 24px;
`;

const BodyText = styled(BodyDefaultBold)`
  color: ${colors.neutral100};
  flex: 1;
`;

// const setBorderRadius = (itemIndex: number, itemLength: number) => {
//   if (itemIndex === 0) {
//     return "16px 4px 16px 16px";
//   } else if (itemIndex == itemLength - 1) {
//     return "16px 4px 16px 4px";
//   } else if (itemIndex % 2 == 0) {
//     return "16px 4px 16px 4px";
//   } else if (itemIndex % 2 != 0) {
//     return "4px 16px 4px 16px";
//   } else {
//     return "16px 16px 16px 16px";
//   }
// };

interface CardProps {
  content: string;
  icon: unknown;
  itemIndex: number;
  itemLength: number;
}

const InformationCard: FunctionComponent = (props: CardProps) => {
  return (
    <InformationCardContainer>
      <Card itemIndex={props.itemIndex} itemLength={props.itemLength}>
        <IconContainer>
          {props?.icon === "shield" ? (
            <ShieldIcon width={"100%"} height={"100%"} />
          ) : props?.icon === "card" ? (
            <CardIcon width={"100%"} height={"100%"} />
          ) : props?.icon === "account_balance" ? (
            <AccountBalanceIcon width={"100%"} height={"100%"} />
          ) : null}
        </IconContainer>
        <BodyText>{props.content}</BodyText>
      </Card>
    </InformationCardContainer>
  );
};

export default InformationCard;
