import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components/native";
import { format } from "date-fns";
import { useNavigation } from "@react-navigation/native";
import { Col, Row, ScreenWidth } from "../shared";
import { colors } from "../colors";
import {
  BodyDefaultBold,
  BodySmall,
  TitleSubsection,
} from "../Texts/Typography";
import { useAppDispatch } from "../../app/hooks";
import { CardDetailsProps } from "../../interfaces/plans";
import { formatPlanName, getPlanProgress } from "../../utils/planEnums";
import { getMyActivitiesList } from "../../feature/plan/planSlice";
import { getCardBackground, getStatusColor } from "../../utils/getColor";
import { planCardItemHeight, planCardItemWidth } from "../../utils/constants";

const componentPadding = 16 + 59; //Distance from the right side = 59

/* 
  Formula for card height to keep the aspect ratio depending on screen width:
  (original height / original width) x new width = new height
*/

const CardContainer = styled.TouchableOpacity`
  margin-top: 18px;
  margin-bottom: 32px;
  margin-right: 8px;
  width: ${Math.round(ScreenWidth - componentPadding)}px;
  height: ${Math.round(
    (planCardItemHeight / planCardItemWidth) * (ScreenWidth - componentPadding)
  )}px;
  max-width: 400px;
  max-height: 400px;
`;

const Card = styled.ImageBackground`
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 16px;
  width: 100%;
  height: 100%;
`;

const PlanDetails = styled.View`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  z-index: 1;
  padding-top: 15px;
  padding-bottom: 17px;
`;

const ProgressDash = styled.View`
  width: 25.9px;
  height: 7px;
  border-radius: 40px;
`;

const PaymentRow = styled(Row)`
  width: 100%;
  padding: 0px 20px;
  gap: 2px;
`;

const DueRow = styled(PaymentRow)`
  padding-left: 0px;
  margin-top: 0px;
`;

const DueCol = styled(Col)`
  width: 100%;
  flex: 1;
`;

const DueAmountRow = styled(Row)`
  justify-content: flex-start;
  flex: 1;
`;

const DueDateRow = styled(Row)`
  justify-content: flex-end;
  flex: 1;
`;

const GroupSection = styled.View`
  width: 100%;
`;

const BodySmallText = styled(BodySmall)`
  color: ${colors.neutral0};
  margin-left: 22px;
`;

const TitleSubsectionText = styled(TitleSubsection)`
  color: ${colors.neutral0};
  margin-left: 22px;
`;

const BodyDefaultBoldText = styled(BodyDefaultBold)`
  color: ${colors.neutral0};
  margin-left: 22px;
`;

const BodySmallBoldText = styled(BodySmall)`
  font-family: "DMSans Bold";
  color: ${colors.neutral0};
`;

const PlanCardItem: FunctionComponent<CardDetailsProps> = (props) => {
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const payments = Array(10).fill({});
  const [planProgress] = useState(payments);
  const startDate = props.startDate
    ? format(new Date(props.startDate), "dd MMM yyyy")
    : "-";

  const init = async () => {
    const result = await dispatch(getMyActivitiesList(props?.id));
    const activities = result.payload["activities"];
    const activityList = [...activities];

    if (activities.length !== 0) {
      getPlanProgress(activityList, planProgress);
    }
  };

  useEffect(() => {
    if (props?.id) {
      init();
    }
  }, []);

  return (
    <CardContainer
      onPress={() => {
        navigation.navigate("PlansTab", {
          screen: "PlanDetails",
          initial: false,
          params: {
            data: {
              index: props.index,
              id: props.id,
              name: props.customPlanName || props.name,
              amount: props.value,
              startDate: startDate,
              payoutDate: props.payoutDate,
              dueDate: startDate,
              dueAmount: props.value,
              payments: props.payments,
              rounds: props.rounds,
              totalPayout: props.totalPayout,
              roundPayout: props.roundPayout,
              status: props.status,
            },
          },
        });
      }}
      key={props.index}
    >
      <Card key={props.index} source={getCardBackground(props.index)}>
        <PlanDetails>
          <BodySmallText>{formatPlanName(props.customPlanName)}</BodySmallText>
          <TitleSubsectionText>${props.value}.00/month</TitleSubsectionText>
          <DueCol>
            <GroupSection>
              <BodySmallText style={{ fontSize: 10 }}>Start Date</BodySmallText>
              <BodyDefaultBoldText>{startDate}</BodyDefaultBoldText>
            </GroupSection>
            <GroupSection>
              <PaymentRow>
                <Row style={{ gap: 2 }}>
                  {planProgress?.slice(0, 5).map((progress, index) => (
                    <ProgressDash
                      key={`PlanCardProgressLeft-${index}`}
                      style={{
                        backgroundColor: getStatusColor(
                          progress?.status,
                          progress?.event
                        ),
                      }}
                    />
                  ))}
                </Row>
                <Row style={{ gap: 2 }}>
                  {planProgress?.slice(5, 10).map((progress, index) => (
                    <ProgressDash
                      key={`PlanCardProgressRight-${index}`}
                      style={{
                        backgroundColor: getStatusColor(
                          progress?.status,
                          progress?.event
                        ),
                      }}
                    />
                  ))}
                </Row>
              </PaymentRow>
              <DueRow>
                <DueAmountRow>
                  <BodySmallText>Due amount </BodySmallText>
                  <BodySmallBoldText>${props.value}.00</BodySmallBoldText>
                </DueAmountRow>
                <DueDateRow>
                  <BodySmallText>Due date </BodySmallText>
                  <BodySmallBoldText>{startDate}</BodySmallBoldText>
                </DueDateRow>
              </DueRow>
            </GroupSection>
          </DueCol>
        </PlanDetails>
      </Card>
    </CardContainer>
  );
};

export default PlanCardItem;
