import React, { FunctionComponent } from "react";
import styled from "styled-components/native";
import { MaterialIcons } from "@expo/vector-icons";
import { GestureResponderEvent, StyleProp, ViewStyle } from "react-native";

const ButtonView = styled.TouchableOpacity`
  align-items: center;
  justify-content: center;
`;

// types
interface ButtonProps {
  color?: string;
  btnStyles?: StyleProp<ViewStyle>;
  iconStyles?: StyleProp<ViewStyle>;
  onPress: ((event: GestureResponderEvent) => void) | undefined;
  name: string;
  size?: number;
}

const IconButton: FunctionComponent<ButtonProps> = (props) => {
  return (
    <ButtonView onPress={props.onPress} style={props.btnStyles}>
      <MaterialIcons
        name={props.name}
        size={props.size || 35}
        style={props.iconStyles}
        color={props.color}
      />
    </ButtonView>
  );
};

export default IconButton;
