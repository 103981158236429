import React, { FunctionComponent } from "react";
import styled from "styled-components/native";
import { MaterialIcons } from "@expo/vector-icons";
import { NotificationsCardItemProps } from "../../interfaces/notifications";
import { colors } from "../colors";
import { Row } from "../shared";
import { BodyLargeBold, BodySmall } from "../Texts/Typography";
import { NOTIFICATION_STATUS, PAYMENT_STATUS } from "../../utils/enums";

import BulletIcon from "../../assets/icons/bullet-icon.svg";

const CardContainer = styled.View`
  width: 100%;
`;

const Divider = styled.View`
  width: 100%;
  border: 0.5px ${colors.neutral30};
  margin: 8px 0px 12px 0px;
`;

const BodySmallText = styled(BodySmall)`
  color: ${colors.neutral60};
`;

const NotificationGroup = styled.TouchableOpacity`
  width: 100%;
  margin-bottom: 12px;
`;

const BulletTextGroup = styled(Row)`
  justify-content: flex-start;
`;

const BulletSection = styled.View`
  justify-content: center;
  margin-right: 10px;
`;

const NotificationCardItem: FunctionComponent<NotificationsCardItemProps> = (
  props
) => {
  return (
    <CardContainer>
      <BodySmallText>{props.date}</BodySmallText>
      <Divider />
      {props.notifications.map((notification, index) => (
        <NotificationGroup
          key={`Notification-${index}`}
          onPress={() => {
            if (props.onPress) {
              props.onPress(notification);
            }
          }}
        >
          <Row>
            <BulletTextGroup>
              {notification.status === NOTIFICATION_STATUS.UNREAD ? (
                <BulletSection>
                  <BulletIcon />
                </BulletSection>
              ) : null}

              <BodyLargeBold
                style={{
                  color:
                    notification.paymentStatus === PAYMENT_STATUS.FAIL
                      ? colors.error
                      : colors.neutral90,
                }}
              >
                {notification.messageTitle}
              </BodyLargeBold>
            </BulletTextGroup>
            <MaterialIcons
              name={"arrow-forward"}
              size={15}
              color={colors.neutral90}
            />
          </Row>
          <BodySmallText
            style={{
              marginLeft:
                notification.status === NOTIFICATION_STATUS.UNREAD ? 18 : 0,
              paddingRight: 10,
            }}
          >
            {notification.message}
          </BodySmallText>
        </NotificationGroup>
      ))}
    </CardContainer>
  );
};

export default NotificationCardItem;
