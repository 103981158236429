import {
  NotificationItem,
  NotificationsCardItemProps,
} from "../../../interfaces/notifications";
import { compareDesc, format } from "date-fns";

export const formatNotificationList = (
  notificationList: NotificationItem[]
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dateGroup: any = {};
  notificationList.sort((a, b) =>
    compareDesc(new Date(a.createdAt), new Date(b.createdAt))
  );
  notificationList.forEach((notification) => {
    if (notification.createdAt) {
      const date = format(new Date(notification.createdAt), "d MMM yyyy");
      if (date in dateGroup) {
        dateGroup[date].push(notification);
      } else {
        dateGroup[date] = new Array(notification);
      }
    }
  });
  const formattedNotificationList: NotificationsCardItemProps[] = Object.keys(
    dateGroup
  ).map((date, index) => {
    return {
      id: index + 1,
      index,
      date,
      notifications: dateGroup[date],
    };
  });
  return formattedNotificationList;
};
