import React from "react";
import styled from "styled-components/native";
import MyActivityCardItem from "../Card/MyActivityCardItem";
import { TitleSubsection } from "../Texts/Typography";
import { MyActivityListProps } from "../../interfaces/plans";

const CardContainer = styled.View`
  width: 100%;
  padding: 0px 16px;
  gap: 24px;
`;

const ContentSection = styled.View`
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
`;

const MyActivitiesList: React.FC<MyActivityListProps> = (props) => {
  return (
    <CardContainer>
      {props.loading ? null : props?.data?.length > 0 ? (
        props?.data.map((item, index: number) => {
          return (
            <MyActivityCardItem {...item} key={`MyActivityItem-${index}`} />
          );
        })
      ) : (
        <ContentSection>
          <TitleSubsection>No activities</TitleSubsection>
        </ContentSection>
      )}
    </CardContainer>
  );
};

export default MyActivitiesList;
