import React, { useState, useRef, Fragment } from "react";
import { View } from "react-native";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Formik } from "formik";
import styled from "styled-components/native";
import { RootStackScreenProps } from "../../../navigation/types";
import { ScrollContainer } from "../../../components/shared";
import { confirmPasswordSchema } from "../../../utils/schema";
import FieldText from "../../../components/FormFields/FieldText";
import { confirmPasswordParam } from "../../../interfaces/auth";
import eyeClose from "../../../assets/icons/eye-close.png";
import eyeOpen from "../../../assets/icons/eye-open.png";

import WaitingModal from "../../../components/Modals/WaitingModal";
import { signInUser } from "../../../feature/auth/authSlice";
import ToastMessage from "../../../components/ToastMessages/Toast";
import PageTemplateScreen from "../../../components/Templates/PageTemplate";

const FieldSection = styled.View`
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  align-items: center;
  flex-direction: column;
`;

const ConfirmPasswordScreen = ({
  navigation,
}: RootStackScreenProps<"ConfirmPassword">) => {
  const confirmPasswordFormRef = useRef(null);
  const { currentUserCognitoDetails } = useAppSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);

  const [activeField, setActiveField] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const [toastVisible, setToastVisible] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const dispatch = useAppDispatch();

  const triggerSubmit = () => {
    if (confirmPasswordFormRef.current) {
      confirmPasswordFormRef.current.handleSubmit();
    }
  };
  const handleSubmit = async (values: confirmPasswordParam) => {
    try {
      const username = currentUserCognitoDetails.attributes.phone_number;
      const { password } = values;
      setLoading(true);
      const result = await dispatch(
        signInUser({
          username,
          password,
        })
      );
      if (result?.meta?.requestStatus === "fulfilled") {
        navigation.navigate("PinSetup", { fromProfile: true });
      } else if (result?.error?.message === "Incorrect username or password.") {
        setToastVisible(true);
        setError("Invalid password.");
      } else {
        setToastVisible(true);
        setError(
          result?.error?.message ||
            "System not available. Please try again later."
        );
      }
    } catch (error) {
      setToastVisible(true);
      setError("System not available. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <Fragment>
      <PageTemplateScreen
        header="Enter your password"
        subHeader="Enter your current password to verify yourself."
        submitTitle="Continue"
        submitButtonEditMode={activeField}
        onSubmit={() => triggerSubmit()}
        submitDisabled={loading}
        backButtonIcon="arrow-back"
        keyboardVerticalOffset={-180}
        hideNotifications={true}
      >
        <ScrollContainer
          automaticallyAdjustKeyboardInsets={true}
          showsVerticalScrollIndicator={false}
        >
          <View onStartShouldSetResponder={() => true}>
            <Formik
              initialValues={{
                password: "",
              }}
              validationSchema={confirmPasswordSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={handleSubmit}
              innerRef={confirmPasswordFormRef}
            >
              {({ values, errors }) => (
                <FieldSection>
                  <FieldText
                    key={"password"}
                    label="Current Password"
                    fieldName="password"
                    onShow={() => {
                      setActiveField(true);
                    }}
                    onHide={() => {
                      setActiveField(false);
                    }}
                    value={values.password}
                    icon={eyeClose}
                    icon2={eyeOpen}
                    showIcon2={showPassword}
                    secureTextEntry={!showPassword}
                    onIconPress={() => {
                      setShowPassword((prevState: boolean) => !prevState);
                    }}
                    error={errors.password}
                  />
                </FieldSection>
              )}
            </Formik>
          </View>
        </ScrollContainer>
      </PageTemplateScreen>
      <WaitingModal visible={loading} />
      {toastVisible ? (
        <ToastMessage type="error" onClose={() => setToastVisible(false)}>
          {error}
        </ToastMessage>
      ) : null}
    </Fragment>
  );
};

export default ConfirmPasswordScreen;
