import styled from "styled-components/native";
import { PixelRatio } from "react-native";

const LineHeightModifier = 0;

const fontScale = PixelRatio.getFontScale();

export const TitleScreen = styled.Text`
  font-family: "DMSans Bold";
  font-size: ${32 / fontScale}px;
  line-height: ${40 - LineHeightModifier}px;
`;

export const TitleSection = styled.Text`
  font-family: "DMSans Bold";
  font-size: ${26 / fontScale}px;
  line-height: ${32 - LineHeightModifier}px;
`;

export const TitleSubsection = styled.Text`
  font-family: "DMSans Bold";
  font-size: ${22 / fontScale}px;
  line-height: ${28 - LineHeightModifier}px;
`;

export const TitleBody = styled.Text`
  font-family: "DMSans Bold";
  font-size: ${18 / fontScale}px;
  line-height: ${24 - LineHeightModifier}px;
`;

export const BodyLarge = styled.Text`
  font-family: "DMSans";
  font-size: ${16 / fontScale}px;
  line-height: ${24 - LineHeightModifier}px;
`;

export const BodyLargeBold = styled.Text`
  font-family: "DMSans Bold";
  font-size: ${16 / fontScale}px;
  line-height: ${24 - LineHeightModifier}px;
`;

export const BodyDefault = styled.Text`
  font-family: "DMSans";
  font-size: ${14 / fontScale}px;
  line-height: ${22 - LineHeightModifier}px;
`;

export const BodyDefaultBold = styled.Text`
  font-family: "DMSans Bold";
  font-size: ${14 / fontScale}px;
  line-height: ${22 - LineHeightModifier}px;
`;

export const BodySmall = styled.Text`
  font-family: "DMSans";
  font-size: ${12 / fontScale}px;
  line-height: ${22 - LineHeightModifier}px;
`;

export const LinkLarge = styled.Text`
  font-family: "DMSans Bold";
  text-decoration-line: underline;
  font-size: ${16 / fontScale}px;
  line-height: ${24 - LineHeightModifier}px;
`;

export const LinkDefault = styled.Text`
  font-family: "DMSans Bold";
  text-decoration-line: underline;
  font-size: ${14 / fontScale}px;
  line-height: ${22 - LineHeightModifier}px;
`;
