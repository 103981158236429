import { LinkingOptions } from "@react-navigation/native";
import * as Linking from "expo-linking";

import { RootStackParamList } from "./types";

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.makeUrl("/")],
  config: {
    screens: {
      Root: {
        screens: {
          HomeTab: {
            screens: {
              HomeScreen: "one",
            },
          },
          PlansTab: {
            screens: {
              PlanScreen: "two",
            },
          },
          ProfileTab: {
            screens: {
              ProfileScreen: "three",
            },
          },
        },
      },
    },
  },
};

export default linking;
