import React from "react";
import { Modal } from "react-native";
import styled from "styled-components/native";

const ModalView = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const StyledActivityIndicator = styled.ActivityIndicator`
  position: absolute;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

type WaitScreenProps = {
  visible: boolean;
};

const WaitingModal = ({ visible }: WaitScreenProps) => {
  return (
    <Modal animationType="slide" transparent={true} visible={visible}>
      <ModalView>
        <StyledActivityIndicator size="large" color="red" />
      </ModalView>
    </Modal>
  );
};

export default WaitingModal;
