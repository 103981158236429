import React, { useState, useRef, Fragment } from "react";
import { View } from "react-native";
import { Formik } from "formik";
import styled from "styled-components/native";
import { useAppDispatch } from "../../../app/hooks";
import { RootStackScreenProps } from "../../../navigation/types";
import { ScrollContainer } from "../../../components/shared";
import { registerAccountSchema } from "../../../utils/schema";
import { passwordChecker } from "../../../utils/passwordStrengthMeter";
import AuthTemplateScreen from "../../../components/Templates/AuthTemplate";
import FieldText from "../../../components/FormFields/FieldText";
import { signUpParam, passwordStrengthType } from "../../../interfaces/auth";
import eyeClose from "../../../assets/icons/eye-close.png";
import eyeOpen from "../../../assets/icons/eye-open.png";

import WaitingModal from "../../../components/Modals/WaitingModal";
import { resetSignUp, signUpUser } from "../../../feature/auth/authSlice";
import PasswordStrengthText from "../../../components/Texts/PasswordStrengthText";
import { BodySmall } from "../../../components/Texts/Typography";
import { colors } from "../../../components/colors";
import ToastMessage from "../../../components/ToastMessages/Toast";

const FieldSection = styled.View`
  margin-top: 24px;
  padding: 0px 16px;
  width: 100%;
  align-items: center;
  flex-direction: column;
`;

const BodySmallText = styled(BodySmall)`
  width: 100%;
  color: ${colors.neutral90};
`;

const SignUpScreen = ({ navigation }: RootStackScreenProps<"SignUp">) => {
  const registerFormRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const [activeField, setActiveField] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] =
    useState<passwordStrengthType | null>(null);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [toastVisible, setToastVisible] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const dispatch = useAppDispatch();

  const triggerSubmit = () => {
    if (registerFormRef.current) {
      registerFormRef.current.handleSubmit();
    }
  };

  const handleSubmit = async (values: signUpParam) => {
    try {
      const { email, password, phone_number } = values;
      const countryCode = "+61";
      const finalMobileNumber = countryCode + phone_number;
      setLoading(true);
      setToastVisible(false);
      setError(null);

      const result = await dispatch(
        signUpUser({
          email,
          password,
          phone_number: finalMobileNumber,
        })
      );
      if (result?.error?.name == "UsernameExistsException") {
        const resetSignUpResult = await dispatch(
          resetSignUp({ phone_number: finalMobileNumber })
        );
        if (resetSignUpResult?.meta?.requestStatus == "fulfilled") {
          const resendSignUp = await dispatch(
            signUpUser({
              email,
              password,
              phone_number: finalMobileNumber,
            })
          );
          if (resendSignUp?.meta?.requestStatus === "fulfilled") {
            navigation.navigate("VerifyAccount", {
              type: "SignUp",
              phone_number: finalMobileNumber,
              password,
            });
          } else {
            setToastVisible(true);
            setError(
              resendSignUp?.error?.message ||
                "System not available. Please try again later."
            );
          }
        } else {
          setToastVisible(true);
          setError(
            resetSignUpResult?.error?.message ||
              "System not available. Please try again later."
          );
        }
      } else if (result?.meta?.requestStatus === "fulfilled") {
        navigation.navigate("VerifyAccount", {
          type: "SignUp",
          phone_number: finalMobileNumber,
          password,
        });
      } else if (
        result?.error?.message ===
        "PreSignUp failed with error Email already Exists."
      ) {
        setToastVisible(true);
        setError("User with email already exists.");
      } else {
        setToastVisible(true);
        setError(
          result?.error?.message ||
            "System not available. Please try again later."
        );
      }
    } catch (error) {
      setToastVisible(true);
      setError("System not available. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <Fragment>
      <AuthTemplateScreen
        header="Create account"
        subHeader="Have an account?"
        subHeaderLink="Sign In"
        submitTitle="Continue"
        onSubHeaderLink={() => navigation.navigate("SignIn")}
        navigation={navigation}
        submitButtonEditMode={activeField}
        onSubmit={() => triggerSubmit()}
        submitDisabled={loading}
        keyboardVerticalOffset={-180}
      >
        <ScrollContainer
          automaticallyAdjustKeyboardInsets={true}
          showsVerticalScrollIndicator={false}
        >
          <View onStartShouldSetResponder={() => true}>
            <Formik
              initialValues={{
                email: "",
                password: "",
                confirmPassword: "",
                phone_number: "",
              }}
              validationSchema={registerAccountSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={handleSubmit}
              innerRef={registerFormRef}
            >
              {({ values, errors }) => (
                <FieldSection>
                  <FieldText
                    key={"email"}
                    label="Email"
                    fieldName="email"
                    onShow={() => {
                      setActiveField(true);
                    }}
                    onHide={() => {
                      setActiveField(false);
                    }}
                    value={values.email}
                    error={errors.email}
                  />
                  <FieldText
                    key={"password"}
                    label="Password"
                    fieldName="password"
                    onChange={(val) => {
                      if (val !== "") {
                        setPasswordStrength(passwordChecker(val));
                      } else {
                        setPasswordStrength(null);
                      }
                    }}
                    onShow={() => {
                      setActiveField(true);
                    }}
                    onHide={() => {
                      setActiveField(false);
                    }}
                    validateOnChange={true}
                    value={values.password}
                    icon={eyeClose}
                    icon2={eyeOpen}
                    showIcon2={showPassword}
                    secureTextEntry={!showPassword}
                    onIconPress={() => {
                      setShowPassword((prevState: boolean) => !prevState);
                    }}
                    children={() =>
                      !errors.password && passwordStrength ? (
                        <PasswordStrengthText
                          strength={passwordStrength?.strength}
                        >
                          {passwordStrength?.val}
                        </PasswordStrengthText>
                      ) : null
                    }
                    error={errors.password}
                  />
                  <FieldText
                    key={"confirmPassword"}
                    label="Confirm Password"
                    fieldName="confirmPassword"
                    validateOnChange={true}
                    value={values.confirmPassword}
                    icon={eyeClose}
                    icon2={eyeOpen}
                    onShow={() => {
                      setActiveField(true);
                    }}
                    onHide={() => {
                      setActiveField(false);
                    }}
                    showIcon2={showConfirmPassword}
                    secureTextEntry={!showConfirmPassword}
                    onIconPress={() => {
                      setShowConfirmPassword(
                        (prevState: boolean) => !prevState
                      );
                    }}
                    error={errors.confirmPassword}
                  />
                  <FieldText
                    key={"phone_number"}
                    label="Phone number"
                    fieldName="phone_number"
                    isMobileNumber={true}
                    keyboardType="numeric"
                    maxLength={9}
                    onShow={() => {
                      setActiveField(true);
                    }}
                    onHide={() => {
                      setActiveField(false);
                    }}
                    value={values.phone_number}
                    error={errors.phone_number}
                  />
                  <BodySmallText>
                    For greater security, we will send a code to verify.
                  </BodySmallText>
                </FieldSection>
              )}
            </Formik>
          </View>
        </ScrollContainer>
      </AuthTemplateScreen>
      <WaitingModal visible={loading} />
      {toastVisible ? (
        <ToastMessage type="error" onClose={() => setToastVisible(false)}>
          {error}
        </ToastMessage>
      ) : null}
    </Fragment>
  );
};

export default SignUpScreen;
