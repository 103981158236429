import React, { FunctionComponent, useState } from "react";
import styled from "styled-components/native";
import { GestureResponderEvent, Alert, Linking } from "react-native";
import * as ImagePicker from "expo-image-picker";
import { getInitialName } from "../../utils/formatText";
import { colors } from "../colors";
import { BodyDefaultBold } from "../Texts/Typography";
import CameraIcon from "../../assets/icons/camera-icon.svg";
import { useAppDispatch } from "../../app/hooks";
import { useActionSheet } from "@expo/react-native-action-sheet";
import ToastMessage from "../ToastMessages/Toast";
import {
  getCurrentUserDetails,
  updateAccountDetails,
  uploadProfileImage,
} from "../../feature/auth/authSlice";
import { requestBlob } from "../../utils/imageUtils/requestBlob";
import { getUnixTime } from "date-fns";
import WaitingModal from "../Modals/WaitingModal";

const ProfileSection = styled.TouchableOpacity`
  background-color: ${(props) =>
    props.notWhiteBg ? colors.neutral20 : colors.neutral0};
  width: ${(props) => (props.size ? props.size : `34`)}px;
  height: ${(props) => (props.size ? props.size : `34`)}px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const ProfilePicture = styled.Image`
  width: 100%;
  height: 100%;
  border-radius: 100px;
`;

const BodyDefaultBoldText = styled(BodyDefaultBold)`
  color: ${colors.primary100};
`;

// types
interface ProfileImageProps {
  userId: string;
  onPress?: ((event: GestureResponderEvent) => void) | undefined;
  onLongPress?: ((event: GestureResponderEvent) => void) | undefined;
  imageSrc?: unknown;
  firstName: string;
  lastName: string;
  showCameraIcon?: boolean;
  size?: number;
  notWhiteBg?: boolean;
}

const MyProfileImage: FunctionComponent<ProfileImageProps> = (props) => {
  const dispatch = useAppDispatch();
  const { showActionSheetWithOptions } = useActionSheet();

  const [loading, setLoading] = useState(false);
  const [showCameraIcon, setShowCameraIcon] = useState<boolean | undefined>(
    props.showCameraIcon ? false : undefined
  );
  const [toastVisible, setToastVisible] = useState(false);
  const [toastError, setToastError] = useState<string | null>(null);

  const changeProfilePicture = () => {
    const options = [
      "Choose from library",
      "Take a photo",
      "Remove current photo",
      "Cancel",
    ];
    const destructiveButtonIndex = 2;
    const cancelButtonIndex = 3;

    showActionSheetWithOptions(
      {
        title: "Change Profile Picture",
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
      },
      (selectedIndex) => {
        switch (selectedIndex) {
          case 0:
            openImagePicker();
            break;

          case 1:
            openCamera();
            break;

          case destructiveButtonIndex:
            removeProfileImage();
            break;

          case cancelButtonIndex:
          // Canceled
        }
      }
    );
  };

  const requestCameraPermissionAlert = () => {
    Alert.alert("Enable access to camera", "", [
      { text: "Go to Settings", onPress: () => Linking.openSettings() },
      {
        text: "Cancel",
        onPress: () => {},
      },
    ]);
  };

  const openImagePicker = async () => {
    try {
      setToastError(null);
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [1, 1],
        quality: 1,
      });
      if (result && result.assets) {
        setLoading(true);
        const blob = await requestBlob(result.assets[0].uri);
        if (blob) {
          await handleImageResult(blob);
        } else {
          setToastVisible(true);
          setToastError("Error processing image. Please try again later.");
        }
      }
    } catch (error) {
      setToastVisible(true);
      setToastError("System not available. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const openCamera = async () => {
    try {
      setToastError(null);
      const permission = await ImagePicker.requestCameraPermissionsAsync();
      if (permission.granted === true) {
        const result = await ImagePicker.launchCameraAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          allowsEditing: true,
          aspect: [1, 1],
          quality: 1,
        });
        if (result && result.assets) {
          setLoading(true);
          const blob = await requestBlob(result.assets[0].uri);
          if (blob) {
            handleImageResult(blob);
          } else {
            setLoading(false);
            setToastVisible(true);
            setToastError("Error processing image. Please try again later.");
          }
        }
      } else if (permission.canAskAgain === false) {
        requestCameraPermissionAlert();
      } else {
        setToastVisible(true);
        setToastError("System not available. Please try again later.");
      }
    } catch (error) {
      setLoading(false);
      setToastVisible(true);
      setToastError("System not available. Please try again later.");
    }
  };

  const handleImageResult = async (imageBlob: Blob) => {
    try {
      if (imageBlob && props.userId) {
        const timestamp = getUnixTime(new Date());
        const imageKey = timestamp + "-" + props.userId;
        const param = {
          key: imageKey,
          file: imageBlob,
        };
        // upload Image to S3 bucket
        await dispatch(uploadProfileImage(param));
        // update User Details with the generated image key
        await dispatch(updateAccountDetails({ imageUrl: imageKey }));
        //refresh User Details
        await dispatch(getCurrentUserDetails());
      } else {
        setToastVisible(true);
        setToastError("System not available. Please try again later.");
      }
    } catch (error) {
      setToastVisible(true);
      setToastError("System not available. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const removeProfileImage = async () => {
    try {
      setLoading(true);
      await dispatch(updateAccountDetails({ imageUrl: "REMOVED" }));
      await dispatch(getCurrentUserDetails());
      if (props.showCameraIcon && showCameraIcon) {
        setShowCameraIcon(false);
      }
    } catch (error) {
      setToastVisible(true);
      setToastError("System not available. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleLongPress = () => {
    if (props.showCameraIcon) {
      setShowCameraIcon(!showCameraIcon);
    }
  };

  const handlePress = () => {
    if (props.showCameraIcon && !showCameraIcon) {
      return;
    }
    changeProfilePicture();
  };

  return (
    <ProfileSection
      size={props.size}
      onPress={handlePress}
      onLongPress={handleLongPress}
      activeOpacity={props.showCameraIcon && !showCameraIcon ? 1 : undefined}
      notWhiteBg={props.notWhiteBg}
    >
      {props.imageSrc ? (
        <ProfilePicture source={{ uri: props.imageSrc }} />
      ) : showCameraIcon ? (
        <CameraIcon />
      ) : (
        props.firstName &&
        props.lastName && (
          <BodyDefaultBoldText>
            {getInitialName(props.firstName, props.lastName)}
          </BodyDefaultBoldText>
        )
      )}
      {toastVisible ? (
        <ToastMessage type="error" onClose={() => setToastVisible(false)}>
          {toastError}
        </ToastMessage>
      ) : null}
      <WaitingModal visible={loading} />
    </ProfileSection>
  );
};

export default MyProfileImage;
