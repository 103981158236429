import { format, addMonths, setDate } from "date-fns";
import { EVENT, PAYMENT_STATUS } from "./enums";
import { planReasonsList } from "./constants";

export const getDate = (dayOfMonth: number, isNextMonth = false) => {
  let currentDate = new Date();
  currentDate = setDate(currentDate, dayOfMonth);

  if (isNextMonth || currentDate < new Date()) {
    currentDate = addMonths(currentDate, 1);
  }

  return format(currentDate, "dd/MM/yyyy");
};

export const getStartDate = (isNextMonth = false) => {
  return getDate(27, isNextMonth);
};

export const getClosingDate = (isNextMonth = false) => {
  return getDate(26, isNextMonth);
};

export const getOtherFeatures = (roundPayout: number) => {
  const features = [
    "Draw System: Payouts occur monthly, one day after your debit, with a new recipient selected through a random draw.",
    "Order of Payout: The order reverses in the second cycle, ensuring fairness in borrowing order",
    "Usage: Use funds for any financial goal",
    `Exclusive Benefits: Member discounts. Opportunity to receive a lump sum of $${roundPayout} in a single month`,
  ];
  return features;
};

export const getPlanProgress = (activityList: [], planProgress: []) => {
  //SORTED BY DATE
  const sortedByDateList = activityList?.sort((a, b) =>
    b.actionedAt.localeCompare(a.actionedAt)
  );

  planProgress.map((_, index) => {
    return sortedByDateList
      .filter((item) => item?.eventOrder === index + 1)
      .forEach((data) => {
        if (data?.event === EVENT.PAYOUT) {
          planProgress[index] = data;
          return;
        }

        if (
          data?.event === EVENT.CONTRIBUTION &&
          data?.status === PAYMENT_STATUS.FAIL
        ) {
          planProgress[index] = data;
          return;
        }

        if (
          data?.event === EVENT.CONTRIBUTION &&
          data?.status === PAYMENT_STATUS.SUCCESS
        ) {
          planProgress[index] = data;
          return;
        }
        return;
      });
  });
};

export const formatPlanName = (name = "") => {
  let returnValue = "-";
  const planReason = planReasonsList.filter((reason) => reason.value === name);
  if (planReason.length === 1) {
    return (returnValue = planReason[0].label);
  } else if (name !== "") {
    return name;
  } else {
    return returnValue;
  }
};
