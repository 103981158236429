import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components/native";
import { useAppDispatch } from "../../../app/hooks";
import { Container } from "../../../components/shared";
import { colors } from "../../../components/colors";
import { BackHandler, Platform } from "react-native";
import { BodySmall, TitleSection } from "../../../components/Texts/Typography";
import SubmitButton from "../../../components/Buttons/SubmitButton";
import { RootStackScreenProps } from "../../../navigation/types";
import {
  disableUserPushToken,
  signOutUser,
  userReactivateAccount,
} from "../../../feature/auth/authSlice";
import { USER_STATUS } from "../../../utils/constants";
import AlertModal from "../../../components/Modals/AlertModal";

const RequestSubmittedContainer = styled(Container)`
  background-color: ${colors.primary100};
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

const ContentSection = styled.View`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
`;

const TitleSectionText = styled(TitleSection)`
  color: ${colors.neutral0};
  text-align: center;
  margin-top: 40px;
`;

const BodySmallText = styled(BodySmall)`
  color: ${colors.neutral0};
  text-align: center;
  padding: 0px 16px;
`;

const ReactivateAccountScreen = ({
  navigation,
}: RootStackScreenProps<"ReactivateAccount">) => {
  const dispatch = useAppDispatch();
  const [alertVisible, setAlertVisible] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const reactivateAccount = async () => {
    const param = {
      status: USER_STATUS.ACTIVE,
    };
    const result = await dispatch(userReactivateAccount(param));
    if (result.meta.requestStatus === "fulfilled") {
      navigation.navigate("BottomNav");
    }
  };

  const signOut = async () => {
    setAlertVisible(false);
    await dispatch(disableUserPushToken());
    await dispatch(signOutUser());
  };

  const handleBackButtonClick = () => {
    setAlertVisible(true);
    setError("Are you sure want to log out from Chippit?");
    return true;
  };

  useEffect(() => {
    if (Platform.OS === "android") {
      BackHandler.addEventListener("hardwareBackPress", handleBackButtonClick);
      return () => {
        BackHandler.removeEventListener(
          "hardwareBackPress",
          handleBackButtonClick
        );
      };
    }
  });

  return (
    <Fragment>
      <RequestSubmittedContainer>
        <ContentSection>
          <TitleSectionText>Deactivate Account</TitleSectionText>
          <BodySmallText>
            Your account was deactivated at some point earlier. You can easily
            reactivate it with just one click and resume using the app.
          </BodySmallText>
          <SubmitButton
            btnStyles={{
              marginTop: 40,
            }}
            onPress={() => {
              reactivateAccount();
            }}
            secondary={true}
          >
            Reactivate account
          </SubmitButton>
          <SubmitButton
            textStyles={{ color: colors.neutral0 }}
            btnStyles={{
              marginTop: 14,
              backgroundColor: colors.primary100,
              borderWidth: 1,
              borderColor: colors.neutral0,
            }}
            onPress={() => {
              setAlertVisible(true);
              setError("Are you sure want to log out from Chippit?");
            }}
          >
            Logout
          </SubmitButton>
        </ContentSection>
      </RequestSubmittedContainer>
      <AlertModal
        isVisible={alertVisible}
        title="Log out"
        onOkay={() => {
          signOut();
        }}
        okayTitle="Yes, log out"
        cancelTitle="Cancel"
        onCancel={() => {
          setAlertVisible(false);
          setError(null);
        }}
      >
        {error}
      </AlertModal>
    </Fragment>
  );
};

export default ReactivateAccountScreen;
