import React from "react";
// import { View } from "react-native";
import styled from "styled-components/native";
import { colors } from "../../../components/colors";
import { Container } from "../../../components/shared";
import PageTemplateScreen from "../../../components/Templates/PageTemplate";
import { BodyDefault, TitleBody } from "../../../components/Texts/Typography";
import { RootStackScreenProps } from "../../../navigation/types";

const PrivacyStatementContainer = styled(Container)`
  background-color: ${colors.neutral0};
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

const ContentSection = styled.View`
  width: 100%;
  padding: 0px 16px;
`;

const TitleBodyText = styled(TitleBody)`
  color: ${colors.neutral90};
`;

const BodyDefaultText = styled(BodyDefault)`
  color: ${colors.neutral90};
`;

const PrivacyStatementScreen = ({
  route,
}: RootStackScreenProps<"PrivacyStatement">) => {
  const type = route?.params?.type;
  return (
    <PrivacyStatementContainer>
      {/* <ScrollContainer
        automaticallyAdjustKeyboardInsets={true}
        showsVerticalScrollIndicator={false}
      >
        <View onStartShouldSetResponder={() => true}> */}
      <PageTemplateScreen
        header="Privacy Statement"
        hideSubmitButton={true}
        hideNotifications={type === "Marketing" ? true : false}
      >
        <ContentSection>
          <BodyDefaultText>
            At Chippit Pty Ltd, we are committed to protecting your privacy in
            accordance with the Privacy Act 1988 (Cth). This Policy describes
            our policies and practices for collecting, handling, storing, using
            and disclosing personal information. It also deals with how you can
            complain about a breach of the privacy laws, access the personal
            information we hold about you and have that information corrected
            (where necessary).
          </BodyDefaultText>
          <TitleBodyText>
            {"\n"}What personal information do we collect and hold?{"\n"}
          </TitleBodyText>
          <BodyDefaultText>
            When we provide our mobile in-application services to you, we ask
            you for the information we need to enable you to send and receive
            money, and to verify your identity as part of our anti-money
            laundering obligations. We collect your information through our
            mobile application and website forms and when we interact with you
            via email or telephone. This can include a broad range of
            information from your name, address, contact details, national
            identification documentation (e.g. passport), age, and information
            about your personal affairs (e.g. bank statements) and address
            verification.{"\n"}We may also collect your information from third
            parties e.g. our identity verification and payment partners.{"\n"}We
            only collect sensitive information such as information about your
            race or health with your agreement and it is necessary for us to do
            so. These details may be reflected on your passport or national
            identity document that you may be required to provide when wanting
            to transact.
          </BodyDefaultText>
          <TitleBodyText>
            {"\n"}How do we use your information?{"\n"}
          </TitleBodyText>
          <BodyDefaultText>
            We use your personal information to identify you and contact you
            when using our services.{"\n\n"}We also use your personal
            information to:{"\n\n"}To verify your identity so that you have the
            ability to transact on our mobile application{"\n\n"}
            To manage your account when you register with us so that we can give
            you access to the difference services that are available to you when
            you register with us{"\n\n"}To provide, maintain and monitor our
            ongoing services to you{"\n\n"}To manage your ongoing requirements
            and our relationships with you including contacting you by mail or
            electronically (unless you tell us you do not wish to receive
            electronic communications) and adequately responding to your queries
            {"\n\n"}To keep you updated and informed from time to time we will
            use your contact details to send you offers, updates, articles,
            newsletters or other information about products and services that we
            believe will be of interest to you. We may also send you regular
            updates by email. We will always give you the option of electing not
            to receive these communications and you can unsubscribe at any time
            by notifying us that you wish to do so.{"\n\n"}For other purposes
            such as data analysis, identifying trends, effectiveness of
            promotional campaigns, product improvement and your user experience
            {"\n\n"}If our business is sold or is changing hands to ensure that
            the services can be continued to be provided to you.
          </BodyDefaultText>
          <TitleBodyText>
            {"\n"}What if you don’t provide information to us?{"\n"}
          </TitleBodyText>
          <BodyDefaultText>
            If you do not provide us with some or all the information that we
            ask for, we may not be able to proceed with your transaction request
            and/or continue to provide you with our services on our mobile
            application.{"\n"}You can contact us without using your name or by
            using a pseudonym. However we may need your name or contact details
            to respond to you.
          </BodyDefaultText>
          <TitleBodyText>
            {"\n"}Web and mobile data{"\n"}
          </TitleBodyText>
          <BodyDefaultText>
            Personal data{"\n"}Via our mobile application and website, we
            collect personal information such as name, address, contact details,
            national identification documentation (e.g. passport), age,
            information about your personal affairs (e.g. bank statements),
            profile photo, address verification and device identifier. As
            mentioned above, this allows us to provide you with the relevant
            in-app transactional services and required customer support.
            Personal information related to customer on-boarding and
            verification is collected by our customer identity verification
            partner and disclosed and stored by our payment partner who supports
            our payment processes.{"\n\n"}Other data{"\n"}We may use technology
            such as cookies, beacons, tags, scripts and tracking pixels to
            collect, store and use anonymous data about how you use our website
            / mobile technology. This includes your server address, the date and
            time of your visit, the pages and links accessed, the type of
            browser used and other information about your browsing activities.
            This data is used to increase functionality and can also enable us
            to display information and content that is tailored to our
            understanding of your interests. This information alone cannot be
            used to discover your identity.
          </BodyDefaultText>
          <TitleBodyText>
            {"\n"}How do we store and protect your personal information?
            {"\n"}
          </TitleBodyText>
          <BodyDefaultText>
            We strive to maintain the relevance, accuracy and completeness and
            currency of the personal information we hold and to protect its
            privacy and security. We keep personal information only for as long
            as is reasonably necessary for the purpose for which it was
            collected or to comply with any applicable legal reporting or
            document retention requirements.{"\n\n"} We hold the information we
            collect from you and ensure that it is safe in secure systems that
            are protected in controlled facilities by providers in Australia who
            are also regulated by the Privacy Act. Our employees and authorised
            agents are obliged to respect the confidentiality of any personal
            information held by us.
          </BodyDefaultText>
          <TitleBodyText>
            {"\n"} Will we disclose your personal information to anyone?
            {"\n"}
          </TitleBodyText>
          <BodyDefaultText>
            We do not sell, trade, or rent your personal information to others.
            {"\n\n"}We will disclose your information to our business partners
            and suppliers (e.g. our identity verification partner and payment
            providers) and may need to provide your information to contractors.
            We do this , so that they can that support the product and services
            we provide to you{"\n\n"}We will take all reasonable steps to ensure
            that they protect your information in the same way that we do.
            {"\n\n"}We may provide your information to others (e.g. authorities,
            regulators and enforcement agencies) if we are required to do so by
            law or under some other unusual circumstances which the Privacy Act
            permits.
          </BodyDefaultText>
          <TitleBodyText>
            {"\n"} How can you check, update or change the information we are
            holding?
            {"\n"}
          </TitleBodyText>
          <BodyDefaultText>
            You may ask us for access to your personal information and to
            correct it at any time. Upon receipt of enough information to allow
            us to identify the information, we will tell you what personal
            information we hold about you. We will also correct, amend or delete
            your personal information if we agree is inaccurate, irrelevant, out
            of date or incomplete.{"\n\n"}We do not charge for receiving a
            request for access to personal information or complying with a
            correction request.{"\n\n"}To access or correct your personal
            information, please write to our Privacy Officer at
            support@chippit.com.au.{"\n\n"}We do not charge for providing access
            to personal information and may charge an administrative fee for
            providing a copy of your personal information.{"\n\n"}In some
            limited situations, we may need to refuse access to your information
            or refuse a request for correction. We will advise you as soon as
            possible after your request if this is the case and the reasons for
            our refusal.
          </BodyDefaultText>
          <TitleBodyText>
            {"\n"} What happens if you want to complain? {"\n"}
          </TitleBodyText>
          <BodyDefaultText>
            We welcome your questions and comments about how we manage your
            privacy. If you have any concerns about whether we have complied
            with the Privacy Act, the Australian Privacy Principles, or this
            Privacy Policy, please write to our Privacy Officer at
            support@chippit.com.au.{"\n\n"}We will consider your complaint
            through our internal complaints resolution process, and we will try
            to respond with a decision within 30 days of you making the
            complaint.{"\n\n"}Please note we may request certain information
            from you upon receipt of your request in order to confirm your
            identity. If you are still not satisfied with the outcome upon
            conclusion of our investigation, you may escalate the matter to
            Office of the Australian Information Commissioner, GPO Box 5218,
            Sydney, NSW, 2001 or see www.oaic.gov.au
          </BodyDefaultText>
          <TitleBodyText>
            {"\n"} Your consent {"\n"}
          </TitleBodyText>
          <BodyDefaultText>
            By asking us to assist with your finance needs, you consent to the
            collection and use of the information you have provided to us for
            the purposes described above.
          </BodyDefaultText>
          <TitleBodyText>
            {"\n"} Updating this policy {"\n"}
          </TitleBodyText>
          <BodyDefaultText>
            This Privacy Policy was prepared on 20 December 2022. We may update
            it at any time. The new version will be published on our website.
          </BodyDefaultText>
        </ContentSection>
      </PageTemplateScreen>
      {/* </View>
      </ScrollContainer> */}
    </PrivacyStatementContainer>
  );
};

export default PrivacyStatementScreen;
