import React, { FunctionComponent, useEffect, useState } from "react";
import { useNavigation } from "@react-navigation/native";
import {
  Keyboard,
  TouchableWithoutFeedback,
  View,
  KeyboardAvoidingView,
  Platform,
  BackHandler,
} from "react-native";
import styled from "styled-components/native";
import { PageProps } from "../../interfaces/templates";
import { Col, Container, Row, ScreenWidth, ScrollContainer } from "../shared";
import { colors } from "../colors";
import IconButton from "../Buttons/IconButton";
import SubmitButton from "../Buttons/SubmitButton";
import RegularButton from "../Buttons/RegularButton";
import { getHeaderCardBackground, getStatusColor } from "../../utils/getColor";
import { formatPlanName, getPlanProgress } from "../../utils/planEnums";
import {
  planCardHeaderHeight,
  planCardHeaderWidth,
} from "../../utils/constants";

import {
  BodySmall,
  BodyDefaultBold,
  TitleSubsection,
} from "../Texts/Typography";
import NotificationIcon from "../Icons/NotificationIcon";

const PageContainer = styled(Container)`
  background-color: ${colors.neutral0};
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

const NavigationSection = styled(Row)`
  width: 100%;
  margin-top: 45px;
  margin-bottom: 19px;
`;
const ContentSection = styled.View`
  width: 100%;
  justify-content: space-between;
  flex: 1;
  align-items: center;
`;

const ButtonSection = styled.View`
  width: 100%;
`;

const ButtonWrapper = styled.View`
  width: 90%;
  align-items: center;
  padding: 30px 5px 30px 5px;
  margin: auto;
`;

const ButtonSelectedWrapper = styled(ButtonWrapper)`
  width: 100%;
  padding: 0px;
`;

const ButtonOutlineWrapper = styled(ButtonWrapper)`
  padding: 0px 5px 20px 5px;
  margin-top: -20px;
`;

const CardContainer = styled.View`
  margin-bottom: 32px;
  width: 100%;
  height: ${Math.round(
    (planCardHeaderHeight / planCardHeaderWidth) * ScreenWidth
  )}px;
  max-height: 400px;
`;

const Card = styled.ImageBackground`
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const PlanDetails = styled.View`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  z-index: 1;
  padding: 0px 16px 17px 16px;
`;

const ProgressDash = styled.View`
  width: 25.9px;
  height: 7px;
  border-radius: 40px;
`;

const PaymentRow = styled(Row)`
  width: 100%;
  gap: 2px;
`;

const DueRow = styled(PaymentRow)`
  padding-left: 0px;
  margin-top: 0px;
`;

const DueCol = styled(Col)`
  width: 100%;
  flex: 1;
`;

const DueAmountRow = styled(Row)`
  justify-content: flex-start;
  flex: 1;
`;

const DueDateRow = styled(Row)`
  justify-content: flex-end;
  flex: 1;
`;

const GroupSection = styled.View`
  width: 100%;
`;

const BodySmallText = styled(BodySmall)`
  color: ${colors.neutral0};
`;

const TitleSubsectionText = styled(TitleSubsection)`
  color: ${colors.neutral0};
`;

const BodyDefaultBoldText = styled(BodyDefaultBold)`
  color: ${colors.neutral0};
`;

const BodySmallBoldText = styled(BodySmall)`
  font-family: "DMSans Bold";
  color: ${colors.neutral0};
`;

const PlanTemplateScreen: FunctionComponent<PageProps> = (props) => {
  const navigation = useNavigation();
  const payments = Array(10).fill({});
  const [planProgress] = useState(payments);

  useEffect(() => {
    if (props?.headerPlanProgress?.length > 0) {
      getPlanProgress(props?.headerPlanProgress, planProgress);
    }
  }, [props?.headerPlanProgress]);

  const handleBackButtonClick = () => {
    navigation.navigate("HomeTab");
    return true;
  };

  useEffect(() => {
    if (Platform.OS === "android") {
      BackHandler.addEventListener("hardwareBackPress", handleBackButtonClick);
      return () => {
        BackHandler.removeEventListener(
          "hardwareBackPress",
          handleBackButtonClick
        );
      };
    }
  });

  return (
    <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>
      <PageContainer>
        <CardContainer>
          <Card source={getHeaderCardBackground(props?.headerDetails?.index)}>
            <PlanDetails>
              <NavigationSection>
                <IconButton
                  onPress={() => {
                    navigation.navigate("HomeTab");
                  }}
                  name={"arrow-back"}
                  color={colors.primary100}
                  size={24}
                  btnStyles={{
                    backgroundColor: colors.neutral20,
                    borderRadius: 300,
                  }}
                  iconStyles={{
                    padding: 10,
                  }}
                />
                {!props.hideNotifications ? <NotificationIcon /> : null}
              </NavigationSection>
              <BodySmallText>
                {formatPlanName(props?.headerDetails?.name)}
              </BodySmallText>
              <TitleSubsectionText>
                ${props?.headerDetails?.dueAmount}.00/month
              </TitleSubsectionText>
              <DueCol>
                <GroupSection>
                  <BodySmallText style={{ fontSize: 10 }}>
                    Start Date
                  </BodySmallText>
                  <BodyDefaultBoldText>
                    {props?.headerDetails?.startDate}
                  </BodyDefaultBoldText>
                </GroupSection>
                <GroupSection>
                  <PaymentRow>
                    <Row style={{ gap: 3 }}>
                      {planProgress.slice(0, 5).map((payment, index) => {
                        return (
                          <ProgressDash
                            key={`PlanCardProgressLeft-${index}`}
                            style={{
                              backgroundColor: getStatusColor(
                                payment?.status,
                                payment?.event
                              ),
                            }}
                          />
                        );
                      })}
                    </Row>
                    <Row style={{ gap: 3 }}>
                      {planProgress.slice(5, 10).map((payment, index) => (
                        <ProgressDash
                          key={`PlanCardProgressRight-${index}`}
                          style={{
                            backgroundColor: getStatusColor(
                              payment?.status,
                              payment?.event
                            ),
                          }}
                        />
                      ))}
                    </Row>
                  </PaymentRow>
                  <DueRow>
                    <DueAmountRow>
                      <BodySmallText>Due amount </BodySmallText>
                      <BodySmallBoldText>
                        ${props?.headerDetails?.dueAmount}.00
                      </BodySmallBoldText>
                    </DueAmountRow>
                    <DueDateRow>
                      <BodySmallText>Due date </BodySmallText>
                      <BodySmallBoldText>
                        {props?.headerDetails?.dueDate}
                      </BodySmallBoldText>
                    </DueDateRow>
                  </DueRow>
                </GroupSection>
              </DueCol>
            </PlanDetails>
          </Card>
        </CardContainer>
        <ScrollContainer
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          <View onStartShouldSetResponder={() => true} style={{ flex: 1 }}>
            <ContentSection>{props.children}</ContentSection>
          </View>
        </ScrollContainer>
        <ButtonSection>
          {!props.hideSubmitButton &&
            (props.submitButtonEditMode === false ? (
              <ButtonWrapper>
                <SubmitButton
                  btnStyles={{ paddingLeft: 40, paddingRight: 40 }}
                  textStyles={{
                    color: colors.neutral0,
                  }}
                  onPress={() => {
                    props.onSubmit();
                  }}
                  disabled={props.submitDisabled}
                >
                  {props.submitTitle}
                </SubmitButton>
              </ButtonWrapper>
            ) : (
              <KeyboardAvoidingView
                style={{ flex: Platform.OS === "ios" ? 1 : 0, width: "100%" }}
                behavior={Platform.OS === "ios" ? "position" : undefined}
                keyboardVerticalOffset={
                  Platform.OS === "ios"
                    ? props.keyboardVerticalOffset
                      ? props.keyboardVerticalOffset
                      : -180
                    : 0
                }
                contentContainerStyle={{
                  paddingVertical: Platform.OS === "ios" ? 110 : 0,
                }}
              >
                <ButtonSelectedWrapper>
                  <SubmitButton
                    textStyles={{ color: colors.neutral0 }}
                    onPress={() => {
                      props.onSubmit();
                    }}
                    buttonBox={true}
                    disabled={props.submitDisabled}
                  >
                    {props.submitTitle}
                  </SubmitButton>
                </ButtonSelectedWrapper>
              </KeyboardAvoidingView>
            ))}
          {props?.showButtonOutline && (
            <ButtonOutlineWrapper>
              <RegularButton
                textStyles={{ color: colors.primary100 }}
                btnStyles={{
                  backgroundColor: colors.neutral0,
                  borderWidth: 1,
                  borderColor: colors.primary100,
                }}
                onPress={() => {
                  {
                    props.submitButtonOutline();
                  }
                }}
              >
                {props.titleButtonOutline}
              </RegularButton>
            </ButtonOutlineWrapper>
          )}
        </ButtonSection>
      </PageContainer>
    </TouchableWithoutFeedback>
  );
};

export default PlanTemplateScreen;
