import React, { FunctionComponent, Fragment } from "react";
import styled from "styled-components/native";
import UnreadNotification from "../../assets/icons/unread-notification.svg";
import ReadNotification from "../../assets/icons/read-notification.svg";
import UnreadNotificationPrimary from "../../assets/icons/unread-notification-primary.svg";
import ReadNotificationPrimary from "../../assets/icons/read-notification-primary.svg";
import { useNavigation } from "@react-navigation/native";
import { useAppSelector } from "../../app/hooks";

const Button = styled.TouchableOpacity`
  justify-content: center;
`;

const ReadSection = styled.View`
  margin-top: 2px;
  margin-right: 4px;
`;

interface NotificationIconProps {
  hasPrimaryOverlay?: boolean;
}

const NotificationIcon: FunctionComponent<NotificationIconProps> = (props) => {
  const navigation = useNavigation();
  const notifications = useAppSelector((state) => state.notification);

  const openNotifications = () => {
    navigation.navigate("Notifications");
  };

  return (
    <Button onPress={openNotifications}>
      <ReadSection>
        {notifications.hasUnreadNotification ? (
          <Fragment>
            {props.hasPrimaryOverlay ? (
              <UnreadNotification />
            ) : (
              <UnreadNotificationPrimary />
            )}
          </Fragment>
        ) : (
          <Fragment>
            {props.hasPrimaryOverlay ? (
              <ReadNotification />
            ) : (
              <ReadNotificationPrimary />
            )}
          </Fragment>
        )}
      </ReadSection>
    </Button>
  );
};

export default NotificationIcon;
