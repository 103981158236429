import React, { useEffect } from "react";
import styled from "styled-components/native";
import Timeline from "react-native-timeline-flatlist";
import { View, PixelRatio, Platform, BackHandler } from "react-native";
import { colors } from "../../components/colors";
import { RootStackScreenProps } from "../../navigation/types";
import { Container, Row, ScrollContainer } from "../../components/shared";
import IconButton from "../../components/Buttons/IconButton";
import {
  BodySmall,
  LinkDefault,
  TitleBody,
  TitleSection,
} from "../../components/Texts/Typography";
import ReferFriendsCard from "../../components/Card/ReferFriendsCard";
import { referralHowItWorks } from "../../utils/constants";
import NotificationIcon from "../../components/Icons/NotificationIcon";

const fontScale = PixelRatio.getFontScale();

const ReferFriendsContainer = styled(Container)`
  background-color: ${colors.primary100};
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 0px 16px;
`;

const NavigationSection = styled(Row)`
  width: 100%;
  margin-top: 45px;
  margin-bottom: 25px;
`;

const ContentSection = styled.View`
  width: 100%;
  flex: 1;
  gap: 16px;
`;

const TitleSectionText = styled(TitleSection)`
  color: ${colors.neutral0};
`;

const BodySmallText = styled(BodySmall)`
  color: ${colors.neutral0};
`;

const LinkDefaultText = styled(LinkDefault)`
  color: ${colors.neutral0};
  font-size: ${12 / fontScale}px;
`;

const TitleBodyText = styled(TitleBody)`
  color: ${colors.neutral0};
`;

const ReferFriendsScreen = ({
  navigation,
}: RootStackScreenProps<"ReferFriends">) => {
  const handleBackButtonClick = () => {
    navigation.goBack();
    return true;
  };

  useEffect(() => {
    if (Platform.OS === "android") {
      BackHandler.addEventListener("hardwareBackPress", handleBackButtonClick);
      return () => {
        BackHandler.removeEventListener(
          "hardwareBackPress",
          handleBackButtonClick
        );
      };
    }
  });

  return (
    <ReferFriendsContainer>
      <NavigationSection>
        <IconButton
          onPress={() => {
            navigation.goBack();
          }}
          name="arrow-back"
          color={colors.primary100}
          size={24}
          btnStyles={{
            backgroundColor: colors.neutral20,
            borderRadius: 300,
          }}
          iconStyles={{
            padding: 10,
          }}
        />
        <NotificationIcon hasPrimaryOverlay={true} />
      </NavigationSection>
      <ScrollContainer
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ flexGrow: 1 }}
        style={{ backgroundColor: colors.primary100 }}
      >
        <View onStartShouldSetResponder={() => true} style={{ flex: 1 }}>
          <ContentSection>
            <TitleSectionText>Refer friends and earn</TitleSectionText>
            <ReferFriendsCard />
            <BodySmallText>
              By proceeding, I accept the{" "}
              <LinkDefaultText onPress={() => {}}>
                Referral Terms and Conditions
              </LinkDefaultText>{" "}
              and understand that anyone with my invite link will be aware I use
              Chippit.
            </BodySmallText>
            <TitleBodyText>How it works.</TitleBodyText>
            <Timeline
              data={referralHowItWorks}
              lineColor={colors.neutral0}
              showTime={false}
              circleColor={colors.neutral0}
              circleSize={8}
              isUsingFlatlist={false}
              columnFormat="single-column-left"
              eventDetailStyle={{ paddingTop: 0 }}
              titleStyle={{
                color: colors.neutral0,
                fontFamily: "DMSans Bold",
                fontSize: 12,
              }}
              descriptionStyle={{
                color: colors.neutral0,
                fontFamily: "DMSans",
                fontSize: 12,
              }}
            />
          </ContentSection>
        </View>
      </ScrollContainer>
    </ReferFriendsContainer>
  );
};

export default ReferFriendsScreen;
