import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import planAPI from "./planAPI";
import {
  CardListProps,
  MyActivityProps,
  RecommendedPlanListProps,
  generateConsentURLParams,
  UserAccountType,
  nominateAccountParams,
  startPlanParams,
  recommendedPlanType,
  requestCustomPlanParams,
  PayoutOrderProps,
} from "../../interfaces/plans";
import AsyncStorage from "@react-native-async-storage/async-storage";

type StateStatus = "idle" | "loading" | "succeeded" | "failed";

interface PlanState {
  status: StateStatus;
  recommendedPlanListStatus: StateStatus;
  myPlanList: CardListProps[];
  recommendedPlanList: RecommendedPlanListProps[];
  abridgedRecommendedPlans: recommendedPlanType[];
  myActivitiesList: MyActivityProps[];
  myAccounts: UserAccountType[];
  userDetails: PayoutOrderProps[];
  error: string;
}

interface PersistedPlanData {
  myPlanList: CardListProps[];
  abridgedRecommendedPlans: recommendedPlanType[];
}

const initialState: PlanState = {
  status: "idle",
  recommendedPlanListStatus: "idle",
  myPlanList: [],
  recommendedPlanList: [],
  abridgedRecommendedPlans: [],
  myActivitiesList: [],
  myAccounts: [],
  userDetails: [],
  error: "",
};

export const getMyPlanList = createAsyncThunk("/get-my-plan-list", async () => {
  try {
    const response = await planAPI.getPlanList();
    return response?.data?.plans || [];
  } catch (err) {
    return err;
  }
});

export const getRecommendedPlanList = createAsyncThunk(
  "/get-recommended-plan-list",
  async () => {
    try {
      const response = await planAPI.planList();
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const getAbridgedRecommendedPlans = createAsyncThunk(
  "/get-abridged-recommended-plans",
  async () => {
    try {
      const response = await planAPI.getAbridgedRecommendedPlans();
      const recommendation = response.data?.recommendation;
      const planList = await planAPI.planList();
      let availablePlans = [];
      if (planList[0].plans.length > 0) {
        availablePlans = planList[0].plans;
      } else {
        availablePlans = planList[1].plans;
      }
      return availablePlans.filter((plan: recommendedPlanType) =>
        recommendation.includes(plan.planMonthId)
      );
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getUserAccounts = createAsyncThunk<UserAccountType[]>(
  "/get-user-accounts",
  async (params, thunkAPI) => {
    try {
      const response = await planAPI.getAccounts();
      return response?.data?.accounts || [];
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const generateConsentURL = createAsyncThunk(
  "/generate-consent-url",
  async (params: generateConsentURLParams, thunkAPI) => {
    try {
      const response = await planAPI.generateConsentURL(params);
      return response?.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const submitConsent = createAsyncThunk(
  "/submit-consent",
  async (params, thunkAPI) => {
    try {
      const response = await planAPI.submitConsent();
      return response?.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const nominateAccounts = createAsyncThunk(
  "/nominate-accounts",
  async (params: nominateAccountParams, thunkAPI) => {
    try {
      const response = await planAPI.nominateAccounts(params);
      return response?.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const startPlan = createAsyncThunk(
  "/start-plan",
  async (params: startPlanParams, thunkAPI) => {
    try {
      const response = await planAPI.startPlan(params);
      return response?.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getMyActivitiesList = createAsyncThunk(
  "/get-my-activities-list",
  async (params: string, thunkAPI) => {
    try {
      const response = await planAPI.myActivitiesList(params);
      return response?.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const loadPersistedPlanData = createAsyncThunk(
  "/load-plan-data",
  async () => {
    const myPlanList = await AsyncStorage.getItem("myPlanList");
    const abridgedRecommendedPlans = await AsyncStorage.getItem(
      "abridgedRecommendedPlans"
    );
    const returnData = <PersistedPlanData>{
      myPlanList: [],
      abridgedRecommendedPlans: [],
    };
    if (myPlanList) {
      returnData.myPlanList = JSON.parse(myPlanList);
    }
    if (abridgedRecommendedPlans) {
      returnData.abridgedRecommendedPlans = JSON.parse(
        abridgedRecommendedPlans
      );
    }
    return returnData;
  }
);

export const requestCustomPlan = createAsyncThunk(
  "/request-custom-plan",
  async (params: requestCustomPlanParams, thunkAPI) => {
    try {
      const response = await planAPI.requestCustomPlan(params);
      return response?.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getUserDetails = createAsyncThunk(
  "/get-user-details",
  async (param: string, thunkAPI) => {
    try {
      const response = await planAPI.getUserDetails(param);

      return response?.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const authSlice = createSlice({
  name: "plan",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMyPlanList.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getMyPlanList.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.myPlanList = action.payload;
      AsyncStorage.setItem("myPlanList", JSON.stringify(action.payload));
    });
    builder.addCase(getMyPlanList.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });

    builder.addCase(getRecommendedPlanList.pending, (state) => {
      state.recommendedPlanListStatus = "loading";
    });
    builder.addCase(getRecommendedPlanList.fulfilled, (state, action) => {
      state.recommendedPlanListStatus = "succeeded";
      state.recommendedPlanList = action.payload;
    });
    builder.addCase(getRecommendedPlanList.rejected, (state, action) => {
      state.recommendedPlanListStatus = "failed";
      state.error = action.payload;
    });

    builder.addCase(getAbridgedRecommendedPlans.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getAbridgedRecommendedPlans.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.abridgedRecommendedPlans = action.payload;
      AsyncStorage.setItem(
        "abridgedRecommendedPlans",
        JSON.stringify(action.payload)
      );
    });
    builder.addCase(getAbridgedRecommendedPlans.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });

    builder.addCase(getMyActivitiesList.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getMyActivitiesList.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.myActivitiesList = action.payload;
    });
    builder.addCase(getMyActivitiesList.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });
    builder.addCase(getUserAccounts.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getUserAccounts.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.myAccounts = action.payload;
    });
    builder.addCase(getUserAccounts.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });
    builder.addCase(nominateAccounts.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(nominateAccounts.fulfilled, (state) => {
      state.status = "succeeded";
    });
    builder.addCase(nominateAccounts.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });
    builder.addCase(startPlan.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(startPlan.fulfilled, (state) => {
      state.status = "succeeded";
    });
    builder.addCase(startPlan.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });
    builder.addCase(loadPersistedPlanData.fulfilled, (state, action) => {
      state.myPlanList = action.payload.myPlanList;
      state.abridgedRecommendedPlans = action.payload.abridgedRecommendedPlans;
    });
    builder.addCase(requestCustomPlan.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(requestCustomPlan.fulfilled, (state) => {
      state.status = "succeeded";
    });
    builder.addCase(requestCustomPlan.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });

    builder.addCase(getUserDetails.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.userDetails = action.payload;
    });
    builder.addCase(getUserDetails.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });
  },
});

export default authSlice.reducer;
