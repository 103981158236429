import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  FaqQuestionProps,
  PersonalDetailsParam,
} from "../../interfaces/profile";
import profileAPI from "./profileAPI";

type StateStatus = "idle" | "loading" | "succeeded" | "failed";

interface ProfileState {
  status: StateStatus;
  generalQuestion3List: FaqQuestionProps[];
  planQuestionList: FaqQuestionProps[];
  connectBankQuestionList: FaqQuestionProps[];
  personalDetails: PersonalDetailsParam;
  updatedPersonalDetails: PersonalDetailsParam[];
  error: string;
}

const initialState: ProfileState = {
  status: "idle",
  generalQuestion3List: [],
  planQuestionList: [],
  connectBankQuestionList: [],
  personalDetails: {
    phoneNumber: "",
    firstName: "",
    middleName: "",
    lastName: "",
    birthDate: "",
    address: "",
    suburb: "",
    state: "",
    postCode: "",
    occupation: "",
    incomeRange: "",
    bio: "",
  },
  updatedPersonalDetails: [],
  error: "",
};

export const getGeneralQuestion3List = createAsyncThunk(
  "/get-general-question-3-list",
  async () => {
    try {
      const response = await profileAPI.generalQuestion3List();
      return response;
    } catch (err) {
      return err;
    }
  }
);
export const getPlanQuestionList = createAsyncThunk(
  "/get-plan-question-list",
  async () => {
    try {
      const response = await profileAPI.planQuestionList();
      return response;
    } catch (err) {
      return err;
    }
  }
);
export const getConnectBankQuestionList = createAsyncThunk(
  "/get-connect-bank-question-list",
  async () => {
    try {
      const response = await profileAPI.connectBankQuestionList();
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const getPersonalDetails = createAsyncThunk(
  "/get-personal-details",
  async (param, thunkAPI) => {
    try {
      const response = await profileAPI.getPersonalDetails();
      return response?.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updatePersonaDetails = createAsyncThunk(
  "/update-personal-details",
  async (param: PersonalDetailsParam, thunkAPI) => {
    try {
      const response = profileAPI.updatePersonalDetails(param);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getGeneralQuestion3List.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getGeneralQuestion3List.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.generalQuestion3List = action.payload;
    });
    builder.addCase(getGeneralQuestion3List.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });

    builder.addCase(getPlanQuestionList.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getPlanQuestionList.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.planQuestionList = action.payload;
    });
    builder.addCase(getPlanQuestionList.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });

    builder.addCase(getConnectBankQuestionList.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getConnectBankQuestionList.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.connectBankQuestionList = action.payload;
    });
    builder.addCase(getConnectBankQuestionList.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });

    builder.addCase(updatePersonaDetails.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(updatePersonaDetails.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.updatedPersonalDetails = action.payload;
    });
    builder.addCase(updatePersonaDetails.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });

    builder.addCase(getPersonalDetails.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getPersonalDetails.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.personalDetails = action.payload.currentUser;
    });
    builder.addCase(getPersonalDetails.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });
  },
});

export default profileSlice.reducer;
