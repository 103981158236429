import React, { useState } from "react";
import { View, Pressable } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { animated, useSpring } from "@react-spring/web";
import { MaterialIcons } from "@expo/vector-icons";
import styled from "styled-components/native";
import { format, parse } from "date-fns";
import { colors } from "../colors";
import { Col, Row } from "../shared";
import { recommendedPlanType } from "../../interfaces/plans";
import BulletItem from "../Texts/BulletText";
import SmallButton from "../Buttons/SmallButton";
import { BodyLargeBold, BodySmall } from "../Texts/Typography";
// import { getBorderRadius } from "../../utils/borderEnums";
import { setToastContent } from "../../feature/notification/notificationSlice";
import { useAppDispatch } from "../../app/hooks";
import AlertModal from "../Modals/AlertModal";

// const Card = styled(View)`
//   flex: 0;
//   width: 100%;
//   align-items: flex-start;
//   justify-content: flex-start;
//   border-radius: 16px;
//   background-color: ${colors.primary70};
//   padding: 12px 16px 12px 16px;
//   transition: opacity 5s;
// `;

const LengthRow = styled(Row)`
  justify-content: space-between;
  margin: 8px 0px;
  width: 100%;
  gap: 5px;
`;

const BodyLargeBoldText = styled(BodyLargeBold)`
  color: ${colors.neutral0};
`;

const BodySmallText = styled(BodySmall)`
  color: ${colors.neutral0};
`;

const BodySmallBoldText = styled(BodySmallText)``;

const BottonRow = styled(Row)`
  justify-content: flex-start;
  gap: 8px;
  margin-top: 20px;
`;

interface cardProps {
  index: number;
  data: recommendedPlanType;
  expandCard: () => void;
  isExpanded: boolean;
  startPlan?: (data: recommendedPlanType) => void;
  hideButtons?: boolean;
  fixedBorders?: boolean;
  activePlans?: number;
  redirectToast?: string;
}

const RecommendedPlanCard = (props: cardProps) => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const {
    data,
    expandCard,
    isExpanded = false,
    startPlan,
    hideButtons = false,
    // fixedBorders = false,
  } = props;
  const [showChildren, setShowChildren] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const { maxHeight, opacity } = useSpring({
    maxHeight: isExpanded ? 1 : 0,
    opacity: isExpanded ? 1 : 0,
    onChange: (data) => {
      if (data.value.maxHeight > 0 && !showChildren) {
        setShowChildren(true);
      } else if (data.value.maxHeight <= 0 && showChildren) {
        setShowChildren(false);
      }
    },
  });

  const animatedHeight = maxHeight.to({
    range: [0, 1],
    output: [105, 600],
  });
  const animatedOpacity = opacity.to({
    range: [0, 1],
    output: [0, 1],
  });

  const toastErrorMessage = async () => {
    const params = {
      errorType: "error",
      content: "You have reached the maximum number of enrolled plans (Max: 5)",
    };
    await dispatch(setToastContent(params));
    navigation.navigate(props.redirectToast);
  };

  return (
    <Pressable
      onPress={() => {
        expandCard();
      }}
    >
      <animated.div
        // fixedBorders={fixedBorders}
        // index={props.index}
        style={{
          maxHeight: animatedHeight,
          flex: 1,
          alignItems: "flex-start",
          justifyContent: "flex-start",
          borderRadius: "16px",
          backgroundColor: `${colors.primary70}`,
          padding: "12px 16px 12px 16px",
          transition: `opacity 5s`,
        }}
      >
        <Col>
          <Row
            style={{
              width: "100%",
            }}
          >
            <BodyLargeBoldText>{`$${(data?.value || 0).toFixed(
              2
            )}/month`}</BodyLargeBoldText>
            <MaterialIcons
              name={isExpanded ? "keyboard-arrow-up" : "keyboard-arrow-down"}
              size={24}
              color={colors.neutral0}
            />
          </Row>
          <LengthRow>
            <Col>
              <BodySmallText>Length</BodySmallText>
              <BodySmallBoldText>
                {`${data?.length || "-"} mths`}
              </BodySmallBoldText>
            </Col>
            <Col>
              <BodySmallText>Closing Soon</BodySmallText>
              <BodySmallBoldText>
                {`${data?.closingAt
                  ? format(
                    parse(data.closingAt, "dd/MM/yyyy", new Date()),
                    "do MMM yyyy"
                  )
                  : "-"
                  }`}
              </BodySmallBoldText>
            </Col>
            <Col>
              <BodySmallText>Payout</BodySmallText>
              <BodySmallBoldText>
                ${data?.installmentValue || 0}/cycle
              </BodySmallBoldText>
            </Col>
          </LengthRow>
          <animated.div
            style={{
              width: "100%",
              opacity: animatedOpacity,
            }}
          >
            {showChildren && (
              <Col style={{ width: "100%" }}>
                <View>
                  <BulletItem>
                    <BodySmallBoldText>${data?.value}</BodySmallBoldText>
                    {` will be debited on `}
                    <BodySmallBoldText>
                      {data?.startDate
                        ? format(
                          parse(data.startDate, "dd/MM/yyyy", new Date()),
                          "do MMMM yyyy"
                        )
                        : "-"}
                    </BodySmallBoldText>
                  </BulletItem>
                  <BulletItem>
                    {`Length: 2 cycles of `}
                    <BodySmallBoldText>{`${data.length / 2
                      } months`}</BodySmallBoldText>
                    {` each`}
                  </BulletItem>
                  <BulletItem>
                    {`Total Members: `}
                    <BodySmallBoldText>{`${data.members}`}</BodySmallBoldText>
                  </BulletItem>
                  {data?.otherFeatures
                    ? data?.otherFeatures.map((text, index) => (
                      <BulletItem key={`otherFeatureText-${index}`}>
                        {text}
                      </BulletItem>
                    ))
                    : null}
                </View>
                {!hideButtons ? (
                  <BottonRow>
                    <SmallButton
                      btnStyles={{ minWidth: 94 }}
                      textStyles={{
                        color: colors.primary100,
                      }}
                      onPress={() => {
                        setAlertVisible(true)
                        /**
                        if (props.activePlans >= 5) {
                          toastErrorMessage();
                        } else {
                          startPlan(data);
                        }
                        */
                      }}
                    >
                      Start Plan
                    </SmallButton>
                    <SmallButton
                      btnStyles={{
                        minWidth: 94,
                        backgroundColor: colors.primary70,
                        borderWidth: 1,
                        borderColor: colors.neutral0,
                      }}
                      textStyles={{
                        color: colors.neutral0,
                      }}
                      onPress={() => {
                        navigation.navigate("ReferFriends");
                      }}
                    >
                      Share
                    </SmallButton>
                  </BottonRow>
                ) : null}
              </Col>
            )}
          </animated.div>
        </Col>
      </animated.div>
      <AlertModal
        isVisible={alertVisible}
        title="Chippit's Membership Expansion Underway!"
        onOkay={() => {
          setAlertVisible(false);
        }}
        okayTitle="Close"
        cancelTitle="Cancel"
        onCancel={() => { }}
        hideCancel
      >
        Chippit is currently at full capacity as we upgrade features to improve user experience,
        {'\n'}
        BUT we’re expanding to welcome more members in the coming weeks! {'\n'} You are now in the waiting pool and will be notified immediately once your membership is activated.
      </AlertModal>
    </Pressable>
  );
};

export default RecommendedPlanCard;
