import { Video, ResizeMode } from "expo-av";
import React, { Fragment, useState, useEffect, useRef } from "react";
import { Platform, SafeAreaView, StatusBar, PixelRatio } from "react-native";
import { animated, useSpring } from "@react-spring/web";
import styled from "styled-components/native";
import {
  onboardingScreen1,
  onboardingScreen2,
  onboardingScreen3,
  onboardingVideoHeight,
  onboardingVideoWidth,
} from "../../utils/constants";
import { colors } from "../../components/colors";
import { RootStackScreenProps } from "../../navigation/types";
import { Row, ScreenHeight, ScreenWidth } from "../../components/shared";
import { BodySmall } from "../../components/Texts/Typography";
import SubmitButton from "../../components/Buttons/SubmitButton";
import { useAppDispatch } from "../../app/hooks";
import {
  getCurrentUserCognitoDetails,
  getCurrentUserDetails,
  setFromSignupScreen,
} from "../../feature/auth/authSlice";

const LineHeightModifier = 0;

const fontScale = PixelRatio.getFontScale();

const componentPadding = 16 * 2;
const VideoWidth = ScreenWidth - componentPadding;
const VideoHeight = Math.round(
  (onboardingVideoHeight / onboardingVideoWidth) * VideoWidth
);

const OnboardingContainer = styled(SafeAreaView)`
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: ${Platform.OS === "android" ? StatusBar.currentHeight : 0}px;
`;

//needed for ios devices to pad safeAreaView
const PaddedView = styled.View`
  padding-left: 16px;
  padding-right: 16px;
  flex: 1;
`;

const HeaderSection = styled.View`
  margin-top: 14px;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
`;

const ProgressSection = styled(Row)`
  height: 3px;
  width: 100%;
  gap: 8px;
`;

const ProgressBarContainer = styled.View`
  height: 100%;
  flex: 0.33;
  border-radius: 40px;
  background-color: rgba(255, 255, 255, 0.2);
`;

const SubtitleSection = styled(Row)`
  margin-top: 12px;
  width: 100%;
  justify-content: flex-start;
  gap: 8px;
`;

const SubtitleIcon = styled.Image`
  width: 26px;
  height: 20px;
`;

const SubtitleText = styled(BodySmall)`
  line-height: 20px;
  font-weight: 700;
  color: ${colors.neutral10};
`;

const ContentSection = styled.View`
  margin-top: 73px;
  align-items: center;
  justify-content: center;
`;

const VideoContainer = styled.View`
  border-radius: 16px;
  overflow: hidden;
`;
//navigation not used on this line as we are using a redux flag state to change the screen stack instead of navigating to another screen
const OnboardingScreen = ({
  navigation,
}: RootStackScreenProps<"Onboarding">) => {
  const videoRef = useRef(null);
  const [activeVideo, setActiveVideo] = useState<number>(0);
  const [videoPlaying, setVideoPlaying] = useState<boolean>(false);
  const [showTitle, setShowTitle] = useState(false);
  const [title, setTitle] = useState(onboardingScreen1.title);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setVideoPlaying(true);
    setShowTitle(true);
  }, []);

  const resetVideo = async () => {
    if (videoRef?.current) {
      await videoRef.current.setStatusAsync({ positionMillis: 0 });
      await videoRef.current.playAsync();
    }
  };

  const { width1 } = useSpring({
    width1: (activeVideo === 0 && videoPlaying) || activeVideo > 0 ? 1 : 0,
    config: {
      duration:
        activeVideo === 0 && videoPlaying ? onboardingScreen1.duration : 0,
    },
    onChange: async (data) => {
      if (data.value.width1 === 1) {
        await resetVideo();
        setActiveVideo(1);
        setShowTitle(false);
      } else if (
        data.value.width1 === 0 &&
        activeVideo === 0 &&
        !videoPlaying
      ) {
        await resetVideo();
        setVideoPlaying(true);
        setShowTitle(false);
      }
    },
  });
  const { width2 } = useSpring({
    width2: (activeVideo === 1 && videoPlaying) || activeVideo > 1 ? 1 : 0,
    config: {
      duration:
        activeVideo === 1 && videoPlaying ? onboardingScreen2.duration : 0,
    },
    onChange: async (data) => {
      if (data.value.width2 === 1) {
        await resetVideo();
        setActiveVideo(2);
        setShowTitle(false);
      } else if (
        data.value.width2 === 0 &&
        activeVideo === 1 &&
        !videoPlaying
      ) {
        await resetVideo();
        setVideoPlaying(true);
        setShowTitle(false);
      }
    },
  });

  const { width3 } = useSpring({
    width3: activeVideo === 2 ? 1 : 0,
    config: {
      duration: activeVideo === 2 ? onboardingScreen3.duration : 0,
    },
  });

  const animatedWidth1 = width1.to({
    range: [0, 1],
    output: ["0%", "100%"],
  });
  const animatedWidth2 = width2.to({
    range: [0, 1],
    output: ["0%", "100%"],
  });
  const animatedWidth3 = width3.to({
    range: [0, 1],
    output: ["0%", "100%"],
  });

  const { opacity } = useSpring({
    opacity: showTitle ? 1 : 0,
    config: {
      duration: 250,
    },
    onChange: (data) => {
      if (!showTitle && data.value.opacity === 0) {
        setShowTitle(true);
        activeVideo === 2
          ? setTitle(onboardingScreen3.title)
          : activeVideo === 1
          ? setTitle(onboardingScreen2.title)
          : setTitle(onboardingScreen1.title);
      }
    },
  });

  const { position2, position3 } = useSpring({
    position2: activeVideo >= 1 ? 1 : 0,
    position3: activeVideo >= 2 ? 1 : 0,
    config: {
      duration: 250,
    },
  });

  const rightPosition = position2.to({
    range: [0, 1],
    output: [ScreenWidth, 0],
  });

  const topPosition = position3.to({
    range: [0, 1],
    output: [ScreenHeight, 0],
  });

  const displayVideo = () => {
    return activeVideo === 2
      ? onboardingScreen3.video
      : activeVideo === 1
      ? onboardingScreen2.video
      : onboardingScreen1.video;
  };
  /* NOTE: not working on web, need to find a workaround */
  const transitionScreen = async (e: unknown) => {
    const touchPosition = e?.nativeEvent?.locationX;
    const isOnLeftSide = touchPosition <= ScreenWidth / 2;

    setVideoPlaying(false);
    if (isOnLeftSide) {
      if (activeVideo >= 1) {
        setActiveVideo(activeVideo - 1);
      }
    } else {
      if (activeVideo <= 1) {
        await resetVideo();
        setActiveVideo(activeVideo + 1);
        setVideoPlaying(true);
      }
    }
  };

  const dispatch = useAppDispatch();
  return (
    <Fragment>
      <OnboardingContainer>
        <animated.div
          style={{
            backgroundColor: "#de5364",
            position: "absolute",
            height: ScreenHeight,
            width: ScreenWidth,
            zIndex: -3,
          }}
        />
        <animated.div
          style={{
            backgroundColor: colors.primary70,
            position: "absolute",
            height: ScreenHeight,
            width: ScreenWidth,
            right: rightPosition,
            zIndex: -2,
          }}
        />
        <animated.div
          style={{
            backgroundColor: "#f27b38",
            position: "absolute",
            height: ScreenHeight,
            width: ScreenWidth,
            top: topPosition,
            zIndex: -1,
          }}
        />
        <PaddedView onTouchEnd={transitionScreen}>
          <HeaderSection>
            <ProgressSection>
              <ProgressBarContainer>
                <animated.div
                  style={{
                    width: animatedWidth1,
                    height: "100%",
                    borderRadius: 40,
                    opacity: 1,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                  }}
                />
              </ProgressBarContainer>
              <ProgressBarContainer>
                <animated.div
                  style={{
                    width: animatedWidth2,
                    height: "100%",
                    borderRadius: 40,
                    opacity: 1,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                  }}
                />
              </ProgressBarContainer>
              <ProgressBarContainer>
                <animated.div
                  style={{
                    width: animatedWidth3,
                    height: "100%",
                    borderRadius: 40,
                    opacity: 1,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                  }}
                />
              </ProgressBarContainer>
            </ProgressSection>
            <SubtitleSection>
              <SubtitleIcon source={require("../../assets/icon-white.png")} />
              <SubtitleText>Welcome to Chippit</SubtitleText>
            </SubtitleSection>
            <animated.div
              style={{
                fontFamily: "DMSans Bold",
                fontSize: `${32 / fontScale}px`,
                lineHeight: `${40 - LineHeightModifier}px`,
                minHeight: 80,
                opacity,
                marginTop: "14px",
                color: colors.neutral10,
              }}
            >
              {title}
            </animated.div>
          </HeaderSection>
          <ContentSection>
            <VideoContainer>
              <Video
                ref={videoRef}
                style={{
                  height: VideoHeight,
                  width: VideoWidth,
                }}
                resizeMode={ResizeMode.STRETCH}
                source={displayVideo()}
                shouldPlay={true}
                useNativeControls={false}
                isLooping={true}
                onReadyForDisplay={(videoData) => {
                  videoData.srcElement.style.position = "initial";
                }}
              />
            </VideoContainer>
          </ContentSection>
          <SubmitButton
            secondary={true}
            btnStyles={{ marginTop: "auto", marginBottom: 16 }}
            disabled={loading}
            onPress={async () => {
              setLoading(true);
              await dispatch(
                getCurrentUserCognitoDetails({ bypassCache: false })
              );
              await dispatch(getCurrentUserDetails());
              await dispatch(setFromSignupScreen(false));
              navigation.navigate("BottomNav");
              setLoading(false);
            }}
          >
            Continue to Dashboard
          </SubmitButton>
        </PaddedView>
      </OnboardingContainer>
    </Fragment>
  );
};

export default OnboardingScreen;
