export const passwordChecker = (password: string) => {
  let pwdCheck = 0;
  const validateRegex = ["[A-Z]", "[a-z]", "[0-9]", "\\W"];
  validateRegex.forEach((regex) => {
    if (new RegExp(regex).test(password)) {
      pwdCheck += 1;
    }
  });
  switch (pwdCheck) {
    case 0:
      return {
        strength: 0,
        val: "",
      };
    case 1:
      return {
        strength: 1,
        val: "Weak",
      };
    case 2:
      return {
        strength: 2,
        val: "Fair",
      };
    case 3:
      return {
        strength: 3,
        val: "Good",
      };
    case 4:
      return {
        strength: 4,
        val: "Strong",
      };
    default:
      return null;
  }
};
