import React, { FunctionComponent, ReactNode } from "react";
import styled from "styled-components/native";
import { Modal } from "react-native";
import { colors } from "../colors";
import { Col, Row } from "../shared";
import LinkButton from "../Buttons/LinkButton";
import { BodyDefault, TitleBody } from "../Texts/Typography";

const ModalContainer = styled(Row)`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const ModalView = styled.View`
  margin: 25px 20px 0px 20px;
  background-color: ${colors.neutral0};
  border-radius: 20px;
  padding: 35px;
  shadow-color: ${colors.neutral90};
  shadow-opacity: 0.25;
  shadow-radius: 4px;
  elevation: 5;
  align-items: center;
`;

const ModalBody = styled.View`
  padding: 10px 32px;
  align-items: center;
`;

const FooterColumn = styled(Col)`
  flex: 1;
  flex-basis: auto;
  align-items: center;
`;

const VerticalDivider = styled.View`
  margin-vertical: 16px;
  height: 1px;
  width: 100%;
  background-color: ${colors.neutral30};
`;

const HorizontalDivider = styled.View`
  margin-horizontal: 16px;
  height: 100%;
  width: 1px;
  background-color: ${colors.neutral30};
`;

const BodyDefaultText = styled(BodyDefault)`
  text-align: center;
`;

// types
interface DialogProps {
  children: ReactNode;
  isVisible: boolean;
  title: string;
  onCancel: () => void;
  onOkay: () => void;
  dialogContent?: React.ReactNode;
  hideCancel?: boolean;
  okayTitle: string;
  cancelTitle: string;
  emojiImage?: string;
}

const AlertModal: FunctionComponent<DialogProps> = (props) => {
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={props.isVisible}
      onRequestClose={() => {
        props.onCancel;
      }}
    >
      <ModalContainer>
        <ModalView>
          <TitleBody>{props.title}</TitleBody>
          <ModalBody>
            <BodyDefaultText>{props.children}</BodyDefaultText>
          </ModalBody>
          <Row>
            <VerticalDivider />
          </Row>
          <Row>
            {props.hideCancel ? (
              <></>
            ) : (
              <>
                <FooterColumn>
                  <LinkButton
                    textStyles={{ color: colors.neutral90 }}
                    onPress={props.onCancel}
                  >
                    {props.cancelTitle}
                  </LinkButton>
                </FooterColumn>
                <HorizontalDivider />
              </>
            )}
            <FooterColumn>
              <LinkButton
                textStyles={{ color: colors.primary100 }}
                onPress={props.onOkay}
              >
                {props.okayTitle}
              </LinkButton>
            </FooterColumn>
          </Row>
        </ModalView>
      </ModalContainer>
    </Modal>
  );
};

export default AlertModal;
