import React from "react";
import { useFocusEffect } from "@react-navigation/native";
import styled from "styled-components/native";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getNotificationList } from "../../feature/notification/notificationSlice";
import { colors } from "../../components/colors";
import { Container, ScrollContainer } from "../../components/shared";
import PageTemplateScreen from "../../components/Templates/PageTemplate";
import NotificationsList from "../../components/Lists/NotificationsList";
import { TitleSubsection } from "../../components/Texts/Typography";

const NotificationContainer = styled(Container)`
  background-color: ${colors.neutral0};
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

const ContentSection = styled.View`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
`;

const NotificationsScreen = () => {
  const dispatch = useAppDispatch();
  const notifications = useAppSelector((state) => state.notification);

  const init = async () => {
    await dispatch(getNotificationList());
  };

  useFocusEffect(
    React.useCallback(() => {
      init();
    }, [])
  );

  return (
    <NotificationContainer>
      <PageTemplateScreen
        header={
          notifications?.notificationList.length > 0 ? "Notifications" : ""
        }
        onSubmit={() => {}}
        hideSubmitButton={true}
        hideNotifications={true}
      >
        {notifications?.notificationList.length > 0 ? (
          <ScrollContainer showsVerticalScrollIndicator={false}>
            <NotificationsList data={notifications?.notificationList} />
          </ScrollContainer>
        ) : (
          <ContentSection>
            <TitleSubsection>No notifications</TitleSubsection>
          </ContentSection>
        )}
      </PageTemplateScreen>
    </NotificationContainer>
  );
};

export default NotificationsScreen;
