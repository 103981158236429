import React, { useState, useRef, Fragment } from "react";
import { View } from "react-native";
import { useAppDispatch } from "../../../app/hooks";
import { Formik } from "formik";
import styled from "styled-components/native";
import { RootStackScreenProps } from "../../../navigation/types";
import { ScrollContainer } from "../../../components/shared";
import { confirmEmailSchema } from "../../../utils/schema";
import AuthTemplateScreen from "../../../components/Templates/AuthTemplate";
import FieldText from "../../../components/FormFields/FieldText";
import WaitingModal from "../../../components/Modals/WaitingModal";
import {
  updateAccountDetails,
  verifyEmail,
} from "../../../feature/auth/authSlice";
import { updateAccountDetailsParam } from "../../../interfaces/auth";
import ToastMessage from "../../../components/ToastMessages/Toast";

const FieldSection = styled.View`
  margin-top: 24px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  align-items: center;
  flex-direction: column;
`;

const ConfirmEmailScreen = ({
  route,
  navigation,
}: RootStackScreenProps<"ConfirmEmail">) => {
  const confirmEmailFormRef = useRef(null);
  const emailParam = route?.params?.email || "";
  const [loading, setLoading] = useState(false);
  const [activeField, setActiveField] = useState(false);

  const [toastVisible, setToastVisible] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const dispatch = useAppDispatch();

  const triggerSubmit = () => {
    if (confirmEmailFormRef.current) {
      confirmEmailFormRef.current.handleSubmit();
    }
  };

  const handleSubmit = async (values: updateAccountDetailsParam) => {
    try {
      const { email } = values;
      setLoading(true);
      //if user uses the email from signup
      if (emailParam === email) {
        const result = await dispatch(verifyEmail());
        if (result?.meta?.requestStatus === "fulfilled") {
          navigation.navigate("ProfileTab", {
            screen: "VerifyEmail",
            params: { email, type: "VerifyEmail" },
          });
        } else {
          setToastVisible(true);
          setError(
            result.error.error ||
              "System not available. Please try again later."
          );
        }
      } else {
        //if user uses another email
        const updateEmailResult = await dispatch(
          updateAccountDetails({ email })
        );
        if (updateEmailResult?.meta?.requestStatus === "fulfilled") {
          const result = await dispatch(verifyEmail());
          if (result?.meta?.requestStatus === "fulfilled") {
            navigation.navigate("ProfileTab", {
              screen: "VerifyEmail",
              params: { email, type: "VerifyEmail" },
            });
          } else {
            setToastVisible(true);
            setError(
              result?.error?.message ||
                "System not available. Please try again later."
            );
          }
        } else {
          setToastVisible(true);
          setError(
            updateEmailResult?.error?.message ||
              "System not available. Please try again later."
          );
        }
      }
    } catch (error) {
      setError("System not available. Please try again later.");
      setToastVisible(true);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Fragment>
      <AuthTemplateScreen
        header="Verify email"
        subHeader="Confirm your email address to verify."
        submitTitle="Verify email"
        navigation={navigation}
        submitButtonEditMode={activeField}
        onSubmit={() => triggerSubmit()}
        submitDisabled={loading}
        backButtonIcon="arrow-back"
        keyboardVerticalOffset={-180}
      >
        <ScrollContainer
          automaticallyAdjustKeyboardInsets={true}
          showsVerticalScrollIndicator={false}
        >
          <View onStartShouldSetResponder={() => true}>
            <Formik
              initialValues={{
                email: emailParam,
                confirmEmail: "",
              }}
              validationSchema={confirmEmailSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={handleSubmit}
              innerRef={confirmEmailFormRef}
            >
              {({ values, errors }) => (
                <FieldSection>
                  <FieldText
                    label="Email"
                    fieldName="email"
                    value={values.email}
                    onShow={() => {
                      setActiveField(true);
                    }}
                    onHide={() => {
                      setActiveField(false);
                    }}
                    error={errors.email}
                  />
                  <FieldText
                    label="Confirm Email"
                    fieldName="confirmEmail"
                    validateOnChange={true}
                    value={values.confirmEmail}
                    onShow={() => {
                      setActiveField(true);
                    }}
                    onHide={() => {
                      setActiveField(false);
                    }}
                    error={errors.confirmEmail}
                  />
                </FieldSection>
              )}
            </Formik>
          </View>
        </ScrollContainer>
      </AuthTemplateScreen>
      <WaitingModal visible={loading} />
      {toastVisible ? (
        <ToastMessage type="error" onClose={() => setToastVisible(false)}>
          {error}
        </ToastMessage>
      ) : null}
    </Fragment>
  );
};

export default ConfirmEmailScreen;
