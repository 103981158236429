import React, { useState, useRef, Fragment } from "react";
import { View } from "react-native";
import { Formik } from "formik";
import styled from "styled-components/native";
import { useAppDispatch } from "../../../app/hooks";
import { RootStackScreenProps } from "../../../navigation/types";
import { ScrollContainer } from "../../../components/shared";
import { signInSchema } from "../../../utils/schema";
import AuthTemplateScreen from "../../../components/Templates/AuthTemplate";
import FieldText from "../../../components/FormFields/FieldText";
import { signInParam } from "../../../interfaces/auth";
import eyeClose from "../../../assets/icons/eye-close.png";
import eyeOpen from "../../../assets/icons/eye-open.png";
import WaitingModal from "../../../components/Modals/WaitingModal";
import { checkEmail, signInUser } from "../../../feature/auth/authSlice";
import LinkButton from "../../../components/Buttons/LinkButton";
import { colors } from "../../../components/colors";
import { isValidEmailChecker } from "../../../utils/isValidEmail";
import ToastMessage from "../../../components/ToastMessages/Toast";
import AsyncStorage from "@react-native-async-storage/async-storage";

const FieldSection = styled.View`
  margin-top: 24px;
  padding: 0px 16px;
  width: 100%;
  align-items: center;
  flex-direction: column;
`;

const SignInScreen = ({
  route,
  navigation,
}: RootStackScreenProps<"SignIn">) => {
  const preFilledUsername = route?.params?.username;
  const signInFormRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [activeField, setActiveField] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [toastVisible, setToastVisible] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const dispatch = useAppDispatch();

  const triggerSubmit = () => {
    if (signInFormRef.current) {
      signInFormRef.current.handleSubmit();
    }
  };

  const handleSubmit = async (values: signInParam) => {
    try {
      const { username, password } = values;
      const truncatedUsername = username.replace(/\s/g, "");

      const loginParams = {
        username: truncatedUsername,
        password,
      };
      if (truncatedUsername.startsWith("0")) {
        loginParams.username = "+61" + truncatedUsername.substring(1);
      }
      setLoading(true);
      setToastVisible(false);
      setError(null);
      const isUsernameEmail = await isValidEmailChecker(truncatedUsername);
      if (isUsernameEmail) {
        const checkEmailResult = await dispatch(checkEmail(truncatedUsername));
        const userPhoneNumer = checkEmailResult?.payload?.data?.data;
        if (
          checkEmailResult?.meta?.requestStatus === "fulfilled" &&
          userPhoneNumer
        ) {
          loginParams.username = userPhoneNumer;
        } else if (
          checkEmailResult?.error?.message === "Email is unverified."
        ) {
          navigation.navigate("SignInVerifyEmail");
          return;
        } else {
          setToastVisible(true);
          setError(
            checkEmailResult?.error?.message ||
              "System not available. Please try again later."
          );
        }
      }
      const result = await dispatch(signInUser(loginParams));
      if (result?.meta?.requestStatus === "fulfilled") {
        await AsyncStorage.setItem("isLoggedIn", "true");
      } else {
        if (result?.error?.code === "UserNotConfirmedException") {
          setToastVisible(true);
          setError("Phone number not verified.");
        } else if (
          result?.error?.code === "InvalidParameterException" ||
          result?.error?.code === "UserNotFoundException"
        ) {
          setToastVisible(true);
          setError("Invalid username or password.");
        } else {
          setToastVisible(true);
          setError(
            result?.error?.message ||
              "System not available. Please try again later."
          );
        }
      }
    } catch (error) {
      setToastVisible(true);
      setError("System not available. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <Fragment>
      <AuthTemplateScreen
        header="Sign in"
        subHeader="Don't have an account?"
        subHeaderLink="Create now"
        onSubHeaderLink={() => {
          navigation.navigate("SignUp");
        }}
        submitTitle="Sign In"
        navigation={navigation}
        submitButtonEditMode={activeField}
        onSubmit={() => triggerSubmit()}
        submitDisabled={loading}
        keyboardVerticalOffset={-180}
      >
        <ScrollContainer
          automaticallyAdjustKeyboardInsets={true}
          showsVerticalScrollIndicator={false}
        >
          <View onStartShouldSetResponder={() => true}>
            <Formik
              initialValues={{
                username: preFilledUsername ? preFilledUsername : "",
                password: "",
              }}
              validationSchema={signInSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={handleSubmit}
              innerRef={signInFormRef}
            >
              {({ values, errors }) => (
                <FieldSection>
                  <FieldText
                    key={"username"}
                    label="Email or phone number"
                    fieldName="username"
                    onShow={() => {
                      setActiveField(true);
                    }}
                    onHide={() => {
                      setActiveField(false);
                    }}
                    value={values.username}
                    error={errors.username}
                  />
                  <FieldText
                    key={"password"}
                    label="Password"
                    fieldName="password"
                    onShow={() => {
                      setActiveField(true);
                    }}
                    onHide={() => {
                      setActiveField(false);
                    }}
                    value={values.password}
                    icon={eyeClose}
                    icon2={eyeOpen}
                    showIcon2={showPassword}
                    secureTextEntry={!showPassword}
                    onIconPress={() => {
                      setShowPassword((prevState: boolean) => !prevState);
                    }}
                    error={errors.password}
                  />
                  <LinkButton
                    textStyles={{ color: colors.neutral90 }}
                    btnStyles={{
                      alignItems: "flex-start",
                      width: "100%",
                    }}
                    onPress={() => {
                      navigation.navigate("ForgotPassword");
                    }}
                  >
                    Forgot your password?
                  </LinkButton>
                </FieldSection>
              )}
            </Formik>
          </View>
        </ScrollContainer>
      </AuthTemplateScreen>
      <WaitingModal visible={loading} />
      {toastVisible ? (
        <ToastMessage type="error" onClose={() => setToastVisible(false)}>
          {error}
        </ToastMessage>
      ) : null}
    </Fragment>
  );
};

export default SignInScreen;
