import React, { Fragment, useState } from "react";
// import { View } from "react-native";
import styled from "styled-components/native";
import { useFocusEffect } from "@react-navigation/native";
import { MaterialIcons } from "@expo/vector-icons";
import { colors } from "../../../../../components/colors";
import { RootStackScreenProps } from "../../../../../navigation/types";
import {
  Row,
  Col,
  Container,
  // ScrollContainer,
} from "../../../../../components/shared";
import PageTemplateScreen from "../../../../../components/Templates/PageTemplate";
import { BodyLargeBold } from "../../../../../components/Texts/Typography";
import { useAppDispatch } from "../../../../../app/hooks";
import {
  getConnectBankQuestionList,
  getGeneralQuestion3List,
  getPlanQuestionList,
} from "../../../../../feature/profile/profileSlice";
import { FaqQuestionProps } from "../../../../../interfaces/profile";

const FaqContainer = styled(Container)`
  background-color: ${colors.neutral0};
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

const ContentSection = styled.View`
  width: 100%;
  gap: 24px;
  padding: 0px 16px;
`;

const MenuItemButton = styled.TouchableOpacity`
  flex-direction: row;
  justify-content: space-between;
`;

const MenuItemRow = styled(Row)`
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const MenuItemArrow = styled(Col)`
  justify-content: center;
`;

const BodyLargeBoldText = styled(BodyLargeBold)`
  color: ${(props) =>
    props.list.length !== 0 ? colors.neutral100 : colors.neutral60};
`;

const FaqScreen = ({ route, navigation }: RootStackScreenProps<"Faq">) => {
  const type = route?.params?.type || "";
  const dispatch = useAppDispatch();
  const [faqs, setFaqs] = useState<FaqQuestionProps[]>([]);

  const init = async () => {
    let result = [];
    switch (type) {
      case "General":
        result = await dispatch(getGeneralQuestion3List());
        setFaqs(result?.payload);
        break;
      case "Plans":
        result = await dispatch(getPlanQuestionList());
        setFaqs(result?.payload);
        break;
      case "Connect Bank":
        result = await dispatch(getConnectBankQuestionList());
        setFaqs(result?.payload);
        break;
      default:
        break;
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      init();
    }, [])
  );

  return (
    <Fragment>
      <FaqContainer>
        {/* <ScrollContainer
          automaticallyAdjustKeyboardInsets={true}
          showsVerticalScrollIndicator={false}
        >
          <View onStartShouldSetResponder={() => true}> */}
        <PageTemplateScreen header={type} hideSubmitButton={true}>
          <ContentSection>
            {faqs.map((question: FaqQuestionProps, index: number) => (
              <MenuItemButton
                disabled={question.list.length === 0 ? true : false}
                onPress={() => {
                  navigation.navigate("Questions", {
                    list: question.list,
                    header: question.title,
                    headerDetails: question.details,
                  });
                }}
                key={`FaqQuestion-${index}`}
              >
                <Col>
                  <MenuItemRow>
                    <BodyLargeBoldText list={question.list}>
                      {question.title}
                    </BodyLargeBoldText>
                  </MenuItemRow>
                </Col>
                <MenuItemArrow>
                  <MaterialIcons
                    name="arrow-right-alt"
                    size={24}
                    color={
                      question.list.length !== 0
                        ? colors.neutral100
                        : colors.neutral60
                    }
                  />
                </MenuItemArrow>
              </MenuItemButton>
            ))}
          </ContentSection>
        </PageTemplateScreen>
        {/* </View>
        </ScrollContainer> */}
      </FaqContainer>
    </Fragment>
  );
};

export default FaqScreen;
