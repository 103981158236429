const baseURL = process.env.EXPO_PUBLIC_BACKEND_URL;

const endpoints = {
  resetSignUp: `${baseURL}/auth/reset/signup`,
  checkEmail: `${baseURL}/auth/check/email`,
  updateUser: `${baseURL}/auth/users/:userId`,
  setUserPin: `${baseURL}/auth/pin/set`,
  verifyUserPin: `${baseURL}/auth/pin`,
  getCurrentUserDetails: `${baseURL}/auth/users/me`,
  getUserDetails: `${baseURL}/auth/users/:userId`,
  setUserPreferences: `${baseURL}/auth/users/preferences`,
  generateConsentURL: `${baseURL}/kyc/users/:userId/consents/initiate`,
  getUserAccounts: `${baseURL}/transaction/users/:userId/accounts`,
  submitConsent: `${baseURL}/kyc/users/:userId/data/process`,
  nominateAccount: `${baseURL}/transaction/users/:userId/accounts`,
  startPlan: `${baseURL}/plan-management/users/:userId/start/plans`,
  getPlanList: `${baseURL}/plan-management/users/:userId/plans`,
  getMyActivities: `${baseURL}/plan-management/users/:userId/plans/:planId/activities`,
  getAbridgedRecommendedPlans: `${baseURL}/plan-management/users/:userId/recommend/plans`,
  requestCustomPlan: `${baseURL}/plan-management/users/:userId/request/custom`,
  getNotificationsList: `${baseURL}/notification/user/:userId/push`,
  updateNotification: `${baseURL}/notification/user/:userId/push/:notificationId`,
};

export default endpoints;
