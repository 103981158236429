import AsyncStorage from "@react-native-async-storage/async-storage";
import axios, { AxiosResponse } from "axios";
/**
 * A separate axios instance that will bypass the interceptor
 */
const uninterceptedAxiosInstance = axios.create({
  baseURL: process.env.EXPO_PUBLIC_BACKEND_URL,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
  },
});

uninterceptedAxiosInstance.interceptors.request.use(async function (config) {
  const token = await AsyncStorage.getItem("token");
  if (token) {
    config.headers["Authorization"] = `${token}`;
  }
  return config;
});

uninterceptedAxiosInstance.interceptors.response.use(
  function (response: AxiosResponse<unknown>) {
    return response;
  },
  function (error: unknown) {
    return Promise.reject(error?.response?.data?.message || "Server Error");
  }
);

export default uninterceptedAxiosInstance;
