import React, { useState, useRef, Fragment } from "react";
import { View } from "react-native";
import { useAppDispatch } from "../../../app/hooks";
import { Formik } from "formik";
import styled from "styled-components/native";
import { RootStackScreenProps } from "../../../navigation/types";
import { ScrollContainer } from "../../../components/shared";
import { changePasswordSchema } from "../../../utils/schema";
import { passwordChecker } from "../../../utils/passwordStrengthMeter";
import FieldText from "../../../components/FormFields/FieldText";
import {
  changePasswordParam,
  passwordStrengthType,
} from "../../../interfaces/auth";
import eyeClose from "../../../assets/icons/eye-close.png";
import eyeOpen from "../../../assets/icons/eye-open.png";
import PasswordIcon from "../../../assets/icons/password-white-icon.svg";

import WaitingModal from "../../../components/Modals/WaitingModal";
import { changePassword } from "../../../feature/auth/authSlice";
import PasswordStrengthText from "../../../components/Texts/PasswordStrengthText";
import ToastMessage from "../../../components/ToastMessages/Toast";
import PageTemplateScreen from "../../../components/Templates/PageTemplate";
import { setToastContent } from "../../../feature/notification/notificationSlice";

const FieldSection = styled.View`
  margin-top: 24px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  align-items: center;
  flex-direction: column;
`;

const ChangePasswordScreen = ({
  navigation,
}: RootStackScreenProps<"ChangePassword">) => {
  const changePasswordFormRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const [activeField, setActiveField] = useState(false);

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] =
    useState<passwordStrengthType | null>(null);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [toastVisible, setToastVisible] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const dispatch = useAppDispatch();

  const triggerSubmit = () => {
    if (changePasswordFormRef.current) {
      changePasswordFormRef.current.handleSubmit();
    }
  };

  const handleSubmit = async (values: changePasswordParam) => {
    try {
      const { oldPassword, password } = values;
      setLoading(true);
      const result = await dispatch(
        changePassword({
          oldPassword,
          password,
        })
      );

      if (result?.meta?.requestStatus === "fulfilled") {
        const params = {
          errorType: "success",
          icon: PasswordIcon,
          content: result?.error?.message || "Password Updated.",
        };
        await dispatch(setToastContent(params));
        navigation.navigate("Security");
      } else {
        setToastVisible(true);
        setError(
          result?.error?.message ||
            "System not available. Please try again later."
        );
      }
    } catch (error) {
      setToastVisible(true);
      setError("System not available. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <Fragment>
      <PageTemplateScreen
        header="Change Password"
        submitTitle="Change password"
        submitButtonEditMode={activeField}
        onSubmit={() => triggerSubmit()}
        submitDisabled={loading}
        backButtonIcon="arrow-back"
        keyboardVerticalOffset={-180}
        hideNotifications={true}
      >
        <ScrollContainer
          automaticallyAdjustKeyboardInsets={true}
          showsVerticalScrollIndicator={false}
        >
          <View onStartShouldSetResponder={() => true}>
            <Formik
              initialValues={{
                oldPassword: "",
                password: "",
                confirmPassword: "",
              }}
              validationSchema={changePasswordSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={handleSubmit}
              innerRef={changePasswordFormRef}
            >
              {({ values, errors }) => (
                <FieldSection>
                  <FieldText
                    key={"oldPassword"}
                    label="Old Password"
                    fieldName="oldPassword"
                    onShow={() => {
                      setActiveField(true);
                    }}
                    onHide={() => {
                      setActiveField(false);
                    }}
                    value={values.oldPassword}
                    icon={eyeClose}
                    icon2={eyeOpen}
                    showIcon2={showOldPassword}
                    secureTextEntry={!showOldPassword}
                    onIconPress={() => {
                      setShowOldPassword((prevState: boolean) => !prevState);
                    }}
                    error={errors.oldPassword}
                  />
                  <FieldText
                    key={"password"}
                    label="New Password"
                    fieldName="password"
                    validateOnChange={true}
                    onChange={(val) => {
                      if (val !== "") {
                        setPasswordStrength(passwordChecker(val));
                      } else {
                        setPasswordStrength(null);
                      }
                    }}
                    onShow={() => {
                      setActiveField(true);
                    }}
                    onHide={() => {
                      setActiveField(false);
                    }}
                    value={values.password}
                    icon={eyeClose}
                    icon2={eyeOpen}
                    showIcon2={showPassword}
                    secureTextEntry={!showPassword}
                    onIconPress={() => {
                      setShowPassword((prevState: boolean) => !prevState);
                    }}
                    children={() =>
                      !errors.password && passwordStrength ? (
                        <PasswordStrengthText
                          strength={passwordStrength?.strength}
                        >
                          {passwordStrength?.val}
                        </PasswordStrengthText>
                      ) : null
                    }
                    error={errors.password}
                  />
                  <FieldText
                    key={"confirmPassword"}
                    label="Confirm Password"
                    fieldName="confirmPassword"
                    validateOnChange={true}
                    value={values.confirmPassword}
                    icon={eyeClose}
                    icon2={eyeOpen}
                    onShow={() => {
                      setActiveField(true);
                    }}
                    onHide={() => {
                      setActiveField(false);
                    }}
                    showIcon2={showConfirmPassword}
                    secureTextEntry={!showConfirmPassword}
                    onIconPress={() => {
                      setShowConfirmPassword(
                        (prevState: boolean) => !prevState
                      );
                    }}
                    error={errors.confirmPassword}
                  />
                </FieldSection>
              )}
            </Formik>
          </View>
        </ScrollContainer>
      </PageTemplateScreen>
      <WaitingModal visible={loading} />
      {toastVisible ? (
        <ToastMessage type="error" onClose={() => setToastVisible(false)}>
          {error}
        </ToastMessage>
      ) : null}
    </Fragment>
  );
};

export default ChangePasswordScreen;
