import React from "react";
// import { View } from "react-native";
import styled from "styled-components/native";
import { RootStackScreenProps } from "../../../../../navigation/types";
import { colors } from "../../../../../components/colors";
import { Container } from "../../../../../components/shared";
import PageTemplateScreen from "../../../../../components/Templates/PageTemplate";
import QuestionList from "../../../../../components/Lists/QuestionList";
import { BodyDefault } from "../../../../../components/Texts/Typography";

const QuestionsContainer = styled(Container)`
  background-color: ${colors.neutral0};
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

const ContentSection = styled.View`
  width: 100%;
  gap: 24px;
  padding: 0px 16px;
`;

const BodyDefaultText = styled(BodyDefault)`
  color: ${colors.neutral90};
`;

const QuestionsScreen = ({ route }: RootStackScreenProps<"Questions">) => {
  const header = route?.params?.header;
  const headerDetails = route?.params?.headerDetails;
  const list = route?.params?.list;

  return (
    <QuestionsContainer>
      {/* <ScrollContainer
        automaticallyAdjustKeyboardInsets={true}
        showsVerticalScrollIndicator={false}
      >
        <View onStartShouldSetResponder={() => true}> */}
      <PageTemplateScreen header={header} hideSubmitButton={true}>
        <ContentSection>
          <BodyDefaultText>{headerDetails}</BodyDefaultText>
          <QuestionList list={list} />
        </ContentSection>
      </PageTemplateScreen>
      {/* </View>
      </ScrollContainer> */}
    </QuestionsContainer>
  );
};

export default QuestionsScreen;
