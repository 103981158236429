import React, { useState, useRef, useEffect } from "react";
import { Platform } from "react-native";
import styled from "styled-components/native";
import { RootStackScreenProps } from "../../../navigation/types";
import { colors } from "../../../components/colors";
import { ScreenHeight, ScreenWidth } from "../../../components/shared";
import WaitingModal from "../../../components/Modals/WaitingModal";
import WebView from "react-native-webview";
import IconButton from "../../../components/Buttons/IconButton";
import { SafeAreaView } from "react-native-safe-area-context";
import ToastMessage from "../../../components/ToastMessages/Toast";

const ConnectBankContainer = styled(SafeAreaView)`
  flex: 1;
  background-color: ${colors.neutral0};
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

const WebViewWrapper = styled.View`
  flex: 1;
  width: 100%;
  height: 100%;
`;

const ButtonSection = styled.View`
  margin-top: 24px;
  padding: 0px 16px;
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
`;

const ConsentWebviewScreen = ({
  route,
  navigation,
}: RootStackScreenProps<"ConsentWebview">) => {
  const {
    type,
    selectedPlan,
    reasonToSave,
    customPlanName,
    creditAccount,
    debitAccount,
    consentURL: consentURLParam,
    fromProfile = false,
  } = route.params;
  const webViewRef = useRef();

  const [loading, setLoading] = useState(false);
  const [toastVisible, setToastVisible] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [consentURL, setConsentURL] = useState<string | null>(null);

  useEffect(() => {
    if (consentURLParam) {
      if (type === "payment") {
        setConsentURL(consentURLParam + "&action=payment");
      } else {
        setConsentURL(consentURLParam);
      }
    }
  }, []);
  const handleSubmit = async () => {
    try {
      setLoading(true);
      setError(null);
      if (type === "connect") {
        let params = {
          selectedPlan,
          reasonToSave,
          customPlanName,
        };
        if (fromProfile) {
          params = {
            fromProfile,
          };
        }
        navigation.navigate("SubmitConsent", params);
      } else if (type === "payment") {
        let params = {
          creditAccount,
          debitAccount,
          fromProfile,
        };
        if (!fromProfile) {
          params = {
            ...params,
            selectedPlan,
            reasonToSave,
            customPlanName,
          };
        }
        navigation.navigate("AccountSummary", params);
      } else {
        setToastVisible(true);
        setError("System not available. Please try again later.");
      }
    } catch (error) {
      setToastVisible(true);
      setError("System not available. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <ConnectBankContainer>
      {consentURL ? (
        <WebViewWrapper>
          <ButtonSection>
            <IconButton
              onPress={handleSubmit}
              name={"close"}
              color={colors.primary100}
              size={24}
              btnStyles={{
                backgroundColor: colors.neutral20,
                borderRadius: 300,
              }}
              iconStyles={{
                padding: 10,
              }}
            />
          </ButtonSection>
          {Platform.OS === "web" ? (
            <iframe
              height={ScreenHeight - 200}
              width={ScreenWidth}
              resizeMode="contain"
              ref={webViewRef}
              src={consentURL}
            />
          ) : (
            <WebView
              style={{
                height: ScreenHeight - 200,
                width: ScreenWidth,
                resizeMode: "contain",
              }}
              ref={webViewRef}
              source={{ uri: consentURL }}
            />
          )}

          <WaitingModal visible={loading} />
        </WebViewWrapper>
      ) : (
        <WaitingModal visible={true} />
      )}
      {loading ? <WaitingModal visible={true} /> : null}
      {toastVisible ? (
        <ToastMessage type="error" onClose={() => setToastVisible(false)}>
          {error}
        </ToastMessage>
      ) : null}
    </ConnectBankContainer>
  );
};

export default ConsentWebviewScreen;
