import React, { FunctionComponent } from "react";
import styled from "styled-components/native";
import { Octicons } from "@expo/vector-icons";
import { colors } from "../colors";
import { Row } from "../shared";
import { TextProps } from "./types";
import { BodySmall } from "./Typography";

const BulletContainer = styled(Row)`
  justify-content: flex-start;
  align-items: flex-start;
`;

const IconWrapper = styled.View`
  padding-top: 5px;
`;

const BodySmallText = styled(BodySmall)`
  color: ${colors.neutral0};
  margin-left: 8px;
`;

const BulletItem: FunctionComponent<TextProps> = ({ children, iconName }) => {
  return (
    <BulletContainer>
      <IconWrapper>
        <Octicons
          name={iconName ? iconName : "dot-fill"}
          size={12}
          color={colors.neutral0}
        />
      </IconWrapper>
      <BodySmallText>{children}</BodySmallText>
    </BulletContainer>
  );
};

export default BulletItem;
