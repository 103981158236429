import React, { FunctionComponent } from "react";
import { useNavigation } from "@react-navigation/native";
import styled from "styled-components/native";
import { colors } from "../colors";
import { Col, Row } from "../shared";
import RedeemIcon from "../../assets/icons/redeem-icon.svg";
import { BodyDefaultBold, BodySmall } from "../Texts/Typography";

const CardContainer = styled.TouchableOpacity`
  margin-top: 18px;
  margin-bottom: 32px;
  flex: 0;
  padding: 0px 16px;
`;

const Card = styled.View`
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 16px;
  border-width: 1px;
  border-color: ${colors.neutral30};
  background-color: ${colors.neutral10};
  padding: 16px;
`;

const IconContainer = styled.View`
  height: 48px;
  width: 48px;
  padding: 12px;
  border-radius: 8px;
  background: rgba(176, 168, 255, 0.4);
  margin-right: 16px;
`;

const InviteCard: FunctionComponent = () => {
  const navigation = useNavigation();
  return (
    <CardContainer
      onPress={() => {
        navigation.navigate("ReferFriends");
      }}
    >
      <Card>
        <Row>
          <IconContainer>
            <RedeemIcon></RedeemIcon>
          </IconContainer>
          <Col style={{ flexShrink: 1 }}>
            <BodyDefaultBold>Share with friends and family</BodyDefaultBold>
            <BodySmall>
              Because saving is more fun and effective when you connect with
              others.
            </BodySmall>
          </Col>
        </Row>
      </Card>
    </CardContainer>
  );
};

export default InviteCard;
