import React, { FunctionComponent } from "react";
import styled from "styled-components/native";
import { colors } from "../colors";
import {
  GestureResponderEvent,
  StyleProp,
  TextStyle,
  ViewStyle,
} from "react-native";
import { BodySmall } from "../Texts/Typography";

const ButtonView = styled.TouchableOpacity`
  background-color: ${colors.neutral0};
  display: flex;
  min-width: 139px;
  height: 32px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 64px;
`;

const BodySmallText = styled(BodySmall)`
  font-family: "DMSans Bold";
  line-height: 20px;
`;

// types
interface ButtonProps {
  btnStyles?: StyleProp<ViewStyle>;
  onPress: ((event: GestureResponderEvent) => void) | undefined;
  textStyles?: StyleProp<TextStyle>;
  children: React.ReactNode;
}

const SmallButton: FunctionComponent<ButtonProps> = (props) => {
  return (
    <ButtonView onPress={props.onPress} style={props.btnStyles}>
      <BodySmallText style={props.textStyles}>{props.children}</BodySmallText>
    </ButtonView>
  );
};

export default SmallButton;
