import { Auth } from "aws-amplify";
import backendAPI from "../../api/client";
import endpoints from "../../api/endpoints";
import { PersonalDetailsParam } from "../../interfaces/profile";

const generalQuestion3List = async () => {
  try {
    const result = [
      {
        id: 1,
        title: "Who are we?",
        details: `We're a thriving, award-winning company on a mission to empower you through teamwork in personal finance. Our innovative digital savings platform is designed to speed up your journey to that dream home, reach your savings goals sooner, and level up your financial well-being. We've already assisted over 2,000 individuals in achieving their financial dreams, and we're eager to help you too. Together, let's turn your financial aspirations into reality! 😄🏡💰`,
        list: [{}],
      },
      {
        id: 2,
        title: "What are the benefits of joining a chippit plan?",
        details: `We're not just another savings app – we're a friendly group that believes in working together. Imagine getting to your money goals faster, all while having fun with people who think like you.
        And here's something cool: research shows that when folks team up to save, they're 300% more likely to reach those money goals. That's a big deal!
        So, why wait? Join Chippit today, and let's turn your money dreams into reality, together! 💪🤑`,
        list: [{}],
      },
      // ***********Leaving this here as a template************
      // {
      //   id: 3,
      //   title: "How can i exit a plan?",
      //   details: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.${"\n\n"}Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
      //   list: [
      //     {
      //       id: 1,
      //       title: "Information heading #1",
      //       context1: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
      //       contextLinkText: "Contact us to exit a plan",
      //       contextLinkFunction: () => {},
      //       context2:
      //         "esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      //     },
      //     {
      //       id: 2,
      //       title: "Question 1 goes here?",
      //       context1: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
      //     },
      //   ],
      // },
      {
        id: 4,
        title: "How does chippit make money?",
        details: `Great question! When you cash in, there's a one-time fee of 2.5% on the remaining amount you owe the group. That's calculated as the total plan amount minus what you've already put in. It's as simple as that – just one fee when you cash in, and we guarantee it's the only deduction you'll see.

        We charge this fee so we can keep offering you the best, efficient, and super secure service. Your financial journey with us is what matters most, and we're here to make it awesome!" 😄💪💰`,
        list: [{}],
      },
      {
        id: 5,
        title: "What are the risks?",
        details: `Yes, there are risks involved. Although we diligently verify all our customers to confirm their identity and ability to repay, we can't completely eliminate all risks.
        Therefore, it's important to be aware that there's a possibility you may lose your share of the contribution in the event of non-repayment among members and Chippit cannot be held liable for such losses. By joining a group, you are committing to responsible borrowing and repayment of any loans issued to you by members.`,
        list: [{}],
      },
    ];
    return result;
  } catch (error) {
    return error;
  }
};
const planQuestionList = async () => {
  try {
    const result = [
      {
        id: 1,
        title: `How do "Plans" work?`,
        details: `Here's the simple and exciting process: Choose a plan that aligns with your goals and budget, and we'll connect you with a group of fellow savers who share your aspirations. Each month, everyone contributes the same amount. Like clockwork, one lucky member in your group gets their well-deserved reward. We establish the payout order right from the beginning of the plan, ensuring you always know when your turn is coming. Rinse and repeat this winning cycle every month until everyone achieves their goals!`,
        list: [{}],
      },
      {
        id: 2,
        title: "How much do I have to pay?",
        details: `We've got plans that start as low as $100 a month, so you can choose the one that perfectly fits your goals. And stay tuned because we're working on giving you the power to request custom plans that work just for you and your circle of friends and family. Your financial journey, your way!`,
        list: [{}],
      },
      {
        id: 3,
        title: "Can I sign up for multiple plans?",
        details: `Of course. You can mix and match different plans to suit your budget and financial goals. `,
        list: [{}],
      },
      {
        id: 4,
        title: "How do I make my payments?",
        details: `We'll handle your monthly payments through Direct Debits, so there's no need for you to worry about remembering them. If you ever encounter any issues, don't hesitate to reach out to our exceptional customer success team here.`,
        list: [
          {
            id: 1,
            title: "",
            context1: `If you ever encounter any issues, don't hesitate to reach out to our exceptional customer success team`,
            contextLinkText: "here.",
            contextLinkFunction: () => {
              "hello@chippit.com.au";
            },
          },
        ],
      },
      {
        id: 5,
        title: "How would I get the money when its my turn?",
        details: `We'll smoothly transfer the funds to the bank account you designated when you enrolled in the plan. Anticipate the funds arriving within a mere 2-3 business days from the processing date. It's all about keeping your financial progress swift and seamless!`,
        list: [{}],
      },
      {
        id: 6,
        title: "Do I have to keep making payments after I've been drawn?",
        details: `Yes, you'll keep making payments throughout the entire journey of your plan. It's a friendly reminder that when you join a plan and become part of a group, you're making a commitment to contribute to the group until the plan successfully reaches its end. Together, we'll achieve your goals.`,
        list: [{}],
      },
      {
        id: 7,
        title: "What if I decide to drop out from a plan?",
        details: `Dropping out of a plan is not the default option, but we're always open to understanding special circumstances, and we handle things on an individual basis. If you ever find yourself in one of these exceptional situations, don't hesitate to reach out to our customer success team here. They're here to support you and have a chat about your unique situation, so you can move forward with confidence.`,
        list: [{}],
      },
    ];
    return result;
  } catch (error) {
    return error;
  }
};
const connectBankQuestionList = async () => {
  try {
    const result = [
      {
        id: 1,
        title: "Why Chippit needs to connect with my bank?",
        details: `We link up with your bank for a few good reasons:

        1) To confirm your identity.
        2) To double-check your income and estimate your repayment capacity.
        3) To smoothly handle automatic payments.
        4) And, of course, to seamlessly transfer your funds to your bank when it's your turn to cash in.`,
        list: [{}],
      },
      {
        id: 2,
        title: "Why cant I find my Bank?",
        details: `
        We're driven by a mission to bring Chippit to as many people as we can. To achieve that, we're in constant collaboration with our providers, working hard to connect with as many banks as possible. It's important to mention that this journey might mean there are a few banks we haven't reached yet, but rest assured, we're actively working on it.
        We're driven by a mission to bring Chippit to as many people as we can. To achieve that, we're in constant collaboration with our providers, working hard to connect with as many banks as possible. It's important to mention that this journey might mean there are a few banks we haven't reached yet, but rest assured, we're actively working on it.`,
        list: [
          {
            id: 1,
            title: "Don't see your bank on our list? ",
            context1: `No problem! Just give us a shout, and we'll join forces with our providers to make it happen. Send your request right here, and let's expand our financial horizons together!`,
          },
        ],
      },
      {
        id: 3,
        title: "Why cant I connect my bank account?",
        details: `Sometimes we encounter technical problems, often related to updates from your bank. These can temporarily disrupt our connection or cause delays. But don't worry, we're on top of these issues, working diligently to resolve them swiftly. We'll keep you informed, providing updates on any problems or necessary actions, like re-establishing your bank connection.`,
        list: [
          {
            id: 1,
            title: "Now, a couple of common reasons for connection troubles",
            context1: `
            Incorrect bank details:- Hey, we all make mistakes! Be sure to double-check those bank details for accuracy.

            Lost bank connection:- In this case, you'll need to re-enter your bank info, even if you've previously connected successfully with Chippit. This helps reauthorize and restore the connection, ensuring we can continue delivering our stellar services. If you find yourself without a bank connection for more than 24 hours or encounter any difficulties while reconnecting, don't hesitate to reach out to our Customer Service team right here. Together, we'll keep things running smoothly!`,
          },
        ],
      },
      {
        id: 4,
        title: "How many bank accounts can I connect with Chippit",
        details: `At the moment, you can connect one bank account for Auto-Debit. On top of that, you can link one bank account to receive funds from us. If you'd like, you can use the same account for both purposes.

        We highly recommend connecting your primary personal account, the one where you typically receive your salary payments.
        Please note that we cannot accept payments from or process payments to credit card accounts. We apologize for any inconvenience this may cause, but our current system only supports bank accounts for payment processing. If you have any questions or concerns about linking your bank account or need assistance with any other aspect of our services, feel free to reach out to our customer support team. We're here to help you make the most of your financial journey.`,
        list: [{}],
      },
    ];
    return result;
  } catch (error) {
    return error;
  }
};

const getPersonalDetails = async () => {
  const user = await Auth.currentAuthenticatedUser();
  if (!user) {
    throw new Error("User not logged in");
  }
  const result = await backendAPI.get(endpoints.getCurrentUserDetails);
  return result;
};

const updatePersonalDetails = async (
  updatedPersonalDetails: PersonalDetailsParam
) => {
  const user = await Auth.currentAuthenticatedUser();
  if (!user) {
    throw new Error("User not logged in");
  }
  const result = await backendAPI.post(
    endpoints.updateUser.replace(":userId", user?.username),
    updatedPersonalDetails
  );
  return result;
};

const api = {
  generalQuestion3List,
  planQuestionList,
  connectBankQuestionList,
  getPersonalDetails,
  updatePersonalDetails,
};

export default api;
