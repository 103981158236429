import React, { FunctionComponent } from "react";
import styled from "styled-components/native";
import { AntDesign } from "@expo/vector-icons";
import {
  GestureResponderEvent,
  StyleProp,
  TextStyle,
  ViewStyle,
} from "react-native";
import { Row } from "../shared";
import { BodyDefaultBold } from "../Texts/Typography";
import { colors } from "../colors";

const ButtonView = styled.TouchableOpacity``;
const ButtonRow = styled(Row)`
  align-items: center;
  justify-content: space-between;
`;
// types
interface ButtonProps {
  btnStyles?: StyleProp<ViewStyle>;
  onPress: ((event: GestureResponderEvent) => void) | undefined;
  textStyles?: StyleProp<TextStyle>;
  children: React.ReactNode;
  iconStyles?: StyleProp<ViewStyle>;
  name: string;
  size?: number;
  color?: string;
}

const MoreButton: FunctionComponent<ButtonProps> = (props) => {
  return (
    <ButtonView onPress={props.onPress} style={props.btnStyles}>
      <ButtonRow>
        <BodyDefaultBold
          style={{
            ...props.textStyles,
            marginRight: 6,
            color: colors.primary100,
          }}
        >
          {props.children}
        </BodyDefaultBold>
        <AntDesign
          name={props.name}
          size={props.size || 35}
          style={props.iconStyles}
          color={props.color}
        />
      </ButtonRow>
    </ButtonView>
  );
};

export default MoreButton;
