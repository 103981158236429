import { Storage } from "aws-amplify";

export const loadImage = async (imageKey: string) => {
  if (!imageKey) {
    return;
  }

  return new Promise((resolve, reject) => {
    Storage.get(imageKey, {
      level: "public",
      validateObjectExistence: true,
    })
      .then((data) => resolve(data))
      .catch(() => reject("Error loading image"));
  });
};
