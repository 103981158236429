import React, { FunctionComponent } from "react";
import styled from "styled-components/native";
import { Container, ScreenWidth } from "../shared";
import { colors } from "../colors";
import SmallButton from "../Buttons/SmallButton";
import { BodyLargeBold, BodySmall } from "../Texts/Typography";
import PlanImage from "../../assets/svg/plan_image.svg";
import { planImageHeight, planImageWidth } from "../../utils/constants";

const componentPadding = 16 * 2;

const CustomPlanContainer = styled(Container)`
  background-color: ${colors.neutral0};
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 32px;
  padding: ${(props) => (props?.showImage ? "0px" : "0px 16px")};
`;

const Card = styled.View`
  background-color: ${colors.primary100};
  width: 100%;
  display: flex;
  padding: 16px 12px 16px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 16px;
`;

/* 
  Formula for card height to keep the aspect ratio depending on screen width:
  (original height / original width) x new width = new height
*/
const ImageContainer = styled.View`
  width: 100%;
  height: ${Math.round(
    (planImageHeight / planImageWidth) * (ScreenWidth - componentPadding)
  )}px;
  max-height: 150px;
  margin-bottom: 8px;
`;

const BodyLargeBoldText = styled(BodyLargeBold)`
  color: ${colors.neutral0};
`;

const BodySmallText = styled(BodySmall)`
  color: ${colors.neutral0};
  opacity: 0.7;
`;

const CustomPlanCard: FunctionComponent = (props) => {
  return (
    <CustomPlanContainer showImage={props.showImage}>
      <Card>
        {props?.showImage && (
          <ImageContainer>
            <PlanImage width={"100%"} height={"100%"} />
          </ImageContainer>
        )}
        <BodyLargeBoldText>Request a custom plan</BodyLargeBoldText>
        <BodySmallText>
          Want to save for a goal with friends and family? We got you covered.
        </BodySmallText>
        <SmallButton
          onPress={() => {
            props.navigation.navigate("PlansTab", {
              screen: "RequestCustomPlan",
              initial: false,
            });
          }}
          textStyles={{ color: colors.primary100 }}
        >
          Let’s get started
        </SmallButton>
      </Card>
    </CustomPlanContainer>
  );
};

export default CustomPlanCard;
