import React, { FunctionComponent } from "react";
import styled from "styled-components/native";
import { Share } from "react-native";
import { ScreenWidth } from "../shared";
import { colors } from "../colors";
import { TitleBody } from "../Texts/Typography";
import {
  GET_CHIPPIT_URL,
  referFriendsCardHeight,
  referFriendsCardWidth,
} from "../../utils/constants";
import ShareButton from "../Buttons/ShareButton";
import ReferralCard from "../../assets/svg/referral-card.svg";

const svgHeight = Math.round(
  (referFriendsCardHeight / referFriendsCardWidth) * ScreenWidth
);
const svgWidth = Math.round(ScreenWidth);

const Card = styled.View`
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 16px;
  width: 100%;
  aspect-ratio: ${svgWidth / svgHeight};
`;

const ReferDetails = styled.View`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  position: absolute;
  z-index: 1;
  padding: 0px 0px 21px 21px;
`;

const TitleBodyText = styled(TitleBody)`
  color: ${colors.neutral0};
  margin-bottom: 5px;
`;

const ReferFriendsCard: FunctionComponent = () => {
  const onShare = async () => {
    try {
      const message = "Refer friends and earn" + "\n" + GET_CHIPPIT_URL;
      const result = await Share.share({
        message: message,
      });
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      alert(error?.message);
    }
  };

  return (
    <Card>
      <ReferDetails>
        <TitleBodyText>
          Invite friends and family to join in your exciting saving journey!
        </TitleBodyText>
        <ShareButton
          onPress={() => {
            onShare();
          }}
        >
          Share the link
        </ShareButton>
      </ReferDetails>
      <ReferralCard
        height={"100%"}
        width={"100%"}
        viewBox={`0 0 ${referFriendsCardWidth} ${referFriendsCardHeight}`}
        preserveAspectRatio="slice"
      />
    </Card>
  );
};

export default ReferFriendsCard;
