import React, { FunctionComponent, useState, useEffect } from "react";
import styled from "styled-components/native";
import RecommendedPlanCard from "../Card/RecommendedPlanCard";
import { recommendedPlanType } from "../../interfaces/plans";
import { useNavigation } from "@react-navigation/native";
import { useAppSelector } from "../../app/hooks";

const ListContainer = styled.View`
  margin-top: 18px;
  margin-bottom: 8px;
  gap: 8px;
  padding: 0px 16px;
`;

const RecommendedPlansList: FunctionComponent = () => {
  const navigation = useNavigation();
  const [planList, setPlanList] = useState<recommendedPlanType[]>([]);
  const [activeCard, setActiveCard] = useState<number | null>(null);
  const plans = useAppSelector((state) => state.plan);

  useEffect(() => {
    if (
      plans.abridgedRecommendedPlans &&
      plans.abridgedRecommendedPlans.length > 0
    ) {
      setPlanList(plans.abridgedRecommendedPlans || []);
    }
  }, [plans.abridgedRecommendedPlans]);

  const expandCard = (index: number) => {
    setActiveCard(index !== activeCard ? index : null);
  };
  const startPlan = (data: recommendedPlanType) => {
    navigation.navigate("PlansTab", {
      screen: "AdditionalInformation",
      params: { data },
      initial: false,
    });
  };

  return (
    <ListContainer>
      {planList &&
        planList.map((data, index) => (
          <RecommendedPlanCard
            key={`RecommendedPlansCard-${index}`}
            index={index}
            data={data}
            expandCard={() => expandCard(index)}
            isExpanded={index === activeCard}
            startPlan={(data) => startPlan(data)}
            activePlans={plans?.myPlanList.length}
            redirectToast="HomeTab"
          />
        ))}
    </ListContainer>
  );
};

export default RecommendedPlansList;
