import React, { useState, useRef, Fragment } from "react";
import { View } from "react-native";
import { useAppDispatch } from "../../../app/hooks";
import { Formik } from "formik";
import styled from "styled-components/native";
import { RootStackScreenProps } from "../../../navigation/types";
import { ScrollContainer } from "../../../components/shared";
import { forgotPasswordSchema } from "../../../utils/schema";
import AuthTemplateScreen from "../../../components/Templates/AuthTemplate";
import FieldText from "../../../components/FormFields/FieldText";
import { forgotPasswordParam } from "../../../interfaces/auth";
import WaitingModal from "../../../components/Modals/WaitingModal";
import { forgotPassword } from "../../../feature/auth/authSlice";
import ToastMessage from "../../../components/ToastMessages/Toast";

const FieldSection = styled.View`
  margin-top: 24px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  align-items: center;
  flex-direction: column;
`;

const ForgotPasswordScreen = ({
  navigation,
}: RootStackScreenProps<"ForgotPassword">) => {
  const forgotPasswordFormRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [activeField, setActiveField] = useState(false);
  const [toastVisible, setToastVisible] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const dispatch = useAppDispatch();

  const triggerSubmit = () => {
    if (forgotPasswordFormRef.current) {
      forgotPasswordFormRef.current.handleSubmit();
    }
  };

  const handleSubmit = async (values: forgotPasswordParam) => {
    try {
      const { phone_number } = values;
      const countryCode = "+61";
      const finalPhoneNumber = countryCode + phone_number;
      setLoading(true);
      setError(null);

      const result = await dispatch(
        forgotPassword({ phone_number: finalPhoneNumber })
      );
      if (result?.meta?.requestStatus === "fulfilled") {
        if (result?.payload?.message) {
          setToastVisible(true);
          setError(result?.payload?.message);
        } else {
          navigation.navigate("VerifyAccount", {
            type: "ForgotPassword",
            phone_number: finalPhoneNumber,
            password: "",
            email: "",
          });
        }
      } else {
        setToastVisible(true);
        if (result?.error?.code === "UserNotFoundException") {
          setError("Account with specified number not found");
        } else {
          setError(
            result?.error?.message ||
              "System not available. Please try again later."
          );
        }
      }
    } catch (error) {
      setError("System not available. Please try again later.");
      setToastVisible(true);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Fragment>
      <AuthTemplateScreen
        header="Forgot Password?"
        subHeader="Enter your phone number, and we'll SMS you a coded to verify your account."
        submitTitle="Send code"
        navigation={navigation}
        submitButtonEditMode={activeField}
        onSubmit={triggerSubmit}
        submitDisabled={loading}
        backButtonIcon="arrow-back"
        keyboardVerticalOffset={-170}
      >
        <ScrollContainer
          automaticallyAdjustKeyboardInsets={true}
          showsVerticalScrollIndicator={false}
        >
          <View onStartShouldSetResponder={() => true}>
            <Formik
              initialValues={{
                phone_number: "",
              }}
              validationSchema={forgotPasswordSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={handleSubmit}
              innerRef={forgotPasswordFormRef}
            >
              {({ values, errors }) => (
                <FieldSection>
                  <FieldText
                    key={"phone_number"}
                    label="Phone Number"
                    fieldName="phone_number"
                    onShow={() => {
                      setActiveField(true);
                    }}
                    onHide={() => {
                      setActiveField(false);
                    }}
                    value={values.phone_number}
                    error={errors.phone_number}
                    isMobileNumber={true}
                    keyboardType="numeric"
                    maxLength={9}
                  />
                </FieldSection>
              )}
            </Formik>
          </View>
        </ScrollContainer>
      </AuthTemplateScreen>
      <WaitingModal visible={loading} />
      {toastVisible ? (
        <ToastMessage type="error" onClose={() => setToastVisible(false)}>
          {error}
        </ToastMessage>
      ) : null}
    </Fragment>
  );
};

export default ForgotPasswordScreen;
