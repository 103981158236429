import React, { useState } from "react";
import styled from "styled-components/native";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootStackScreenProps } from "../../../navigation/types";

import { colors } from "../../../components/colors";
import { Col, Container } from "../../../components/shared";
import PageTemplateScreen from "../../../components/Templates/PageTemplate";
import WaitingModal from "../../../components/Modals/WaitingModal";
import ToastMessage from "../../../components/ToastMessages/Toast";
import {
  BodyDefaultBold,
  BodyLargeBold,
} from "../../../components/Texts/Typography";
import { UserAccountType } from "../../../interfaces/plans";
import {
  getMyPlanList,
  nominateAccounts,
  startPlan,
} from "../../../feature/plan/planSlice";
import { setToastContent } from "../../../feature/notification/notificationSlice";
import { parse } from "date-fns";
import AlertModal from "../../../components/Modals/AlertModal";

const SelectAccountContainer = styled(Container)`
  flex: 1;
  background-color: ${colors.neutral0};
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

const ContentSection = styled.View`
  padding: 0px 16px 20px 16px;
  width: 100%;
  align-items: center;
  flex-direction: column;
  flex-basis: auto;
`;

const OuterCard = styled.View`
  border-radius: 16px;
  border: 1px solid ${colors.neutral30};
  padding: 16px;
  width: 100%;
  display: flex;
  flex: 1;
  flex-basis: auto;
`;

const InnerCard = styled.View`
  border-radius: 16px;
  border: 1px solid ${colors.neutral30};
  padding: 16px;
  width: 100%;
`;

const Title = styled(BodyLargeBold)`
  color: ${colors.neutral100};
`;

const SubTitle = styled(BodyDefaultBold)`
  color: ${colors.neutral90};
`;

const LineBreak = styled.View`
  height: 1px;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: ${colors.neutral30};
`;

const AccountItem = styled.View`
  width: 100%;
  flex-direction: row;
  align-items: center;
  flex: 1;
  flex-basis: auto;
`;

const BSBCol = styled(Col)`
  flex: 1;
`;

const AccountNumberCol = styled(Col)`
  flex: 1;
`;

const AccountSummaryScreen = ({
  route,
  navigation,
}: RootStackScreenProps<"AccountSummary">) => {
  const {
    selectedPlan,
    reasonToSave,
    customPlanName,
    debitAccount,
    creditAccount,
    fromProfile = false,
  } = route.params;
  const dispatch = useAppDispatch();
  const { currentUserCognitoDetails } = useAppSelector((state) => state.auth);
  const [selectedDebitAccount] = useState<UserAccountType>(debitAccount);
  const [selectedCreditAccount] = useState<UserAccountType>(creditAccount);

  const [loading, setLoading] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [toastVisible, setToastVisible] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setError(null);
      setToastVisible(false);
      setAlertVisible(false);
      const nominateResult = await dispatch(
        nominateAccounts({
          creditAccountId: creditAccount.accountId,
          debitAccountId: debitAccount.accountId,
        })
      );
      if (nominateResult?.meta?.requestStatus === "fulfilled") {
        if (fromProfile) {
          await dispatch(
            setToastContent({
              errorType: "success",
              content: "Accounts updated successfully.",
            })
          );
          navigation.navigate("ProfileTab", {
            screen: "AccountSettings",
            params: { fromProfile },
          });
        } else {
          const planDetails = {
            userId: currentUserCognitoDetails.attributes.sub,
            name: reasonToSave,
            customPlanName: customPlanName || reasonToSave,
            description: "",
            frequency: "monthly",
            memberCount: selectedPlan.members,
            value: selectedPlan.value,
            length: selectedPlan.length,
            rounds: selectedPlan.installmentCount,
            roundPayout: selectedPlan.installmentValue,
            totalPayout: selectedPlan.totalPayout,
            startDate: parse(
              selectedPlan.startDate,
              "dd/MM/yyyy",
              new Date()
            ).toISOString(),
            closeDate: parse(
              selectedPlan.closingAt,
              "dd/MM/yyyy",
              new Date()
            ).toISOString(),
          };
          const startPlanResult = await dispatch(startPlan(planDetails));

          if (startPlanResult?.meta?.requestStatus === "fulfilled") {
            await dispatch(
              setToastContent({
                errorType: "success",
                content: "Plan started successfully.",
              })
            );
            await dispatch(getMyPlanList());
            navigation.navigate("HomeTab");
          } else {
            setToastVisible(true);
            setError(
              startPlanResult?.error?.message ||
                "System not available. Please try again later."
            );
          }
        }
      } else {
        setToastVisible(true);
        setError(
          nominateResult?.error?.message ||
            "System not available. Please try again later."
        );
      }
    } catch (error) {
      console.log(error);
      setToastVisible(true);
      setError("System not available. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const editButtonPressed = () => {
    if (fromProfile) {
      navigation.navigate("SelectAccount", {
        fromProfile,
        debitAccount,
        creditAccount,
      });
    } else {
      navigation.navigate("SelectAccount", {
        selectedPlan,
        reasonToSave,
        customPlanName,
        debitAccount,
        creditAccount,
      });
    }
  };

  const confirmChanges = async () => {
    if (fromProfile) {
      setAlertVisible(true);
    } else {
      handleSubmit();
    }
  };

  return (
    <SelectAccountContainer>
      <PageTemplateScreen
        header="Summary"
        subHeader="Please check your debit and credit account carefully."
        onSubmit={() => confirmChanges()}
        submitButtonEditMode={false}
        submitDisabled={
          selectedDebitAccount === null || selectedCreditAccount === null
        }
        showButtonOutline={true}
        titleButtonOutline="Edit"
        submitButtonOutline={editButtonPressed}
        submitTitle="Confirm"
        hideNavigationSection={fromProfile ? false : true}
        hideNotifications={true}
        onCancel={() => {
          if (fromProfile) {
            navigation.navigate("AccountSettings");
          } else {
            navigation.goBack();
          }
        }}
      >
        <ContentSection onStartShouldSetResponder={() => true}>
          <OuterCard>
            <InnerCard>
              <Col>
                <Title>You pay from</Title>
                <SubTitle>Linked bank account</SubTitle>
                <LineBreak />
                <AccountItem>
                  <BSBCol>
                    <SubTitle>BSB ({selectedDebitAccount.bank})</SubTitle>
                    <Title>{selectedDebitAccount.bsb}</Title>
                  </BSBCol>
                  <AccountNumberCol>
                    <SubTitle>Account number</SubTitle>
                    <Title>{selectedDebitAccount.accountNumber}</Title>
                  </AccountNumberCol>
                </AccountItem>
              </Col>
            </InnerCard>
            <InnerCard style={{ marginTop: 8 }}>
              <Col>
                <Title>You receive to</Title>
                <SubTitle>Linked bank account</SubTitle>
                <LineBreak />
                <AccountItem>
                  <BSBCol>
                    <SubTitle>BSB ({selectedCreditAccount.bank})</SubTitle>
                    <Title>{selectedCreditAccount.bsb}</Title>
                  </BSBCol>
                  <AccountNumberCol>
                    <SubTitle>Account number</SubTitle>
                    <Title>{selectedCreditAccount.accountNumber}</Title>
                  </AccountNumberCol>
                </AccountItem>
              </Col>
            </InnerCard>
          </OuterCard>
        </ContentSection>
      </PageTemplateScreen>
      <WaitingModal visible={loading} />
      {toastVisible ? (
        <ToastMessage type="error" onClose={() => setToastVisible(false)}>
          {error}
        </ToastMessage>
      ) : null}
      <AlertModal
        isVisible={alertVisible && !loading}
        title="Save changes"
        onOkay={() => {
          handleSubmit();
        }}
        okayTitle="Okay"
        cancelTitle="Cancel"
        onCancel={() => {
          setAlertVisible(false);
        }}
      >
        Making changes to this will affect your current Plans
      </AlertModal>
    </SelectAccountContainer>
  );
};

export default AccountSummaryScreen;
