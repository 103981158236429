import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Image,
  ImageRequireSource,
  TouchableOpacity,
  Keyboard,
  Platform,
  Modal,
  PixelRatio,
} from "react-native";
import styled from "styled-components/native";
import { useFormikContext } from "formik";
import DatePicker, {
  DateTimePickerEvent,
} from "@react-native-community/datetimepicker";
import { format } from "date-fns";
import { colors } from "../colors";
import { Col, ErrorText, Row } from "../shared";
import { BodyDefault } from "../Texts/Typography";
import IconButton from "../Buttons/IconButton";

const fontScale = PixelRatio.getFontScale();

const Container = styled.View`
  margin-bottom: 12px;
  width: 100%;
`;

const DateText = styled.Text`
  font-family: "DMSans";
  font-size: ${12 / fontScale}px;
  color: ${(props) => (props.isDisabled ? colors.neutral50 : colors.neutral90)};
`;

const TextContainer = styled.TouchableOpacity`
  padding: 13px 10px;
  margin-top: 5px;
  border-color: ${(props) =>
    props.hasErrors ? colors.error : colors.neutral60};
  border-width: 1px;
  border-radius: 8px;
  height: 55px;
  justify-content: center;
`;

const ModalContainer = styled(Row)`
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
`;

const ModalView = styled.View`
  width: 90%;
  background-color: ${colors.neutral0};
  border-radius: 20px;
  padding: 35px;
  shadow-color: ${colors.neutral90};
  shadow-opacity: 0.25;
  shadow-radius: 4px;
`;

const BodyDefaultText = styled(BodyDefault)`
  color: ${(props) => (props.isDisabled ? colors.neutral50 : colors.neutral60)};
`;

interface TextInputProps {
  label: string;
  fieldName: string;
  value: string;
  icon?: ImageRequireSource;
  icon2?: ImageRequireSource;
  showIcon2?: boolean;
  error?: string;
  onShow?: (() => void) | undefined;
  onHide?: (() => void) | undefined;
  onIconPress?: (() => void) | undefined;
  onChange?: ((val: string) => void) | undefined;
  secureTextEntry?: boolean;
  isNumberField?: boolean;
  children?: () => React.JSX.Element | null | undefined;
}

const FieldDatePicker: FunctionComponent<TextInputProps> = (props) => {
  const { setFieldValue, touched, setFieldTouched, isSubmitting } =
    useFormikContext();
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    const keyboardDidHideListener = Keyboard.addListener(
      "keyboardDidHide",
      () => {
        setFieldTouched(props.fieldName, false);
      }
    );
    return () => {
      keyboardDidHideListener.remove();
    };
  }, []);

  useEffect(() => {
    if (!isSubmitting) {
      if (
        touched[props.fieldName] == true &&
        Object.values(touched).filter((item) => item).length == 1 &&
        !modalVisible
      ) {
        setModalVisible(true);
        if (props.onShow) {
          props.onShow();
        }
      } else {
        if (touched[props.fieldName] == false) {
          if (props.onHide) {
            props.onHide();
          }
          setFieldTouched(props.fieldName, null);
        }
      }
    } else {
      setFieldTouched(props.fieldName, null);
    }
  }, [touched, isSubmitting]);

  const handleChange = (event: DateTimePickerEvent, date: Date | undefined) => {
    if (event.type === "set" && date) {
      setModalVisible(false);
      setFieldValue(props.fieldName, date);
      if (props.onChange) {
        props.onChange(e);
      }
    }
    if (Platform.OS === "android") {
      setModalVisible(false);
      setFieldTouched(props.fieldName, false);
    }
  };

  return (
    <Container key={`formField-${props.fieldName}`}>
      <TextContainer
        hasErrors={props.error}
        onPress={() => {
          if (!modalVisible) {
            setFieldTouched(props.fieldName, true);
          }
        }}
        disabled={props.isDisabled ? true : false}
      >
        <Row>
          <Col
            style={{
              width: props.icon ? "90%" : "100%",
            }}
          >
            <BodyDefaultText
              style={{
                fontSize:
                  props.value === ""
                    ? touched[props.fieldName]
                      ? 12
                      : 14
                    : 12,
              }}
              isDisabled={props.isDisabled}
            >
              {props.label}
            </BodyDefaultText>
            <DateText
              style={
                touched[props.fieldName] || props.value !== ""
                  ? {}
                  : { width: 0, height: 0 }
              }
              isDisabled={props.isDisabled}
            >
              {props.value && props.value !== ""
                ? format(props.value, "dd/MM/yyyy")
                : ""}
            </DateText>
            {Platform.OS === "ios" ? (
              <Modal
                animationType="slide"
                transparent={false}
                visible={modalVisible}
                onRequestClose={() => {
                  setFieldTouched(props.fieldName, false);
                  setModalVisible(false);
                }}
              >
                <ModalContainer>
                  <ModalView>
                    <Row
                      style={{ justifyContent: "flex-end", marginBottom: 10 }}
                    >
                      <IconButton
                        onPress={() => {
                          setFieldTouched(props.fieldName, false);
                          setModalVisible(false);
                        }}
                        name="close"
                        color={colors.primary100}
                        size={18}
                        btnStyles={{
                          backgroundColor: colors.neutral20,
                          borderRadius: 300,
                        }}
                        iconStyles={{
                          padding: 10,
                        }}
                      />
                    </Row>
                    <DatePicker
                      value={props.value ? new Date(props.value) : new Date()}
                      textColor={colors.neutral100}
                      mode="date"
                      maximumDate={new Date()}
                      display={"spinner"}
                      onChange={handleChange}
                    />
                  </ModalView>
                </ModalContainer>
              </Modal>
            ) : (
              modalVisible && (
                <DatePicker
                  value={props.value ? new Date(props.value) : new Date()}
                  mode="date"
                  maximumDate={new Date()}
                  display={"default"}
                  onChange={handleChange}
                  disabled={props.isDisabled}
                />
              )
            )}
          </Col>
          <Col>
            {props.icon && (
              <TouchableOpacity onPress={props.onIconPress}>
                <Image
                  source={!props.showIcon2 ? props.icon : props.icon2}
                  style={{
                    marginTop:
                      touched[props.fieldName] || props.value !== "" ? 15 : 5,
                  }}
                />
              </TouchableOpacity>
            )}
          </Col>
        </Row>
      </TextContainer>
      {props?.children && props.children()}
      {!!props.error && <ErrorText>{props.error}</ErrorText>}
    </Container>
  );
};

export default FieldDatePicker;
