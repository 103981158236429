export const formatToOrdinalNumber = (order: number) => {
  if (order > 3 && order < 21) return "th";
  switch (order % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};
