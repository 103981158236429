import React, { FunctionComponent } from "react";
import styled from "styled-components/native";
import { colors } from "../colors";
import { BodyDefault, TitleBody } from "../Texts/Typography";
import { FaqQuestionItemProps } from "../../interfaces/profile";

const ListItemContainer = styled.View`
  width: 100%;
`;

const BodyDefaultText = styled(BodyDefault)`
  color: ${colors.neutral90};
`;

const BodyDefaultUnderlineText = styled(BodyDefault)`
  font-family: DMSans Medium;
  color: ${colors.primary100};
  text-decoration-line: underline;
`;

const QuestionItem: FunctionComponent<FaqQuestionItemProps> = (props) => {
  return (
    <ListItemContainer>
      <TitleBody>{props.title}</TitleBody>
      <BodyDefaultText>
        {props.context1}{" "}
        <BodyDefaultUnderlineText
          onPress={() => {
            props.contextLinkFunction();
          }}
        >
          {props.contextLinkText}
        </BodyDefaultUnderlineText>{" "}
        {props.context2}
      </BodyDefaultText>
    </ListItemContainer>
  );
};

export default QuestionItem;
