/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from "react";
import axios from "axios";
import { useAppDispatch } from "../app/hooks";
import {
  refreshCurrentUserSession,
  signOutUser,
} from "../feature/auth/authSlice";
import axiosInstance from "./client";

const AxiosErrorHandler = ({ children }: { children: any }) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const responseInterceptor = axiosInstance.interceptors.response.use(
      (response) => response,
      async (error: any) => {
        const status = error?.response?.status;
        const originalConfig = error?.config;
        if (status === 401) {
          if (!originalConfig._retry) {
            originalConfig._retry = true;
            await dispatch(refreshCurrentUserSession());
            return axiosInstance(originalConfig);
          } else {
            dispatch(signOutUser());
            return Promise.reject("Unauthorized Access");
          }
        }
        return Promise.reject(error?.response?.data?.message || "Server Error");
      }
    );

    return () => {
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  return children;
};

export default AxiosErrorHandler;
