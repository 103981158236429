import React, { FunctionComponent, useEffect } from "react";
import { useNavigation } from "@react-navigation/native";
import {
  Keyboard,
  TouchableWithoutFeedback,
  View,
  KeyboardAvoidingView,
  Platform,
  BackHandler,
} from "react-native";
import styled from "styled-components/native";
import { PageProps } from "../../interfaces/templates";
import { Container, Row, ScrollContainer } from "../shared";
import { colors } from "../colors";
import IconButton from "../Buttons/IconButton";
import SubmitButton from "../Buttons/SubmitButton";
import LinkButton from "../Buttons/LinkButton";
import { TitleSection, BodyDefault } from "../Texts/Typography";
import RegularButton from "../Buttons/RegularButton";
import EditButton from "../Buttons/EditButton";
import NotificationIcon from "../Icons/NotificationIcon";

const PageContainer = styled(Container)`
  background-color: ${colors.neutral0};
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  max-width: 500px;
  margin: 0 auto;
`;
const NavigationSection = styled(Row)`
  width: 100%;
  margin-top: 45px;
  margin-bottom: 25px;
  padding: 0px 16px;
`;

const HeaderSection = styled.View`
  width: 100%;
  padding: 0px 16px;
  margin-bottom: 24px;
`;

const RowText = styled(Row)`
  justify-content: flex-start;
`;

const ContentSection = styled.View`
  width: 100%;
  justify-content: space-between;
  flex: 1;
  align-items: center;
`;

const ButtonSection = styled.View`
  width: 100%;
`;

const ButtonWrapper = styled.View`
  width: 90%;
  align-items: center;
  padding: 30px 5px 30px 5px;
  margin: auto;
`;

const ButtonSelectedWrapper = styled(ButtonWrapper)`
  width: 100%;
  padding: 0px;
`;

const ButtonOutlineWrapper = styled(ButtonWrapper)`
  padding: 0px 5px 20px 5px;
  margin-top: -20px;
`;

const BodyDefaultText = styled(BodyDefault)`
  color: ${colors.neutral90};
`;

const PageTemplateScreen: FunctionComponent<PageProps> = (props) => {
  const navigation = useNavigation();

  const handleBackButtonClick = () => {
    if (props.isEdit) {
      props.onEdit();
    } else if (props.onCancel) {
      props.onCancel();
    } else {
      navigation.goBack();
    }
    return true;
  };

  useEffect(() => {
    if (Platform.OS === "android") {
      BackHandler.addEventListener("hardwareBackPress", handleBackButtonClick);
      return () => {
        BackHandler.removeEventListener(
          "hardwareBackPress",
          handleBackButtonClick
        );
      };
    }
  }, []);

  return (
    <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>
      <PageContainer>
        <NavigationSection>
          {props.hideNavigationSection ? null : (
            <React.Fragment>
              <IconButton
                onPress={() => {
                  if (props.isEdit) {
                    props.onEdit();
                  } else if (props.onCancel) {
                    props.onCancel();
                  } else {
                    navigation.goBack();
                  }
                }}
                name={props.isEdit ? "close" : "arrow-back"}
                color={colors.primary100}
                size={24}
                btnStyles={{
                  backgroundColor: colors.neutral20,
                  borderRadius: 300,
                }}
                iconStyles={{
                  padding: 10,
                }}
              />
              {!props.hideNotifications ? <NotificationIcon /> : null}
            </React.Fragment>
          )}
        </NavigationSection>

        <HeaderSection>
          <Row>
            <TitleSection>{props.header}</TitleSection>
            {props.showEditButton
              ? !props.isEdit && (
                  <EditButton
                    onPress={() => {
                      props.onEdit();
                    }}
                  >
                    Edit
                  </EditButton>
                )
              : null}
          </Row>
          {props.subHeader && (
            <RowText>
              <BodyDefaultText>{props.subHeader} </BodyDefaultText>
              <LinkButton
                textStyles={{ color: colors.primary100 }}
                onPress={() => {
                  props.onSubHeaderLink();
                }}
              >
                {props.subHeaderLink}
              </LinkButton>
            </RowText>
          )}
        </HeaderSection>
        <ScrollContainer
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          <View onStartShouldSetResponder={() => true} style={{ flex: 1 }}>
            <ContentSection>{props.children}</ContentSection>
          </View>
        </ScrollContainer>
        <ButtonSection>
          {props.informationComponent ? props.informationComponent() : null}
          {!props.hideSubmitButton &&
            (props.submitButtonEditMode === false ? (
              <ButtonWrapper>
                <SubmitButton
                  btnStyles={
                    props.submitDisabled
                      ? { backgroundColor: colors.neutral30 }
                      : undefined
                  }
                  textStyles={{
                    color: props.submitDisabled
                      ? colors.neutral60
                      : colors.neutral0,
                  }}
                  onPress={() => {
                    props.onSubmit();
                  }}
                  disabled={props.submitDisabled}
                >
                  {props.submitTitle}
                </SubmitButton>
              </ButtonWrapper>
            ) : (
              <KeyboardAvoidingView
                style={{ flex: Platform.OS === "ios" ? 1 : 0, width: "100%" }}
                behavior={Platform.OS === "ios" ? "position" : undefined}
                keyboardVerticalOffset={
                  Platform.OS === "ios"
                    ? props.keyboardVerticalOffset
                      ? props.keyboardVerticalOffset
                      : -180
                    : 0
                }
                contentContainerStyle={{
                  paddingVertical: Platform.OS === "ios" ? 110 : 0,
                }}
              >
                <ButtonSelectedWrapper>
                  <SubmitButton
                    btnStyles={
                      props.submitDisabled
                        ? { backgroundColor: colors.neutral30 }
                        : undefined
                    }
                    textStyles={{
                      color: props.submitDisabled
                        ? colors.neutral60
                        : colors.neutral0,
                    }}
                    onPress={() => {
                      props.onSubmit();
                    }}
                    buttonBox={true}
                    disabled={props.submitDisabled}
                  >
                    {props.submitTitle}
                  </SubmitButton>
                </ButtonSelectedWrapper>
              </KeyboardAvoidingView>
            ))}
          {props?.showButtonOutline && (
            <ButtonOutlineWrapper>
              <RegularButton
                textStyles={{ color: colors.primary100 }}
                btnStyles={{
                  backgroundColor: colors.neutral0,
                  borderWidth: 1,
                  borderColor: colors.primary100,
                }}
                onPress={() => {
                  {
                    props.submitButtonOutline();
                  }
                }}
              >
                {props.titleButtonOutline}
              </RegularButton>
            </ButtonOutlineWrapper>
          )}
        </ButtonSection>
      </PageContainer>
    </TouchableWithoutFeedback>
  );
};

export default PageTemplateScreen;
