import { EVENT, PAYMENT_STATUS } from "./enums";
import { colors } from "../components/colors";
import PlanCard1 from "../assets/planCards/plan-card-1.png";
import PlanCard2 from "../assets/planCards/plan-card-2.png";
import PlanCard3 from "../assets/planCards/plan-card-3.png";
import PlanCard4 from "../assets/planCards/plan-card-4.png";
import PlanCardHeader1 from "../assets/planCards/plan-card-header-1.png";
import PlanCardHeader2 from "../assets/planCards/plan-card-header-2.png";
import PlanCardHeader3 from "../assets/planCards/plan-card-header-3.png";
import PlanCardHeader4 from "../assets/planCards/plan-card-header-4.png";

const planCards = [PlanCard1, PlanCard2, PlanCard3, PlanCard4];
const planCardHeaders = [
  PlanCardHeader1,
  PlanCardHeader2,
  PlanCardHeader3,
  PlanCardHeader4,
];

export const getCardBackground = (card: number) => {
  return planCards[card % planCards.length];
};

export const getHeaderCardBackground = (card: number) => {
  return planCardHeaders[card % planCardHeaders.length];
};

export const getStatusColor = (status: string, event: string) => {
  switch (status + "_" + event) {
    case PAYMENT_STATUS.SUCCESS + "_" + EVENT.CONTRIBUTION:
      return colors.neutral0;
    case PAYMENT_STATUS.FAIL + "_" + EVENT.CONTRIBUTION:
      return colors.error;
    case PAYMENT_STATUS.SUCCESS + "_" + EVENT.PAYOUT:
      return colors.correct;
    default:
      return "rgba(255, 255, 255, 0.2)";
  }
};
