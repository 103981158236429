import React, { useEffect, useState } from "react";
import styled from "styled-components/native";
import { Container } from "../../../components/shared";
import { colors } from "../../../components/colors";
import { BodySmall, TitleSection } from "../../../components/Texts/Typography";
import { RootStackScreenProps } from "../../../navigation/types";
import { useAppDispatch } from "../../../app/hooks";
import {
  getUserAccounts,
  submitConsent,
} from "../../../feature/plan/planSlice";
import ToastMessage from "../../../components/ToastMessages/Toast";
import { setToastContent } from "../../../feature/notification/notificationSlice";

const RequestSubmittedContainer = styled(Container)`
  background-color: ${colors.primary100};
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

const ContentSection = styled.View`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
`;

const TitleSectionText = styled(TitleSection)`
  color: ${colors.neutral0};
  text-align: center;
  margin-top: 40px;
`;

const BodySmallText = styled(BodySmall)`
  color: ${colors.neutral0};
  text-align: center;
`;

const SubmitConsentScreen = ({
  navigation,
  route,
}: RootStackScreenProps<"SubmitConsent">) => {
  const { selectedPlan, reasonToSave, customPlanName, fromProfile } =
    route.params;
  const [toastVisible, setToastVisible] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    processConsent();
  }, []);

  const processConsent = async () => {
    try {
      setToastVisible(false);
      setError(null);
      setTimeout(async () => {
        const result = await dispatch(getUserAccounts());
        if (result?.payload && result?.payload.length === 0) {
          await dispatch(
            setToastContent({
              errorType: "error",
              content:
                "Something went wrong: Ensure you're connecting your regular bank account and NOT a credit card account.",
            })
          );
          if (fromProfile) {
            navigation.navigate("ProfileTab", { screen: "Profile" });
          } else {
            navigation.navigate("AdditionalInformation", {
              data: selectedPlan,
              reasonToSave,
              customPlanName,
            });
          }
        } else {
          const consentResult = await dispatch(submitConsent());

          if (consentResult?.meta?.requestStatus === "fulfilled") {
            let params = {
              selectedPlan,
              reasonToSave,
              customPlanName,
            };
            if (fromProfile) {
              params = {
                fromProfile,
              };
            }

            navigation.navigate("SelectAccount", params);
          } else {
            setToastVisible(true);
            setError(
              consentResult?.error?.message ||
                "System not available. Please try again later."
            );
          }
        }
      }, 40000);
    } catch (error) {
      setToastVisible(true);
      setError("System not available. Please try again later.");
    }
  };

  return (
    <RequestSubmittedContainer>
      <ContentSection>
        <TitleSectionText>We are almost there!</TitleSectionText>
        <BodySmallText>
          Please hang in tight we are checking the{"\n"}information you
          provided.
        </BodySmallText>
      </ContentSection>
      {toastVisible ? (
        <ToastMessage
          type="error"
          onClose={() => {
            navigation.navigate("PlansTab", {
              screen: "AdditionalInformation",
              params: { data: selectedPlan, reasonToSave, customPlanName },
            });
            setToastVisible(false);
          }}
        >
          {error}
        </ToastMessage>
      ) : null}
    </RequestSubmittedContainer>
  );
};

export default SubmitConsentScreen;
