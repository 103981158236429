import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  verifySignUpParam,
  signUpParam,
  signInParam,
  updateAccountDetailsParam,
  getCurrentUserCognitoDetailsParam,
  forgotPasswordParam,
  confirmForgotPasswordParam,
  changePasswordAPIParam,
  setUserPinParam,
  verifyEmailParam,
  uploadProfileImageParam,
  UserParam,
  verifyUserPinParam,
  resetSignUpParam,
  userPreferenceParam,
  reactivateAccountDetailsParam,
} from "../../interfaces/auth";
import authAPI from "./authAPI";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { initialCurrentUserDetails } from "../../utils/constants";
import { getDeviceId } from "../../utils/getDevicePreferences";

type StateStatus = "idle" | "loading" | "succeeded" | "failed";

interface AuthState {
  status: StateStatus;
  signUpDetails: unknown[];
  confirmSignUpDetails: unknown[];
  signInDetails: unknown[];
  currentUserCognitoDetails: unknown;
  currentUserDetails: UserParam;
  forgotPasswordDetails: unknown[];
  confirmForgotPasswordDetails: unknown[];
  changePasswordDetails: unknown[];
  fromSignupScreen: boolean;
  error: string;
  requestVerificationEmailTimestamp: string | undefined;
  verifyScreenStatus: string | undefined;
}

interface PersistedAuthData {
  currentUserCognitoDetails: unknown;
  currentUserDetails: UserParam;
  requestVerificationEmailTimestamp: string | undefined;
}

const initialState: AuthState = {
  status: "idle",
  signUpDetails: [],
  confirmSignUpDetails: [],
  signInDetails: [],
  currentUserCognitoDetails: {},
  currentUserDetails: initialCurrentUserDetails,
  forgotPasswordDetails: [],
  confirmForgotPasswordDetails: [],
  changePasswordDetails: [],
  fromSignupScreen: false,
  error: "",
  requestVerificationEmailTimestamp: undefined,
  verifyScreenStatus: undefined,
};

export const signUpUser = createAsyncThunk(
  "/signUp",
  async (param: signUpParam, thunkAPI) => {
    try {
      const response = authAPI.signUp(param);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const verifySignUpUser = createAsyncThunk(
  "/confirmSignUp",
  async (param: verifySignUpParam, thunkAPI) => {
    try {
      const response = authAPI.verifySignUp(param);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const signInUser = createAsyncThunk(
  "/signIn",
  async (param: signInParam, thunkAPI) => {
    try {
      const response = authAPI.signIn(param);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getCurrentUserCognitoDetails = createAsyncThunk(
  "/getCurrentUserCognitoDetails",
  async (param: getCurrentUserCognitoDetailsParam, thunkAPI) => {
    try {
      const response = authAPI.checkCurrentUserAuthenticated(param);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const refreshCurrentUserSession = createAsyncThunk(
  "/refreshCurrentUserSession",
  async (param, thunkAPI) => {
    try {
      const response = await authAPI.refreshCurrentUserSession();
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getCurrentUserDetails = createAsyncThunk(
  "/getCurrentUserDetails",
  async (param, thunkAPI) => {
    try {
      const response = await authAPI.getCurrentUserDetails();
      return response?.data?.currentUser;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateAccountDetails = createAsyncThunk(
  "/updateAccountDetails",
  async (param: updateAccountDetailsParam, thunkAPI) => {
    try {
      const response = authAPI.updateAccountDetails(param);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const resetSignUp = createAsyncThunk(
  "/resetSignUp",
  async (params: resetSignUpParam, thunkAPI) => {
    try {
      const response = authAPI.resetSignUp(params);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const resendSignUp = createAsyncThunk(
  "/resendSignUp",
  async (phone_number: string, thunkAPI) => {
    try {
      const response = authAPI.resendSignUp(phone_number);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const verifyUserPin = createAsyncThunk(
  "/setUserPin",
  async (param: verifyUserPinParam, thunkAPI) => {
    try {
      const response = authAPI.verifyUserPin(param);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const setUserPin = createAsyncThunk(
  "/setUserPin",
  async (param: setUserPinParam, thunkAPI) => {
    try {
      const response = authAPI.setUserPin(param);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const verifyEmail = createAsyncThunk(
  "/verifyEmail",
  async (param: undefined, thunkAPI) => {
    try {
      const response = authAPI.verifyEmail();
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const verifyEmailSubmit = createAsyncThunk(
  "/verifyEmailSubmit",
  async (param: verifyEmailParam, thunkAPI) => {
    try {
      const response = authAPI.verifyEmailSubmit(param);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "/forgotPassword",
  async (param: forgotPasswordParam, thunkAPI) => {
    try {
      const response = authAPI.forgotPassword(param);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const confirmForgotPassword = createAsyncThunk(
  "/confirmForgotPassword",
  async (param: confirmForgotPasswordParam, thunkAPI) => {
    try {
      const response = authAPI.confirmForgotPassword(param);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const changePassword = createAsyncThunk(
  "/changePassword",
  async (param: changePasswordAPIParam, thunkAPI) => {
    try {
      const response = authAPI.changePassword(param);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const signOutUser = createAsyncThunk(
  "/signOutUser",
  async (param, thunkAPI) => {
    try {
      const response = authAPI.signOut();
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const setFromSignupScreen = createAsyncThunk(
  "/setFromSignupScreen",
  async (param: boolean) => {
    return param;
  }
);

export const setVerifyScreenStatus = createAsyncThunk(
  "/setVerifyScreenStatus",
  async (param: string | undefined) => {
    return param;
  }
);

export const uploadProfileImage = createAsyncThunk(
  "/uploadProfileImage",
  async (param: uploadProfileImageParam) => {
    const response = authAPI.uploadProfileImage(param);
    return response;
  }
);

export const setRequestVerificationEmailTimestamp = createAsyncThunk(
  "/requestVerificationEmailTimestamp",
  async (timestamp: string) => {
    return timestamp;
  }
);

export const checkEmail = createAsyncThunk(
  "/checkEmail",
  async (email: string, thunkAPI) => {
    try {
      const response = authAPI.checkEmail(email);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const setUserPreferences = createAsyncThunk(
  "/setUserPreferences",
  async (param: userPreferenceParam, thunkAPI) => {
    try {
      const response = authAPI.setUserPreferences(param);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const disableUserPushToken = createAsyncThunk(
  "/disableUserPushToken",
  async (param, thunkAPI) => {
    try {
      const deviceId = await getDeviceId();
      const response = authAPI.setUserPreferences({
        deviceId,
        isPushTokenActive: false,
      });
      return response;
    } catch (err) {
      console.log("err", err);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const setUserBiometricsPreference = createAsyncThunk(
  "/setUserBiometricsPreference",
  async (param: boolean, thunkAPI) => {
    try {
      const deviceId = await getDeviceId();
      const response = authAPI.setUserPreferences({
        deviceId,
        isBiometricsEnabled: param,
      });
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const loadPersistedAuthData = createAsyncThunk(
  "/load-auth-data",
  async () => {
    const currentUserCognitoDetails = await AsyncStorage.getItem(
      "currentUserCognitoDetails"
    );
    const currentUserDetails = await AsyncStorage.getItem("currentUserDetails");
    const requestVerificationEmailTimestamp = await AsyncStorage.getItem(
      "requestVerificationEmailTimestamp"
    );
    const returnData = <PersistedAuthData>{
      currentUserCognitoDetails: {},
      currentUserDetails: initialCurrentUserDetails,
      requestVerificationEmailTimestamp: "",
    };

    if (currentUserCognitoDetails) {
      returnData.currentUserCognitoDetails = JSON.parse(
        currentUserCognitoDetails
      );
    }
    if (currentUserDetails) {
      returnData.currentUserDetails = JSON.parse(currentUserDetails);
    }
    if (requestVerificationEmailTimestamp) {
      returnData.requestVerificationEmailTimestamp =
        requestVerificationEmailTimestamp;
    }
    return returnData;
  }
);

export const userReactivateAccount = createAsyncThunk(
  "/user-reactivate-account",
  async (param: reactivateAccountDetailsParam, thunkAPI) => {
    try {
      const response = await authAPI.reactivateAccount(param);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(signUpUser.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(signUpUser.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.signUpDetails = action.payload;
    });
    builder.addCase(signUpUser.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });

    builder.addCase(verifySignUpUser.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(verifySignUpUser.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.confirmSignUpDetails = action.payload;
    });
    builder.addCase(verifySignUpUser.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });

    builder.addCase(signInUser.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(signInUser.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.signInDetails = action.payload;
      if (action?.payload?.signInUserSession?.idToken?.jwtToken)
        AsyncStorage.setItem(
          "token",
          action?.payload?.signInUserSession?.idToken?.jwtToken
        );
    });
    builder.addCase(signInUser.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });
    builder.addCase(getCurrentUserCognitoDetails.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getCurrentUserCognitoDetails.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.currentUserCognitoDetails = action.payload;
      AsyncStorage.setItem(
        "currentUserCognitoDetails",
        JSON.stringify(action.payload)
      );
      if (action?.payload?.signInUserSession?.idToken?.jwtToken)
        AsyncStorage.setItem(
          "token",
          action?.payload?.signInUserSession?.idToken?.jwtToken
        );
    });
    builder.addCase(getCurrentUserCognitoDetails.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });
    builder.addCase(refreshCurrentUserSession.fulfilled, (state, action) => {
      if (action?.payload?.idToken?.jwtToken)
        AsyncStorage.setItem("token", action?.payload?.idToken?.jwtToken);
    });
    builder.addCase(getCurrentUserDetails.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.currentUserDetails = action.payload;
      AsyncStorage.setItem(
        "currentUserDetails",
        JSON.stringify(action.payload)
      );
    });
    builder.addCase(getCurrentUserDetails.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });

    builder.addCase(forgotPassword.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.forgotPasswordDetails = action.payload;
    });
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });

    builder.addCase(confirmForgotPassword.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(confirmForgotPassword.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.confirmForgotPasswordDetails = action.payload;
    });
    builder.addCase(confirmForgotPassword.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });

    builder.addCase(changePassword.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(changePassword.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.changePasswordDetails = action.payload;
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });

    builder.addCase(signOutUser.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(signOutUser.fulfilled, (state) => {
      state.status = "succeeded";
      state = initialState;
    });
    builder.addCase(signOutUser.rejected, (state) => {
      state.status = "failed";
    });

    builder.addCase(setUserPin.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(setUserPin.fulfilled, (state) => {
      state.status = "succeeded";
    });
    builder.addCase(setUserPin.rejected, (state) => {
      state.status = "failed";
    });
    builder.addCase(setFromSignupScreen.fulfilled, (state, action) => {
      state.fromSignupScreen = action.payload;
    });
    builder.addCase(setVerifyScreenStatus.fulfilled, (state, action) => {
      state.verifyScreenStatus = action.payload;
    });
    builder.addCase(
      setRequestVerificationEmailTimestamp.fulfilled,
      (state, action) => {
        state.requestVerificationEmailTimestamp = action.payload;
        AsyncStorage.setItem(
          "requestVerificationEmailTimestamp",
          action.payload
        );
      }
    );
    builder.addCase(loadPersistedAuthData.fulfilled, (state, action) => {
      state.currentUserCognitoDetails =
        action.payload.currentUserCognitoDetails;
      state.currentUserDetails = action.payload.currentUserDetails;
      state.requestVerificationEmailTimestamp =
        action.payload.requestVerificationEmailTimestamp;
    });
  },
});

export default authSlice.reducer;
