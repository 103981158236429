import React, { FunctionComponent, SVGProps, useEffect } from "react";
import styled from "styled-components/native";
import { MaterialIcons } from "@expo/vector-icons";
import { colors } from "../colors";
import { Platform } from "react-native";
import { BodyDefaultBold } from "../Texts/Typography";
import { Row, ScreenWidth } from "../shared";
import ErrorIcon from "../../assets/icons/danger-icon.svg";

const componentPadding = 16;
const ToastContainer = styled.View`
  background-color: ${(props) =>
    props.type === "error" ? colors.error_light : colors.tertiary_light};
  position: absolute;
  top: ${Platform.OS === "ios" ? "40px" : "30px"};
  left: ${componentPadding}px;
  width: ${ScreenWidth - componentPadding * 2}px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 4px 0px;
  padding: 9px 12px 9px 16px;
  border-radius: 16px;
`;

const ContentContainer = styled(Row)`
  align-items: center;
  gap: 8px;
  flex: 1;
`;

const ContentText = styled(BodyDefaultBold)`
  flex: 1;
`;

const IconContainer = styled.View`
  background-color: ${(props) =>
    props.type === "error" ? colors.error : colors.correct};
  border-radius: 8px;
  padding: 10px;
`;

const CloseButton = styled.TouchableOpacity`
  padding: 6px;
`;

// types
interface ToastTypes {
  icon?: SVGProps<SVGElement>;
  type?: string;
  onClose: ((autoClose: boolean | undefined | unknown) => void) | undefined;
  children: React.ReactNode;
}

const ToastMessage: FunctionComponent<ToastTypes> = (props) => {
  useEffect(() => {
    setTimeout(() => {
      props.onClose(true);
    }, 5000);
  }, []);

  return (
    <ToastContainer type={props.type}>
      <ContentContainer>
        <IconContainer type={props.type}>
          {props.icon ? (
            <props.icon height={16} width={16} />
          ) : props.type === "error" ? (
            <ErrorIcon height={16} width={16} />
          ) : (
            <MaterialIcons name="check" size={16} color={colors.neutral0} />
          )}
        </IconContainer>
        <ContentText>{props.children}</ContentText>
      </ContentContainer>
      <CloseButton onPress={props.onClose}>
        <MaterialIcons name="close" size={16} color={colors.neutral100} />
      </CloseButton>
    </ToastContainer>
  );
};

export default ToastMessage;
