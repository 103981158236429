import React, { useState } from "react";
import styled from "styled-components/native";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { generateConsentURL } from "../../../feature/plan/planSlice";
import { RootStackScreenProps } from "../../../navigation/types";

import { colors } from "../../../components/colors";
import { Container, ScrollContainer } from "../../../components/shared";
import PageTemplateScreen from "../../../components/Templates/PageTemplate";
import InformationCard from "../../../components/Card/InformationCard";
import {
  ConsentLearnMoreURL,
  InformationTabData,
} from "../../../utils/constants";
import WaitingModal from "../../../components/Modals/WaitingModal";
import { Linking } from "react-native";
import ToastMessage from "../../../components/ToastMessages/Toast";

const ConnectBankContainer = styled(Container)`
  flex: 1;
  background-color: ${colors.neutral0};
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

const ContentSection = styled.View`
  padding: 0px 16px 20px 16px;
  width: 100%;
  align-items: center;
  flex-direction: column;
`;

const ConnectBankScreen = ({
  route,
  navigation,
}: RootStackScreenProps<"ConnectBank">) => {
  const { selectedPlan, reasonToSave, customPlanName, fromProfile } =
    route.params;
  const user = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [toastVisible, setToastVisible] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setError(null);
      const isEmailVerified =
        user?.currentUserCognitoDetails?.attributes?.email_verified === true;

      const params = {
        firstName: user?.currentUserDetails?.firstName,
        lastName: user?.currentUserDetails?.lastName,
        phoneNumber: user?.currentUserDetails?.phoneNumber,
        ...(isEmailVerified && {
          email: user?.currentUserCognitoDetails?.attributes?.email,
        }),
      };
      let result = null;

      if (user.currentUserDetails) {
        result = await dispatch(generateConsentURL(params));
      }

      if (result?.payload?.url) {
        let params = {
          consentURL: result?.payload?.url,
          type: "connect",
        };
        if (fromProfile) {
          params = {
            ...params,
            fromProfile,
          };
        } else {
          params = {
            ...params,
            selectedPlan,
            reasonToSave,
            customPlanName,
          };
        }
        navigation.navigate("ConsentWebview", params);
      } else {
        setToastVisible(true);
        setError(
          result?.error?.message ||
            "System not available. Please try again later."
        );
      }
    } catch (error) {
      setToastVisible(true);
      setError("System not available. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <ConnectBankContainer>
      <PageTemplateScreen
        header="Let’s connect your bank account"
        subHeader="We understand linking your bank account can be a bit inconvenient, but it helps us verify your identity and ensures a safe and reliable community."
        onSubmit={() => handleSubmit()}
        submitButtonEditMode={false}
        submitTitle="Continue"
        submitButtonOutline={() => {
          Linking.openURL(ConsentLearnMoreURL);
        }}
        showButtonOutline={true}
        titleButtonOutline="Learn More"
      >
        <ScrollContainer
          automaticallyAdjustKeyboardInsets={true}
          showsVerticalScrollIndicator={false}
        >
          <ContentSection onStartShouldSetResponder={() => true}>
            {InformationTabData.map((data, index) => (
              <InformationCard
                key={`InformationCard-${index}`}
                {...data}
                itemIndex={index}
                itemLength={InformationTabData.length}
              />
            ))}
          </ContentSection>
        </ScrollContainer>
      </PageTemplateScreen>
      <WaitingModal visible={loading} />
      {toastVisible ? (
        <ToastMessage type="error" onClose={() => setToastVisible(false)}>
          {error}
        </ToastMessage>
      ) : null}
    </ConnectBankContainer>
  );
};

export default ConnectBankScreen;
