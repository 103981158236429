import React, { useState, useRef, Fragment, useEffect } from "react";
import {
  NativeSyntheticEvent,
  TextInput,
  TextInputKeyPressEventData,
  PixelRatio,
} from "react-native";
import styled from "styled-components/native";
import { RootStackScreenProps } from "../../../navigation/types";
import AuthTemplateScreen from "../../../components/Templates/AuthTemplate";
import WaitingModal from "../../../components/Modals/WaitingModal";
import { Container } from "../../../components/shared";
import { useAppDispatch } from "../../../app/hooks";
import {
  getCurrentUserCognitoDetails,
  setUserPin,
} from "../../../feature/auth/authSlice";
import { colors } from "../../../components/colors";
import ToastMessage from "../../../components/ToastMessages/Toast";
import { setToastContent } from "../../../feature/notification/notificationSlice";
import PinIcon from "../../../assets/icons/pin-icon-white.svg";

const fontScale = PixelRatio.getFontScale();

const FieldSection = styled.View`
  margin-top: 24px;
  padding-left: 18px;
  padding-right: 18px;
  width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  gap: 6px;
`;

const CodeTextInputContainer = styled.View`
  height: 48px;
  width: 48px;
  border-color: ${(props) =>
    props?.isActive ? colors.neutral90 : colors.neutral40};
  border-width: ${(props) => (props?.isActive ? "2px" : "1px")};
  border-radius: 12px;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const CodeTextInput = styled.TextInput`
  font-family: "DMSans";
  font-size: ${12 / fontScale}px;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 0px;
  padding-bottom: 0px;
`;

const codeLength = 4;

const PinSetupScreen = ({
  route,
  navigation,
}: RootStackScreenProps<"PinSetup">) => {
  const fromProfile = route?.params?.fromProfile || false;
  const previousPin = route?.params?.previousPin || false;

  const pinCodeInputRefs = useRef<TextInput[]>([]);

  const [pinCodeArray, setPinCodeArray] = useState(Array(codeLength).fill(""));

  const [loading, setLoading] = useState(false);
  const [toastVisible, setToastVisible] = useState(false);
  const [activeFieldIndex, setActiveFieldIndex] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);

  const dispatch = useAppDispatch();

  const handleRef = (ref: TextInput, index: number) => {
    pinCodeInputRefs.current[index] = ref;
  };

  useEffect(() => {
    if (pinCodeArray.filter((d) => d.trim() === "").length == 0 && !loading) {
      triggerSubmit();
      pinCodeInputRefs.current[0].focus();
    }
  }, [pinCodeArray]);

  const triggerSubmit = async () => {
    setError(null);
    try {
      setLoading(true);
      if (!previousPin) {
        navigation.push("PinSetup", {
          fromProfile,
          previousPin: pinCodeArray.join(""),
        });
      } else {
        const userPin = pinCodeArray.join("");
        if (previousPin === userPin) {
          const result = await dispatch(setUserPin({ code: userPin }));
          if (result?.meta?.requestStatus === "fulfilled") {
            if (fromProfile) {
              dispatch(
                setToastContent({
                  icon: PinIcon,
                  errorType: "success",
                  content: "Pin Updated",
                })
              );
              await dispatch(
                getCurrentUserCognitoDetails({ bypassCache: true })
              );
              navigation.navigate("ProfileTab", {
                screen: "Security",
              });
            } else {
              navigation.navigate("Onboarding");
            }
          } else {
            setToastVisible(true);
            setError(
              result?.error?.message ||
                "System not available. Please try again later."
            );
          }
        } else {
          setToastVisible(true);
          setError("Pin code does not match");
        }
      }
    } catch (e) {
      setToastVisible(true);
      setError("System not available. Please try again later.");
    } finally {
      setActiveFieldIndex(null);
      setLoading(false);
    }
  };

  const handleChange = (index: number) => (text: string) => {
    if (text !== "") {
      let nextIndex = index + 1;
      const newArr = [...pinCodeArray];
      if (text.length == codeLength) {
        [...text].forEach((t, index) => (newArr[index] = t));
        setActiveFieldIndex(text.length);
        nextIndex = text.length;
      } else {
        newArr[index] = text.charAt(text.length - 1);
        setActiveFieldIndex(index + 1);
      }
      setPinCodeArray(newArr);
      if (pinCodeInputRefs.current[nextIndex]) {
        pinCodeInputRefs.current[nextIndex].focus();
      }
    }
  };

  const handleBackspace =
    (index: number) =>
    (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
      if (e.nativeEvent.key === "Backspace") {
        const newArr = [...pinCodeArray];
        newArr[index] = "";
        setActiveFieldIndex(index - 1);
        setPinCodeArray(newArr);
        if (pinCodeInputRefs.current[index - 1]) {
          pinCodeInputRefs.current[index - 1].focus();
        }
      }
    };

  return (
    <Fragment>
      <AuthTemplateScreen
        header="Set your pin"
        subHeader={
          previousPin
            ? `Re-enter your 4 digit pin code`
            : `Enter your 4 digit pin code`
        }
        submitTitle="Verify"
        backButtonIcon={"arrow-back"}
        navigation={navigation}
        submitButtonEditMode={activeFieldIndex !== null}
        submitDisabled={loading}
        onSubmit={() => triggerSubmit()}
        hideSubmitButton={true}
      >
        <Container>
          <FieldSection>
            {pinCodeArray.map((value, index) => (
              <CodeTextInputContainer
                key={`VerificationCodeInput-${index}`}
                isActive={index === activeFieldIndex}
              >
                <CodeTextInput
                  style={
                    index === activeFieldIndex
                      ? { borderColor: "#48464C" }
                      : undefined
                  }
                  key={`PinCodeInput-${index}`}
                  onKeyPress={handleBackspace(index)}
                  ref={(ref: TextInput) => handleRef(ref, index)}
                  value={value}
                  onChangeText={handleChange(index)}
                  onFocus={() => setActiveFieldIndex(index)}
                  onBlur={() =>
                    index === activeFieldIndex
                      ? setActiveFieldIndex(null)
                      : null
                  }
                  placeholder="-"
                  keyboardType="number-pad"
                />
              </CodeTextInputContainer>
            ))}
          </FieldSection>
        </Container>
      </AuthTemplateScreen>
      <WaitingModal visible={loading} />
      {toastVisible ? (
        <ToastMessage type="error" onClose={() => setToastVisible(false)}>
          {error}
        </ToastMessage>
      ) : null}
    </Fragment>
  );
};

export default PinSetupScreen;
