import React, { Fragment } from "react";
import { Linking, View } from "react-native";
import styled from "styled-components/native";
import { MaterialIcons } from "@expo/vector-icons";
import { colors } from "../../../../components/colors";
import { RootStackScreenProps } from "../../../../navigation/types";
import {
  Row,
  Col,
  Container,
  ScrollContainer,
} from "../../../../components/shared";
import PageTemplateScreen from "../../../../components/Templates/PageTemplate";
import { BodyDefaultBold } from "../../../../components/Texts/Typography";
import ProfileMenuIcon from "../../../../components/Icons/ProfileMenuIcon";
import ReleaseAlertIcon from "../../../../assets/icons/release_alert.svg";
import UniversalCurrentAltIcon from "../../../../assets/icons/universal-currency-alt.svg";
import AccountBalanceIcon from "../../../../assets/icons/account-balance.svg";

const HelpAndSupportContainer = styled(Container)`
  background-color: ${colors.neutral0};
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

const ContentSection = styled.View`
  width: 100%;
  height: 100%;
  gap: 24px;
  padding: 0px 16px;
`;

const MenuItemButton = styled.TouchableOpacity`
  flex-direction: row;
  justify-content: space-between;
`;

const MenuItemRow = styled(Row)`
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const MenuItemArrow = styled(Col)`
  justify-content: center;
`;

const HelpAndSupportScreen = ({
  navigation,
}: RootStackScreenProps<"HelpAndSupport">) => {
  const HELPDESK_URL = "https://forms.gle/FASBm4SxKSnprb9A8";
  return (
    <Fragment>
      <HelpAndSupportContainer>
        <PageTemplateScreen
          header="Help and Support"
          onSubmit={() => {
            Linking.openURL(HELPDESK_URL);
          }}
          submitTitle="Raise a support ticket"
          submitButtonEditMode={false}
          showButtonOutline={true}
          titleButtonOutline="Talk to our team"
          submitButtonOutline={() => {
            navigation.navigate("TalkToOurTeam");
          }}
        >
          <ScrollContainer
            automaticallyAdjustKeyboardInsets={true}
            showsVerticalScrollIndicator={false}
          >
            <View onStartShouldSetResponder={() => true}>
              <ContentSection>
                <MenuItemButton
                  onPress={() => {
                    navigation.navigate("Faq", { type: "General" });
                  }}
                >
                  <Col>
                    <MenuItemRow>
                      <ProfileMenuIcon isSvg={true}>
                        <ReleaseAlertIcon />
                      </ProfileMenuIcon>
                      <BodyDefaultBold>General</BodyDefaultBold>
                    </MenuItemRow>
                  </Col>
                  <MenuItemArrow>
                    <MaterialIcons name="arrow-forward-ios" size={16} />
                  </MenuItemArrow>
                </MenuItemButton>
                <MenuItemButton
                  onPress={() => {
                    navigation.navigate("Faq", { type: "Plans" });
                  }}
                >
                  <Col>
                    <MenuItemRow>
                      <ProfileMenuIcon isSvg={true}>
                        <UniversalCurrentAltIcon />
                      </ProfileMenuIcon>
                      <BodyDefaultBold>Plans</BodyDefaultBold>
                    </MenuItemRow>
                  </Col>
                  <MenuItemArrow>
                    <MaterialIcons name="arrow-forward-ios" size={16} />
                  </MenuItemArrow>
                </MenuItemButton>
                <MenuItemButton
                  onPress={() => {
                    navigation.navigate("Faq", { type: "Connect Bank" });
                  }}
                >
                  <Col>
                    <MenuItemRow>
                      <ProfileMenuIcon isSvg={true}>
                        <AccountBalanceIcon />
                      </ProfileMenuIcon>
                      <BodyDefaultBold>Connecting Your Bank</BodyDefaultBold>
                    </MenuItemRow>
                  </Col>
                  <MenuItemArrow>
                    <MaterialIcons name="arrow-forward-ios" size={16} />
                  </MenuItemArrow>
                </MenuItemButton>
              </ContentSection>
            </View>
          </ScrollContainer>
        </PageTemplateScreen>
      </HelpAndSupportContainer>
    </Fragment>
  );
};

export default HelpAndSupportScreen;
