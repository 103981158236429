import React, { FunctionComponent } from "react";
import styled from "styled-components/native";
import { MaterialIcons } from "@expo/vector-icons";
import { StyleProp, ViewStyle } from "react-native";
import { colors } from "../colors";

const MenuIconSection = styled.View`
  background-color: ${colors.neutral20};
  width: 48px;
  height: 48px;
  padding: 12px;
  border-radius: 50px;
  gap: 10px;
  justify-content: center;
  align-items: center;
`;

// types
interface MenuIconProps {
  badgeStyle?: StyleProp<ViewStyle>;
  color?: string;
  iconStyles?: StyleProp<ViewStyle>;
  name?: string;
  isSvg?: boolean;
  children?: React.ReactNode;
}

const ProfileMenuIcon: FunctionComponent<MenuIconProps> = (props: any) => {
  return (
    <MenuIconSection style={props.badgeStyle}>
      {props.isSvg ? (
        props.children
      ) : (
        <MaterialIcons
          name={props.name}
          size={24}
          style={props.iconStyles}
          color={props.color}
        />
      )}
    </MenuIconSection>
  );
};

export default ProfileMenuIcon;
