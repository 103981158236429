import React, { FunctionComponent } from "react";
import styled from "styled-components/native";
import { GestureResponderEvent, StyleProp, ViewStyle } from "react-native";
import { colors } from "../colors";

const ButtonContainer = styled.TouchableOpacity`
  justify-content: center;
  align-items: ${(props) => (props.active ? "flex-end" : "flex-start")};
  height: 20px;
  width: 32px;
  padding: 2.5px;
  background-color: ${(props) =>
    props.active ? colors.neutral0 : colors.primary70};
  border-radius: 16px;
`;

const Toggler = styled.View`
  height: 15px;
  width: 15px;
  border-radius: 7.5px;
  background-color: ${(props) =>
    props.active ? colors.primary100 : colors.neutral0};
`;

interface ButtonProps {
  active: boolean;
  btnStyles?: StyleProp<ViewStyle>;
  onPress: ((event: GestureResponderEvent) => void) | undefined;
}

const SmallToggleButton: FunctionComponent<ButtonProps> = (props) => {
  return (
    <ButtonContainer
      onPress={props.onPress}
      style={props.btnStyles}
      active={props.active}
    >
      <Toggler active={props.active} />
    </ButtonContainer>
  );
};

export default SmallToggleButton;
