import React, { FunctionComponent } from "react";
import styled from "styled-components/native";
import ChippitLogo from "../../assets/svg/chippit_logo.svg";
import { Container } from "../../components/shared";
import { colors } from "../../components/colors";

const SplashContainer = styled(Container)`
  background-color: ${colors.primary100};
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const SplashScreen: FunctionComponent = () => {
  return (
    <SplashContainer>
      <ChippitLogo />
    </SplashContainer>
  );
};
export default SplashScreen;
