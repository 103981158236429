import React, { FunctionComponent } from "react";
import styled from "styled-components/native";
import { MaterialIcons } from "@expo/vector-icons";
import { colors } from "../colors";
import { GestureResponderEvent, StyleProp, ViewStyle } from "react-native";
import { ScreenWidth } from "../shared";
import { planCardItemHeight, planCardItemWidth } from "../../utils/constants";

const componentPadding = 16 + 59; //Distance from the right side = 59

/* 
  Formula for card height to keep the aspect ratio depending on screen width:
  (original height / original width) x new width = new height*/

const ButtonView = styled.TouchableOpacity`
  background-color: ${colors.neutral0};
  width: 35px;
  height: ${Math.round(
    (planCardItemHeight / planCardItemWidth) * (ScreenWidth - componentPadding)
  )}px;
  border: dashed 2px ${colors.primary100};
  gap: 3px;
  justify-content: center;
  align-items: center;
  margin-top: 18px;
  margin-bottom: 32px;
  border-radius: 52px;
  max-height: 400px;
`;

// types
interface ButtonProps {
  btnStyles?: StyleProp<ViewStyle>;
  onPress: ((event: GestureResponderEvent) => void) | undefined;
  iconStyles?: StyleProp<ViewStyle>;
  name: string;
  size?: number;
}

const DashButton: FunctionComponent<ButtonProps> = (props) => {
  return (
    <ButtonView onPress={props.onPress} style={props.btnStyles}>
      <MaterialIcons name={props.name} size={16} color={colors.primary100} />
    </ButtonView>
  );
};

export default DashButton;
