import React, { FunctionComponent } from "react";
import styled from "styled-components/native";
import { BottomSheet } from "react-native-btr";
import { format } from "date-fns";
import { Col, Row, ScreenWidth } from "../shared";
import { colors } from "../colors";
import { BodyLargeBold, BodySmall, TitleBody } from "../Texts/Typography";
import StatusBadge from "../Badges/StatusBadge";
import ProfileImage from "../Images/ProfileImage";
import { UserDetailsProps } from "../../interfaces/plans";
import {
  userProfileModalHeight,
  userProfileModalWidth,
} from "../../utils/constants";

const BottomSheetBodySection = styled.View`
  background-color: ${colors.neutral0};
  width: 100%;
  height: ${Math.round(
    (userProfileModalHeight / userProfileModalWidth) * ScreenWidth
  )}px;
  align-items: center;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 0px 16px;
`;

const LineBar = styled.View`
  margin-top: 10px;
  margin-bottom: 16px;
  background-color: ${colors.primary100};
  width: 32px;
  height: 3px;
  border-radius: 16px;
`;

const BottomSheetContentSection = styled.View`
  align-self: flex-start;
  margin-bottom: 30px;
  width: 100%;
`;

const RowText = styled(Row)`
  width: 100%;
  margin-top: 8px;
  margin-bottom: 16px;
`;

const ColText = styled(Col)`
  width: 100%;
  align-items: center;
  justify-content: flex-start;
`;

const BodySmallText = styled(BodySmall)`
  color: ${colors.neutral90};
  text-align: justify;
`;

// types
interface SheetProps {
  isVisible: boolean;
  cancelButton: () => void;
  details: UserDetailsProps;
  payoutDate: string;
  profileImage: unknown;
}

const UserProfileModal: FunctionComponent<SheetProps> = (props) => {
  return (
    <BottomSheet visible={props.isVisible} onBackdropPress={props.cancelButton}>
      <BottomSheetBodySection>
        <ColText>
          <LineBar />

          <BottomSheetContentSection>
            <ProfileImage
              firstName={props?.details?.firstName}
              lastName={props?.details?.lastName}
              imageSrc={props.profileImage}
              notWhiteBg={true}
              size={70}
            />
            <RowText>
              <Col>
                <TitleBody>
                  {props?.details?.firstName} {props?.details?.lastName}
                </TitleBody>
                <BodySmall>
                  Payday: {format(new Date(props?.payoutDate), "dd MMMM yyyy")}
                </BodySmall>
              </Col>
              <Col style={{ justifyContent: "center" }}>
                <StatusBadge status={props?.details?.status}>
                  {props?.details?.status === "ACTIVE"
                    ? "VERIFIED"
                    : "NOT VERIFIED"}
                </StatusBadge>
              </Col>
            </RowText>
            <BodyLargeBold>Bio</BodyLargeBold>
            <BodySmallText>
              {props?.details?.bio ? props?.details?.bio : "---"}
            </BodySmallText>
          </BottomSheetContentSection>
        </ColText>
      </BottomSheetBodySection>
    </BottomSheet>
  );
};

export default UserProfileModal;
