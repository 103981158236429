import React, { useState, useRef, useEffect, Fragment } from "react";
import {
  NativeSyntheticEvent,
  TextInput,
  TextInputKeyPressEventData,
  TouchableOpacity,
  PixelRatio,
  Platform,
} from "react-native";
import styled from "styled-components/native";
import { useAppDispatch } from "../../../app/hooks";
import { RootStackScreenProps } from "../../../navigation/types";
import AuthTemplateScreen from "../../../components/Templates/AuthTemplate";
import {
  resendSignUp,
  verifySignUpUser,
  signInUser,
  confirmForgotPassword,
  forgotPassword,
  verifyEmail,
  verifyEmailSubmit,
  getCurrentUserCognitoDetails,
  setFromSignupScreen,
} from "../../../feature/auth/authSlice";
import { colors } from "../../../components/colors";
import WaitingModal from "../../../components/Modals/WaitingModal";
import { Container } from "../../../components/shared";
import { randomChar } from "../../../utils/generateRandomChar";
import { BodyDefault } from "../../../components/Texts/Typography";
import { setToastContent } from "../../../feature/notification/notificationSlice";
import ToastMessage from "../../../components/ToastMessages/Toast";
import EmailIcon from "../../../assets/icons/email_read_white.svg";
import AsyncStorage from "@react-native-async-storage/async-storage";

const fontScale = PixelRatio.getFontScale();

const FieldSection = styled.View`
  margin-top: 24px;
  padding-left: 18px;
  padding-right: 18px;
  width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  gap: 6px;
`;

const CodeTextInputContainer = styled.View`
  height: 48px;
  width: 48px;
  border-color: ${(props) =>
    props?.isActive ? colors.neutral90 : colors.neutral40};
  border-width: ${(props) => (props?.isActive ? "2px" : "1px")};
  border-radius: 12px;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const CodeTextInput = styled.TextInput`
  font-family: "DMSans";
  font-size: ${12 / fontScale}px;
  width: 100%;
  text-align: center;
  padding-top: 0px;
  padding-bottom: 0px;
`;

const BodyDefaultText = styled(BodyDefault)`
  margin-top: 32px;
  color: ${(props) => (props.link ? colors.primary100 : colors.neutral90)};
  font-family: ${(props) => (props.link ? "DMSans Bold" : "DMSans")};
`;

const verificationExpiryTime = 60;
const codeLength = 6;

const VerifyAccountScreen = ({
  route,
  navigation,
}: RootStackScreenProps<"VerifyAccount">) => {
  const type = route?.params?.type || "";
  const email = route?.params?.email || "";
  const mobileNumber = route?.params?.phone_number || "";
  const password = route?.params?.password || "";

  const verificationCodeInputRefs = useRef<TextInput[]>([]);
  const dispatch = useAppDispatch();

  const initVerificationCodeArray = Array(codeLength).fill("");
  const [verificationCodeArray, setVerificationCodeArray] = useState(
    initVerificationCodeArray
  );
  const countdownTimer = useRef<ReturnType<typeof setInterval> | null>(null);

  const [loading, setLoading] = useState(false);
  const [toastVisible, setToastVisible] = useState(false);
  const [activeFieldIndex, setActiveFieldIndex] = useState<number | null>(null);
  const [countdown, setCountDown] = useState<string | number>(
    verificationExpiryTime
  );
  const [error, setError] = useState<string | null>(null);

  const handleRef = (ref: TextInput, index: number) => {
    verificationCodeInputRefs.current[index] = ref;
  };

  useEffect(() => {
    timer(verificationExpiryTime);
    setTimeout(() => {
      if (verificationCodeInputRefs.current[0]) {
        verificationCodeInputRefs.current[0].focus();
      }
    }, 500);

    return () => {
      if (countdownTimer.current) clearTimeout(countdownTimer.current);
    };
  }, []);

  useEffect(() => {
    if (verificationCodeArray.filter((d) => d.trim() === "").length == 0) {
      triggerSubmit();
      verificationCodeInputRefs.current[0].focus();
    }
  }, [verificationCodeArray]);

  const timer = (initialTime: number) => {
    if (!countdownTimer.current) {
      let duration = initialTime;
      const countdown = () => {
        if (duration == -1) {
          clearTimeout(countdownTimer.current);
        } else {
          if (duration <= 9) {
            setCountDown("0" + duration);
          } else {
            setCountDown(duration);
          }
          duration--;
        }
      };
      countdownTimer.current = setInterval(countdown, 1000);
    }
  };

  const resendCode = async () => {
    try {
      setLoading(true);
      let resendResult;
      if (type === "VerifyEmail") {
        resendResult = await dispatch(verifyEmail());
      } else if (type === "ForgotPassword") {
        resendResult = await dispatch(
          forgotPassword({ phone_number: mobileNumber })
        );
      } else {
        resendResult = await dispatch(resendSignUp(mobileNumber));
      }

      const resetTimer = resendResult.meta.requestStatus == "fulfilled";
      if (resetTimer) {
        timer(verificationExpiryTime);
      } else {
        setToastVisible(true);
        setError(
          "There was an error in resending the verification code.\n Please try again later."
        );
      }
    } catch (error) {
      setError("System not available. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const triggerSubmit = async () => {
    try {
      setLoading(true);
      setError(null);

      if (type === "VerifyEmail") {
        const result = await dispatch(
          verifyEmailSubmit({ code: verificationCodeArray.join("") })
        );

        if (result?.meta?.requestStatus === "fulfilled") {
          dispatch(
            setToastContent({
              icon: EmailIcon,
              errorType: "success",
              content: "Email address verified",
            })
          );
          await dispatch(getCurrentUserCognitoDetails({ bypassCache: true }));
          navigation.navigate("ProfileTab", {
            screen: "Security",
          });
        } else {
          setToastVisible(true);
          setError(
            result.error.message ||
              "Error verifying your email. Please verify again."
          );
        }
      } else if (type === "ForgotPassword") {
        const tempPassword = randomChar(16);
        const result = await dispatch(
          confirmForgotPassword({
            username: mobileNumber,
            code: verificationCodeArray.join(""),
            password: tempPassword,
          })
        );

        if (result?.meta?.requestStatus === "fulfilled") {
          navigation.navigate("ResetPassword", {
            username: mobileNumber,
            tempPassword,
          });
        } else if (
          result?.error?.message ===
          "Password does not conform to policy: Password must have numeric characters"
        ) {
          setToastVisible(true);
          setError("Error verifying your number. Please verify again.");
        } else {
          setToastVisible(true);
          setError(result.error.message);
        }
      } else {
        const result = await dispatch(
          verifySignUpUser({
            phone_number: mobileNumber,
            code: verificationCodeArray.join(""),
          })
        );
        if (result?.meta?.requestStatus === "fulfilled") {
          await dispatch(setFromSignupScreen(true));
          await AsyncStorage.setItem("isLoggedIn", "true");
          await dispatch(
            signInUser({
              username: mobileNumber,
              password,
            })
          );
        } else {
          setToastVisible(true);
          setError(
            result?.error?.message ||
              "System not available. Please try again later."
          );
        }
      }
    } catch (error) {
      setError("System not available. Please try again later.");
    } finally {
      setVerificationCodeArray(initVerificationCodeArray);
      setActiveFieldIndex(null);
      setLoading(false);
    }
  };

  const handleChange = (index: number) => (text: string) => {
    if (text !== "") {
      if (text.length === 6 && /^\d+$/.test(text)) {
        let newArr = [...verificationCodeArray];
        newArr = newArr.map((val, index) => text[index]);
        setVerificationCodeArray(newArr);
      } else {
        let nextIndex = index + 1;
        const newArr = [...verificationCodeArray];
        if (text.length === codeLength) {
          [...text].forEach((t, index) => (newArr[index] = t));
          setActiveFieldIndex(text.length);
          nextIndex = text.length;
        } else {
          newArr[index] = text.charAt(text.length - 1);
          setActiveFieldIndex(index + 1);
        }
        setVerificationCodeArray(newArr);
        if (verificationCodeInputRefs.current[nextIndex]) {
          verificationCodeInputRefs.current[nextIndex].focus();
        }
      }
    }
  };

  const handleBackspace =
    (index: number) =>
    (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
      if (e.nativeEvent.key === "Backspace") {
        const newArr = [...verificationCodeArray];
        newArr[index] = "";
        setActiveFieldIndex(index - 1);
        setVerificationCodeArray(newArr);
        if (verificationCodeInputRefs.current[index - 1]) {
          verificationCodeInputRefs.current[index - 1].focus();
        }
      }
    };

  return (
    <Fragment>
      <AuthTemplateScreen
        header={type === "VerifyEmail" ? "Verify Email" : "Verify number"}
        subHeader={
          type === "VerifyEmail"
            ? `Enter the 6 digit code we sent to your email address ${email}`
            : `Enter the 6 digit code we sent to your mobile number ending in ${mobileNumber.slice(
                -4
              )}.`
        }
        submitTitle="Verify"
        navigation={navigation}
        submitButtonEditMode={activeFieldIndex !== null}
        submitDisabled={loading}
        onSubmit={() => triggerSubmit()}
        backButtonIcon="arrow-back"
        hideSubmitButton={true}
      >
        <Container>
          <FieldSection>
            {verificationCodeArray.map((value, index) => (
              <CodeTextInputContainer
                key={`VerificationCodeInput-${index}`}
                isActive={index === activeFieldIndex}
              >
                <CodeTextInput
                  key={`VerificationCodeInput-${index}`}
                  onKeyPress={handleBackspace(index)}
                  ref={(ref: TextInput) => handleRef(ref, index)}
                  value={value}
                  onChangeText={handleChange(index)}
                  onFocus={() => setActiveFieldIndex(index)}
                  onBlur={() =>
                    index === activeFieldIndex
                      ? setActiveFieldIndex(null)
                      : null
                  }
                  placeholder="-"
                  keyboardType="number-pad"
                  multiline={false}
                  autoComplete={
                    type !== "VerifyEmail" && Platform.OS === "android"
                      ? "sms-otp"
                      : undefined
                  }
                  textContentType={
                    type !== "VerifyEmail" && Platform.OS === "ios"
                      ? "oneTimeCode"
                      : undefined
                  }
                />
              </CodeTextInputContainer>
            ))}
          </FieldSection>
          {countdown === "00" ? (
            <TouchableOpacity onPress={resendCode}>
              <BodyDefaultText link={true}>Resend Code</BodyDefaultText>
            </TouchableOpacity>
          ) : (
            <BodyDefaultText>
              Resend code in 0{countdown == 60 ? "1" : "0"}:
              {countdown == 60 ? "00" : countdown}
            </BodyDefaultText>
          )}
          {type === "VerifyEmail" ? null : (
            <TouchableOpacity onPress={() => navigation.goBack()}>
              <BodyDefaultText link={true}>Try another number</BodyDefaultText>
            </TouchableOpacity>
          )}
        </Container>
      </AuthTemplateScreen>
      <WaitingModal visible={loading} />
      {toastVisible ? (
        <ToastMessage type="error" onClose={() => setToastVisible(false)}>
          {error}
        </ToastMessage>
      ) : null}
    </Fragment>
  );
};

export default VerifyAccountScreen;
