import React from "react";
import { useFocusEffect } from "@react-navigation/native";
import styled from "styled-components/native";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { colors } from "../../components/colors";
import { Container } from "../../components/shared";
import PageTemplateScreen from "../../components/Templates/PageTemplate";
import PlansList from "../../components/Lists/PlansList";
import { getRecommendedPlanList } from "../../feature/plan/planSlice";
import { setToastContent } from "../../feature/notification/notificationSlice";
import ToastMessage from "../../components/ToastMessages/Toast";

const PlansContainer = styled(Container)`
  background-color: ${colors.neutral0};
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

const PlansScreen = () => {
  const dispatch = useAppDispatch();
  const plans = useAppSelector((state) => state.plan);
  const { toastContent } = useAppSelector((state) => state.notification);

  const init = async () => {
    await dispatch(getRecommendedPlanList());
  };

  useFocusEffect(
    React.useCallback(() => {
      init();
    }, [])
  );

  const closeToast = async () => {
    const params = {
      errorType: "",
      icon: undefined,
      content: "",
    };
    await dispatch(setToastContent(params));
  };

  return (
    <PlansContainer>
      {/* <ScrollContainer showsVerticalScrollIndicator={false}> */}
      <PageTemplateScreen
        header="Select a plan"
        onSubmit={() => {}}
        hideSubmitButton={true}
      >
        <PlansList
          data={plans?.recommendedPlanList || []}
          activePlans={plans?.myPlanList.length}
        />
      </PageTemplateScreen>
      {/* </ScrollContainer> */}
      {toastContent.content !== "" ? (
        <ToastMessage
          type={toastContent?.errorType}
          onClose={closeToast}
          icon={toastContent?.icon}
        >
          {toastContent?.content}
        </ToastMessage>
      ) : null}
    </PlansContainer>
  );
};

export default PlansScreen;
