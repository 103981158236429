import { Auth, Storage } from "aws-amplify";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  signInParam,
  signUpParam,
  verifySignUpParam,
  updateAccountDetailsParam,
  getCurrentUserCognitoDetailsParam,
  forgotPasswordParam,
  confirmForgotPasswordParam,
  changePasswordAPIParam,
  setUserPinParam,
  verifyEmailParam,
  uploadProfileImageParam,
  verifyUserPinParam,
  resetSignUpParam,
  userPreferenceParam,
  reactivateAccountDetailsParam,
} from "../../interfaces/auth";
import backendAPI from "../../api/client";
import uninterceptedBackendAPI from "../../api/uninterceptedClient";
import endpoints from "../../api/endpoints";

const signIn = async (signInDetails: signInParam) => {
  const result = await Auth.signIn(signInDetails);
  return result;
};

const checkCurrentUserAuthenticated = async (
  getCurrentUserCognitoDetails: getCurrentUserCognitoDetailsParam
) => {
  const result = await Auth.currentAuthenticatedUser({
    bypassCache: getCurrentUserCognitoDetails.bypassCache,
  });
  return result;
};

const refreshCurrentUserSession = async () => {
  const result = await Auth.currentSession();
  return result;
};

const getCurrentUserDetails = async () => {
  const user = await Auth.currentAuthenticatedUser();
  if (!user) {
    throw new Error("User not logged in");
  }
  const result = await backendAPI.get(endpoints.getCurrentUserDetails);
  return result;
};

const signUp = async (signUpDetails: signUpParam) => {
  const result = await Auth.signUp({
    username: signUpDetails.phone_number,
    password: signUpDetails.password,
    attributes: {
      email: signUpDetails.email,
      phone_number: signUpDetails.phone_number,
    },
  });
  return result;
};

const resetSignUp = async (params: resetSignUpParam) => {
  const result = await backendAPI.post(endpoints.resetSignUp, params);
  return result;
};

const resendSignUp = async (phone_number: string) => {
  const result = await Auth.resendSignUp(phone_number);
  return result;
};

const verifySignUp = async (verifySignUpDetails: verifySignUpParam) => {
  const result = await Auth.confirmSignUp(
    verifySignUpDetails.phone_number,
    verifySignUpDetails.code
  );
  return result;
};

const updateAccountDetails = async (
  updateAccountDetails: updateAccountDetailsParam
) => {
  const user = await Auth.currentAuthenticatedUser();
  if (!user) {
    throw new Error("User not logged in");
  }
  const result = await backendAPI.post(
    endpoints.updateUser.replace(":userId", user?.username),
    updateAccountDetails
  );
  return result;
};

const verifyEmail = async () => {
  const result = await Auth.verifyCurrentUserAttribute("email");
  return result;
};

const verifyEmailSubmit = async (verifyEmailDetails: verifyEmailParam) => {
  const result = await Auth.verifyCurrentUserAttributeSubmit(
    "email",
    verifyEmailDetails.code
  );
  return result;
};

const forgotPassword = async (forgotPasswordDetails: forgotPasswordParam) => {
  const result = await Auth.forgotPassword(forgotPasswordDetails.phone_number);
  return result;
};

const confirmForgotPassword = async (
  confirmForgotPasswordDetails: confirmForgotPasswordParam
) => {
  const result = await Auth.forgotPasswordSubmit(
    confirmForgotPasswordDetails.username,
    confirmForgotPasswordDetails.code,
    confirmForgotPasswordDetails.password
  );

  return result;
};

const changePassword = async (
  changePasswordDetails: changePasswordAPIParam
) => {
  const bypassCache = false;
  const user = await checkCurrentUserAuthenticated({ bypassCache });
  const result = await Auth.changePassword(
    user,
    changePasswordDetails.oldPassword,
    changePasswordDetails.password
  );
  return result;
};

const setUserPin = async (userPin: setUserPinParam) => {
  const result = await backendAPI.post(endpoints.setUserPin, userPin);
  return result;
};

const verifyUserPin = async (userPin: verifyUserPinParam) => {
  const result = await uninterceptedBackendAPI.post(
    endpoints.verifyUserPin,
    userPin
  );
  return result;
};

const signOut = async () => {
  await AsyncStorage.clear();
  const result = await Auth.signOut();
  return result;
};

const uploadProfileImage = async ({ key, file }: uploadProfileImageParam) => {
  const result = await Storage.put(key, file, {
    level: "public",
    contentType: "image/jpeg",
  });
  return result;
};

const checkEmail = async (email: string) => {
  const result = await backendAPI.post(endpoints.checkEmail, { email });
  return result;
};

const setUserPreferences = async (userPreference: userPreferenceParam) => {
  const user = await Auth.currentAuthenticatedUser();
  if (!user) {
    throw new Error("User not logged in");
  }
  const result = await backendAPI.post(
    endpoints.setUserPreferences,
    userPreference
  );
  return result;
};

const reactivateAccount = async (
  reactivateAccountDetails: reactivateAccountDetailsParam
) => {
  const user = await Auth.currentAuthenticatedUser();
  if (!user) {
    throw new Error("User not logged in");
  }
  const result = await backendAPI.post(
    endpoints.updateUser.replace(":userId", user?.username),
    reactivateAccountDetails
  );
  return result;
};

const api = {
  signIn,
  checkCurrentUserAuthenticated,
  getCurrentUserDetails,
  signUp,
  verifySignUp,
  resetSignUp,
  resendSignUp,
  updateAccountDetails,
  verifyEmail,
  verifyEmailSubmit,
  forgotPassword,
  confirmForgotPassword,
  changePassword,
  setUserPin,
  verifyUserPin,
  signOut,
  uploadProfileImage,
  refreshCurrentUserSession,
  checkEmail,
  setUserPreferences,
  reactivateAccount,
};

export default api;
