import { configureStore, combineReducers } from "@reduxjs/toolkit";

import authReducer from "../feature/auth/authSlice";
import planReducer from "../feature/plan/planSlice";
import notificationReducer from "../feature/notification/notificationSlice";
import profileSlice from "../feature/profile/profileSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  plan: planReducer,
  notification: notificationReducer,
  profile: profileSlice,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
