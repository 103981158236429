import React, { Fragment, useEffect, useState } from "react";
import { Linking, View } from "react-native";
import styled from "styled-components/native";
import { useFocusEffect } from "@react-navigation/native";
import { MaterialIcons } from "@expo/vector-icons";
import * as LocalAuthentication from "expo-local-authentication";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { colors } from "../../../components/colors";
import {
  Row,
  Col,
  Container,
  ScrollContainer,
} from "../../../components/shared";
import PageTemplateScreen from "../../../components/Templates/PageTemplate";
import { BodyDefaultBold } from "../../../components/Texts/Typography";
import ProfileMenuIcon from "../../../components/Icons/ProfileMenuIcon";
import ShieldPersonIcon from "../../../assets/icons/shield-person-icon.svg";
import PasswordIcon from "../../../assets/icons/password-icon.svg";
import PinIcon from "../../../assets/icons/pin-icon.svg";
import DeleteIcon from "../../../assets/icons/delete-icon.svg";
import StatusBadge from "../../../components/Badges/StatusBadge";
import ToggleButton from "../../../components/Buttons/ToggleButton";
import { RootStackScreenProps } from "../../../navigation/types";
import ToastMessage from "../../../components/ToastMessages/Toast";
import { setToastContent } from "../../../feature/notification/notificationSlice";
import { getDevicePreferenceByKey } from "../../../utils/getDevicePreferences";
import { UserPreferenceKeys } from "../../../utils/constants";
import {
  getCurrentUserDetails,
  setUserBiometricsPreference,
} from "../../../feature/auth/authSlice";
import WaitingModal from "../../../components/Modals/WaitingModal";
import { getAccountClosureFormLink } from "../../../constants/EnvVariables";

const SecurityContainer = styled(Container)`
  background-color: ${colors.neutral0};
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

const ContentSection = styled.View`
  width: 100%;
  height: 100%;
  gap: 24px;
  padding: 0px 16px;
`;

const MenuItemButton = styled.TouchableOpacity`
  flex-direction: row;
  justify-content: space-between;
`;

const MenuItemRow = styled(Row)`
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const MenuItemArrow = styled(Col)`
  justify-content: center;
`;

const SecurityScreen = ({ navigation }: RootStackScreenProps<"Security">) => {
  const user = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { toastContent } = useAppSelector((state) => state.notification);
  const [isLoading, setIsLoading] = useState(false);
  const [biometricsSupported, setBiometricsSupported] = useState(false);
  const [isBiometrics, setBiometrics] = useState(false);
  const [isVerified, setVerified] = useState(false);

  const closeToast = async () => {
    const params = {
      errorType: "",
      icon: undefined,
      content: "",
    };
    await dispatch(setToastContent(params));
  };

  const init = async () => {
    if (user?.currentUserCognitoDetails?.attributes?.email_verified === false) {
      setVerified(false);
    } else {
      setVerified(true);
    }
    if (
      user?.currentUserDetails?.preferences &&
      user?.currentUserDetails?.preferences?.length > 0
    ) {
      const isBiometricsEnabled = await getDevicePreferenceByKey(
        user.currentUserDetails.preferences,
        UserPreferenceKeys.isBiometricsEnabled
      );
      if (isBiometricsEnabled) {
        setBiometrics(true);
      } else {
        setBiometrics(false);
      }
    }
  };

  const enableBiometrics = async (param: boolean) => {
    try {
      setIsLoading(true);
      await dispatch(setUserBiometricsPreference(param));
      await dispatch(getCurrentUserDetails());
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      init();
    }, [user])
  );

  useEffect(() => {
    const checkIfBiometricsSupported = async () => {
      const isSupported = await LocalAuthentication.hasHardwareAsync();
      if (isSupported) {
        setBiometricsSupported(true);
      }
    };
    checkIfBiometricsSupported();
  }, []);

  const requestDeactivateAccount = () => {
    const accountClosureFormLink = getAccountClosureFormLink();
    Linking.openURL(accountClosureFormLink);
  };

  return (
    <Fragment>
      <SecurityContainer>
        <PageTemplateScreen
          header="Security"
          submitTitle="Raise a support ticket"
          submitButtonEditMode={false}
          hideSubmitButton={true}
        >
          <ScrollContainer
            automaticallyAdjustKeyboardInsets={true}
            showsVerticalScrollIndicator={false}
          >
            <View onStartShouldSetResponder={() => true}>
              <ContentSection>
                {biometricsSupported ? (
                  <Row>
                    <Col>
                      <MenuItemRow>
                        <ProfileMenuIcon isSvg={true}>
                          <ShieldPersonIcon />
                        </ProfileMenuIcon>
                        <BodyDefaultBold>
                          Use biometrics to log in
                        </BodyDefaultBold>
                      </MenuItemRow>
                    </Col>
                    <MenuItemArrow>
                      <ToggleButton
                        active={isBiometrics}
                        onPress={() => {
                          enableBiometrics(!isBiometrics);
                        }}
                      />
                    </MenuItemArrow>
                  </Row>
                ) : null}
                <MenuItemButton
                  onPress={() => {
                    navigation.navigate("ConfirmEmail", {
                      email: user?.currentUserCognitoDetails?.attributes?.email,
                    });
                  }}
                  disabled={isVerified}
                >
                  <Col>
                    <MenuItemRow>
                      <ProfileMenuIcon name="mail-outline" />
                      <BodyDefaultBold>
                        Verify your email
                        {isVerified ? "" : "\n"} address
                      </BodyDefaultBold>
                    </MenuItemRow>
                  </Col>
                  <MenuItemArrow>
                    <MenuItemRow>
                      <StatusBadge
                        status={isVerified ? "VERIFIED" : "NOT VERIFIED"}
                        badgeStyle={{ marginRight: 8 }}
                      >
                        {isVerified ? "VERIFIED" : "NOT VERIFIED"}
                      </StatusBadge>
                      {!isVerified && (
                        <MaterialIcons name="arrow-forward-ios" size={16} />
                      )}
                    </MenuItemRow>
                  </MenuItemArrow>
                </MenuItemButton>
                <MenuItemButton
                  onPress={() => {
                    navigation.navigate("ChangePassword");
                  }}
                >
                  <Col>
                    <MenuItemRow>
                      <ProfileMenuIcon isSvg={true}>
                        <PasswordIcon />
                      </ProfileMenuIcon>
                      <BodyDefaultBold>Change password</BodyDefaultBold>
                    </MenuItemRow>
                  </Col>
                  <MenuItemArrow>
                    <MaterialIcons name="arrow-forward-ios" size={16} />
                  </MenuItemArrow>
                </MenuItemButton>
                <MenuItemButton
                  onPress={() => {
                    navigation.navigate("ConfirmPin");
                  }}
                >
                  <Col>
                    <MenuItemRow>
                      <ProfileMenuIcon isSvg={true}>
                        <PinIcon />
                      </ProfileMenuIcon>
                      <BodyDefaultBold>Change pin</BodyDefaultBold>
                    </MenuItemRow>
                  </Col>
                  <MenuItemArrow>
                    <MaterialIcons name="arrow-forward-ios" size={16} />
                  </MenuItemArrow>
                </MenuItemButton>
                <MenuItemButton
                  onPress={() => {
                    requestDeactivateAccount();
                  }}
                >
                  <Col>
                    <MenuItemRow>
                      <ProfileMenuIcon isSvg={true}>
                        <DeleteIcon />
                      </ProfileMenuIcon>
                      <BodyDefaultBold>
                        Request to Deactivate Account
                      </BodyDefaultBold>
                    </MenuItemRow>
                  </Col>
                </MenuItemButton>
              </ContentSection>
            </View>
          </ScrollContainer>
        </PageTemplateScreen>
      </SecurityContainer>
      <WaitingModal visible={isLoading} />
      {toastContent.content !== "" ? (
        <ToastMessage
          type={toastContent?.errorType}
          onClose={closeToast}
          icon={toastContent?.icon}
        >
          {toastContent?.content}
        </ToastMessage>
      ) : null}
    </Fragment>
  );
};

export default SecurityScreen;
