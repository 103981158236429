import React, { useRef, useState } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { Formik } from "formik";
import { MaterialIcons } from "@expo/vector-icons";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getUserAccounts } from "../../../feature/plan/planSlice";
import { additionalInformationSchema } from "../../../utils/schema";
import { RootStackScreenProps } from "../../../navigation/types";

import { colors } from "../../../components/colors";
import {
  Col,
  Container,
  Row,
  // ScrollContainer,
} from "../../../components/shared";
import PageTemplateScreen from "../../../components/Templates/PageTemplate";
import FieldDropdown from "../../../components/FormFields/FieldDropdown";
import FieldText from "../../../components/FormFields/FieldText";
import RecommendedPlanCard from "../../../components/Card/RecommendedPlanCard";
import { additionalInformationParams } from "../../../interfaces/plans";
import WaitingModal from "../../../components/Modals/WaitingModal";
import ToastMessage from "../../../components/ToastMessages/Toast";
import { Nomination, planReasonsList } from "../../../utils/constants";
import { setToastContent } from "../../../feature/notification/notificationSlice";
import { BodySmall } from "../../../components/Texts/Typography";

const AdditionalInformationContainer = styled(Container)`
  background-color: ${colors.neutral0};
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  flex: 1;
`;

const FieldSection = styled.View`
  padding: 0px 16px 20px 16px;
  width: 100%;
  align-items: center;
  flex-direction: column;
`;

const FootNote = styled(Row)`
  padding: 0px 16px;
`;

const BodySmallBold = styled(BodySmall)`
  font-family: "DMSans Bold";
`;

const AdditionalInformationScreen = ({
  route,
  navigation,
}: RootStackScreenProps<"AdditionalInformation">) => {
  const selectedPlan = route?.params?.data;

  const dispatch = useAppDispatch();
  const { toastContent } = useAppSelector((state) => state.notification);
  const [activeField, setActiveField] = useState(false);
  const [toastVisible, setToastVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isPlanCardExpanded, setIsPlanCardExpanded] = useState(false);
  const additionalInformationFormRef = useRef(null);

  const triggerSubmit = () => {
    if (additionalInformationFormRef.current) {
      additionalInformationFormRef.current.handleSubmit();
    }
  };

  const handleSubmit = async (values: additionalInformationParams) => {
    try {
      setLoading(true);
      setError(null);
      const result = await dispatch(getUserAccounts());
      if (result?.payload && result?.payload.length > 0) {
        let creditAccount: unknown = null;
        let debitAccount: unknown = null;
        result?.payload.some((account) => {
          if (account?.nomination === Nomination.ALL) {
            creditAccount = account;
            debitAccount = account;
            return true;
          } else if (account?.nomination === Nomination.DEBIT) {
            debitAccount = account;
            if (creditAccount) return true;
          } else if (account?.nomination === Nomination.CREDIT) {
            creditAccount = account;
            if (debitAccount) return true;
          }
        });
        if (creditAccount && debitAccount) {
          navigation.navigate("AccountSummary", {
            selectedPlan,
            reasonToSave: values.reasonToSave,
            customPlanName: values.customPlanName.trim(),
            creditAccount,
            debitAccount,
          });
        } else {
          navigation.navigate("SelectAccount", {
            selectedPlan,
            reasonToSave: values.reasonToSave,
            customPlanName: values.customPlanName.trim(),
          });
        }
      } else {
        const plansTabProps = {
          screen: "ConnectBank",
          params: {
            selectedPlan,
            reasonToSave: values.reasonToSave,
            customPlanName: values.customPlanName.trim(),
          },
        };
        navigation.navigate("PlansTab", plansTabProps);
      }
    } catch (error) {
      setToastVisible(true);
      setError("System not available. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const closeToast = async () => {
    const params = {
      errorType: "",
      icon: undefined,
      content: "",
    };
    await dispatch(setToastContent(params));
  };

  return (
    <AdditionalInformationContainer>
      <PageTemplateScreen
        header="Additional Information"
        subHeader="We love helping you hit your money goals faster.  Please let us know your reason for saving."
        onSubmit={() => triggerSubmit()}
        submitButtonEditMode={activeField}
        submitTitle="Continue"
      >
        {/* <ScrollContainer
          automaticallyAdjustKeyboardInsets={true
          showsVerticalScrollIndicator={false}
        > */}
        <View
          style={{ flex: 1, width: "100%" }}
          onStartShouldSetResponder={() => true}
        >
          <Formik
            initialValues={{
              reasonToSave: "",
              customPlanName: "",
            }}
            validationSchema={additionalInformationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={handleSubmit}
            innerRef={additionalInformationFormRef}
          >
            {({ values, errors }) => (
              <FieldSection>
                <FieldDropdown
                  label="Reason to save"
                  fieldName="reasonToSave"
                  options={planReasonsList}
                  value={values.reasonToSave}
                  error={errors.reasonToSave}
                />
                <FieldText
                  key={"customPlanName"}
                  label="Customise plan’s name (Optional)"
                  fieldName="customPlanName"
                  onShow={() => {
                    setActiveField(true);
                  }}
                  onHide={() => {
                    setActiveField(false);
                  }}
                  value={values.customPlanName}
                  error={errors.customPlanName}
                />
                <View style={{ width: "100%" }}>
                  <RecommendedPlanCard
                    key={`PlanCard`}
                    index={0}
                    fixedBorders={true}
                    data={selectedPlan}
                    expandCard={() =>
                      setIsPlanCardExpanded(!isPlanCardExpanded)
                    }
                    isExpanded={isPlanCardExpanded}
                    hideButtons={true}
                  />
                </View>
              </FieldSection>
            )}
          </Formik>
        </View>
        {/* </ScrollContainer> */}
        <FootNote>
          <Col style={{ marginTop: 5, marginRight: 4 }}>
            <MaterialIcons name="info-outline" size={12} />
          </Col>
          <Col>
            <BodySmall>
              Please ensure you have understood the risks. Read our{" "}
              <BodySmallBold
                onPress={() => {
                  navigation.navigate("TermsOfUse");
                }}
              >
                Terms of use
              </BodySmallBold>{" "}
              and{" "}
              <BodySmallBold
                onPress={() => {
                  navigation.navigate("HelpAndSupport");
                }}
              >
                FAQs
              </BodySmallBold>{" "}
              for more information on this.
            </BodySmall>
          </Col>
        </FootNote>
      </PageTemplateScreen>
      <WaitingModal visible={loading} />
      {toastVisible ? (
        <ToastMessage type="error" onClose={() => setToastVisible(false)}>
          {error}
        </ToastMessage>
      ) : null}
      {toastContent.content !== "" ? (
        <ToastMessage
          type={toastContent?.errorType}
          onClose={closeToast}
          icon={toastContent?.icon}
        >
          {toastContent?.content}
        </ToastMessage>
      ) : null}
    </AdditionalInformationContainer>
  );
};

export default AdditionalInformationScreen;
