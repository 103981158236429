import React, { FunctionComponent, useEffect } from "react";
import {
  Keyboard,
  TouchableWithoutFeedback,
  View,
  KeyboardAvoidingView,
  Platform,
  BackHandler,
} from "react-native";
import styled from "styled-components/native";
import { PageProps } from "../../interfaces/templates";
import { Container, Row, ScrollContainer } from "../shared";
import { colors } from "../colors";
import IconButton from "../Buttons/IconButton";
import SubmitButton from "../Buttons/SubmitButton";
import { TitleSection, BodyDefault } from "../Texts/Typography";
import LinkButton from "../Buttons/LinkButton";
import { useNavigation } from "@react-navigation/native";

const AuthContainer = styled(Container)`
  background-color: ${colors.neutral0};
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  max-width: 500px;
  margin: 0 auto;
`;

const NavigationSection = styled(Row)`
  width: 100%;
  justify-content: flex-start;
  margin-top: 45px;
  margin-bottom: 24px;
  padding: 0px 16px;
`;

const HeaderSection = styled.View`
  width: 100%;
  padding: 0px 16px;
`;

const RowText = styled(Row)`
  margin-top: 8px;
  justify-content: flex-start;
`;

const ContentSection = styled.View`
  width: 100%;
  justify-content: space-between;
  flex: 1;
  align-items: center;
`;

const ButtonWrapper = styled.View`
  width: 90%;
  padding-top: 10px;
  align-items: center;
  padding: 30px 5px 30px 5px;
  margin: auto;
`;

const ButtonSelectedWrapper = styled(ButtonWrapper)`
  width: 100%;
  padding: 0px;
`;

const BodyDefaultText = styled(BodyDefault)`
  color: ${colors.neutral90};
`;

const AuthTemplateScreen: FunctionComponent<PageProps> = (props) => {
  const navigation = useNavigation();

  const handleBackButtonClick = () => {
    if (props.onCancel) {
      props.onCancel();
    } else {
      navigation.goBack();
    }
    return true;
  };

  useEffect(() => {
    if (Platform.OS === "android") {
      BackHandler.addEventListener("hardwareBackPress", handleBackButtonClick);
      return () => {
        BackHandler.removeEventListener(
          "hardwareBackPress",
          handleBackButtonClick
        );
      };
    }
  });

  return (
    <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>
      <AuthContainer>
        <NavigationSection>
          {props.hideNavigationSection ? null : (
            <IconButton
              onPress={() => {
                if (props.onCancel) {
                  props.onCancel();
                } else {
                  navigation.goBack();
                }
              }}
              name={props.backButtonIcon ? props.backButtonIcon : "close"}
              color={colors.primary100}
              size={24}
              btnStyles={{
                backgroundColor: colors.neutral20,
                borderRadius: 300,
              }}
              iconStyles={{
                padding: 10,
              }}
            />
          )}
        </NavigationSection>
        <HeaderSection>
          <TitleSection>{props.header}</TitleSection>
          <RowText>
            <BodyDefaultText>{props.subHeader} </BodyDefaultText>
            <LinkButton
              textStyles={{ color: colors.primary100 }}
              onPress={() => {
                props.onSubHeaderLink();
              }}
            >
              {props.subHeaderLink}
            </LinkButton>
          </RowText>
        </HeaderSection>
        <ScrollContainer
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          <View onStartShouldSetResponder={() => true} style={{ flex: 1 }}>
            <ContentSection>{props.children}</ContentSection>
          </View>
        </ScrollContainer>
        {!props.hideSubmitButton &&
          (props.submitButtonEditMode === false ? (
            <ButtonWrapper>
              <SubmitButton
                textStyles={{ color: colors.neutral0 }}
                onPress={() => {
                  props.onSubmit();
                }}
                disabled={props.submitDisabled}
              >
                {props.submitTitle}
              </SubmitButton>
            </ButtonWrapper>
          ) : (
            <KeyboardAvoidingView
              style={{ flex: Platform.OS === "ios" ? 1 : 0, width: "100%" }}
              behavior={Platform.OS === "ios" ? "position" : undefined}
              keyboardVerticalOffset={
                Platform.OS === "ios"
                  ? props.keyboardVerticalOffset
                    ? props.keyboardVerticalOffset
                    : -180
                  : 0
              }
              contentContainerStyle={{
                paddingVertical: Platform.OS === "ios" ? 110 : 0,
              }}
            >
              <ButtonSelectedWrapper>
                <SubmitButton
                  textStyles={{ color: colors.neutral0 }}
                  onPress={() => {
                    props.onSubmit();
                  }}
                  buttonBox={true}
                  disabled={props.submitDisabled}
                >
                  {props.submitTitle}
                </SubmitButton>
              </ButtonSelectedWrapper>
            </KeyboardAvoidingView>
          ))}
      </AuthContainer>
    </TouchableWithoutFeedback>
  );
};

export default AuthTemplateScreen;
