import React, { FunctionComponent } from "react";
import styled from "styled-components/native";
import { BottomSheet } from "react-native-btr";
import { colors } from "../colors";
import { BodyDefault, TitleSection } from "../Texts/Typography";

import EmailIcon from "../../assets/svg/mark_email_read.svg";
import SubmitButton from "../Buttons/SubmitButton";
import { useAppDispatch } from "../../app/hooks";
import { setRequestVerificationEmailTimestamp } from "../../feature/auth/authSlice";

const BottomSheetBodySection = styled.View`
  background-color: ${colors.neutral0};
  width: 100%;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 0px 15px;
`;

const LineBar = styled.View`
  margin-top: 10px;
  margin-bottom: 16px;
  background-color: ${colors.primary100};
  width: 32px;
  height: 3px;
  border-radius: 16px;
`;

const Title = styled(TitleSection)`
  margin-top: 30px;
  margin-bottom: 8px;
`;

const BottomSheetContentSection = styled.View`
  align-self: flex-start;
  margin-bottom: 30px;
`;

const BodyDefaultText = styled(BodyDefault)`
  color: ${colors.neutral90};
`;

const BottomSheetButtonSection = styled.View`
  gap: 12px;
  width: 100%;
  margin-bottom: 20px;
`;
// types
interface SheetProps {
  isVisible: boolean;
  submitButton: () => void;
  cancelButton: () => void;
}

const RequestVerifyEmailModal: FunctionComponent<SheetProps> = (props) => {
  const dispatch = useAppDispatch();
  const setRequestTimestamp = async () => {
    const currentTimestamp = new Date();
    await dispatch(
      setRequestVerificationEmailTimestamp(currentTimestamp.toString())
    );
  };
  return (
    <BottomSheet visible={props.isVisible} onBackdropPress={props.cancelButton}>
      <BottomSheetBodySection>
        <LineBar />
        <BottomSheetContentSection>
          <EmailIcon />
          <Title>Verify Email</Title>
          <BodyDefaultText>
            We haven't verified your email yet, please verify for easier access
            next time.
          </BodyDefaultText>
        </BottomSheetContentSection>
        <BottomSheetButtonSection>
          <SubmitButton
            onPress={async () => {
              await setRequestTimestamp();
              props.submitButton();
            }}
          >
            Verify email now
          </SubmitButton>
          <SubmitButton
            secondary={true}
            btnStyles={{
              borderWidth: 1,
              borderColor: colors.primary100,
            }}
            onPress={async () => {
              await setRequestTimestamp();
              props.cancelButton();
            }}
          >
            No thanks, I’ll do later
          </SubmitButton>
        </BottomSheetButtonSection>
      </BottomSheetBodySection>
    </BottomSheet>
  );
};

export default RequestVerifyEmailModal;
