export const PLAN_STATUS = {
  NO_TRANSACTION: "NO_TRANSACTION",
  SUCCESSFUL_CONTRIBUTION: "SUCCESSFUL_CONTRIBUTION",
  PAYMENT_DECLINED: "PAYMENT_DECLINED",
  NO_PAYMENT: "NO_PAYMENT",
  SUCCESSFUL_PAYOUT: "SUCCESSFUL_PAYOUT",
};

export const NOTIFICATION_STATUS = {
  READ: "READ",
  UNREAD: "UNREAD",
};

export const PAYMENT_STATUS = {
  FAIL: "FAIL",
  SUCCESS: "SUCCESS",
};

export const EVENT = {
  CONTRIBUTION: "CONTRIBUTION",
  PAYOUT: "PAYOUT",
};

export const VERIFY_PIN_SCREEN_STATUS = {
  SHOW: "SHOW",
  HIDE: "HIDE",
  SIGN_IN: "SIGN_IN",
};
