import * as Yup from "yup";

export const registerAccountSchema = Yup.object().shape({
  // username: Yup.string()
  //   .min(2, "*username must have at least 2 characters")
  //   .max(100, "*username can't be longer than 100 characters")
  //   .required("*username is required"),

  email: Yup.string()
    .email("*Must be a valid email address")
    .max(100, "*Email must be less than 100 characters")
    .required("*Email is required"),
  password: Yup.string()
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Password must contain 8 or more characters with at least one uppercase, one lowercase, one number and one special character"
    )
    .required("*Password is required"),

  confirmPassword: Yup.string()
    .required("*Confirmation password is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),

  phone_number: Yup.string()
    .min(9, "*Mobile Number can't be shorter than 9 characters")
    .max(9, "*Mobile Number can't be longer than 9 characters")
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^[0-9]+$/,
      "Invalid phone number format"
    )
    .required("*Mobile Number is required"),
});

export const registerAccountDetailsSchema1 = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "*First name must have at least 2 characters")
    .max(100, "*First name can't be longer than 100 characters")
    .required("*First name is required"),
  middleName: Yup.string()
    .min(2, "*Last name must have at least 2 characters")
    .max(100, "*Last name can't be longer than 100 characters"),
  lastName: Yup.string()
    .min(2, "*Last name must have at least 2 characters")
    .max(100, "*Last name can't be longer than 100 characters")
    .required("*Last name is required"),
  birthDate: Yup.date().required("*Date of birth is required"),
});

export const registerAccountDetailsSchema2 = Yup.object().shape({
  address: Yup.string()
    .min(2, "Address must have at least 2 characters")
    .max(255, "*Address can't be longer than 255 characters")
    .required("*Address is required"),
  suburb: Yup.string()
    .min(2, "*Suburb must have at least 2 characters")
    .max(50, "*Suburb can't be longer than 50 characters")
    .required("*Suburb is required"),
  state: Yup.string()
    .min(2, "*State must have at least 2 characters")
    .max(3, "*State can't be longer than 3 characters")
    .required("*State is required"),
  postCode: Yup.string()
    .min(4, "*Postal Code must have at least 4 characters")
    .max(10, "*Postal Code can't be longer than 10 characters")
    .required("*Postal Code is required"),
});

export const registerAccountDetailsSchema3 = Yup.object().shape({
  occupation: Yup.string()
    .min(2, "*First name must have at least 2 characters")
    .max(100, "*First name can't be longer than 100 characters")
    .required("*Occupation is required"),
  incomeRange: Yup.string().required("*Income Range is required"),
});

export const signInSchema = Yup.object().shape({
  username: Yup.string().required("*Email or phone number is required"),
  password: Yup.string().required("*Password is required"),
});

export const forgotPasswordSchema = Yup.object().shape({
  phone_number: Yup.string()
    .min(9, "*Mobile Number can't be shorter than 9 characters")
    .max(9, "*Mobile Number can't be longer than 9 characters")
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^[0-9]+$/,
      "Invalid phone number format"
    )
    .required("*Phone number is required"),
});

export const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Password must contain 8 or more characters with at least one uppercase, one lowercase, one number and one special character"
    )
    .required("*Password is required"),

  confirmPassword: Yup.string()
    .required("*Confirmation password is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export const changePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required("*Old password is required"),
  password: Yup.string()
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Password must contain 8 or more characters with at least one uppercase, one lowercase, one number and one special character"
    )
    .required("*New Password is required"),

  confirmPassword: Yup.string()
    .required("*Confirmation password is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export const requestCustomPlanSchema = Yup.object().shape({
  reasonToSave: Yup.string().required("*Reason to save is required"),
  moreInfo: Yup.string().required("*Tell us more is required"),
});

export const additionalInformationSchema = Yup.object().shape({
  reasonToSave: Yup.string().required("*Reason to save is required"),
  customPlanName: Yup.string(),
});

export const confirmEmailSchema = Yup.object().shape({
  email: Yup.string()
    .email("*Must be a valid email address")
    .max(100, "*Email must be less than 100 characters")
    .required("*Email is required"),
  confirmEmail: Yup.string()
    .email("*Must be a valid email address")
    .required("*Confirmation email is required")
    .oneOf([Yup.ref("email"), null], "Email does not match"),
});

export const personalDetailsSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "*First name must have at least 2 characters")
    .max(100, "*First name can't be longer than 100 characters")
    .required("*First name is required"),
  lastName: Yup.string()
    .min(2, "*Last name must have at least 2 characters")
    .max(100, "*Last name can't be longer than 100 characters")
    .required("*Last name is required"),
  birthDate: Yup.date().required("*Date of birth is required"),
  address: Yup.string()
    .min(2, "Address must have at least 2 characters")
    .max(255, "*Address can't be longer than 255 characters")
    .required("*Address is required"),
  suburb: Yup.string()
    .min(2, "*Suburb must have at least 2 characters")
    .max(50, "*Suburb can't be longer than 50 characters")
    .required("*Suburb is required"),
  state: Yup.string()
    .min(2, "*State must have at least 2 characters")
    .max(3, "*State can't be longer than 3 characters")
    .required("*State is required"),
  postCode: Yup.string()
    .min(4, "*Postal Code must have at least 4 characters")
    .max(10, "*Postal Code can't be longer than 10 characters")
    .required("*Postal Code is required"),
  occupation: Yup.string()
    .min(2, "*First name must have at least 2 characters")
    .max(100, "*First name can't be longer than 100 characters")
    .required("*Occupation is required"),
  incomeRange: Yup.string().required("*Income Range is required"),
});

export const confirmPasswordSchema = Yup.object().shape({
  password: Yup.string().required("*Password is required"),
});
