import React from "react";
// import { SafeAreaProvider } from "react-native-safe-area-context";
import { Amplify } from "aws-amplify";
import { Provider } from "react-redux";
// import { StatusBar } from "expo-status-bar";
import { ActionSheetProvider } from "@expo/react-native-action-sheet";
import { store } from "./app/store";
import Navigation from "./navigation";
import useCachedResources from "./hooks/useCachedResources";
import AxiosErrorHandler from "./api/errorHandler";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.EXPO_PUBLIC_AWS_COGNITO_IDENTITY_POOL_ID,
    region: process.env.EXPO_PUBLIC_AWS_COGNITO_REGION,
    userPoolId: process.env.EXPO_PUBLIC_AWS_USER_POOLS_ID,
    userPoolWebClientId: process.env.EXPO_PUBLIC_AWS_USER_POOLS_WEB_CLIENT_ID,
    mandatorySignIn: process.env.EXPO_PUBLIC_AWS_MANDATORY_SIGN_IN,
  },
  Storage: {
    AWSS3: {
      bucket: process.env.EXPO_PUBLIC_AWS_S3_BUCKET_NAME,
      region: process.env.EXPO_PUBLIC_AWS_REGION,
    },
  },
});

export default function App() {
  const isLoadingComplete = useCachedResources();

  if (!isLoadingComplete) {
    return null;
  }
  return (
    <Provider store={store}>
      <AxiosErrorHandler>
        <ActionSheetProvider>
          {/* <SafeAreaProvider> */}
          {/* <StatusBar style="auto" /> */}
          <Navigation />
          {/* </SafeAreaProvider> */}
        </ActionSheetProvider>
      </AxiosErrorHandler>
    </Provider>
  );
}
