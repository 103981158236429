import React, { FunctionComponent } from "react";
import styled from "styled-components/native";
import { colors } from "../colors";
import { StyleProp, TextStyle, ViewStyle } from "react-native";
import { BodyDefaultBold } from "../Texts/Typography";

const BadgeSection = styled.View`
  background-color: ${(props) =>
    props.status === "ACTIVE" || props.status === "VERIFIED"
      ? colors.tertiary2
      : colors.error};
  padding: 2px 10px 2px 10px;
  border-radius: 20px;
  gap: 4px;
`;

// types
interface BadgeProps {
  badgeStyle?: StyleProp<ViewStyle>;
  textStyles?: StyleProp<TextStyle>;
  children: React.ReactNode;
  status: string;
}

const StatusBadge: FunctionComponent<BadgeProps> = (props) => {
  return (
    <BadgeSection style={props.badgeStyle} status={props.status}>
      <BodyDefaultBold
        style={
          (props.textStyles,
          {
            color:
              props.status === "ACTIVE" || props.status === "VERIFIED"
                ? colors.correct
                : colors.neutral0,
          })
        }
      >
        {props.children}
      </BodyDefaultBold>
    </BadgeSection>
  );
};

export default StatusBadge;
