import React, { FunctionComponent } from "react";
import styled, { css } from "styled-components/native";
import { colors } from "../colors";
import {
  GestureResponderEvent,
  StyleProp,
  TextStyle,
  ViewStyle,
} from "react-native";
import { BodyDefaultBold } from "../Texts/Typography";

const ButtonView = styled.TouchableOpacity<{ buttonBox?: boolean }>`
  background-color: ${(props) =>
    props.secondary ? colors.neutral0 : colors.primary100};
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 15px 70px 15px 70px;
  border-radius: 70px;

  ${(props) =>
    props.buttonBox &&
    css`
      padding: 20px 0;
      border-radius: 0;
    `}
`;

// types
interface ButtonProps {
  btnStyles?: StyleProp<ViewStyle>;
  onPress: ((event: GestureResponderEvent) => void) | undefined;
  textStyles?: StyleProp<TextStyle>;
  children: React.ReactNode;
  buttonBox?: boolean;
  disabled?: boolean;
  secondary?: boolean;
}

const SubmitButton: FunctionComponent<ButtonProps> = (props) => {
  return (
    <ButtonView
      onPress={props.onPress}
      style={props.btnStyles}
      buttonBox={props.buttonBox}
      disabled={props.disabled}
      secondary={props.secondary}
    >
      <BodyDefaultBold
        style={[
          props.textStyles,
          props.secondary
            ? { color: colors.primary100 }
            : { color: colors.neutral0 },
          { textAlign: "center" },
        ]}
      >
        {props.children}
      </BodyDefaultBold>
    </ButtonView>
  );
};

export default SubmitButton;
