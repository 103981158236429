import React, { Fragment } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { MaterialIcons } from "@expo/vector-icons";
import * as MailComposer from "expo-mail-composer";
import { colors } from "../../../../../components/colors";
import { RootStackScreenProps } from "../../../../../navigation/types";
import {
  Row,
  Col,
  Container,
  ScrollContainer,
} from "../../../../../components/shared";
import PageTemplateScreen from "../../../../../components/Templates/PageTemplate";
import {
  BodyDefaultBold,
  BodySmall,
} from "../../../../../components/Texts/Typography";
import ProfileMenuIcon from "../../../../../components/Icons/ProfileMenuIcon";
import CallIcon from "../../../../../assets/icons/call-icon.svg";
import ChatIcon from "../../../../../assets/icons/chat-icon.svg";

const TalkToOurTeamContainer = styled(Container)`
  background-color: ${colors.neutral0};
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

const ContentSection = styled.View`
  width: 100%;
  height: 100%;
  gap: 24px;
  padding: 0px 16px;
`;

const BodySmallSubHeaderText = styled(BodySmall)`
  color: ${colors.neutral80};
`;

const Divider = styled.View`
  border: 1px solid ${colors.neutral20};
  margin-top: -12px;
`;

const MenuItemButton = styled.TouchableOpacity`
  flex-direction: row;
  justify-content: space-between;
`;

const MenuItemRow = styled(Row)`
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const MenuItemArrow = styled(Col)`
  justify-content: center;
`;

const BodySmallText = styled(BodySmall)`
  color: ${colors.neutral60};
`;

const BodyDefaultBoldText = styled(BodyDefaultBold)`
  color: ${colors.neutral60};
`;

const TalkToOurTeamScreen = ({
  navigation,
}: RootStackScreenProps<"TalkToOurTeam">) => {
  const sendEmail = () => {
    MailComposer.composeAsync({
      recipients: ["hello@chippit.com.au"],
      subject: "",
      body: "",
    }).then((result) => {
      if (result.status === MailComposer.MailComposerStatus.SENT) {
        return navigation.navigate("RequestSubmitted", {
          buttonTitle: "Go back to homepage",
          buttonNavigate: () => {
            navigation.navigate("HomeTab");
          },
        });
      }
    });
  };
  return (
    <Fragment>
      <TalkToOurTeamContainer>
        <PageTemplateScreen header="Talk to our team" hideSubmitButton={true}>
          <ScrollContainer
            automaticallyAdjustKeyboardInsets={true}
            showsVerticalScrollIndicator={false}
          >
            <View onStartShouldSetResponder={() => true}>
              <ContentSection>
                <BodySmallSubHeaderText>
                  Your preferred option
                </BodySmallSubHeaderText>
                <Divider />
                <MenuItemButton
                  onPress={() => {
                    sendEmail();
                  }}
                >
                  <Col>
                    <MenuItemRow>
                      <ProfileMenuIcon name="mail-outline" />
                      <Col>
                        <BodyDefaultBold>Email us</BodyDefaultBold>
                        <BodySmallText>
                          We’ll aim to respond in 2 days
                        </BodySmallText>
                      </Col>
                    </MenuItemRow>
                  </Col>
                  <MenuItemArrow>
                    <MaterialIcons name="arrow-forward-ios" size={16} />
                  </MenuItemArrow>
                </MenuItemButton>
                <MenuItemButton onPress={() => {}}>
                  <Col>
                    <MenuItemRow>
                      <ProfileMenuIcon isSvg={true}>
                        <CallIcon />
                      </ProfileMenuIcon>
                      <Col>
                        <BodyDefaultBold>Call us</BodyDefaultBold>
                        <BodySmallText>
                          Monday - Friday, from 9:00 to 15:00 (AET)
                        </BodySmallText>
                      </Col>
                    </MenuItemRow>
                  </Col>
                  <MenuItemArrow>
                    <MaterialIcons name="arrow-forward-ios" size={16} />
                  </MenuItemArrow>
                </MenuItemButton>
                <MenuItemButton onPress={() => {}} disabled>
                  <Col>
                    <MenuItemRow>
                      <ProfileMenuIcon isSvg={true}>
                        <ChatIcon />
                      </ProfileMenuIcon>
                      <Col>
                        <BodyDefaultBoldText>Chat wit us</BodyDefaultBoldText>
                        <BodySmallText>Unavailable</BodySmallText>
                      </Col>
                    </MenuItemRow>
                  </Col>
                  <MenuItemArrow>
                    <MaterialIcons name="arrow-forward-ios" size={16} />
                  </MenuItemArrow>
                </MenuItemButton>
              </ContentSection>
            </View>
          </ScrollContainer>
        </PageTemplateScreen>
      </TalkToOurTeamContainer>
    </Fragment>
  );
};

export default TalkToOurTeamScreen;
