import React from "react";
import { useNavigation } from "@react-navigation/native";
import styled from "styled-components/native";
import { CardListProps } from "../../interfaces/plans";
import PlanCardItem from "../Card/PlanCardItem";
import DashButton from "../Buttons/DashButton";

const CardContainer = styled.View`
  flex-direction: row;
`;

const CardList = styled.FlatList`
  width: 100%;
`;

const PlanCardList: React.FC<CardListProps> = (props) => {
  const navigation = useNavigation();

  return (
    <CardContainer>
      <CardList
        data={[...props.data, { id: 0, addButton: true }]}
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{ paddingHorizontal: 16 }}
        keyExtractor={({ id }: unknown) => id?.toString()}
        renderItem={({ item, index }: unknown) => {
          if (item.addButton) {
            if (props.data.length >= 5) {
              return null;
            } else {
              return (
                <DashButton
                  onPress={() => {
                    navigation.navigate("PlansTab", { screen: "Plans" });
                  }}
                  name="add"
                />
              );
            }
          }
          return (
            <PlanCardItem
              {...item}
              index={index}
              navigation={props.navigation}
            />
          );
        }}
      />
    </CardContainer>
  );
};

export default PlanCardList;
