import {
  getClosingDate,
  getOtherFeatures,
  getStartDate,
} from "../../utils/planEnums";
import backendAPI from "../../api/client";
import endpoints from "../../api/endpoints";
import { Auth } from "aws-amplify";
import {
  nominateAccountParams,
  generateConsentURLParams,
  startPlanParams,
  RecommendedPlanItemProps,
  requestCustomPlanParams,
} from "../../interfaces/plans";
import { compareAsc, parse } from "date-fns";

const planList = async (): Promise<RecommendedPlanItemProps[] | []> => {
  try {
    const result = [
      {
        month: "This month",
        plans: [
          {
            id: 1,
            planMonthId: 1,
            value: 100,
            length: 10,
            closingAt: getClosingDate(),
            totalPayout: 1000,
            installmentCount: 2,
            installmentValue: 500,
            startDate: getStartDate(),
            members: 5,
            otherFeatures: getOtherFeatures(500),
          },
          {
            id: 2,
            planMonthId: 2,
            value: 200,
            length: 10,
            closingAt: getClosingDate(),
            totalPayout: 2000,
            installmentCount: 2,
            installmentValue: 1000,
            startDate: getStartDate(),
            members: 5,
            otherFeatures: getOtherFeatures(1000),
          },
          {
            id: 3,
            planMonthId: 3,
            value: 300,
            length: 10,
            closingAt: getClosingDate(),
            totalPayout: 3000,
            installmentCount: 2,
            installmentValue: 1500,
            startDate: getStartDate(),
            members: 5,
            otherFeatures: getOtherFeatures(1500),
          },
          {
            id: 4,
            planMonthId: 4,
            value: 400,
            length: 10,
            closingAt: getClosingDate(),
            totalPayout: 4000,
            installmentCount: 2,
            installmentValue: 2000,
            startDate: getStartDate(),
            members: 5,
            otherFeatures: getOtherFeatures(2000),
          },
          {
            id: 5,
            planMonthId: 5,
            value: 500,
            length: 10,
            closingAt: getClosingDate(),
            totalPayout: 5000,
            installmentCount: 2,
            installmentValue: 2500,
            startDate: getStartDate(),
            members: 5,
            otherFeatures: getOtherFeatures(2500),
          },
          {
            id: 6,
            planMonthId: 6,
            value: 750,
            length: 10,
            closingAt: getClosingDate(),
            totalPayout: 7500,
            installmentCount: 2,
            installmentValue: 3750,
            startDate: getStartDate(),
            members: 5,
            otherFeatures: getOtherFeatures(3750),
          },
          {
            id: 7,
            planMonthId: 7,
            value: 1000,
            length: 10,
            closingAt: getClosingDate(),
            totalPayout: 10000,
            installmentCount: 2,
            installmentValue: 5000,
            startDate: getStartDate(),
            members: 5,
            otherFeatures: getOtherFeatures(5000),
          },
        ],
      },
      {
        month: "Next month",
        plans: [
          {
            id: 8,
            planMonthId: 1,
            value: 100,
            length: 10,
            closingAt: getClosingDate(true),
            totalPayout: 1000,
            installmentCount: 2,
            installmentValue: 500,
            startDate: getStartDate(true),
            members: 5,
            otherFeatures: getOtherFeatures(500),
          },
          {
            id: 9,
            planMonthId: 2,
            value: 200,
            length: 10,
            closingAt: getClosingDate(true),
            totalPayout: 2000,
            installmentCount: 2,
            installmentValue: 1000,
            startDate: getStartDate(true),
            members: 5,
            otherFeatures: getOtherFeatures(1000),
          },
          {
            id: 10,
            planMonthId: 3,
            value: 300,
            length: 10,
            closingAt: getClosingDate(true),
            totalPayout: 3000,
            installmentCount: 2,
            installmentValue: 1500,
            startDate: getStartDate(true),
            members: 5,
            otherFeatures: getOtherFeatures(1500),
          },
          {
            id: 11,
            planMonthId: 4,
            value: 400,
            length: 10,
            closingAt: getClosingDate(true),
            totalPayout: 4000,
            installmentCount: 2,
            installmentValue: 2000,
            startDate: getStartDate(true),
            members: 5,
            otherFeatures: getOtherFeatures(2000),
          },
          {
            id: 12,
            planMonthId: 5,
            value: 500,
            length: 10,
            closingAt: getClosingDate(true),
            totalPayout: 5000,
            installmentCount: 2,
            installmentValue: 2500,
            startDate: getStartDate(true),
            members: 5,
            otherFeatures: getOtherFeatures(2500),
          },
          {
            id: 13,
            planMonthId: 6,
            value: 750,
            length: 10,
            closingAt: getClosingDate(true),
            totalPayout: 7500,
            installmentCount: 2,
            installmentValue: 3750,
            startDate: getStartDate(true),
            members: 5,
            otherFeatures: getOtherFeatures(3750),
          },
          {
            id: 14,
            planMonthId: 7,
            value: 1000,
            length: 10,
            closingAt: getClosingDate(true),
            totalPayout: 10000,
            installmentCount: 2,
            installmentValue: 5000,
            startDate: getStartDate(true),
            members: 5,
            otherFeatures: getOtherFeatures(5000),
          },
        ],
      },
    ];
    const currentDate = new Date();

    const closingAt = parse(
      result[0].plans[0].closingAt,
      "dd/MM/yyyy",
      new Date()
    );
    const comparison = compareAsc(currentDate, closingAt);
    if (comparison === 1) {
      result[0].plans = [];
    }
    return result;
  } catch (error) {
    return error;
  }
};

const generateConsentURL = async (params: generateConsentURLParams) => {
  const user = await Auth.currentAuthenticatedUser();
  if (!user) {
    throw new Error("User not logged in");
  }
  const result = await backendAPI.post(
    endpoints.generateConsentURL.replace(":userId", user.attributes.sub),
    params
  );
  return result;
};

const getAccounts = async () => {
  const user = await Auth.currentAuthenticatedUser();
  if (!user) {
    throw new Error("User not logged in");
  }
  const result = await backendAPI.get(
    endpoints.getUserAccounts.replace(":userId", user.attributes.sub)
  );
  return result;
};

const submitConsent = async () => {
  const user = await Auth.currentAuthenticatedUser();
  if (!user) {
    throw new Error("User not logged in");
  }
  const result = await backendAPI.post(
    endpoints.submitConsent.replace(":userId", user.attributes.sub)
  );
  return result;
};

const nominateAccounts = async (params: nominateAccountParams) => {
  const user = await Auth.currentAuthenticatedUser();
  if (!user) {
    throw new Error("User not logged in");
  }
  const result = await backendAPI.post(
    endpoints.nominateAccount.replace(":userId", user.attributes.sub),
    params
  );
  return result;
};

const startPlan = async (params: startPlanParams) => {
  const user = await Auth.currentAuthenticatedUser();
  if (!user) {
    throw new Error("User not logged in");
  }
  const result = await backendAPI.post(
    endpoints.startPlan.replace(":userId", user.attributes.sub),
    params
  );
  return result;
};

const myActivitiesList = async (params: string) => {
  const user = await Auth.currentAuthenticatedUser();

  if (!user) {
    throw new Error("User not logged in");
  }

  try {
    const result = await backendAPI.get(
      endpoints.getMyActivities
        .replace(":userId", user.attributes.sub)
        .replace(":planId", params)
    );
    return result;
  } catch (error) {
    throw new Error(error);
  }
};

const getPlanList = async () => {
  const user = await Auth.currentAuthenticatedUser();

  if (!user) {
    throw new Error("User not logged in");
  }

  const result = await backendAPI.get(
    endpoints.getPlanList.replace(":userId", user.attributes.sub)
  );
  return result;
};

const getAbridgedRecommendedPlans = async () => {
  const user = await Auth.currentAuthenticatedUser();

  if (!user) {
    throw new Error("User not logged in");
  }

  const result = await backendAPI.get(
    endpoints.getAbridgedRecommendedPlans.replace(
      ":userId",
      user.attributes.sub
    )
  );

  return result;
};

const requestCustomPlan = async (params: requestCustomPlanParams) => {
  const user = await Auth.currentAuthenticatedUser();
  if (!user) {
    throw new Error("User not logged in");
  }
  const result = await backendAPI.post(
    endpoints.requestCustomPlan.replace(":userId", user.attributes.sub),
    params
  );
  return result;
};

const getUserDetails = async (param: string) => {
  const result = await backendAPI.get(
    endpoints.getUserDetails.replace(":userId", param)
  );
  return result;
};

const api = {
  planList,
  getAccounts,
  generateConsentURL,
  submitConsent,
  myActivitiesList,
  nominateAccounts,
  startPlan,
  getPlanList,
  getAbridgedRecommendedPlans,
  requestCustomPlan,
  getUserDetails,
};

export default api;
