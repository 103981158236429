import * as SecureStore from "expo-secure-store";
import * as Application from "expo-application";
import { Platform } from "react-native";
import * as Device from "expo-device";
import * as Notifications from "expo-notifications";
import Constants from "expo-constants";
import { userPreferenceParam } from "../interfaces/auth";

export const getDevicePreferences = async () => {
  try {
    let token = undefined;
    const { status } = await Notifications.getPermissionsAsync();
    const pushTokenStatus =
      status === "denied" || status === "granted" ? status : "unset";
    if (
      Device.isDevice &&
      Constants.expoConfig?.extra?.eas.projectId &&
      pushTokenStatus === "granted"
    ) {
      token = await Notifications.getExpoPushTokenAsync({
        projectId: Constants.expoConfig.extra.eas.projectId,
      });
    }
    if (
      Constants.expoConfig?.extra?.eas.projectId &&
      (Platform.OS === "android" || Platform.OS === "ios")
    ) {
      if (Platform.OS === "android") {
        let deviceId = await SecureStore.getItemAsync("deviceId");
        if (!deviceId) {
          deviceId = Application.androidId;
          if (!deviceId) {
            throw new Error("Error in getting user device id for android");
          }
          await SecureStore.setItemAsync("deviceId", deviceId);
        }
        const userPreference = {
          deviceId,
          deviceType: "android",
          deviceName: Device.modelName,
          pushTokenStatus,
          ...(token && { expoPushToken: token.data }),
          isPushTokenActive: token ? true : false,
        };
        return userPreference;
      } else {
        let deviceId = await SecureStore.getItemAsync("deviceId");
        if (!deviceId) {
          deviceId = await Application.getIosIdForVendorAsync();
          if (!deviceId || deviceId === "nil") {
            throw new Error("Error in getting user device id for ios");
          }
          await SecureStore.setItemAsync("deviceId", deviceId);
        }
        const userPreference = {
          deviceId,
          deviceType: "ios",
          deviceName: Device.modelName,
          pushTokenStatus,
          ...(token && { expoPushToken: token.data }),
          isPushTokenActive: token ? true : false,
        };
        return userPreference;
      }
    } else {
      return null;
    }
  } catch (error) {
    console.log("error", error);
    throw new Error("Error in getting user preferences");
  }
};

export const getDeviceId = async () => {
  try {
    if (Platform.OS === "android" || Platform.OS === "ios") {
      if (Platform.OS === "android") {
        let deviceId = await SecureStore.getItemAsync("deviceId");
        if (!deviceId) {
          deviceId = Application.androidId;
          if (!deviceId) {
            throw new Error("Error in getting user device id for android");
          }
          await SecureStore.setItemAsync("deviceId", deviceId);
        }
        return deviceId;
      } else {
        let deviceId = await SecureStore.getItemAsync("deviceId");
        if (!deviceId) {
          deviceId = await Application.getIosIdForVendorAsync();
          if (!deviceId || deviceId === "nil") {
            throw new Error("Error in getting user device id for ios");
          }
          await SecureStore.setItemAsync("deviceId", deviceId);
        }
        return deviceId;
      }
    }
    return null;
  } catch (error) {
    throw new Error("Error in getting user preferences");
  }
};

export const getDevicePreferenceByKey = async (
  userPreferences: userPreferenceParam[],
  key: string
): Promise<boolean | null> => {
  try {
    if (Platform.OS === "android" || Platform.OS === "ios") {
      const deviceId = await getDeviceId();
      if (!deviceId || !userPreferences) {
        throw new Error("Error in getting user biometrics preferences");
      }
      const devicePreference = userPreferences.find(
        (pref: userPreferenceParam) => pref.deviceId === deviceId
      );
      return devicePreference[key];
    }
    return null;
  } catch (error) {
    throw new Error("Error in getting user biometrics preferences");
  }
};
