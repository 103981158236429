import React from "react";
import styled from "styled-components/native";
import QuestionItem from "../Card/QuestionItem";
import {
  FaqQuestionItemProps,
  FaqQuestionProps,
} from "../../interfaces/profile";

const ListContainer = styled.View`
  width: 100%;
  gap: 24px;
`;

const QuestionList: React.FC<FaqQuestionProps> = (props) => {
  return (
    <ListContainer>
      {props?.list.map((item: FaqQuestionItemProps, index: number) => {
        return <QuestionItem {...item} key={`FaqQuestionItem-${index}`} />;
      })}
    </ListContainer>
  );
};

export default QuestionList;
